import React from "react";
import "./BillingPlansCardNew.scss";

//svg icons
import BillingButton from "components/BillingButton/BillingButton";

import freeImage from "../../assets/icons/free-image.png";
import basicImage from "../../assets/icons/basic-image.png";
import premiumImage from "../../assets/icons/premium-image.png";
import platinumImage from "../../assets/icons/platinum-image.png";
import skeepxImage from "../../assets/icons/skeepx-image.png";
import skeepxCurrentImage from "../../assets/icons/skeepx-image.png";

import freeDisabledImage from "../../assets/icons/free-image-disabled.png";
import basicDisabledImage from "../../assets/icons/basic-image-disabled.png";
import premiumDisabledImage from "../../assets/icons/premium-image-disabled.png";
import platinumDisabledImage from "../../assets/icons/platinum-image-disabled.png";

export default function BillingPlansCardNew({
  info,
  selected,
  handleSelectedPlan,
  currentPlan,
  disabledButton,
  disabledPlan,
  billing,
  style = {},
  miniCardSubtitle,
  type = "FULL-CARD"
}) {
  const getSelectedPlan = (plan) => {
    handleSelectedPlan(plan);
  };

  const free_image =
    info.name === "Free" || (info.name === "Free" && currentPlan);
  const free_image_disabled = info.name === "Free" && disabledPlan;
  const basic_image =
    info.name === "Basic" || (info.name === "Basic" && currentPlan);
  const basic_image_disabled = info.name === "Basic" && disabledPlan;
  const premium_image =
    info.name === "Growth" || (info.name === "Growth" && currentPlan);
  const premium_image_disabled = info.name === "Premium" && disabledPlan;
  const platinum_image =
    info.name === "Platinum" || (info.name === "Platinum" && currentPlan);
  const platinum_image_disabled = info.name === "Platinum" && disabledPlan;
  const skeepx_image = info.name === "SkeepX";
  const skeepx_image_current =
    (info.name === "SkeepX" && currentPlan) ||
    (info.name === "SkeepX" &&
      billing?.skeepXPending !== null &&
      billing?.skeepXPending);

  return (
    <div
      className={`
        ${disabledPlan
          ? "app-container-disabled"
          : (currentPlan && !info.show_pricing) ||
            (!info.show_pricing &&
              billing?.skeepXPending !== null &&
              billing?.skeepXPending)
          ? "app-container-skeepx"
          : currentPlan
          ? "app-container"
          : selected && info.show_pricing
          ? "app-container-selected"
          : "app-container-current"}
          
          ${type === "MINI-CARD" ? "pricing-mini-card" : ""}
      `}
    style={style?.card}
    >
      <img
        src={
          free_image_disabled
            ? freeDisabledImage
            : free_image
            ? freeImage
            : basic_image_disabled
            ? basicDisabledImage
            : basic_image
            ? basicImage
            : premium_image_disabled
            ? premiumDisabledImage
            : premium_image
            ? premiumImage
            : platinum_image_disabled
            ? platinumDisabledImage
            : platinum_image
            ? platinumImage
            : skeepx_image_current
            ? skeepxCurrentImage
            : skeepxImage
        }
        className={`app-image-${info.name}`}
      />
      <p
        className={
          disabledPlan
            ? "app-header-disabled"
            : info.name === "SkeepX"
            ? "app-header-skeepx"
            : "app-header"
        }
      >
        {info.name}
      </p>
      {info.show_pricing ? (
        <p className={disabledPlan ? "app-pricing-disabled" : "app-pricing"}>
          <b
            className={
              disabledPlan
                ? "app-pricing-big-disabled"
                : "app-pricing app-pricing-big"
            }
          >
            ${info.pricing}
          </b>{" "}
          / per month
        </p>
      ) : (
        <p className={disabledPlan ? "app-pricing-disabled" : "app-no-pricing"}>
          {billing?.hasCustomPlan ? "Select" : "Contact Sales" }
        </p>
      )}
      {type !== "MINI-CARD" &&
      <div className="app-limits-container">
        {info.limits.map((limit) => {
          return <p className="app-limits" key={limit}>{limit}</p>;
        })}
      </div>}
      {miniCardSubtitle && <p style={{fontSize: 14, color: "grey",textAlign:"start"}}>{miniCardSubtitle}</p>}
      <BillingButton
        disabledButton={disabledButton}
        disabledPlan={disabledPlan}
        getSelectedPlan={getSelectedPlan}
        selected={selected}
        name={info.name}
        style={style?.button}
        currentPlan={!currentPlan}
        has_pricing={info.show_pricing}
        text={
          info.name === "SkeepX" ? billing?.hasCustomPlan ? "Select" : "In review"
            : currentPlan
            ? "Current Plan"
            : selected && info.show_pricing
            ? "Selected"
            : info.show_pricing
            ? "Select"
            : billing?.hasCustomPlan ? "Select" : "Contact us"
        }
      />
    </div>
  );
}
