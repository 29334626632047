import CustomTextArea from "components/Forms/CustomTextArea";
import {useState} from "react";
import {ReactComponent as Arrow} from "../../../../../assets/icons/arrow.svg";
import useEditor from "../../../../../stores/use-editor";
import {useEditorEvents} from "../../../../../stores/use-editor-events";
import "../TagsSelection/TagsSelection.scss";
import SwiperSVG from "../../../../../assets/icons/SwiperSVG";
import {Pane} from "evergreen-ui";

const SwiperText = () => {
  const { experience, setSwiperShowIntroScreen, swiperShowIntroScreen } = useEditor();
  const { addEvents } = useEditorEvents();
  const [swiper, setSwiper] = useState(false);
  const swiperDesign = experience?.design?.swiper || {};
  const design = experience?.design || {};

  return (
    <div className="selection-tags">
      <div className="title" onClick={() => setSwiper(!swiper)}>
        <SwiperSVG  />
        Swiper Experience
        <Arrow
          style={swiper ? { transform: "rotate(180deg)" } : {}}
          className="arrow"
        />
      </div>
      {swiper && (
        <div className="data">
          <div className="data-holders">
            Title
            {/* @ts-ignore */}
            <CustomTextArea
              placeHolder={swiperDesign?.header}
              value={swiperDesign?.header}
              charLimit={60}
              onChange={(e) =>
                addEvents("design", "update", {
                  "swiper/header": e.target.value,
                })
              }
            />
          </div>
          <div className="data-holders">
            Tip
            {/* @ts-ignore */}
            <CustomTextArea
              placeHolder={swiperDesign?.hintText}
              value={swiperDesign?.hintText}
              charLimit={60}
              onChange={(e) =>
                addEvents("design", "update", {
                  "swiper/hintText": e.target.value,
                })
              }
            />
          </div>
          <div className="data-holders">
            <Pane display="flex" alignItems="center" justifyContent="space-between">
              <div>
                Thumbs Down Text
                {/* @ts-ignore */}
                <CustomTextArea
                    placeHolder={swiperDesign?.thumbsDownText}
                    value={swiperDesign?.thumbsDownText}
                    charLimit={14}
                    onChange={(e) =>
                        addEvents("design", "update", {
                          "swiper/thumbsDownText": e.target.value,
                        })
                    }
                />
              </div>
              <div>
                Thumbs Up Text
                {/* @ts-ignore */}
                <CustomTextArea
                    placeHolder={swiperDesign?.thumbsUpText}
                    value={swiperDesign?.thumbsUpText}
                    charLimit={14}
                    onChange={(e) =>
                        addEvents("design", "update", {
                          "swiper/thumbsUpText": e.target.value,
                        })
                    }
                />
              </div>

            </Pane>

          </div>
        </div>
      )}
    </div>
  )
};

export default SwiperText;
