import { Client } from "Client";
import { IoClose } from "react-icons/io5";
import { Pane, TextInput, Tooltip } from "evergreen-ui";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search-icon-new.svg";
import { useEffect, useRef, useState } from "react";
import { useEditorEvents } from "../../../../../stores/use-editor-events";
import useCustomTags from "../../../../../stores/use-custom-tags";
import useEditor from "../../../../../stores/use-editor";
import { SelectedItem } from "../../../../ExperiencesPage/CreateDialog/components/SelectedItem";
import { ReactComponent as AllProductsIcon } from "../../../../../assets/images/all-product-selected.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/new-gray-icon.svg";
import { useClickAway, useDebounce } from "react-use";

const AddProductsWizard = ({
  ans,
  products,
  idx,
  setShow,
  show,
  selectedProducts,
  setSelectedProducts,
  disabled,
  showTabs = true,
}) => {
  const { experience, activeQuestionIndex, questions } = useEditor();
  const { addEvents } = useEditorEvents();
  const { addTag, tags } = useCustomTags();

  const [allProducts, setAllProducts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchTagInput, setSearchTagInput] = useState("");
  const [selected, setSelected] = useState("products");
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectAllProducts, setSelectAllProducts] = useState(false);
  const customTag = tags?.[ans?.tagIDs?.[0]];
  const [tagProducts, setTagProducts] = useState([]);
  const ref = useRef();

  useEffect(() => {
    setSelectedProducts([...(customTag ? customTag?.products : products)]);
  }, [products, customTag, show]);

  const onSearchProducts = (string) => {
    // If search is empty -> show all products
    setSearchInput(string);
    setSelectAllProducts(false);
  };

  const onCloseTags = () => {
    // If search tag is empty -> show all products
    setSearchTagInput("");
    setSelectAllProducts(false);
    setSelectedTags([]);
  };

  useEffect(() => {
    Client.get(`/emp/experiences/${experience?.id}/products`).then((res) => {
      setAllProducts(res.data);
    });
  }, []);

  const addOrRemoveSelectedProduct = (productOBJ) => {
    const index = selectedProducts.findIndex((p) => p.id === productOBJ.id);
    index !== -1
      ? setSelectAllProducts(false)
      : finalProductUIAfterFilter?.length === 1 && setSelectAllProducts(true);
    const selProducts =
      index !== -1
        ? [
            ...selectedProducts.slice(0, index),
            ...selectedProducts.slice(index + 1),
          ]
        : [...selectedProducts, productOBJ];
    setSelectedProducts(selProducts);
    const newTag = addTag({
      originalTagId: ans.tagIDs[0],
      ...(tags[ans.tagIDs[0]] ?? {}),
      name: ans.answer,
      products: [...selProducts],
    });
    // addEvents("question", "update", {
    //   quesIdx: activeQuestionIndex,
    //   questionId: questions[activeQuestionIndex].id,
    //   answers: [
    //     ...questions[activeQuestionIndex].answers.slice(0, idx),
    //     {
    //       ...questions[activeQuestionIndex].answers[idx],
    //       tagIDs: [newTag.id],
    //     },
    //     ...questions[activeQuestionIndex].answers.slice(idx + 1),
    //   ],
    // });
  };

  const handleSearch = (value, clear = true) => {
    const index = selectedTags?.findIndex((el) => el.id === value.id);

    if (index === -1) {
      setSelectedTags((prev) => [...prev, value]);
      Client.get(
        `emp/tags/products?tagID=${value?.id}&experienceID=${experience?.id}`
      ).then((res) => {
        setTagProducts((prev) => [...prev, ...res.data]);
      });
    } else {
      setSelectedTags(selectedTags?.filter((el, idx) => idx !== index));
      Client.get(
        `emp/tags/products?tagID=${value?.id}&experienceID=${experience?.id}`
      ).then((res) => {
        setTagProducts((p) => {
          res?.data?.forEach((el, idx) => {
            p = p?.filter((elm) => el.id !== elm.id);
          });
          return p;
        });
      });
    }

    clear && setSearchInput("");
  };

  const selectAll = () => {
    let selProducts = [];
    if (!selectAllProducts && selectedTags.length === 0) {
      selProducts = [...finalProductUIAfterFilter, ...selectedProducts];
    } else if (!selectAllProducts && selectedTags.length > 0) {
      selProducts = [...tagProducts, ...selectedProducts];
    }
    setSelectedProducts(selProducts);
    // const newTag = addTag({
    //   originalTagId: ans.tagIDs[0],
    //   ...(tags[ans.tagIDs[0]] ?? {}),
    //   name: ans.answer,
    //   products: [...selProducts],
    // });
    // addEvents("question", "update", {
    //   quesIdx: activeQuestionIndex,
    //   questionId: questions[activeQuestionIndex].id,
    //   answers: [
    //     ...questions[activeQuestionIndex].answers.slice(0, idx),
    //     {
    //       ...questions[activeQuestionIndex].answers[idx],
    //       tagIDs: [newTag.id],
    //     },
    //     ...questions[activeQuestionIndex].answers.slice(idx + 1),
    //   ],
    // });
    setSelectAllProducts(!selectAllProducts);
  };

  const allProdutsTagsId = allProducts
    .map((pr) => pr.tags.map((item) => item.tagID))
    .flat(1);

  const productsUI = [
    // ...(allProducts || []),
    ...(allProducts?.filter(
      (p) => !selectedProducts?.map((p) => p.id)?.includes(p.id)
    ) || []),
  ];

  const finalProductUIAfterFilter = productsUI?.filter((p) =>
    p?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())
  );

  const onClickTab = (e) => {
    const { name } = e.target;
    setSelected(name);
    setSearchInput("");
    setSearchTagInput("");
  };

  useDebounce(
    () => {
      if (selected === "tags" && searchTagInput.length >= 1) {
        // Client.get(`emp/tags/products?tagID=${1213}&experienceID=${experience?.id}}`)
        Client.get(`emp/tags?query=${searchTagInput}`).then((r) => {
          setTagList(r.data);
        });
      } else {
        setTagList([]);
      }
    },
    200,
    [searchTagInput]
  );

  useClickAway(ref, () => {
    // setSearchTagInput("");
    setTagList([]);
  });

  const queryRegex = new RegExp(
    searchTagInput.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
    "gi"
  );

  const handleQuery = (name) => {
    const idx = name.toLowerCase().indexOf(searchTagInput.toLowerCase());
    if (idx !== -1) {
      let newItem = (
        <p className="product-search-tag-list-api-name">
          {name.substring(0, idx)}
          <span style={{ color: "#017CF8" }}>
            {name.substr(idx, searchTagInput.length)}
          </span>
          {name.substring(idx + searchTagInput.length)}
        </p>
      );
      return newItem;
    }
  };

  return (
    <div
      style={{
        filter: disabled ? "grayscale(80%)" : "",
        opacity: disabled ? "0.5" : "",
        pointerEvents: disabled ? "none" : "",
      }}
    >
      {showTabs && (
        <div className="connected-tabs-group">
          <button
            name="products"
            onClick={onClickTab}
            className={
              selected === "products" ? "connected-tabs-group-active" : ""
            }
          >
            Products
          </button>

          <button
            name="tags"
            onClick={onClickTab}
            className={selected === "tags" ? "connected-tabs-group-active" : ""}
          >
            Tags
            <Tooltip
              statelessProps={{
                height: "61px",
                width: "200px",
                marginTop: "10px",
                fontSize: "10px",
                backgroundColor: "#E4F1FF",
                borderRadius: "8px",
              }}
              content={
                <span
                  style={{
                    fontSize: "10px",
                    color: "#75787E",
                    fontFamily: "Satoshi",
                    fontWeight: "400",
                  }}
                >
                  Find products by tag name <br />
                  e.g search for ‘Blue’. the products that have blue in their
                  tags list, will appear
                </span>
              }
            >
              <InfoIcon
                className="info-icon"
                style={{ width: "10px", height: "10px", marginLeft: "4px" }}
              />
            </Tooltip>
          </button>
        </div>
      )}

      <div style={{ position: "relative", margin: "27px 0 0px" }}>
        <SearchIcon
          style={{
            width: "18px",
            height: "16px",
            position: "absolute",
            top: 12,
            left: 8,
            stroke: "#75787E",
          }}
        />
        {(searchInput?.length > 1 || searchTagInput?.length >= 1) && (
          <IoClose
            size={16}
            style={{
              position: "absolute",
              top: 12,
              left: 220,
              fill: "#96989b",
              cursor: "pointer",
            }}
            onClick={() => {
              selected === "products" ? onSearchProducts("") : onCloseTags();
            }}
          />
        )}
        <TextInput
          className="added-product-input-box"
          height={40}
          paddingLeft={32}
          placeholder="Search"
          value={selected === "products" ? searchInput : searchTagInput}
          onChange={(e) => {
            if (selected === "products") {
              onSearchProducts(e.target.value);
            } else {
              setSearchTagInput(e.target.value);
            }
          }}
          size="large"
          onKeyDown={(e) => {
            e.key === "Enter" && handleSearch(searchInput);
          }}
          width={257}
          borderRadius={8}
        ></TextInput>
      </div>
      {selected === "tags" ? (
        <>
          {tagList.length > 0 && (
            <div
              className="product-search-tag-list-api-container"
              style={{
                zIndex: 11,
              }}
              ref={ref}
            >
              {tagList
                ?.filter((item) => allProdutsTagsId?.includes(item.id))
                .sort((a, b) => {
                  if (a.name.match(queryRegex) && b.name.match(queryRegex)) {
                    return a.name.length - b.name.length;
                  } else if (a.name.match(queryRegex)) {
                    return -1;
                  } else if (b.name.match(queryRegex)) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((item) => {
                  let has = false;
                  if (typeof item === "string")
                    has = selectedTags?.find((s) => s === item);
                  else if (typeof item === "object")
                    has = selectedTags?.find((s) => s.id === item.id);
                  return (
                    <div className="product-search-tag-list-api-box">
                      <div
                        className="products-checkbox-container"
                        style={{
                          marginTop: "3px",
                          minWidth: "16px",
                          minHeight: "16px",
                        }}
                        onClick={() => {
                          handleSearch(item, false);
                        }}
                      >
                        {has && <div className="products-checkbox"></div>}
                      </div>

                      <div className="product-search-type-list-api-info-container">
                        {item.name.match(queryRegex) ? (
                          handleQuery(item.name)
                        ) : (
                          <p className="product-search-tag-list-api-name">
                            {item.name}
                          </p>
                        )}
                        <p
                          style={{ width: "100%", textTransform: "capitalize" }}
                          className="product-search-tag-list-api-type"
                        >
                          {item["type"]?.toLowerCase()?.replaceAll("_", " ")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <Pane
            display="flex"
            gap={16}
            marginBottom={26}
            borderTop="1px solid lightgrey"
            style={{
              border: "none",
              maxHeight: "300px",
              overflow: "hidden",
              marginTop: 20,
              marginBottom: -2,
            }}
            className="create-selected-items-section"
          >
            <div className="products-container">
              <div>
                <SelectedItem
                  item={{ name: "Product " }}
                  border={false}
                  addOrRemoveSelectedCollection={() => {
                    selectAll();
                  }}
                  addOrRemoveSelectedProduct={() => {
                    selectAll();
                  }}
                  type="products"
                  selected={
                    selectAllProducts || finalProductUIAfterFilter?.length === 0
                  }
                />
                {selectedTags.length === 0 ? (
                  <>
                    {finalProductUIAfterFilter?.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80%",
                          flexDirection: "column",
                        }}
                      >
                        <AllProductsIcon />
                        <p style={{ color: "#75787E", marginTop: "8px" }}>
                          All products selected
                        </p>
                      </div>
                    )}
                    {finalProductUIAfterFilter
                      .filter(
                        (p) =>
                          !selectedProducts?.map((p) => p.id)?.includes(p.id)
                      )
                      ?.map((product, idx) => (
                        <SelectedItem
                          item={product}
                          addOrRemoveSelectedProduct={
                            addOrRemoveSelectedProduct
                          }
                          selectedProducts={[]}
                          type="product"
                        />
                      ))}
                  </>
                ) : (
                  <>
                    {!tagProducts.length && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80%",
                          flexDirection: "column",
                        }}
                      >
                        <AllProductsIcon />
                        <p style={{ color: "#75787E", marginTop: "8px" }}>
                          All products selected
                        </p>
                      </div>
                    )}

                    {tagProducts
                      ?.filter(
                        (p) =>
                          !selectedProducts?.map((p) => p.id)?.includes(p.id)
                      )
                      ?.map((product, idx) => (
                        <SelectedItem
                          item={product}
                          addOrRemoveSelectedProduct={
                            addOrRemoveSelectedProduct
                          }
                          selectedProducts={[]}
                          type="product"
                        />
                      ))}
                  </>
                )}
              </div>

              <div className="separator"></div>

              <div>
                <p>
                  Selected Products |{" "}
                  <span
                    style={{
                      color: "#75787E",
                    }}
                  >{`${selectedProducts?.length}/${allProducts?.length} Products `}</span>
                </p>
                {selectedProducts?.map((product, idx) => (
                  <SelectedItem
                    item={product}
                    addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                    selectedProducts={selectedProducts}
                    type="product"
                  />
                ))}
              </div>
            </div>{" "}
          </Pane>
        </>
      ) : (
        <>
          <Pane
            display="flex"
            gap={16}
            marginBottom={26}
            borderTop="1px solid lightgrey"
            style={{
              border: "none",
              maxHeight: "300px",
              overflow: "hidden",
              marginTop: 20,
              marginBottom: -2,
            }}
            className="create-selected-items-section"
          >
            <div className="products-container">
              <div>
                <SelectedItem
                  item={{ name: "Product " }}
                  border={false}
                  addOrRemoveSelectedCollection={() => {
                    selectAll();
                  }}
                  addOrRemoveSelectedProduct={() => {
                    selectAll();
                  }}
                  type="products"
                  selected={
                    selectAllProducts || finalProductUIAfterFilter?.length === 0
                  }
                />
                {finalProductUIAfterFilter?.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80%",
                      flexDirection: "column",
                    }}
                  >
                    <AllProductsIcon />
                    <p style={{ color: "#75787E", marginTop: "8px" }}>
                      All products selected
                    </p>
                  </div>
                )}
                {finalProductUIAfterFilter
                  .filter(
                    (p) => !selectedProducts?.map((p) => p.id)?.includes(p.id)
                  )
                  ?.map((product, idx) => (
                    <SelectedItem
                      item={product}
                      addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                      selectedProducts={[]}
                      type="product"
                    />
                  ))}
              </div>
              <div className="separator" style={{ visibility: "hidden" }}></div>
              <div>
                {/* <p>
                  Selected Products |{" "}
                  <span
                    style={{
                      color: "#75787E",
                    }}
                  >{`${selectedProducts?.length}/${allProducts?.length} Products `}</span>
                </p> */}
                <SelectedItem
                  item={{
                    name: `Selected Products | ${selectedProducts?.length}/${allProducts?.length} Products`,
                  }}
                  border={false}
                  addOrRemoveSelectedProduct={() => {
                    setSelectedProducts([]);
                    setSelectAllProducts(false);
                  }}
                  type="selected"
                  selected={selectedProducts.length !== 0}
                />
                {selectedProducts?.map((product, idx) => (
                  <SelectedItem
                    item={product}
                    addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                    selectedProducts={selectedProducts}
                    type="product"
                  />
                ))}
              </div>
            </div>{" "}
          </Pane>
        </>
      )}
    </div>
  );
};

export default AddProductsWizard;
