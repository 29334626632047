import React from 'react';
import {ReactComponent as Island} from "../../assets/icons/island.svg";

import "./UnsuccessfulLoadData.scss";

export default function UnsuccessfulLoadData() {
  return (
    <div className='unsuccessful-load-container'>
        <Island className='unsuccessful-load-image'/>
        <p className='unsuccessful-load-details'>Oh no! Something went wrong... We're doing the best that we can to fix it! Please try again later</p>
    </div>
  )
}
