import React, {useEffect, useState} from 'react';
import "./ProfilePage.scss";
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import {FaCheck} from 'react-icons/fa';
import useAuth from 'stores/auth';
import LoadingPage from 'pages/LoadingPage/LoadingPage';

import info from "../../assets/icons/info-black-error.svg";
import exit from "../../assets/icons/exit-black-error.svg";
import {Client} from "../../Client";


export default function ProfilePage() {

  const { minimized } = useAuth();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [initials, setInitials] = useState("");
  const [brand, setBrand] = useState({})

  useEffect(() => {
    Client("/emp/brand")
        .then((res) => {
          setBrand(res.data)
        });
  }, [])

  useEffect(() => {
    const name = localStorage.getItem('SKP_NAME');
    setEmail(localStorage.getItem('EMAIL'));
    setName(localStorage.getItem('SKP_NAME'));
    setInitials(name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), ''))
    setTimeout(() => setLoading(false), 3000);
  }, [])

  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [nextStep, setNextStep] = useState(false);

  const [deleteHover, setDeleteHover] = useState(false);

  const [otherReason, setOtherReason] = useState("");

  const [sendDataToEmail, setSendDataToEmail] = useState(false);

  const [understand, setUnderstand] = useState(false);

  const [loading, setLoading] = useState(true);

  const [hasError, setHasError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("delete account");

  const [showTour, setShowTour] = useState(false);
  const [tourCurrentIndex, setTourCurrentIndex] = useState(0);

  function handleCurrentTourIndex(index) {
    setTourCurrentIndex(index)
  }

  function handleOpenTour(value) {
    setTourCurrentIndex(0);
    setShowTour(value);
  }

  const reasons = [
    {
      id: 1,
      value: "I'm not getting any value from the product",
    },
    {
      id: 2,
      value: "It's too expensive for me",
    },
    {
      id: 3,
      value: "I have another product that I'm using it instead",
    },
    {
      id: 4,
      value: "Other",
    },
  ]

  const DeleteStep = () => {
    return (
      <div className={minimized ? 'profile-page-popup-container-minimized' : 'profile-page-popup-container'}>
        <div className='profile-page-popup'>
          <p className='profile-page-popup-header'>Delete Account</p>
          <p className='profile-page-popup-subheader'>We are sorry to see you go..</p>
          <p className='profile-page-popup-subheader'>Before that, We would like to know what was the reason you're leaving us.</p>
          <div className='profile-page-popup-options'>
            {reasons.map((reason) => {
              return <div key={reason.id} className='profile-page-popup-options-container'>
                {selectedReason === reason.value ? <RadioButton checked/> : <RadioButton onClick={() => setSelectedReason(reason.value)} />}
                <p className='profile-page-popup-options-value'>{reason.value}</p>
              </div>
            })}
          </div>
          {selectedReason === "Other" && <input autoFocus type="textarea" className='profile-page-popup-options-input' value={otherReason} onChange={(e) => { e.preventDefault(); setOtherReason(e.target.value); console.info(e.target.value) }} placeholder="We would like to hear your thoughts in a few words " />}
          <div className='profile-page-popup-options-button-container'>
            <button className='profile-page-popup-options-button-cancel' onClick={() => { setShowPopUpDelete(false); setSelectedReason(""); setOtherReason(""); setNextStep(false); setSendDataToEmail(false); setUnderstand(false) }}>
              Cancel
            </button>
            <button disabled={selectedReason === "Other" && otherReason === ""} className={(selectedReason !== "" && selectedReason !== "Other") || (selectedReason === "Other" && otherReason !== "") ? 'profile-page-popup-options-button-confirm' : 'profile-page-popup-options-button-disabled'} onClick={() => setNextStep(true)}>
              {selectedReason !== "" || (selectedReason === "Other" && otherReason === "") ? 'Next' : "Skip"}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const FinalDelete = () => {
    return (
      <div className={minimized ? 'profile-page-popup-container-minimized' : 'profile-page-popup-container'}>
        <div className='profile-page-popup'>
          <p className='profile-page-popup-header'>Delete Account</p>
          <p className='profile-page-popup-subheader'>Time to say goodbye...</p>
          <div className='profile-page-popup-options-container' style={{ margin: "20px 0" }}>
            <div className='profile-page-popup-options-checkbox' style={{ backgroundColor: understand ? "#017CF8" : "transparent" }} onClick={() => setUnderstand(prev => !prev)}>
              {understand && <FaCheck color="white" size={12} />}
            </div>
            <p className='profile-page-popup-options-value'>I understand that all of my data will be deleted</p>
          </div>
          <div className='profile-page-popup-options-button-container'>
            <button className='profile-page-popup-options-button-cancel' onClick={() => { setShowPopUpDelete(false); setSelectedReason(""); setOtherReason(""); setNextStep(false); setSendDataToEmail(false); setUnderstand(false) }}>
              Cancel
            </button>
            <a href={understand ? brand?.ecommerceURL + "/admin/settings/apps" : "#"} className={understand ? 'profile-page-popup-options-button-confirm' : 'profile-page-popup-options-button-disabled'}>
              Delete
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='profile-page'>
      {/* {showTour && <TourPopup tourCurrentIndex={tourCurrentIndex} handleCurrentTourIndex={handleCurrentTourIndex} handleOpenTour={handleOpenTour} />} */}
      {showPopUpDelete && nextStep ? <FinalDelete /> : showPopUpDelete && !nextStep ? <DeleteStep /> : null}
      <Header name="Account" handleOpenTour={handleOpenTour} />
      {loading ? <LoadingPage /> : <div className='profile-container'>
        <p className='profile-header'>Personal Details</p>
        <p className='profile-subheader'>View and update your account details, profile and more.</p>
        <div className='profile-image' style={{ backgroundColor: "#F4B037" }}>
          <p className='profile-image-text'>{initials}</p>
        </div>
        <p className='profile-tag'>Name</p>
        <p className='profile-tag-value'>{name}</p>
        <p className='profile-tag'>Email</p>
        <p className='profile-tag-value'>{email}</p>

        <div className='profile-button-container'>
          <Button type="SUBMIT" text="Change password" onClick={() => window.location.href = "/reset-password"} />
          <button style={{ backgroundColor: deleteHover ? '#E45447' : "transparent", border: deleteHover ? "none" : "2px solid rgba(228, 84, 71, 0.5)", color: deleteHover ? 'white' : "#E45447", marginLeft: "50px", borderRadius: "8px", minWidth: "147px", padding: "12px", cursor: "pointer" }} onMouseEnter={() => setDeleteHover(true)} onMouseLeave={() => setDeleteHover(false)} onClick={() => setShowPopUpDelete(true)}>
            Delete Account
          </button>
        </div>
        {hasError && <div style={{ width: "345px", position: "relative" }}>
          <div style={{ position: "absolute", top: "10px", right: 0, padding: "15px 10px", backgroundColor: "rgba(255, 49, 4, 0.38)", borderRadius: "8px" }}>
            <img src={exit} style={{ width: "7px", height: "7px", position: "absolute", top: 8, right: 8, }} onClick={() => setHasError(false)} />
            <div style={{ display: "flex", flexDirection: "row", }}>
              <img src={info} style={{ width: "14px", height: "14px" }} />
              <p style={{ maxWidth: "230px", padding: "0 10px", fontSize: "10px" }}>
                Seems like you tried to {errorMessage} and it didn't work. Please try again.
              </p>
            </div>
          </div>
        </div>}
      </div>}
    </div>
  )
}

const RadioButton = ({checked,onClick}) => {

  return (
      <div onClick={onClick} className="radio-button-popup" id={checked ? "checked-radio-button-popup" : ""}>
        <div className="radio-inner-button-popup"/>
      </div>
  )
}
