import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SliderArrow from "../../../../../assets/icons/SliderArrow";
// import cluster from "../../../../../assets/images/cluster.webp";
import imageSelection from "../../../../../assets/images/image-selection-new-icon.svg";
import selection from "../../../../../assets/images/selection-icon-new.svg";


const QuesTypeSlider = ({
  selected = "SELECTION",
  onChange = (type) => console.info(type),
}) => {
  const templates = [
    { key: "SELECTION", img: selection, content: "Up to 6 answers" },
    {
      key: "IMAGE_SELECTION",
      img: imageSelection,
      content: "Up to 6 answers",
    },
    // { key: "CLUSTER", img: cluster, content: "Up to 12 answers" },
  ];
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    // @ts-ignore
    nextArrow: <SliderArrow type={"next"} />,
    // @ts-ignore
    prevArrow: <SliderArrow type={"previous"} />,
  };

  return (
    <>
      <p style={{ padding: "10px 0px 10px 10px", fontSize: "12px" }}>Question type</p>
      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          padding: "0 10px 10px 10px",
        }}
        className="questions-slider"
      >
        {templates.map((template) => (
          <div
            key={template.key}
            className={`type-slide-container ${
              selected === template.key ? "selected" : ""
            }`}
            onClick={() => onChange(template.key)}
          >
            <img
              style={{
                width: "94%",
                height:"74px"
              }}
              src={template.img}
              alt=""
            />
            <p style={{marginTop:"5px"}}>{template.key.replace("_", " ").toLowerCase()}</p>
            {/* <p>
              <small>{template.content}</small>
            </p> */}
          </div>
        ))}
      </div>
    </>
  );
};

export default QuesTypeSlider;
