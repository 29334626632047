import shoppingMadeMagical from "../../../assets/images/shopping-made-magical.png";
import SkeepLogo from "../../../assets/icons/logo.svg";
import {useOnboarding} from "../../../stores/use-onboarding";
import Preview from "../../EditorPage/components/Editor/Preview";
import React, {useRef} from "react";
import {useEditorEvents} from "../../../stores/use-editor-events";
import useExperiencePage from "../../../hooks/useExperiencePage";
import {motion} from "framer-motion";
import Skeleton from 'react-loading-skeleton'


const SideIllustrations = ({}) => {
    const {step, productsForAnimation, createExpLoading} = useOnboarding();
    const previewRef = useRef();
    const {playEvents, events, addEvents, clearEvents} = useEditorEvents();
    const {isEditing, brand} = useExperiencePage();


    return (<div className="onboarding-side-illustrations" id={"onboarding-side-step-" + `${step}`}>
        {step === 0 && <Step1Illustration/>}
        {step === 1 && !createExpLoading && <Mockup/>}
        {step === 1 && createExpLoading && <ProductsAnimation/>}
        {step > 1 && <div className="preview" ref={previewRef}>
            <Preview
                fromOnboarding
                previewOf={"experience"}
                isEditing={isEditing}
                events={events}
                brand={brand}
                // loading={loading}
                // disable={disable}
                // editExpOBJ={editExpOBJ}
                // handlePublishClick={handlePublishClick}
                // handleSaveChanges={handleSaveChanges}
            />
        </div>}

    </div>)
}

export default SideIllustrations;

const Step1Illustration = () => {
    return (<>
        <img src={shoppingMadeMagical} className="shopping-made-magical"/>
        <img src={SkeepLogo} className="skeep-logo-side"/>

        {/*<div className="trusted-by-container">
            <p className="trusted-by-text">Trusted by</p>
            <div className="brand-boxes-container">
                <div className="brand-box"></div>
                <div className="brand-box"></div>
                <div className="brand-box"></div>
                <div className="brand-box"></div>
            </div>
        </div>*/}
    </>)
}

const Mockup = () => {
    const design = useOnboarding(s => s.design);
    return (<svg
            ref={svg => {
                if (!svg) return;
                svg.style.width = "100%";
                svg.style.height = "100%";
            }}
            width="629" height="473" viewBox="0 0 629 473" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5542_53828)">
                <g filter="url(#filter0_bdi_5542_53828)">
                    <g clipPath="url(#clip1_5542_53828)">
                        <rect x="31" y="73" width="567" height="375" rx="10" fill="white"/>
                        <rect x="31" y="73" width="567" height="375" rx="10" fill="url(#paint0_linear_5542_53828)"/>
                        <path d="M575 98L569 104M563 110L569 104M569 104L563 98L575 110" stroke="#4F4F4F"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M57 131H388M57 154H259.949" stroke={design?.text} strokeWidth="6" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <rect x="57.5" y="201.5" width="95" height="29" rx="14.5" fill={design?.buttons}
                              stroke={design?.buttons}/>
                        <rect x="163.5" y="201.5" width="95" height="29" rx="14.5" fill={design?.background}
                              stroke={design?.buttons}/>
                        <rect x="57.5" y="247.5" width="95" height="28" rx="14" fill={design?.background}
                              stroke={design?.buttons}/>
                        <rect x="297.5" y="246.5" width="95" height="28" rx="14" fill={design?.background}
                              stroke={design?.buttons}/>
                        <rect x="57.5" y="292.5" width="105" height="28" rx="14" fill={design?.background}
                              stroke={design?.buttons}/>
                        <rect x="269.5" y="201.5" width="95" height="29" rx="14.5" fill={design?.background}
                              stroke={design?.buttons}/>
                        <rect x="163" y="247" width="124" height="29" rx="14.5" fill={design?.buttons}/>
                        <path
                            d="M180.392 266.427L176.375 262.409L177.982 260.802L180.392 263.213L186.017 257.588L187.625 259.195L180.392 266.427Z"
                            fill="white"/>
                        <path
                            d="M71.3923 220.427L67.3745 216.409L68.9818 214.802L71.3923 217.213L77.0173 211.588L78.6245 213.195L71.3923 220.427Z"
                            fill="white"/>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_bdi_5542_53828" x="-42.4375" y="-0.4375" width="713.875" height="521.875"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="36.7188"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5542_53828"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="-18.3594"/>
                    <feGaussianBlur stdDeviation="18.3594"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="effect1_backgroundBlur_5542_53828"
                             result="effect2_dropShadow_5542_53828"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5542_53828" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="36.7188"/>
                    <feGaussianBlur stdDeviation="45.8984"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_5542_53828"/>
                </filter>
                <linearGradient id="paint0_linear_5542_53828" x1="598" y1="258.44" x2="31" y2="258.44"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFF"/>
                    <stop offset="0.59375" stopColor={design.background}/>
                </linearGradient>
                <clipPath id="clip0_5542_53828">
                    <rect x="2" y="2" width="625" height="469" rx="24" fill="white"/>
                </clipPath>
                <clipPath id="clip1_5542_53828">
                    <rect x="31" y="73" width="567" height="375" rx="10" fill="white"/>
                </clipPath>
                <clipPath id="clip2_5542_53828">
                    <rect width="5.50781" height="5.50781" fill="white" transform="translate(231.352 243.781)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

const ProductsAnimation = () => {
    const {step, productsForAnimation} = useOnboarding();

    return (<div className="generator-products-mockup">
        <div className="generator-products">
            {productsForAnimation?.map((product, i) => {
                const productType = product?.tags?.find(tagOBJ => tagOBJ.type === "PRODUCT_TYPE")?.name;
                const productTags = product?.tags?.filter(tagOBJ => tagOBJ.type !== "PRODUCT_TYPE")?.slice(0, 10);
                const finalTags = !product?.id ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : productTags;

                return (<motion.div className="product-row" key={i} animate={{y: 0}} initial={{y: -30}}>
                    {!product?.id ? (<div className="product-row-top">
                        <Skeleton style={{width: 50, height: 60, marginRight: 15}}/>
                        <Skeleton className="product-row-name" style={{width: 200, height: 20}}/>
                        <div style={{marginLeft: "auto"}}/>
                        <Skeleton className="product-row-type"
                                  style={{width: 80, height: 40, marginLeft: "auto"}}/>
                    </div>) : (<motion.div className="product-row-top" animate={{y: 0}} initial={{y: -50}}>
                        <img src={product?.images?.[0]} loading="lazy" style={{width: 50}}/>
                        <p className="product-row-name">{product.name}</p>
                        <p className="product-row-type">{productType}</p>
                    </motion.div>)}
                    <div className="product-row-bottom">
                        {finalTags?.map((tagOBJ, tagIndex) => {
                            if (!product?.id) return <Skeleton style={{margin: "0 !important"}} width={100}
                                                               className={"tag-skeleton"}/>
                            return (<motion.div className="product-row-tag" key={tagIndex} animate={{y: 0}}
                                                initial={{y: -30}}>
                                <p className="product-row-tag-type">{tagOBJ?.type?.toLowerCase()?.replaceAll("_", " ")}</p>
                                <p className="product-row-tag-name">{tagOBJ?.name}</p>
                            </motion.div>)
                        })}
                    </div>
                </motion.div>)
            })}
        </div>
    </div>)
}