import useProducts from "pages/ProductPage/use-products";
import {useState} from "react";
import {IoCloseOutline, IoFilter} from "react-icons/io5";
import AddProductsDialog from "../AddProductsDialog.jsx";
import DropdownFilter from "./DropdownFilter.jsx";

export default function Filters({allProducts, setForceRefetch}) {
  const {
    names,
    productTypes,
    addFilter,
    tags,
    removeFilter,
    clearFilters,
    productIds,
  } = useProducts();

  const [showProductDialog, setShowProductDialog] = useState(false);

  return (
    <>
      <div className="products-filters-container">
        <div className="products-filters-left-container">
          <div style={{ marginRight: "40px" }}>
            <IoFilter color="#000000" size={25} />
          </div>
          <DropdownFilter
            name="name"
            allowIncomplete
            onChange={(value) => {
              addFilter("names", value);
            }}
            values={names}
          />
          <DropdownFilter
            name="product ID"
            allowIncomplete
            onChange={(value) => {
              addFilter("productIds", value);
            }}
            values={productIds}
          />
          <DropdownFilter
            name="product type"
            endpoint="emp/products/types"
            onChange={(value) => {
              addFilter("productTypes", value, true);
            }}
            values={productTypes}
          />
          <DropdownFilter
            name="tag"
            endpoint="emp/tags"
            secondaryKey="type"
            allProducts={allProducts}
            onChange={(value) => {
              addFilter("tags", value, true);
            }}
            values={tags}
          />
        </div>
        <div className="products-filters-right-container">
          <button
            onClick={() => setShowProductDialog(true)}
            className="add-product-button"
          >
            Add Product
          </button>
        </div>
      </div>
      {[...names, ...productTypes, ...tags, ...productIds].length > 0 && (
        <div className="products-filters-list-container">
          <p
            className="products-filters-list-clear"
            style={{ cursor: "pointer" }}
            onClick={() => clearFilters()}
          >
            Clear All Filters
          </p>
          <div className="products-filters-list-wrap-container">
            {names.flatMap((name, i) => (
              <div className="products-filters-list">
                <p className="products-filters-list-type">Name</p>
                <p className="products-filters-list-name">{name}</p>
                <IoCloseOutline
                  color="#75787E"
                  size={18}
                  onClick={() => {
                    removeFilter("names", i);
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    bottom: "3px",
                    right: "3px",
                  }}
                />
              </div>
            ))}
            {productTypes.flatMap((pType, i) => (
              <div className="products-filters-list">
                <p className="products-filters-list-type">Product Type</p>
                <p className="products-filters-list-name">{pType.name}</p>
                <IoCloseOutline
                  color="#75787E"
                  size={18}
                  onClick={() => {
                    removeFilter("productTypes", i);
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    bottom: "3px",
                    right: "3px",
                  }}
                />
              </div>
            ))}
            {tags.flatMap((tag, i) => (
              <div className="products-filters-list">
                <p className="products-filters-list-type">Tag</p>
                <p className="products-filters-list-name">{tag.name}</p>
                <IoCloseOutline
                  color="#75787E"
                  size={18}
                  onClick={() => {
                    removeFilter("tags", i);
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    bottom: "3px",
                    right: "3px",
                  }}
                />
              </div>
            ))}
            {productIds.flatMap((productId, i) => (
              <div className="products-filters-list">
                <p className="products-filters-list-type">Product ID</p>
                <p className="products-filters-list-name">{productId}</p>
                <IoCloseOutline
                  color="#75787E"
                  size={18}
                  onClick={() => {
                    removeFilter("productIds", i);
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    bottom: "3px",
                    right: "3px",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <AddProductsDialog
        show={showProductDialog}
        setShow={setShowProductDialog}
        initialSelectedProducts={allProducts}
        setForceRefetch={setForceRefetch}
      />
    </>
  );
}
