import React, {useState} from "react";
import "./InputsRow.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import CustomSelect from "../../../components/Forms/CustomSelect";
import {experiencesImplementations} from "../../../utils/experiencesOptions";
import CustomTagsSearch from "../../../components/CustomTags/CustomTagsSearch";
import {embeddedPageSnippet} from "../../../utils/codeSnippets";
import CustomInput from "../../../components/Forms/CustomInput";

function InputsRow({
  index,
  setPlacements,
  placements,
  experienceOBJ,
  setSaveDisabled,
}) {
  const [isCodeSnipperCopied, setIsCodeSnipperCopied] = useState(false);
  const placementOBJ = placements[index];
  const onInputChange = (keyName, implementationOBJ) => {
    setPlacements([
      ...placements.slice(0, index),
      {
        ...placements[index],
        [keyName]: implementationOBJ?.value || null,
      },
      ...placements.slice(index + 1),
    ]);
  };

  const onPlacementChange = (values) => {
    setPlacements([
      ...placements.slice(0, index),
      {
        ...placements[index],
        path: values?.[0],
      },
      ...placements.slice(index + 1),
    ]);
    setSaveDisabled(false);
  };

  const copyCodeSnippet = () => {
    navigator.clipboard
      .writeText(embeddedPageSnippet("experience", experienceOBJ?.id))
      .then(() => {
        setIsCodeSnipperCopied(true);
      });
  };

  const deleteRow = () => {
    setPlacements(placements.filter((p, i) => i !== index));
  };

  function getPath(path) {
    if (path?.includes("http://") || path?.includes("https://")) {
      const url = new URL(path);
      return url.pathname;
    } else {
      return path;
    }
  }

  const handleParentID = (value) => {
    setPlacements([
      ...placements.slice(0, index),
      {
        ...placements[index],
        settings: {
          parentID: value,
        },
      },
      ...placements.slice(index + 1),
    ]);
  };
  return (
    <div className="inputs-row fa-center">
      {placements.length !== 1 && (
        <FontAwesomeIcon
          icon={faTrashCan}
          onClick={deleteRow}
          color="#E45447"
        />
      )}

      <CustomSelect
        formSelect
        options={experiencesImplementations}
        size="medium"
        name="implementationType"
        label="Implementation Type"
        onChange={(option) => onInputChange("implementationType", option)}
        value={experiencesImplementations.find(
          (obj) => obj.value === placementOBJ?.implementationType
        )}
      />

      {placementOBJ?.implementationType !== null && (
        <>
          <CustomTagsSearch
            placeHolder="Search page"
            label="Placement"
            name="page"
            noTags
            width={428}
            onTagChange={onPlacementChange}
            onlyOneTag={getPath(placementOBJ?.path)}
            api="/emp/sitemap"
            type="GET-QUERY"
            tooltip={
              " This is the URL link of the page where the experience will be exposed"
            }
          />
        </>
      )}

      {placementOBJ?.implementationType === "EMBEDDED_PAGE" && (
        <>
          <CustomInput
            label="Element ID"
            placeHolder="Enter element ID"
            name="parentID"
            type="text"
            size="medium"
            onChange={(e) => handleParentID(e.target.value)}
            value={placementOBJ?.settings?.parentID}
            forceStyle={{ marginTop: 0 }}
            tooltip={"The experience will be attached to this element ID"}
          />
        </>
      )}

      {/* {placementOBJ?.implementationType === "EMBEDDED_PAGE" &&
                <span className="fa-center" style={{paddingTop:"22px"}}>
                <FontAwesomeIcon icon={faCode} color={"#017CF8"} onClick={copyCodeSnippet}/>
                    {isCodeSnipperCopied && <p className="code-snippet-indicator">Code snippet copied</p>}
            </span>} */}
    </div>
  );
}

export default InputsRow;
