import {Li, majorScale, Menu, Popover, Position, toaster, Ul,} from "evergreen-ui";
import {FaEllipsisV} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {Client} from "../../../Client";
import DeleteExpModal from "./DeleteExpModal";
import {useState} from "react";
import LoadingPage from "pages/LoadingPage/LoadingPage";

const ExpRowMenu = ({
  experience,
  setFailed,
  setIsPlacementsModalOpen,
  setForceFetch,
  setIndexOfHoveredRow,
  setIsShown,
}) => {
  const [isDeleteExpModalOpen, setIsDeleteExpModalOpen] = useState(false);

  const navigate = useNavigate();

  const deleteExp = async (fromMenu = false) => {

    if (fromMenu === true) return setIsDeleteExpModalOpen(true);
    const deletedExp = await Client.patch(`/experiences/${experience?.id}`, {
      status: "ARCHIVED",
    });

    toaster.success("Experience deleted successfully");
    setForceFetch();
    setIsDeleteExpModalOpen(false);
    setIsPlacementsModalOpen(undefined);
  };

  const duplicateExp = async () => {
    try {
      const duplicateExp = await Client.post(
        `/emp/experiences/${experience?.id}/duplicate`,
        {}
      );
      toaster.success("Experience duplicated successfully");
      setForceFetch();
      setIsPlacementsModalOpen(undefined);
    } catch (e) {
      toaster.warning("There was a problem duplicating the experience");
    }

    setForceFetch();
  };

  return (
    <>
      <Popover
        statelessProps={{
          background: "#E4F1FF",
          borderRadius: majorScale(1),
          marginLeft:"-25px"
        }}
        onOpen={()=>{setIsShown(true);
          setIndexOfHoveredRow(null);
        }
        }
        onClose={() => setIsShown(false)}
        position={Position.BOTTOM_RIGHT}
        content={({ close }) => (
          <Menu>
            <Ul className="brand-widget-menuitem" onClick={close}>
              <Li onClick={() => navigate(`/products/${experience.id}`)}>
                View products
              </Li>
              <Li onClick={() =>{ setIsPlacementsModalOpen(experience);}}>
                Placement settings
              </Li>
              <Li onClick={() =>{
                navigate(`/responses/${experience.id}`)}}>
              Responses
              </Li>
              <Li onClick={duplicateExp}>Duplicate</Li>
              <Li style={{ color: "#E45447" }} onClick={() => deleteExp(true)}>
                Delete
              </Li>
            </Ul>
          </Menu>
        )}
      >
        <div style={{ cursor: "pointer"}} >
          <FaEllipsisV size={16} color="#0F0F0F" onClick={()=>setFailed(false)} />
        </div>
      </Popover>
      <DeleteExpModal
        deleteExp={deleteExp}
        isDeleteExpModalOpen={isDeleteExpModalOpen}
        setIsDeleteExpModalOpen={setIsDeleteExpModalOpen}
      />
    
    </>
  );
};

export default ExpRowMenu;
