import {useEffect} from "react";
import {Client} from "../Client";

const useHubSpot = () => {
    const email = localStorage.getItem("EMAIL");

    useEffect(() => {
        const loadHubspotChat = async () => {
            if (!window.HubSpotConversations || !email) return;
            window.hsConversationsSettings = {loadImmediately: false};


            try{
                const {data:{token}} = await Client.get("emp/hubspot/token");

                window.hsConversationsSettings = {
                    identificationEmail: email,
                    identificationToken: token
                };
                await window.HubSpotConversations.resetAndReloadWidget();
            } catch (e) {
                console.error("Problem loading hubspot");
            }
        }

        loadHubspotChat();
    }, [window.HubSpotConversations,email]);

};


export default useHubSpot;