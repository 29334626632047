import {Dialog, Pane} from "evergreen-ui";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import {useState} from "react";
import useDeleteProperties from "../../../../../hooks/useDeleteProperties";
import useEditor from "stores/use-editor";
import "./Dialog.scss";
import {useSwiper} from "stores/use-swiper";

const DeleteDialog = () => {
  const doNotShowDialogStorage = localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
  const { activeSentenceIndex } = useSwiper();
  const { showDeleteDialog, setShowDeleteDialog, experience } = useEditor();
  const { deleteQuestion, deleteSentence } = useDeleteProperties();
  const [doNotShowAgainTemporary, setDoNotShowAgainTemporary] = useState(false);
  const [doNotShowAgainFromStorage, setDoNotShowAgainFromStorage] = useState(false);

  // useEffect(() => {
  //   const doNotShowDialogStorageRefetch =
  //     localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
  //   setDoNotShowAgainFromStorage(doNotShowDialogStorageRefetch);
  // }, [showDeleteDialog]);

  const toggleDoNotShowAgain = () => {
    if (doNotShowAgainTemporary) {
      setDoNotShowAgainTemporary(false);
      return localStorage.setItem("doNotShowAgainDeleteDialog", "false");
    }

    setDoNotShowAgainTemporary(true);
    return localStorage.setItem("doNotShowAgainDeleteDialog", "true");
  };

  return (
    <Pane>
      <Dialog
        isShown={showDeleteDialog && !doNotShowAgainFromStorage}
        onCloseComplete={() => setShowDeleteDialog(false)}
        width="500px"
        hasFooter={false}
        overlayProps={{
          zIndex:21
        }}
        hasHeader={false}
        topOffset={window.innerHeight / 3}
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
      >
        <div className="dialog-content">
          <h4>Delete Question</h4>
          <p>
            Please note, if you delete the question, it might affect the
            percentage of the recommendation.
          </p>
        </div>

        <div className="container">
          <div
            className="do-not-show-again-container"
            onClick={toggleDoNotShowAgain}
          >
            <Checkbox checked={doNotShowAgainTemporary} />
            <p>Do not show again</p>
          </div>
          <button
            onClick={() => setShowDeleteDialog(false)}
            className="cancel dialog-btn"
            style={{
              color: "#75787E",
              marginRight: "16px",
              cursor: "pointer",
              background: "none",
            }}
          >
            Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={() => {
              if (experience?.type === "QUIZ") {
                deleteQuestion(null);
              } else if (experience?.type === "SWIPER") {
                deleteSentence();
              }
            }}
            style={{
              color: "#fff",
              background: "#E45447",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default DeleteDialog;
