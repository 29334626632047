import moment from "moment";

export const free = {
    name: "Free",
    name_uppercase: "FREE",
    pricing: 0,
    product_count: 0,
    show_pricing: true,
    current_plan: false,
    disabled_plan: false,
    users_max: 1,
    maxPublishedQuizzes:1,
    max_engagements:20,
    limits: [`Up to 20 engagements`,],
    details: "Automatic creation experiences personalised to your store",
    included: [
        {
            info: "A/B testing",
            has_description: true,
        },
        {
            info: "Editor",
            has_description: true,
        },
        {
            info: "Analytics dashboard",
            has_description: true,
        },
        {
            info: "Download data collected",
            has_description: false,
        },
        {
            info: "1 Domain",
            has_description: false,
        },
        {
            info: "Indexing & catalogue updates once a month",
            has_description: true,
        },
    ]
}

export const basic = {
    name: "Basic",
    name_uppercase: "BASIC",
    pricing: 19,
    product_count: 20,
    show_pricing: true,
    current_plan: false,
    disabled_plan: false,
    users_max: 1,
    maxPublishedQuizzes:2,
    max_engagements:100,
    price_per_over_engagement: 0.15,
    limits: ["Up to 100 engagements (Every additional engagement $0.15)"],
    details: "Automatic creation experiences personalised to your store",
    included: [
        {
            info: "A/B testing",
            has_description: true,
        },
        {
            info: "Editor",
            has_description: true,
        },
        {
            info: "Analytics dashboard",
            has_description: true,
        },
        {
            info: "Download data collected",
            has_description: false,
        },
        {
            info: "1 Domain",
            has_description: false,
        },
        {
            info: "Indexing & catalogue updates once a month",
            has_description: true,
        },
    ]
}

export const premium = {
    name: "Growth",
    name_uppercase: "GROWTH",
    pricing: 129,
    freeTrialDays:14,
    product_count: 50,
    show_pricing: true,
    current_plan: false,
    maxPublishedQuizzes:5,
    disabled_plan: false,
    max_engagements:1500,
    price_per_over_engagement: 0.1,
    users_max: 2,
    limits: [<p>Up to 1500 engagements (Every additional engagement $0.1)</p>],
    details: "Everything in basic, plus:",
    included: [
        {
            info: "Export Analytics and Reports",
            has_description: false,
        },
        {
            info: "3 Domain",
            has_description: false,
        },
        {
            info: "Integration with Marketing tools like Klaviyo",
            has_description: false,
        },
        {
            info: "Indexing & catalogue updates once a month",
            has_description: true,
        },
    ]
}

export const platinum = {
    name: "Platinum",
    name_uppercase: "PLATINUM_NEW",
    pricing: 299,
    product_count: 100,
    freeTrialDays:14,
    show_pricing: true,
    current_plan: false,
    maxPublishedQuizzes:8,
    max_engagements:5000,
    disabled_plan: false,
    price_per_over_engagement: 0.08,
    users_max: 4,
    limits: ["Up to 5000 engagements (Every additional engagement $0.08)"],
    details: "Everything in growth, plus:",
    included: [
        {
            info: "Customize Reporting",
            has_description: false,
        },
        {
            info: "Export Analytics and Reports",
            has_description: false,
        },
        {
            info: "10 Domains",
            has_description: false,
        },
        {
            info: "Integration with Marketing tools like Klaviyo",
            has_description: false,
        },
        {
            info: "Indexing & catalogue updates once a month",
            has_description: true,
        },
    ]
}

export const skeepx = {
    name: "SkeepX",
    name_uppercase: "SKEEPX",
    pricing: 49,
    product_count: 0,
    freeTrialDays:"Custom",
    show_pricing: false,
    current_plan: false,
    max_engagements:null,
    disabled_plan: false,
    users_max: 5,
    limits: ["Custom"],
    details: "Everything in growth, plus:",
    included: [
        {
            info: "Access to new experiences *FIRST*",
            has_description: false,
        },
        {
            info: "Customized Reporting",
            has_description: false,
        },
        {
            info: "Export Analytics and Reports",
            has_description: false,
        },
        {
            info: "50 Domains",
            has_description: false,
        },
        {
            info: "Integration with Marketing tools like Klaviyo",
            has_description: false,
        },
        {
            info: "Unlimited indexing & catalogue update",
            has_description: true,
        },
    ]
}

export const orderOfPlans = ["FREE","BASIC","PREMIUM","PLATINUM","SKEEPX"];
export const allPlans = {free,basic,premium,platinum,skeepx};


export const calcExtraEng = (startDate,currentEngagements,newPlanEngagement,priceExtraPerEng) => {
    const todayMoment = moment(new Date());
    const startMoment = moment(startDate);
    const duration = moment.duration(moment().diff(startMoment));
    const daysDiff = Math.floor(duration.asDays());

    const extraEngagements = currentEngagements - ((daysDiff/30) * newPlanEngagement);

    return `$${extraEngagements * priceExtraPerEng}`;
}