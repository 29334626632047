import {ObjectID} from "bson";
import {Client} from "Client";
import {OpenReplay} from "utils/OpenReplay";
import create from "zustand";
import {devtools} from "zustand/middleware";

interface ITag {
    id?: string;
    name: string;
    type?: string;
    origin?: "BRAND" | "SKEEP";
    products?: IProduct[];
    originalTagId?: string;
}

interface IProduct {
    id: string;
    name: string;
    image: string;
}

interface ITags {
    tags: {
        [key: string]: ITag;
    };
    saveCustomTags: () => Promise<any>;
    addTag: (tag: ITag) => ITag;
    removeTag: (tagId: string) => void;
    clearTags: () => void;
    setProducts: (tagId: string, products: IProduct[]) => void;
}

const useCustomTags = create<ITags>()(
    devtools(
        (set, get) => ({
            tags: {},
            clearTags: () => {
                set({tags: {}});
            },
            removeTag: (tagId) => {
                const filteredTags = {...get().tags};
                delete filteredTags?.[tagId];
                set({tags: {...filteredTags}});
            },
            addTag: (tag: ITag) => {
                if (!tag.id) {
                    tag.id = new ObjectID().toString();
                }
                if (!tag.type) {
                    tag.type = "CUSTOM";
                }
                if (!tag.origin) {
                    tag.origin = "SKEEP";
                }
                if (!tag.products) {
                    tag.products = [];
                }
                set({
                    tags: {
                        ...get().tags,
                        [tag.id]: tag,
                    },
                });
                return tag;
            },
            setProducts: (tagId: string, products: IProduct[]) => {
                set({
                    tags: {
                        ...get().tags,
                        [tagId]: {
                            ...get().tags[tagId],
                            products,
                        },
                    },
                });
            },
            saveCustomTags: async () => {
                const payload = Object.values(get().tags).map((tag: ITag) => ({
                    newTag: {
                        id: tag.id,
                        name: tag.name,
                        type: tag.type,
                        origin: tag.origin,
                    },
                    products: tag.products.map((product) => product.id),
                }));
                if (payload.length > 0) {
                    return await Client.post(`emp/tags/exchange`, {
                        customTags: payload,
                    });
                }
            },
        }),
        {
            name: "custom-tags",
        }
    )
);

export default useCustomTags;
