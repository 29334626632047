import React from "react";
import {useSearchParams} from "react-router-dom";
import IntegrationPage from "../IntegrationPage";

const MailchimpIntegrationPage = async () => {
    const [params, setParams] = useSearchParams();
    let code = params.get("code")
    window.location = "https://a.skeep.io/skeep/v1/integrations/mailchimp?code=" + code + "&brandID=" + localStorage.getItem("SKP_BRANDID");

    return <IntegrationPage/>
}

export default MailchimpIntegrationPage;