import SideIllustrations from "./Steps/SideIllustrations";
import OnboardingStep1 from "./Steps/OnboardingStep1";
import {useOnboarding} from "../../stores/use-onboarding";
import QuestionsEditor from "./Steps/QuestionsEditor";
import ConnectProducts from "./Steps/ConnectProducts";
import SelectPlacements from "./Steps/SelectPlacements";
import "./Onboarding.scss";
import "./OnboardingEditor.scss";
import "./OnboardingProductsAnimation.scss";
import useEditor from "../../stores/use-editor";
import {useEffect} from "react";
import {useEditorEvents} from "../../stores/use-editor-events";
import {Client} from "../../Client";
import WelcomeStep from "./Steps/WelcomeStep";

const Onboarding = () => {
    const step = useOnboarding(s => s.step);
    const setDesign = useOnboarding(s => s.setDesign);
    const {playEvents, events} = useEditorEvents();
    const {experience, updateExperience, questions, updateQuestions, updateSelectedTagsForRemoval,activeQuestionIndex} = useEditor();

    useEffect(() => {
        playEvents(experience, updateExperience, questions, updateQuestions, updateSelectedTagsForRemoval)
    }, [events]);

    useEffect(() => {
        Client("/emp/brand/ecommerce/design").then(res => {
            const {background,text} = res?.data?.colors || {};
            setDesign({
                background: background?.primary || "#FFF",
                buttons: background?.secondary || "#000",
                text: text?.primary || "#000",
            });
        })
    }, []);


    return (<div className="onboarding-page">
        {step === 0 && <WelcomeStep/>}
        {step === 1 && <OnboardingStep1/>}
        {step === 2 && <QuestionsEditor
            title="We have successfully scanned your collection!"
            subtitle={`We’ve generated ${questions?.length}  questions  and answer you can review and edit`}
            subtitleNoQuestionGenerated="Let’s create your first question together "
            swiperHeaderText={<p>Edit your questions | <span style={{color:"#75787E"}}>{activeQuestionIndex + 1}/{questions?.length}</span></p>}
            swiperHeaderTextNoQuestions="Edit questions content"
        />}
        {step === 3 && <ConnectProducts/>}
        {step === 4 && <QuestionsEditor
            title="Attach a side image to each question"
            subtitle="We scanned the following images. You can change the side image if you wish"
            swiperHeaderText={"Edit questions side image"}
            showSideImageFeature
        />}
        {step === 5 && <SelectPlacements/>}
        <SideIllustrations/>
    </div>)
};


export default Onboarding;