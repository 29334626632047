import useEditor from "stores/use-editor";
import TagsQuestion from "./TagsSelection/TagsQuestion";

export default function TagsContent() {
  const { questions, experience } = useEditor();
  const tagsContent = questions;

  return (
    <div className="tags-content">
      <div>
        {tagsContent?.map((ques, idx) => (
          <TagsQuestion
            question={ques.question}
            quesIdx={idx}
            tags={ques.tags.filter(
              (t) => !experience?.design?.tagsToIgnore?.includes(t.id)
            )}
          />
        ))}
      </div>

      {/* <div className="selection-tags">
        <Recommendation />
      </div>
      <div className="selection-tags">
        <Product />
      </div> */}
    </div>
  );
}
