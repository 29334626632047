import "./UpgradePlanModal.scss";
import {platinum, premium} from "../../utils/billingPricing";
import BillingPlansCardNew from "../BillingPlansCardNew/BillingPlansCardNew";
import {IoClose} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import changeBrandsPlan from "../../utils/changeBrandsPlan";
import useAuth from "../../stores/auth";
import {toaster} from "evergreen-ui";

const UpgradePlanModal = ({closeModal}) => {
    const navigate = useNavigate();
    const {accountOBJ} = useAuth();

    return(
        <>
        <div className="upgrade-plan-modal">
            <IoClose style={{position:"absolute",top:30,right:30,cursor:"pointer"}} size={24} onClick={closeModal}/>
            <h1 className="upgrade-plan-modal-title">Skeep Branding</h1>
            <p className="upgrade-plan-modal-subtitle">To remove skeep branding, select from one of our subscription plans below to continue.</p>
            <div className="upgrade-plan-modal-cards-container">
                <BillingPlansCardNew
                    info={premium}
                    disabledButton={false}
                    type="MINI-CARD"
                    // currentPlan={platinum.name_uppercase === billingInformation.plan}
                    // billing={billingInformation}
                    // selected={platinum.name === selectedPlanName}
                    handleSelectedPlan={selected => changeBrandsPlan({
                        selected,
                        billingInformation:accountOBJ,
                        setDisableButton: () => null,
                        init: () => {
                            toaster.success("Plan changed successfully");
                            closeModal();
                        },
                    })}
                />
                <BillingPlansCardNew
                    info={platinum}
                    disabledButton={false}
                    type="MINI-CARD"
                    // currentPlan={platinum.name_uppercase === billingInformation.plan}
                    // billing={billingInformation}
                    // selected={platinum.name === selectedPlanName}
                    handleSelectedPlan={selected => changeBrandsPlan({
                        selected,
                        billingInformation:accountOBJ,
                        setDisableButton: () => null,
                        init: () => {
                            toaster.success("Plan changed successfully");
                            closeModal();
                        },
                    })}
                />
            </div>
            <p className="cta-link" onClick={() => navigate("/billing/plans?table=true")}>Compare all features</p>
        </div>
            <div className="upgrade-plan-modal-overlay" onClick={closeModal}/>
            </>
    )
}

export default UpgradePlanModal;