import {Client} from "Client";
import Sidebar from "components/SideBar/SideBar";
import {useEffect, useState} from "react";
import useAuth from "stores/auth";
import bell from "../assets/icons/bell-notification.svg"
import info from "../assets/icons/info-orange-notification.svg";
import exit from "../assets/icons/exit-notification.svg";
import {SKEEP_STARTER_KIT} from "../utils/finalVariables";

const { Navigate } = require("react-router-dom");

function PrivateRoute({ children, closedNotification, handleClosedNotification }) {

  const [reachedLimit, setReachedLimit] = useState(false);
  const [percentage, setPercentage] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    Client.get('/emp/billing').then((res) => {
      const plan = res.data.plan;
      if(plan === "FREE") {
        setMessage("% of the amount of engagements in your plan have been used. Your experiences will be paused automatically when you finish the allowed amount of engagements. We recommend upgrading your plan as soon as possible.")
      } else if(plan === "BASIC" || plan === "PREMIUM" || plan === "PLATINUM" || plan === "SKEEPX") {
        setMessage("% of the amount of engagements in your plan have been used. To avoid additional costs, we recommend upgrading your plan as soon as possible.")
      }
    })
  }, []);

  useEffect(() => {
    Client.get("/emp/billing").then((res) => {
      const replies = res.data.engagements;
      const repliesQuota = res.data.engagementsQuota;
      const limit = repliesQuota / 2;
      const percent = Math.min(100, (replies / repliesQuota) * 100);
      if(Number.isInteger(percent)) {
        setPercentage(percent)
      } else {
        setPercentage(percent.toFixed(1))
      }
      if(replies >= limit) {
        if(reachedLimit === false) {
          setReachedLimit(true)
          handleClosedNotification(false)
        }
      }
    });
  },[])

  const { jwt, minimized, toggleMinimized } = useAuth();

  const onLogOut = () => {
    window.analytics?.track('Signed Out', {
        userId:  localStorage.getItem("SKP_USERID")
      }, {
        context: {
          groupId: localStorage.getItem("SKP_BRANDID")
        }
      });
    window.analytics?.reset();


    // Clear all localStorage -> except "SKEEP_STARTER_KIT"
    let propName = SKEEP_STARTER_KIT + localStorage.getItem("SKP_BRANDID");
    let isStarterKitDiabled = localStorage?.getItem(propName);
    let videoProp = localStorage.getItem("SHOW_EXPLORE_PAGE");
    localStorage.clear();
    localStorage?.setItem(propName,isStarterKitDiabled);
    localStorage?.setItem("SHOW_EXPLORE_PAGE",videoProp);

    window.location.href = "/login";
  };

  let isAuthenticated = jwt;

  return isAuthenticated ? (
    <div style={{position: "relative", width: "100vw", height: "100vh",overflowY:"auto",overflowX:'hidden'}}>
      <Sidebar
        onMinimized={toggleMinimized}
        minimized={minimized}
        onLogOut={onLogOut}
      />

      <div className="App-page">
      {children}
      </div>
      {!closedNotification && message !== "" && <div style={{position: "fixed", bottom: "60px", right: "90px", padding: "15px 10px", backgroundColor: "#FFE4B4", borderRadius: "8px"}}>
        <img src={exit} style={{width: "7px", height: "7px", position: "absolute", top: 8, right: 8,}} onClick={() => handleClosedNotification(true)} />
          <div style={{display: "flex", flexDirection : "row", }}>
            <img src={info} style={{width: "14px", height: "14px"}} />
            <p style={{maxWidth: "230px", padding: "0 10px", fontSize: "10px"}}>
            {percentage}{message}
            </p>
          </div>
        </div>}
      {!closedNotification && message !== "" && <div style={{position: "fixed", bottom: "10px", right: "20px", zIndex: 100}}>
        <div style={{width: "63px", height: "63px", background: "#F4B037", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={bell} style={{width: "26px", height: "31px"}} />
          <div style={{position : "absolute", top: -5, right: 0, width: "20px", height: "20px", borderRadius: "50%", background: "#EE6A5F"}} />
        </div>
      </div>}
    </div>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
