import {SearchIcon, TextInput} from "evergreen-ui";
import {IoClose} from "react-icons/io5";

const SearchInput = ({searchInput, setSearchInput,from}) => {
    return (<div style={{position: "relative", margin: from==="onboarding"?"12px 0 0px":"20px 0 0px"}}>
        <SearchIcon
            style={{
                width: "18px", height: "16px", position: "absolute", top: 14, left: 8, fill: "#96989b",
            }}
        />
        {searchInput?.length > 1 && (<IoClose
            size={16}
            style={{
                position: "absolute", top: 14, left: 219, fill: "#96989b", cursor: "pointer",
            }}
            onClick={() => setSearchInput("")}
        />)}
        <TextInput
            className="added-product-input-box"
            height={44}
            width={253}
            paddingLeft={35}
            borderRadius={8}
            size="large"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            fontSize={16}
        ></TextInput>
    </div>)
}

export default SearchInput;