import {QUIZINTRO, SWIPERINTRO} from "pages/EditorPage/design_templates";
import React from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import uploadToS3 from "utils/uploadToS3";
import {camelCaseToTitleCase} from "utils/utls";
import ColorPickerWrapper from "../../ColorPickerWrapper";
import FontPickerWrapper from "../../FontPickerWrapper";
import DesignDisabledError from "../../DesignDisabledError";

const EditorExpIntroDesign = () => {
  const { experience, setColorPicker, colorPicker } = useEditor();
  const { addEvents } = useEditorEvents();
  const INTRO = (experience.type === "QUIZ" && QUIZINTRO) || (experience.type === "SWIPER" && SWIPERINTRO);
  const design = experience?.design;
  console.log("design",design);


  const pickerOpts = {
    types: [
      {
        description: "Fonts",
        accept: {
          "font/*": [".ttf"],
        },
      },
    ],
    excludeAcceptAllOption: true,
    multiple: false,
  };

  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };

  async function handleGetFontFile(option, heading, item) {
    if (option.value === "Custom") {
      try {
        let showFile = await window.showOpenFilePicker(pickerOpts);
        let file = await showFile[0].getFile();
        uploadToS3(file).then((res) => {
          addEvents("design", "update", {
            [`fonts/${heading + "_" + item}`]: res,
          });
        });
      } catch (e) {}
    } else {
      addEvents("design", "update", {
        [`fonts/${heading + "_" + item}`]: option.value,
      });
    }
  }

  if (design?.introScreen === "disabled") {
    return (
        <div className="design-v2-rows">
          <DesignDisabledError text='To edit the design, enable "Intro screen" in "content" tab'  />
        </div>
    )
  }
  return (
    <div className="design-v2-rows">
      <div className="font-controller">
        {Object.keys(INTRO).map((heading, idx) => {
          return Object.keys(INTRO[heading]).flatMap((item, i) => {
            if (INTRO[heading][item] === "font") {
              return (
                <div key={idx + i + ""}>
                  <div className="design-v2-font-controllers">
                    <div className="design-v2-font-row">
                      <p className="design-v2-font-label">
                        {camelCaseToTitleCase("font")}
                      </p>
                      <FontPickerWrapper
                        heading={heading}
                        item={item}
                        handleGetFontFile={handleGetFontFile}
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          });
        })}
      </div>
      <div className="design-v2-colors-controller">
        <div className="design-property-row design-row-titles">
          <p className="design-property-name" />
          <div className="design-properties-titles design-properties">
            <p>Background</p>
            <p>Border</p>
            <p>Text</p>
          </div>
        </div>
        <div className="design-v2-rows">
          {Object.keys(INTRO).flatMap((heading) => (
            <div className="design-property-row">
              <p className="design-property-name">
                {camelCaseToTitleCase(heading)}
              </p>
              <div className="design-properties">
                {Object.keys(INTRO[heading]).map((item, idx) => {
                  if (INTRO[heading][item] === "font") {
                    return null;
                  } else {
                    if (
                    INTRO[heading][item] === "color" ||
                    INTRO[heading][item] === "gradientColor"
                  ) {
                    return (
                      <ColorPickerWrapper
                        key={heading + INTRO[heading][item] + idx}
                        type={INTRO}
                        prefix="intro"
                        designOBJ={experience?.design}
                        heading={heading}
                        item={item}
                        handleColor={handleColor}
                      />
                    );
                  } else {
                    return (
                      <div
                        style={{
                          minWidth: "22px",
                          margin: "0px 21.5px",
                          color: "#DBE1E7",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "24px",
                          height: "1px",
                          border: "1px solid #DBE1E7",
                        }}
                      ></div>
                    );
                  }
                }})}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditorExpIntroDesign;
