import IdeaSvg from '../../../assets/icons/idea.svg';
import "./style.scss";
import InfoWithTooltip from "../../../components/InfoWithTooltip/InfoWithTooltip"
import { Position } from 'evergreen-ui';
function Insights({insights}) {
    return <div className="insights">
        <div><img src={IdeaSvg}/><span className="heading">Insights</span> <InfoWithTooltip text={"Key takeaways from your current data"} position={Position.RIGHT} /></div>
      
        <div>
            {insights?.top5Tags?.length > 0 && <div className="card">
                <div className="card-header">
                    <div className="card-title">5 Popular Tags</div>
                </div>
                <div className="card-body" >
                    {insights?.top5Tags.map(tag => <div className="tag">
                        <div className="tag-type">{tag.type?.toLowerCase()}</div>
                        <div className="tag-name">
                            {tag.name}
                        </div>
                    </div>)}
                </div>

            </div>}
            {insights?.mostRecommendedProducts && <div className="card">
                <div className="card-header">
                    <div className="card-title">Most Recommended Products</div>

                </div>
                <div className="card-body">
                    {insights?.mostRecommendedProducts?.map((product,index) => (
                        <div className="product">
                            <img className="product-image" src={product?.images?.[0]}/>
                            <div className="product-info">
                                <div className="product-name">{product?.name}</div>
                                <div className="card-subtitle">{insights?.recommendationTimes?.[index]} time{insights?.recommendationTimes?.[index] > 1 ? "s" : ""}</div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>}
            {insights?.mostPurchasedTag && <div className="card">
                <div className="card-header">
                    <div className="card-title">More purchases were made by customers who answered the tag</div>
                </div>
                <div className="card-body">
                    <div className="tag">
                        <div className="tag-type">{insights?.mostPurchasedTag?.type?.toLowerCase()}</div>
                        <div className="tag-name">
                            {insights?.mostPurchasedTag?.name}
                        </div>
                    </div>
                </div>

            </div>}
            {insights?.mostSoldProducts &&
                <div className="card">
                <div className="card-header">
                    <div className="card-title">Best Selling Products</div>

                </div>
                <div className="card-body">
                    {insights?.mostSoldProducts?.slice(0,3)?.map((product,index) => (
                        <div className="product">
                            <img className="product-image" src={product?.images?.[0]}/>
                            <div className="product-info">
                                <div className="product-name">{product?.name}</div>
                                <div className="card-subtitle">{insights?.mostSoldProductTimes?.[index]} time{insights?.mostSoldProductTimes?.[index] > 1 ? "s" : ""}</div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>}
            {insights?.citiesFinishedQuizzesMost.length > 0 && (
                <div className="card">
                    <div className="card-header" style={{marginBottom:12}}>
                        <div className="card-title">Users from the following cities are more likely to finish the quiz</div>
                    </div>
                    <div className="card-body" style={{display:"flex",flexWrap:"wrap",gap:8}}>
                        {insights?.citiesFinishedQuizzesMost?.map((cityOBJ,index) => (
                            <div className="insight-cities">
                                <div className="product-info">
                                    <div className="product-name">{cityOBJ?.name}</div>
                                    <div className="card-subtitle">{(cityOBJ?.totalFinished * 100).toFixed(0)}% Finished</div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            )}
        </div>
    </div>
}

export default Insights;