import {Dialog, toaster} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import {ReactComponent as CreateExp} from "../../../assets/icons/createexp.svg";

import "./index.scss";
import {Client} from "../../../Client";
import CreateDialogButton from "./CreateDialogButton";
import {Step1} from "./components/Step1";
import {Step2} from "./components/Step2";
import EditorBackButton from "../../EditorPage/components/EditorBackButton";

export default function CreateDialog({
  show,
  setShow,
  onCreate,
  setFailed = () => {},
}) {
  const [q, setQ] = useState("");
  const [step, setStep] = useState(1);
  const [savedList, setSavedList] = useState({ products: [], collections: [] });
  const [selected, setSelected] = useState("collection");
  const [experience, setExperience] = useState({
    name: "",
    type: "QUIZ",
    products: [],
    collections: [],
  });

  const [countDown, setCountDown] = useState(60);
  const [runTimer, setRunTimer] = useState(false);

  useEffect(() => {
    let timerId;
    if (runTimer) {
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown > 0) {
      setFailed(false);
    } else {
      setFailed(true);
      setShow(false);
    }
  }, []);

  const onCreateExp = async () => {
    setRunTimer(true);
    Client.post(`emp/experiences`, {
      brandID: localStorage.getItem("SKP_BRANDID"),
      environment: window.location.hostname === "emp.skeep.io" ? "prod" : "dev",
      products:
        selected === "products" ? savedList.products.map((p) => p.id) : null,
      collections:
        selected !== "products"
          ? savedList.collections.map((c) => c.handle)
          : null,
      collectionIDs: savedList.collections.map((c) => c?.id),
      path: "/",
      name: experience.name,
      implementationType: "POPUP",
      type: experience.type,
    })
      .then((res) => {
        // toaster.success("Creating your experience...", {
        //   duration: 8,
        //   description: `It will be ready in a few seconds`,
        // });  
        setFailed(false);
        setShow(false);
        onCreate();
      })
      .catch((err) => {
        toaster.danger("There was a problem creating the experience.");
        setShow(false);
        setFailed(true);
      });
  };

  const disableNextButton = !experience.name;

  const getStepTemplate = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            show={show}
            setShow={setShow}
            experience={experience}
            setExperience={setExperience}
          />
        );
      case 2:
        return (
          <Step2
            savedList={savedList}
            setSavedList={setSavedList}
            onCreateExp={onCreateExp}
            selected={selected}
            setShow={setShow}
            setSelected={setSelected}
            key={show}
          />
        );
      default:
        return (
          <Step1
            show={show}
            setShow={setShow}
            experience={experience}
            setExperience={setExperience}
          />
        );
    }
  };

  return (
    <Dialog
      isShown={show}
      shouldCloseOnOverlayClick={false}
      onCloseComplete={() => {
        setShow(false);
        setExperience({
          name: "",
          type: "",
          products: [],
          collections: [],
        });
        setStep(1);
        setSavedList({ products: [], collections: [] });
        setSelected("collection");
      }}
      hasHeader={false}
      hasFooter={false}
      containerProps={{
        width: 640,
        margin: "auto",
        minHeight: step === 2 && "639px",
        borderRadius: 16,
      }}
    >
      {step === 2 && (
        <div style={{ marginTop: 20 }}>
          <EditorBackButton onClick={() => setStep(1)} />
        </div>
      )}
      <div
        className="create-experience-dialog"
        style={{
          height: step === 1 ? "316px" : "570px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          padding: step === 2 && 0,
        }}
      >
        <div className="create-header">
          Let’s generate an experience
          {step === 1 ? <CreateExp /> : ""}
        </div>
        <div className="create-subheader">
          Step {step}/2 -{" "}
          {step === 1
            ? "Enter the name of the experience and select which type of experience you would like to create"
            : "Select which collection / products (at least 5) you wish to include in the experience "}
        </div>

        {getStepTemplate()}

        {step === 1 && (
          <CreateDialogButton
            text="Next"
            disabled={disableNextButton}
            onClick={() => setStep(step + 1)}
            style={{
              position: "absolute",
              bottom: "2rem",
              right: "2rem",
              width: "150px",
            }}
          />
        )}
      </div>
    </Dialog>
  );
}
