import {Client} from "Client";
import CustomTagsInput from "components/CustomTags/CustomTagsInput";
import CustomTagsSearchV2 from "components/CustomTags/CustomTagsSearchV2";
import CustomInput from "components/Forms/CustomInput";
import {Pane} from "evergreen-ui";
import {useEffect, useState} from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {toTitleCase} from "utils/utls";
import "./add-tag-dialog.scss";
import {Modal} from "@mantine/core";
import {useTags} from "stores/use-tags";

const AddTagDialog = () => {
  const {
    experience,
    activeQuestionIndex,
    editAnswerIndex,
    questions,
    addToTagMap,
  } = useEditor();
  const { showAddTagDialog, setShowAddTagDialog } = useTags();
  const { addEvents } = useEditorEvents();
  const [tagName, setTagName] = useState("");
  const [products, setProducts] = useState([]);
  const [disable, setDisable] = useState(true);
  useEffect(() => {
    if (tagName && products.length > 0) {
      setDisable(false);
    }
  }, [tagName, products]);

  return (
    <Pane>
      <Modal
        opened={showAddTagDialog}
        title="Create Tag"
        onClose={() => setShowAddTagDialog(false)}
        size={600}
        radius={10}
        centered
      >
        <div className="add-tag-dialog">
          <div className="inputs">
            {/* @ts-ignore */}
            <CustomInput
              label="Tag Name"
              placeHolder={"e.g Acne"}
              width={"45%"}
              value={tagName}
              onChange={(e) => setTagName(toTitleCase(e.target.value))}
            />
            {/* @ts-ignore */}
            <CustomTagsSearchV2
              api={`emp/experiences/${experience?.id}/products`}
              label="Add Products *"
              labelKey="name"
              placeHolder="Search for product"
              controlledTags={products}
              onChange={(products) => {
                setProducts(products);
              }}
            />
          </div>
          <div className="tag-inputs">
            {products.map((p) => (
              /* @ts-ignore */
              <CustomTagsInput
                key={p.id}
                deleteTag={() => {
                  const index = products.indexOf(p);
                  setProducts([
                    ...products.slice(0, index),
                    ...products.slice(index + 1),
                  ]);
                }}
                tag={p.name}
              />
            ))}
          </div>
          <div className="container">
            <button
              onClick={() => {
                setShowAddTagDialog(false);
              }}
              className="cancel dialog-btn"
              style={{ color: "#75787E", marginRight: "16px", background: 'none' }}
            >
              Cancel
            </button>
            <button
              className="confirm dialog-btn"
              disabled={disable}
              onClick={async () => {
                const res = await Client.post(`/emp/tags`, {
                  name: tagName,
                });
                const tagId = res.data.id;
                const promises = [];
                products.forEach((p) => {
                  promises.push(
                    Client.post(`/emp/products/${p.id}/tags/${tagId}`)
                  );
                });
                await Promise.all(promises);
                addToTagMap(res.data);
                addEvents("question", "update", {
                  questionId: questions[activeQuestionIndex].id,
                  quesIdx: activeQuestionIndex,
                  answers: [
                    ...questions[activeQuestionIndex].answers.slice(
                      0,
                      editAnswerIndex
                    ),
                    {
                      ...questions[activeQuestionIndex].answers[
                        editAnswerIndex
                      ],
                      tagIDs: [
                        ...questions[activeQuestionIndex].answers[
                          editAnswerIndex
                        ]?.tagIDs,
                        tagId,
                      ],
                    },
                    ...questions[activeQuestionIndex].answers.slice(
                      editAnswerIndex + 1
                    ),
                  ],
                });
                setShowAddTagDialog(false);
              }}
              style={{
                background: disable ? "#75787E" : "#017CF8",
                marginRight: "16px",
                color: "#fff",
              }}
            >
              OK, Add
            </button>
          </div>
        </div>
      </Modal>
    </Pane>
  );
};

export default AddTagDialog;
