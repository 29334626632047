import CustomTextArea from "components/Forms/CustomTextArea";
import { useEffect, useState } from "react";
import useEditor from "stores/use-editor";
import { useEditorEvents } from "stores/use-editor-events";
import { ReactComponent as QuesIcon } from "../../../../../assets/icons/quesIcon.svg";
import QuestionContent from "./QuestionContent";
import QuesTypeSlider from "./QuesTypeSlider";
import { DEFAULT_QUESTION } from "../../../../../utils/finalVariables";
import { debounceInput } from "../../../../../utils/utls";
import { ReactComponent as NewIconAi } from "../../../../../assets/icons/ai-new-question.svg";
import { Tooltip, Position } from "evergreen-ui";
import GenerateQuestionDialog from "./QuestionContent/GenerateQuestionDialog";
import useGenerateQuestion from "hooks/useGenerateQuestion";
import { ReactComponent as QuestionLoader } from "../../../../../assets/images/generate-question-loader.svg";
import Skeleton from "react-loading-skeleton";
export default function AddQuestion() {
  const {
    activeQuestionIndex,
    questions,
    changeActiveQuestionIndex,
    setIsEditing,
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const [selected, setSelected] = useState("SELECTION");
  const [generate, setGenerate] = useState(false);
  const { handleGenerate, loading, question, dictionary, started } =
    useGenerateQuestion();
  const setGeneratedQuestion = useEditor((state) => state.setGeneratedQuestion);

  useEffect(() => {
    if (question && dictionary) {
      setGeneratedQuestion(question, dictionary);
    }
  }, [question, dictionary, setGeneratedQuestion]);
  return started && !question ? (
    <div className="generate-question">
      <div className="quesIcon" style={{ background: "rgb(199, 227, 255)" }}>
        <QuesIcon fill="#017CF8" />
      </div>
      <div className="animation">
        <Skeleton borderRadius={10} baseColor="#efdbfd" height={30} />
      </div>
    </div>
  ) : (
    <div>
      <div className="question-types add-question">
        <div className="title" style={{ display: "flex" }}>
          <div
            style={{
              width: 20,
              marginLeft: 4,
              height: 20,
              borderRadius: 4,
            }}
            className="ques-icon"
          >
            <div
              className="quesIcon"
              style={{ background: "rgb(199, 227, 255)" }}
            >
              <QuesIcon fill="#017CF8" />
            </div>
          </div>
          {/* @ts-ignore */}

          <CustomTextArea
            // charLimit={150}
            forceStyle={{
              position: "relative",
            }}
            placeHolder={DEFAULT_QUESTION}
            autoFocus
            value={
              questions[activeQuestionIndex]?.question === DEFAULT_QUESTION
                ? questions[activeQuestionIndex]?.question
                : undefined
            }
            onChange={debounceInput((e) => {
              setIsEditing(true);
              addEvents("question", "update", {
                quesIdx: activeQuestionIndex,
                questionId: questions[activeQuestionIndex].id,
                question: e.target.value,
                isNew: true,
              });
            }, 500)}
          />
          <Tooltip
            statelessProps={{
              backgroundColor: "#E4F1FF",
              color: "#75787E",
              fontSize: "14px",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            }}
            position={Position.RIGHT}
            content={<p style={{ color: "#75787E" }}>Generate Question</p>}
          >
            <NewIconAi className="icon-ai" onClick={() => setGenerate(true)} />
          </Tooltip>
        </div>

        <QuesTypeSlider
          selected={selected}
          onChange={(type) => {
            setSelected(type);
            setIsEditing(true);
            if (type === "IMAGE_SELECTION") {
              addEvents("question", "update", {
                questionId: questions[activeQuestionIndex].id,
                quesIdx: activeQuestionIndex,
                type,
              });
              questions[activeQuestionIndex].answers.forEach((answer) => {
                addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/${answer.id}`]:
                    "https://skeep-public.s3.eu-central-1.amazonaws.com/selection-placeholder.png",
                });
              });
            } else {
              addEvents("question", "update", {
                questionId: questions[activeQuestionIndex].id,
                quesIdx: activeQuestionIndex,
                type,
              });
              questions[activeQuestionIndex].answers.forEach((answer) => {
                addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/${answer.id}`]:
                    undefined,
                });
              });
            }
            changeActiveQuestionIndex(questions.length - 1);
          }}
        />
        <QuestionContent type={selected} />
      </div>
      {generate && (
        <GenerateQuestionDialog
          loading={loading}
          handleGenerate={handleGenerate}
          shown={generate}
          setShown={setGenerate}
        />
      )}
    </div>
  );
}
