import CustomTextArea from "components/Forms/CustomTextArea";
import { Button, CaretDownIcon, Dialog, Pane, Popover, Position } from "evergreen-ui";
import { useState } from "react";
import { ReactComponent as CrossIcon } from "../../../../../../assets/icons/cross-new.svg";
import { ReactComponent as MagicIcon } from "../../../../../../assets/icons/magic-tool.svg";
import useEditor from "../../../../../../stores/use-editor";
import "./GenerateQuestion.scss";

const GenerateQuestionDialog = ({ shown, setShown, handleGenerate, loading }) => {
  const options = [
    { value: "Expert", label: "Expert" },
    { value: "Informative", label: "Informative" },
    {
      value: "Direct",
      label: "Direct",
    },
    { value: "Personalized", label: "Personalized" },
    { value: "Engaging", label: "Engaging" },
    {
      value: "Thoughtful",
      label: "Thoughtful",
    },
  ];
  const experience = useEditor((state) => state.experience);
  const questions = useEditor((state) => state.questions);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const[click,setClick]=useState(false)
  const [questionTopic, setQuestionTopic] = useState("");



  return (
    <Pane>
      <Dialog
        hasHeader={false}
        isShown={shown}
        width={650}
        containerProps={{
          marginTop: "200px",
          height: "430px",
          borderRadius: "16px",
          poisition: "relative",
        }}
        hasFooter={false}
        overlayProps={{
          zIndex: 21,
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        }}
        onCloseComplete={() => setShown(false)}
      >
        <CrossIcon className="cross-icon" onClick={() => setShown(false)} />
        <div className="generate-question-wrapper">
          <p className="dialog-title">Generate Question</p>
          <p className="generate-question-subtitle">
            Get help writing questions and answers with automatic text
            generation.
          </p>
          <p className="question-topic">Question topic</p>
          <CustomTextArea
            charLimit={120}
            placeHolder={"e.g.  Question about Size, Color  "}
            width={"350px"}
            forceStyle={{
              paddingBottom: "30px",
            }}
            onChange={(e) => {
              setQuestionTopic(e.target.value);
            }}
            value={questionTopic}
          />
          <p className="subtitle2">Tone of voice</p>
          <Popover
          statelessProps={{
            borderRadius:"10px",
          }}
          onOpen={()=>{
            setClick(true)
          }}
            minWidth={150}
            content={({ close }) => (
              <Pane
                className="pane-container"
                width={170}
                height={140}
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                {options.map((option) => (
                  <div
                    className={
                      selectedOption?.value === option?.value
                        ? " dropdown-item-selected "
                        : "dropdown-item"
                    }
                    onClick={() => {
                      setClick(true)
                      setSelectedOption(option);
                      close();
                    }}
                    key={option.value}
                  >
                    {option.label}
                  </div>
                ))}
              </Pane>
            )}
            position={Position.BOTTOM_LEFT}
          >
            <Button className= {click?"btn-active":"btn"} >
              <p className="selected-name">{selectedOption.value}</p>

              <CaretDownIcon className="icon" />
            </Button>
          </Popover>
          <button
            className={
              selectedOption === undefined || questionTopic === ""
                ? "generate-btn-disabled"
                : "generate-btn-active"
            }
            disabled={
              selectedOption === undefined || questionTopic === "" || loading
            }
            onClick={() =>{
              handleGenerate(
                questionTopic,
                selectedOption.value,
                experience.id,
                questions.length - 1
              )}
            }
          >
            <MagicIcon />
            {loading ? "Loading..." : "Generate"}
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default GenerateQuestionDialog;

