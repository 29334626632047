import ResultsContent from "pages/ProductRecommendationPage/components/ResultsContent";
import ResultsDesign from "pages/ProductRecommendationPage/components/ResultsDesign";
import useEditor from "stores/use-editor";
import {SWIPER, TAGS} from "../design_templates";
import Content from "./Editor/Content";
import Design from "./Editor/Design";
import EmailContent from "./Editor/Email/EmailContent";
import EmailDesign from "./Editor/Email/EmailDesign";
import EditorExpIntro from "./Editor/ExpIntro/EditorExpIntro";
import EditorExpIntroDesign from "./Editor/ExpIntro/EditorExpIntroDesign";
import SwiperContent from "./Editor/SwiperContent";
import TagsContent from "./Editor/TagsContent";

const RenderContentOrDesign = () => {
  const { tabs, experience } = useEditor();

  const getExpContent = () => {
    if (experience?.type === "QUIZ") return <Content />;
    if (experience?.type === "TAGS_SELECTION") return <TagsContent />;
    if (experience?.type === "SWIPER") return <SwiperContent />;
  };
  const getExpDesign = () => {
    if (experience?.type === "QUIZ") return <Design />;
    if (experience?.type === "TAGS_SELECTION") return <Design type={TAGS} />;
    if (experience?.type === "SWIPER") return <Design type={SWIPER} />;
  };

  const getTemplate = () => {
    switch (tabs) {
      case "intro-content":
        return <EditorExpIntro />;
      case "intro-design":
        return <EditorExpIntroDesign />;
      case "experience-content":
        return getExpContent();
      case "experience-design":
        return getExpDesign();
        case "experience-logic":
          return getExpContent();
      case "email-content":
        return <EmailContent />;
      case "email-design":
        return <EmailDesign />;
      case "results-content":
        return <ResultsContent />;
      case "results-design":
        return <ResultsDesign />;
      default:
        return;
    }
  };
  return getTemplate();
};

export default RenderContentOrDesign;
