import create from "zustand";
import { devtools } from "zustand/middleware";
import {MediaGalleryState} from "./Types/EditorTypes";

export const useMediaGallery = create<MediaGalleryState>()(
  devtools((set) => ({
    galleryImages: [],
    setGalleryImages: (images) => {
      set({ galleryImages: images });
    },
  }))
);
