import CustomInput from "components/Forms/CustomInput";
import {useState} from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {useTags} from "stores/use-tags";
import {ReactComponent as Arrow} from "../../../../../assets/icons/arrow.svg";
import {ReactComponent as Delete} from "../../../../../assets/icons/delete.svg";
import {ReactComponent as TagsIcon} from "../../../../../assets/icons/tagsicon.svg";
import TagsDeleteDialog from "../Dialog/TagsDeleteDialog";
import TagComponent from "./components/TagComponent";
import "./TagsSelection.scss";

const TagsQuestion = ({ question, tags, quesIdx }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const {
    changeActiveQuestionIndex,
    activeQuestionIndex,
    updateQuestions,
    questions,
    experience,
  } = useEditor();
  const { tagsDeleteDialog } = useTags();
  const { addEvents } = useEditorEvents();

  const tagTypes = experience?.design?.tagTypes;
  const questionType = tags[0]?.type;

  //Filtering out the duplicate tags
  const tagIds = tags.map((o) => o.id);
  const filteredTags = tags.filter(
    ({ id }, index) => !tagIds.includes(id, index + 1)
  );

  const handleDeleteQuestion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (tagsDeleteDialog.includes("tagQuestion")) {
      addEvents("design", "update", {
        tagTypesToIgnore: [
          ...(experience?.design?.tagTypesToIgnore ?? []),
          questions[quesIdx].tags?.[0]?.type,
        ],
      });
      addEvents("question", "delete", {
        quesIdx: activeQuestionIndex,
        questionId: questions[activeQuestionIndex].id,
      });
    } else {
      setShowDeleteDialog(true);
    }
  };

  return (
    <div className="tags-question">
      {activeQuestionIndex !== quesIdx ? (
        <div
          className="title"
          onClick={() => {
            changeActiveQuestionIndex(quesIdx);
          }}
        >
          <TagsIcon fill="#5C42E1" />
          {tagTypes?.[questionType]?.question ?? question}
          <Arrow className="arrow" />
        </div>
      ) : (
        <>
          <div className="title">
            <TagsIcon fill="#5C42E1" />
            <CustomInput
              placeHolder={question}
              value={tagTypes?.[questionType]?.question ?? question}
              height={"37px"}
              size={"large"}
              width={"80%"}
              name="tag-question"
              onChange={(e) => {
                addEvents("design", "update", {
                  tagTypes: {
                    ...(tagTypes ?? {}),
                    [tags[0]?.type]: {
                      ...tagTypes?.[questionType],
                      question: e.target.value,
                    },
                  },
                });
              }}
            />
            <Arrow
              style={{ transform: "rotate(180deg)" }}
              className={activeQuestionIndex !== quesIdx ? "arrow" : ""}
              onClick={() => {
                changeActiveQuestionIndex(undefined);
              }}
            />
            <Delete fill="#EE6A5F" onClick={handleDeleteQuestion} />

            <TagsDeleteDialog
              show={showDeleteDialog}
              setShow={setShowDeleteDialog}
              name={"tagQuestion"}
            />
          </div>

          <div className="tags-question-tagsHolder">
            {filteredTags?.map((tag) => (
              <TagComponent tag={tag} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TagsQuestion;
