/* eslint-disable jsx-a11y/alt-text */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Header from "components/Header/Header";
import { Pagination, Tooltip } from "evergreen-ui";
import GuidencePopup from "pages/ProductPage/components/GuidencePopup";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import useEditor from "stores/use-editor";
import { ReactComponent as AlertSVG } from "../../assets/icons/alert.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-gray.svg";
import { ReactComponent as NoProduct } from "../../assets/icons/noproduct-new.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/product-edit-icon.svg";
import { Client } from "../../Client";
import EditorBackButton from "../EditorPage/components/EditorBackButton";
import LoadingPage from "../LoadingPage/LoadingPage";
import Filters from "./components/Filters";
import "./ProductPage.scss";
import ProductPageSkeleton from "./ProductPageSkeleton";
import useProducts from "./use-products";

import DeleteProductDialog from "./components/DeleteProductDialog";
import AddTag from "./components/EditProduct/AddTag";
import ProductRowMenu from "./components/ProductRowMenu";
import DeleteTagDialog from "./components/DeleteTagDialog";
import DropdownEdit from "./components/EditProduct/DropdownEdit";
import CreateTagPopup from "components/CreateTagPopup/CreateTagPopup";
import useAuth from "../../stores/auth";

export default function ProductPage() {
  const {
    names,
    page,
    totalPages,
    setPage,
    setTotalPages,
    productTypes,
    tags,
    productIds,
  } = useProducts();
  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(false);
  const [productLoading, setProductLoading] = useState(true);
  const [selectedOpen, setSelectedOpen] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { experience, fetchExperience } = useEditor();
  const [showGuidancePopup, setShowGuidancePopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedTag, setSelectedTag] = useState();
  const [editing, setEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(undefined);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const { id: experienceId } = useParams();
  const [allProducts, setAllProducts] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const oldPage = useRef(1);
  const [forceRefetch, setForceRefetch] = useState(Math.random());
  const { accountOBJ, setAccount } = useAuth();
  const [isShopifyUser, setIsShopifyUser] = useState(false);



  useEffect(() => {
    Client.get(`/emp/experiences/${experienceId}/products`).then((res) => {
      setAllProducts(res.data);
    });
  }, [experience?.id, forceRefetch]);

  useEffect(() => {
    setLoading(true);
    Client.get("/emp/billing").then((res) => {
      setAccount(res.data);
      setIsShopifyUser(res.data?.integrations.includes("SHOPIFY"));
      setLoading(false);
    });
  }, [forceRefetch]);

  

  const fetchProducts = useCallback(
    async (page, showLoading = true) => {
      return Client.post(`/emp/products?page=${page - 1}`, {
        names: names,
        tags: tags.map((t) => t.id),
        types: productTypes.map((pt) => pt.id),
        experienceID: experienceId,
        productIDs: productIds,
      })
        .then((res) => {
          setTotalProducts(res.data?.totalElements);

          const filterOutCustomTags = res.data?.content?.map((productOBJ) => ({
            ...productOBJ,
            tags: productOBJ?.tags?.filter(
              (tagOBJ) => tagOBJ?.type !== "CUSTOM"
            ),
            customTags: productOBJ?.tags?.filter(
              (tagOBJ) => tagOBJ?.type === "CUSTOM"
            ),
          }));
          setListOfProducts(filterOutCustomTags);
          setTotalPages(res.data.totalPages === 0 ? 1 : res.data.totalPages);
          setPage(page);
          setSelectedProducts([]);
          setProductLoading(false);

          Client.get("/emp/billing").then((res) => {
            setAccount(res.data);
            setLoading(false);
            setSelectedProduct((p) =>
              filterOutCustomTags.find((product) => product.id === p?.id)
            );
            setSelectedTag(undefined);
          });

          return res;
        })
        .catch(() => {
          setProductLoading(false);
        });
    },
    [
      names,
      tags,
      productTypes,
      experienceId,
      productIds,
      setTotalPages,
      setPage,
    ]
  );

  const handleOnEditChange = useCallback(
    (editing) => {
      setEditing(editing);
      fetchProducts(page);
    },
    [page, fetchProducts]
  );
  useEffect(() => {
    fetchExperience(experienceId);
  }, [experienceId, fetchExperience]);
  useEffect(() => {
    let p = page;
    if (oldPage.current === page) {
      p = 1;
    } else {
      oldPage.current = page;
    }
    fetchProducts(p).then((res) => {
      if (
        (!res.data.content || res.data.content.length < 1) &&
        page === 1 &&
        names.length === 0 &&
        productTypes.length === 0 &&
        tags.length === 0 &&
        productIds.length === 0
      ) {
        setSkeleton(true);
      } else {
        setSkeleton(false);
      }
    });
  }, [page, names, productTypes, tags, forceRefetch, productIds, experienceId]);

  const listOfProductsSorted = listOfProducts
    .map((product) => ({
      ...product,
      missingTags: !product?.tags.length > 0 || !product?.type,
    }))
    .sort((a, b) => Number(a.missingTags) - Number(b.missingTags));

  const handleProductDelete = async (product) => {
    if (product) {
      await Client.delete(
        `/emp/experiences/${experienceId}/product/${product?.id}`
      );
      fetchProducts(page).then((res) => {
        setDeleteModal(undefined);
      });
      // setForceRefetch(Math.random());
    }
  };

  if (skeleton) return <ProductPageSkeleton />;
  const handleSyncProducts = async () => {
    // handle
    await Client.post(`/emp/experiences/${experienceId}/products/sync`, {});
    setForceRefetch(Math.random);
  };



  return (
    <div className="app-page product-page">
      <Header
        name={experience?.name}
        videoUrl={"https://www.youtube.com/watch?v=f7NwyBnIRTE"}
      />
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div
            className="products-head"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <EditorBackButton
              onClick={() => {
                navigate("/experiences");
              }}
            />
            <div className="products-header">
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="products-header-text"
              >
                Products mapping{" "}
                <InfoIcon
                  className="products-header-icon"
                  onClick={() => setShowGuidancePopup(true)}
                />
                <p style={{ color: "#75787E" }}>
                  &nbsp; | {totalProducts} products
                </p>
              </h1>
             {isShopifyUser&& <div
                style={{ gap: "5px" }}
                className="products-resync products-filters-right-container"
              >
                <p
                  style={{
                    color:
                      accountOBJ?.syncProducts === accountOBJ?.syncProductsQuota
                        ? "#E45447"
                        : "#75787E",
                    fontSize: "14px",
                  }}
                >{`${accountOBJ?.syncProducts}/${accountOBJ?.syncProductsQuota} Syncing Rounds`}</p>
                <Tooltip
                  statelessProps={{
                    height: "110px",
                    width: "200px",
                    marginTop: "10px",
                    fontSize: "10px",
                    backgroundColor: "#E4F1FF",
                    borderRadius: "8px",
                  }}
                  content={
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#75787E",
                        fontFamily: "Satoshi",
                        fontWeight: "400",
                      }}
                    >
                      Re-sync products process will pull the following
                      information again for the experience products:
                      <br />
                      &#x2022; Name
                      <br />
                      &#x2022; Price
                      <br />
                      &#x2022; Description
                      <br />
                      &#x2022; Images
                    </span>
                  }
                >
                  <InfoIcon
                    className="info-icon"
                    style={{ width: "10px", height: "10px" }}
                  />
                </Tooltip>
                <div
                  onClick={() => {
                    if (
                      accountOBJ?.syncProducts !== accountOBJ?.syncProductsQuota
                    ) {
                      handleSyncProducts();
                    }
                  }}
                  className={`products-filters`}
                  style={{
                    cursor: "pointer",
                    color:
                      accountOBJ?.syncProducts === accountOBJ?.syncProductsQuota
                        ? "#75787E"
                        : "#017CF8",
                    backgroundColor:
                      accountOBJ?.syncProducts === accountOBJ?.syncProductsQuota
                        ? "#F3F5F7"
                        : "#E4F1FF",
                  }}
                >
                  <p className="products-filters-text">Re-sync products</p>
                </div>
              </div>}
            </div>
          </div>
          <p className="products-subheader">
            Products related to the experience
          </p>
          <Filters
            onEditChange={handleOnEditChange}
            selectedProducts={selectedProducts}
            allProducts={allProducts}
            setForceRefetch={setForceRefetch}
          />
          {productLoading ? (
            <LoadingPage />
          ) : listOfProductsSorted.length ? (
            <>
              <TableContainer
                style={{ padding: "0 40px" }}
                className={"product-table-container"}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className="products-table-header"
                      >
                        Products
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className="products-table-header"
                      >
                        Name
                      </TableCell>
                      {accountOBJ?.industry !== "SASS" && (
                        <TableCell
                          style={{ borderBottom: "none" }}
                          className="products-table-header"
                        >
                          Product ID
                        </TableCell>
                      )}
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className="products-table-header"
                      >
                        Product Type
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className="products-table-header"
                      >
                        Tags
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className="products-table-header"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listOfProductsSorted?.map((product, index) => {
                      return (
                        <TableRow
                          onMouseEnter={() => setIsHovering(product)}
                          onMouseLeave={() => setIsHovering(undefined)}
                          key={product.id}
                        >
                          <TableCell className="products-table-tag">
                            <img
                              src={
                                accountOBJ?.industry === "SASS"
                                  ? "https://s3.eu-central-1.amazonaws.com/static.skeep.io/images/plan.svg"
                                  : product?.images[0]
                              }
                              className="products-table-image"
                            />
                          </TableCell>
                          <TableCell className="products-table-tag products-table-medium">
                            <p>{product.name}</p>
                          </TableCell>
                          {accountOBJ?.industry !== "SASS" && (
                            <TableCell className="products-table-tag products-table-medium">
                              <p>{product.externalID}</p>
                            </TableCell>
                          )}
                          <TableCell className="products-table-tag products-table-medium">
                            {product?.tags?.find(
                              (pt) => pt.type === "PRODUCT_TYPE"
                            ) ? (
                              <div
                                className={
                                  selectedProduct?.id === product?.id
                                    ? "products-table-with-background-edit"
                                    : "products-table-with-background"
                                }
                              >
                                <p className="products-table-with-background-text product-type">
                                  {
                                    product?.tags?.find(
                                      (pt) => pt.type === "PRODUCT_TYPE"
                                    )?.name
                                  }
                                  {selectedProduct?.id === product?.id && (
                                    <DropdownEdit
                                      from="product type"
                                      title="Edit Product Type"
                                      label={<EditIcon className="edit-icon" />}
                                      endpoint="emp/products/types"
                                      disabled={false}
                                      createPopup={(
                                        setSelected,
                                        setList,
                                        setShowPopup
                                      ) => (
                                        <CreateTagPopup
                                          onClose={() => {
                                            setShowPopup(false);
                                          }}
                                          onSave={(tags) => {
                                            setSelected((p) => [
                                              ...p,
                                              ...tags.map((t) => t.id),
                                            ]);
                                            setList((p) => [...p, ...tags]);
                                            setShowPopup(false);
                                          }}
                                        />
                                      )}
                                      onSave={async (values) => {
                                        await Client.put(
                                          `emp/products/${selectedProduct?.id}/type/${values}`
                                        );
                                        fetchProducts(page);
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                  fontSize: 12,
                                  width: "150px",
                                  position: "relative",
                                }}
                              >
                                <AlertSVG />
                                No product type
                                {selectedProduct?.id === product?.id && (
                                  <DropdownEdit
                                    from="product type"
                                    title="Edit Product Type"
                                    label={
                                      <EditIcon
                                        className="edit-icon"
                                        style={{
                                          fill: "#017cf8",
                                          position: "absolute",
                                          bottom: "5px",
                                        }}
                                      />
                                    }
                                    endpoint="emp/products/types"
                                    disabled={false}
                                    createPopup={(
                                      setSelected,
                                      setList,
                                      setShowPopup
                                    ) => (
                                      <CreateTagPopup
                                        onClose={() => {
                                          setShowPopup(false);
                                        }}
                                        onSave={(tags) => {
                                          setSelected((p) => [
                                            ...p,
                                            ...tags.map((t) => t.id),
                                          ]);
                                          setList((p) => [...p, ...tags]);
                                          setShowPopup(false);
                                        }}
                                      />
                                    )}
                                    onSave={async (values) => {
                                      await Client.put(
                                        `emp/products/${selectedProduct?.id}/type/${values}`
                                      );
                                      fetchProducts(page);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="products-table-tag products-table-tags">
                            {product?.tags.length > 0 ? (
                              <div
                                id={`tags-${product.id}`}
                                className="products-table-tag-row-container"
                                style={{
                                  overflow:
                                    product.id === selectedOpen
                                      ? "visible"
                                      : "hidden",
                                  height:
                                    product.id === selectedOpen
                                      ? "unset"
                                      : "45px",
                                }}
                              >
                                {product.tags
                                  .filter((pt) => pt.type !== "PRODUCT_TYPE")
                                  .sort((a, b) => (a.type > b.type ? 1 : -1))
                                  .map((tag) => {
                                    return (
                                      <div
                                        key={tag.id}
                                        style={{
                                          position: "relative",
                                        }}
                                        className="products-table-tag-container"
                                      >
                                        <p className="products-table-tag-header">
                                          {tag.type
                                            ? tag.type
                                                ?.toLowerCase()
                                                .replaceAll("_", " ")
                                            : "Custom"}
                                        </p>
                                        {selectedProduct?.id ===
                                          product?.id && (
                                          <span
                                            onClick={() => setSelectedTag(tag)}
                                            className="tag-delete-button"
                                          >
                                            &times;
                                          </span>
                                        )}
                                        <p className="products-table-tag-info">
                                          {tag.name}
                                        </p>
                                      </div>
                                    );
                                  })}
                                {selectedProduct?.id === product.id && (
                                  <div
                                    className="products-table-tag-add products-table-tag-container"
                                    style={{ padding: "8px 15px" }}
                                  >
                                    <AddTag
                                    allProducts={allProducts}
                                      selectedProduct={selectedProduct}
                                      onEditChange={handleOnEditChange}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  gap: 10,
                                  fontSize: 12,
                                }}
                              >
                                <AlertSVG />
                                No tags
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="products-table-tag">
                            {selectedOpen !== product.id ? (
                              <span
                                ref={(el) => {
                                  if (el) {
                                    if (
                                      el?.parentElement?.previousElementSibling
                                        ?.firstElementChild?.scrollHeight /
                                        el?.parentElement
                                          ?.previousElementSibling
                                          ?.firstElementChild?.clientHeight <
                                      2
                                    ) {
                                      el.firstElementChild.style.display =
                                        "none";
                                    }
                                  }
                                }}
                              >
                                <FaChevronDown
                                  color="#75787E"
                                  onClick={() => setSelectedOpen(product.id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            ) : (
                              selectedProduct?.id !== product.id && (
                                <FaChevronUp
                                  style={{ cursor: "pointer" }}
                                  color="#75787E"
                                  onClick={() => setSelectedOpen(null)}
                                />
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                visibility:
                                  isHovering?.id === product?.id
                                    ? "visible"
                                    : "hidden",
                              }}
                            >
                              <ProductRowMenu
                                handleEdit={() => {
                                  setSelectedOpen(product.id);
                                  setSelectedProduct(product);
                                }}
                                handleDelete={() => {
                                  setDeleteModal(product);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="products-pagination-container">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onNextPage={() => {
                    if (totalPages > page) {
                      setPage(page + 1);
                      setSelectedProducts([]);
                    }
                  }}
                  onPageChange={(page) => {
                    setPage(page);
                    setSelectedProducts([]);
                  }}
                  onPreviousPage={() => {
                    if (page > 1) {
                      setPage(page - 1);
                      setSelectedProducts([]);
                    }
                  }}
                ></Pagination>
              </div>
            </>
          ) : (
            <NoProduct style={{ margin: "auto" }} />
          )}

          {showGuidancePopup && (
            <GuidencePopup
              show={showGuidancePopup}
              setShow={setShowGuidancePopup}
              videoContent={true}
              videoUrl={"https://www.youtube.com/embed/30F-tafVII0"}
              name={"Products"}
            />
          )}
        </>
      )}
      <DeleteProductDialog
        product={deleteModal}
        setForceRefetch={ setForceRefetch}
        setProduct={setDeleteModal}
        deleteProduct={(product) => handleProductDelete(product)}
      />
      <DeleteTagDialog
        product={selectedProduct}
        setTag={setSelectedTag}
        tag={selectedTag}
        fetchProducts={() => fetchProducts(page)}
        setProduct={setSelectedProduct}
      />
    </div>
  );
}
