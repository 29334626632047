import InfoWithTooltip from "components/InfoWithTooltip/InfoWithTooltip";
import {Pane} from "evergreen-ui";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {checkImageSelection} from "utils/utls";
import {ReactComponent as Info} from "../../../../../../../assets/icons/info-black.svg";
import CustomImageInput from "../../../../../../../components/Forms/CustomImageInput";
import Answers from "./Answers";
import Switch from "../../../../../../../components/Switch/Switch";
import ColorPickerWrapper from "pages/EditorPage/components/ColorPickerWrapper";

export default function Selection({ imageSelection }) {
  const {
    questions,
    activeQuestionIndex,
    experience,
    experience: { design },
    colorPicker,
    setColorPicker,
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const ques = questions[activeQuestionIndex];

  const isImageSelection = checkImageSelection(ques, design) || imageSelection;

  const uploadImage = async ({s3Url},type="image") => {
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/${type}`]: s3Url,
    });
  };

  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };
  return (
    <div className="question-content">
      <div className="answer-inputs">
        <div className="answers-title">
          Answers
          <InfoWithTooltip
            text={
              "You can connect products to these answers to include them in AI recommendation logic"
            }
            icon={<Info />}
            textStyle={{
              minWidth: "300px",
              color: "#75787E",
              textAlign: "center",
            }}
          />
        </div>
        {activeQuestionIndex !== undefined && (
          <Pane display="flex" alignItems="center" gap={16} marginTop={10}>
            <div>Allow multiple answers</div>
            <Switch
              switchVal1={"enabled"}
              switchVal2={"disabled"}
              defaultValue={
                design?.questions[questions[activeQuestionIndex].id]
                  ?.multipleSelection === "enabled"
                  ? "enabled"
                  : "disabled"
              }
              onChange={async (enabled) => {
                await addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/multipleSelection`]:
                    enabled,
                });
              }}
            />
          </Pane>
        )}
        <Answers ques={ques} />
      </div>

      {isImageSelection ? (
        <>
       
        <Pane marginY="16px" marginX={20} display="flex" alignItems="center" gap="8px" className="colorTag-holder">
          <span style={{
            width: "auto"
          }} className="field-label">Side Color</span>
          <Pane maxWidth="65px" marginY="10px" minHeight="52px">
            <ColorPickerWrapper
              key={ques?.id}
              type={""}
              prefix="questions"
              designOBJ={experience?.design}
              heading={ques?.id}
              item={"sideColor"}
              handleColor={handleColor}
              defaultColor={"#fff"}
              />
          </Pane>
        </Pane>
              </>
      ) : (
        <Pane marginY={16} marginX={20} >
          <p className="side-image-title">Side Image<InfoWithTooltip
            text={
              "The image will apply to all devices. You can change your mobile image for this question"
            }
            icon={<Info style={{marginLeft:"6px",width:"11px",height:"11px"}} />}
            textStyle={{
              minWidth: "300px",
              color: "#75787E",
              textAlign: "center",
            }}
          /></p>
          <div className="selection-inputs">
            {/* @ts-ignore */}
            <CustomImageInput
              // label="Side Image"
              label="Default"
              onChange={uploadImage}
              accept={"image/png , image/jpg, image/jpeg"}
              defaultValue={
                experience?.design?.questions?.[
                  questions[activeQuestionIndex]?.id
                ]?.image??""
              }
              onDelete={() => {
                addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/image`]:
                    undefined,
                });
              }}
              placeHolder="Choose an image"
              size="semi-small"
            />
             <div className="divider"> </div>
             <CustomImageInput
              label="For Mobile "
              optional={true}
              onChange={(arg)=>uploadImage(arg,"mobileImage")}
              accept={"image/png , image/jpg, image/jpeg"}
              defaultValue={
                experience?.design?.questions?.[
                  questions[activeQuestionIndex]?.id
                ]?.mobileImage??""
              }
              onDelete={() => {
                addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/mobileImage`]:
                    undefined,
                });
              }}
              size="semi-small"
              placeHolder="Choose an image"
            />
          </div>
        </Pane>
      )}
    </div>
  );
}
