const SwiperSVG = () => {


    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="22" rx="5" fill="#E4F1FF"/>
            <path d="M17.8278 7.86422C17.9268 7.75111 17.9268 7.58222 17.8278 7.46912L16.1931 5.60088C16.0107 5.39241 15.6673 5.52143 15.6673 5.79843V6.83333C15.6673 6.99902 15.533 7.13333 15.3673 7.13333H13.634C13.4683 7.13333 13.334 7.26765 13.334 7.43333V7.9C13.334 8.06569 13.4683 8.2 13.634 8.2H15.3673C15.533 8.2 15.6673 8.33432 15.6673 8.5V9.5349C15.6673 9.81191 16.0107 9.94092 16.1931 9.73245L17.8278 7.86422Z" fill="#017CF8"/>
            <path d="M4.2281 7.88391C4.10397 7.76568 4.10397 7.56765 4.2281 7.44943L6.2931 5.48276C6.4841 5.30086 6.8 5.43624 6.8 5.7V6.83333C6.8 6.99902 6.93431 7.13333 7.1 7.13333H8.36667C8.53235 7.13333 8.66667 7.26765 8.66667 7.43333V7.9C8.66667 8.06569 8.53235 8.2 8.36667 8.2H7.1C6.93431 8.2 6.8 8.33432 6.8 8.5V9.63333C6.8 9.89709 6.4841 10.0325 6.2931 9.85058L4.2281 7.88391Z" fill="#017CF8"/>
            <path d="M15.5965 10.5093L11.5162 9.8V5.96C11.5162 5.42982 11.0667 5 10.5122 5C9.95764 5 9.5081 5.42982 9.5081 5.96V12.68H9.00608V11.24H8.50405C7.94954 11.24 7.5 11.6698 7.5 12.2V13.5898C7.5 14.2437 7.73278 14.8782 8.16007 15.3889L9.5081 17H16.0344L16.8156 12.145C16.9397 11.3741 16.3992 10.6488 15.5965 10.5093Z" fill="#017CF8"/>
        </svg>
    )
}

export default SwiperSVG;