// @ts-nocheck
import {useState} from "react";
import Content from "pages/EditorPage/components/Editor/Content";
import Design from "pages/EditorPage/components/Editor/Design";
import TagsContent from "pages/EditorPage/components/Editor/TagsContent";
import Templates from "pages/EditorPage/components/Editor/Quiz/Templates";
import EditorPage from "pages/EditorPage/EditorPage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ResetPassword from "../components/Auth/ResetPassword/ResetPassword";
import SignIn from "../components/Auth/SignIn/SignIn";
import AuthPage from "../pages/AuthPage/AuthPage";
import UserManagementPage from "../pages/UserManagmentPage/UserManagementPage";
import ExperiencesPageNew from "../pages/ExperiencesPage/ExperiencesPageNew";
import InsightsPage from "../pages/InsightsPage/InsightsPage";
import OverviewPage from "../pages/OverviewPage/OverviewPage";
import PlacementsPage from "../pages/PlacementsPage/PlacementsPage";
import PrivateRoute from "./PrivateRoute";
import BillingPage from "../pages/BillingPage/BillingPage";
import IntegrationPage from "../pages/IntegrationPage/IntegrationPage";
import MailchimpIntegrationPage from "../pages/IntegrationPage/mailchimp/MailchimpIntegrationPage";
import DataPage from "../pages/DataPage/DataPage";
import SignUp from "components/Auth/SignUp/SignUp";
import Verification from "components/Auth/Verification/Verification";
import {TAGS} from "../pages/EditorPage/design_templates";
import BillingPlansPageNew from "pages/BillingPlanPageNew/BillingPlanPageNew";
import {SWIPER} from "pages/EditorPage/design_templates/swiper";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import SwiperContent from "pages/EditorPage/components/Editor/SwiperContent";
import ProductRecommendationPage from "../pages/ProductRecommendationPage/ProductRecommendationPage";
import ProductPage from "pages/ProductPage/ProductPage";
import EditorExpIntro from "pages/EditorPage/components/Editor/ExpIntro/EditorExpIntro";
import ResultsContent from "pages/ProductRecommendationPage/components/ResultsContent";
import Onboarding from "../pages/Onboarding/Onboarding";
import ResponsePage from "pages/ResponsePage/ResponsePage";

function InnerRouter() {
  const [closedNotification, setClosedNotification] = useState(true);

  function handleClosedNotification(value) {
    setClosedNotification(value);
  }

  return (
    <Routes>
      <Route element={<AuthPage />}>
        <Route path="/" element={<SignIn />} />
      </Route>
      <Route path="/preview" element={<div />} />
      <Route element={<AuthPage />}>
        <Route path="/signup" element={<SignUp />} />
      </Route>
        <Route path="/onboarding" element={<Onboarding />} />
      <Route element={<AuthPage type="SHOPIFY" />}>
        <Route path="/signup/shopify" element={<SignUp />} />
      </Route>
      <Route element={<AuthPage type="WOOCOMMERCE" />}>
        <Route path="/signup/woocommerce" element={<SignUp />} />
      </Route>
      <Route element={<AuthPage />}>
        <Route path="/verification" element={<Verification />} />
      </Route>
      <Route element={<AuthPage />}>
        <Route path="login" element={<SignIn />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
      <Route
        path="/experiences"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            {/*<ExperiencesPage />*/}
            <ExperiencesPageNew />
          </PrivateRoute>
        }
      />
      <Route
        path="/welcome"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            {/*<WelcomePage/>*/}
          </PrivateRoute>
        }
      />
      <Route
        path="/billing"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <BillingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-management"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <UserManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/billing/plans"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <BillingPlansPageNew />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/integrations"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <IntegrationPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/integrations/mailchimp"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <MailchimpIntegrationPage />
          </PrivateRoute>
        }
      />
      <Route
        path="product-recommendation"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <ProductRecommendationPage />
          </PrivateRoute>
        }
      />
      <Route
        path="products/:id"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <ProductPage />
          </PrivateRoute>
        }
      />
       <Route
        path="responses/:id"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
           <ResponsePage/>
          </PrivateRoute>
        }
      />
      <Route
        path="/experiences/edit/:id"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <EditorPage />
          </PrivateRoute>
        }
      >
        <Route path="intro-content" element={<EditorExpIntro />} />
        <Route path="results-content" element={<ResultsContent />} />
        <Route path="design" element={<Design />} />
        <Route path={`content`} element={<Content />} />
        <Route path="templates" element={<Templates />} />
        <Route path="tags-design" element={<Design type={TAGS} />} />
        <Route path="tags-content" element={<TagsContent />} />
        <Route path="swiper-design" element={<Design type={SWIPER} />} />
        <Route path="swiper-content" element={<SwiperContent />} />
      </Route>
      <Route
        path="/experiences/placements/:id"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <PlacementsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/analytics"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <OverviewPage/>
          </PrivateRoute>
        }
      />
      <Route
        path="/overview"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <OverviewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/data"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <DataPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/insights"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <InsightsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute
            closedNotification={closedNotification}
            handleClosedNotification={handleClosedNotification}
          >
            <div>Settings page</div>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default function AppRouter() {
  return (
    <Router>
      <InnerRouter />
    </Router>
  );
}
