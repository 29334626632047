import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { toaster } from "evergreen-ui";
import UnsuccessfulLoadData from "../../../components/UnsuccessfulLoadData/UnsuccessfulLoadData";
import React, { useState } from "react";
import toTitleCase from "../../../utils/toTitleCase";
import useExpTableProps from "../../../hooks/useExpTableProps";
import { timeSince } from "../../../utils/lastEdited";
import previewURL from "../../../utils/previewURL";
import { useNavigate } from "react-router-dom";
import ToggleLiveExpButton from "./ToggleLiveExpButton";
import ExpRowMenu from "./ExpRowMenu";
import ExpPlacementsModal from "./ExpPlacementsModal";
import { Client } from "../../../Client";
import useEditor from "stores/use-editor";
import NoExperiences from "../NoExperiences";
import UpgradeBillingModal from "./UpgradeBillingModal";
import useAuth from "stores/auth";
import { ReactComponent as Line } from "assets/icons/line.svg";
import saveExp from "../../../utils/saveExp";
import Skeleton from "react-loading-skeleton";

const ExperiencesPageTable = ({
  allExperiences,
  loading,
  unsuccessfulLoad,
  isEditing,
  setFailed,
  setIsEditing,
  setForceFetch,
  brand,
  fetchExperiencesPoller,
  isUpgradePlanModalOpen,
  setIsUpgradePlanModalOpen,
  limitOfQuizzesReached,
  isCreateNew,
}) => {
  const navigate = useNavigate();
  const { updateExperience } = useEditor();
  const {
    tableHeaders,
    experienceIcon,
    defaultEditOBJ,
    displayStatus,
    editorPrefix,
  } = useExpTableProps();

  const [editExpOBJ, setEditExpOBJ] = useState(defaultEditOBJ);
  const [indexOfHoveredRow, setIndexOfHoveredRow] = useState(null);
  const [isPlacementsModalOpen, setIsPlacementsModalOpen] = useState(undefined);
  const [placementBtnType, setPlacementBtnType] = useState("");
  const { setSubSidebar } = useAuth();
  const [isShown, setIsShown] = useState(false);
  const filterExperiences = allExperiences?.filter(
    (experience) => experience.status !== "ARCHIVED"
  );

  if (loading) return <LoadingPage />;
  if (filterExperiences?.length === 0 && allExperiences?.length !== 0)
    return <NoExperiences fetchExperiencesPoller={fetchExperiencesPoller} />;

  if (!loading && allExperiences?.length === 0) {
    navigate("/onboarding");
    return null;
  }
  if (unsuccessfulLoad) return <UnsuccessfulLoadData />;

  const changeExp = (keyName, value) => {
    setEditExpOBJ((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };

  const saveEditedExperience = async () => {
    saveExp(editExpOBJ, isEditing)
      .then((res) => {
        toaster.success("Experience updated");
        setForceFetch(Math.random());
        setIsPlacementsModalOpen(undefined);
      })
      .catch((err) =>
        toaster.danger("There was a problem updating the experience.")
      );
  };

  const handleSaveClick = async (experience) => {
    if (experience?.status !== "LIVE") {
      setPlacementBtnType("Publish");
      return setIsPlacementsModalOpen(experience);
    } else {
      await Client.put(`/emp/experiences/${experience?.id}/status`, {
        status: "STOPPED",
      }).then(() =>
        updateExperience({
          ...experience,
          status: experience?.status === "LIVE" ? "STOPPED" : "LIVE",
        })
      );
      toaster.success("Experience paused successfully");
      setForceFetch(Math.random());
    }
  };

  const displayPath = (experience) => {
    if (displayStatus(experience?.status) === "Published") {
      return experience?.locations?.[0]?.path === "/"
        ? "Homepage"
        : decodeURI(experience?.locations?.[0]?.path);
    }

    return (
      <span>
        <Line />
      </span>
    );
  };

  return (
    <TableContainer>
      <Table aria-label="simple table" stickyHeader>
        <TableHead style={{ borderBottom: "1px solid #DBE1E7" }}>
          <TableRow>
            {tableHeaders.map((header, index) => {
              return (
                <TableCell
                  key={header + index}
                  style={{ borderBottom: "none", verticalAlign: "top" }}
                  align={"center"}
                >
                  <p className="experiences-table-header">{header}</p>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {filterExperiences.map((experience, index) => {
            const hoveredRow = index === indexOfHoveredRow;
            return (
              <>
                <TableRow
                  onMouseEnter={() => {
                    !isShown && setIndexOfHoveredRow(index);
                  }}
                  onMouseLeave={() => setIndexOfHoveredRow(null)}
                  key={experience.id}
                  style={{ height: "70px" }}
                  onClick={() => {
                    if (isEditing !== experience) {
                      setIsEditing(experience);
                      setEditExpOBJ(defaultEditOBJ);
                    }
                  }}
                >
                  {/* <TableCell>{experienceIcon(experience?.type)}</TableCell> */}
                  <TableCell>
                    <p className="experience-row">{experience?.name}</p>
                  </TableCell>
                  <TableCell>
                    <p className="experience-row">
                      {toTitleCase(experience?.type)}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p
                      className={`exp-table-status ${
                        displayStatus(experience?.status) === "Published"
                          ? "exp-table-status-active"
                          : ""
                      }`}
                    >
                      {displayStatus(experience?.status)}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className="experience-row" style={{ color: "#75787E" }}>
                      {displayPath(experience)}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className="experience-row">
                      {timeSince(experience?.lastModified)}
                    </p>
                  </TableCell>
                  <TableCell
                    className="action-cell"
                    style={{ minWidth: "345px" }}
                    align="center"
                  >
                    <button
                      className={`action-menu-buttons ${
                        !experience && "disabled"
                      } ${!hoveredRow && "invisible"}`}
                      // appearance="primary"
                      onClick={() => {
                        window.open(previewURL(experience, brand), "_blank");
                        setSubSidebar(false);
                      }}
                    >
                      Preview
                    </button>
                    <button
                      className={`action-menu-buttons ${
                        !experience && "disabled"
                      } ${!hoveredRow && "invisible"}`}
                      onClick={() => {
                        navigate(
                          `/experiences/edit/${experience.id}/${editorPrefix(
                            experience
                          )}content`
                        );
                        setSubSidebar(false);
                      }}
                    >
                      Edit
                    </button>
                    <ToggleLiveExpButton
                      experience={experience}
                      isEditing={isEditing}
                      editExpOBJ={editExpOBJ}
                      hoveredRow={hoveredRow}
                      moreQuizClick={() => setIsUpgradePlanModalOpen(true)}
                      disabled={limitOfQuizzesReached}
                      handleClick={() => {
                        handleSaveClick(experience);
                      }}
                    />
                  </TableCell>
                  <TableCell className="action-cell">
                    <ExpRowMenu
                      setIndexOfHoveredRow={setIndexOfHoveredRow}
                      setIsShown={setIsShown}
                      setFailed={setFailed}
                      experience={experience}
                      setIsPlacementsModalOpen={(val) => {
                        setIsPlacementsModalOpen(val);
                        setPlacementBtnType("Save Changes");
                        setEditExpOBJ((prev) => ({
                          ...prev,
                          path:
                            displayStatus(experience?.status) === "Published"
                              ? decodeURI(experience?.locations?.[0]?.path)
                              : "",
                        }));
                      }}
                      setForceFetch={() => setForceFetch(Math.random())}
                    />
                  </TableCell>
                </TableRow>
              </>
            );
          })}
          {isCreateNew && (
            <>
              <TableRow>
                <TableCell>
                  <p className="experience-row">Generating Experience</p>
                </TableCell>
                <TableCell>
                  <Skeleton
                    width={"75%"}
                    style={{position:"absolute",}}
                    borderRadius={10}
                    baseColor="#efdbfd"
                    height={30}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>

      {isPlacementsModalOpen?.id && (
        <ExpPlacementsModal
          experience={isPlacementsModalOpen}
          editExpOBJ={editExpOBJ}
          changeExp={changeExp}
          setEditExpOBJ={setEditExpOBJ}
          saveEditedExperience={saveEditedExperience}
          setIsPlacementsModalOpen={setIsPlacementsModalOpen}
          handleForceFetch={setForceFetch}
          lastStepButtonText={placementBtnType}
        />
      )}

      {isUpgradePlanModalOpen && (
        <UpgradeBillingModal
          isUpgradePlanModalOpen={isUpgradePlanModalOpen}
          closeModal={() => setIsUpgradePlanModalOpen(false)}
        />
      )}
    </TableContainer>
  );
};

export default ExperiencesPageTable;
