import {Client} from "Client";
import {IoClose} from "react-icons/io5";
import {Pane, TextInput} from "evergreen-ui";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search-icon-new.svg";
import {useEffect, useState} from "react";
import {useEditorEvents} from "../../../stores/use-editor-events";
import useCustomTags from "../../../stores/use-custom-tags";
import useEditor from "../../../stores/use-editor";
import {ReactComponent as AllProductsIcon} from "../../../assets/images/all-product-selected.svg";
import {SelectedItem} from "../../ExperiencesPage/CreateDialog/components/SelectedItem";

const OnboardingAddProductsWizard = ({ans, products, idx, setShow, show, selectedProducts, setSelectedProducts, disabled}) => {
    const {experience, activeQuestionIndex, questions} = useEditor();
    const {addEvents} = useEditorEvents();
    const {addTag, tags} = useCustomTags();

    const [allProducts, setAllProducts] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [selectAllProducts, setSelectAllProducts] = useState(false);
    const customTag = tags?.[ans?.tagIDs?.[0]];
    useEffect(() => {
        setSelectedProducts([...(customTag ? customTag?.products : products),]);
    }, [products, customTag, show]);

    const onSearchProducts = (string) => {
        // If search is empty -> show all products
        setSearchInput(string);
        setSelectAllProducts(false);
    };

    useEffect(() => {
        Client.get(`/emp/experiences/${experience?.id}/products`).then((res) => {
            setAllProducts(res.data);
        });
    }, []);

    const addOrRemoveSelectedProduct = (productOBJ) => {
        const index = selectedProducts.findIndex((p) => p.id === productOBJ.id);
        index !== -1 ? setSelectAllProducts(false) : finalProductUIAfterFilter?.length === 1 && setSelectAllProducts(true);
        const selProducts = index !== -1 ? [...selectedProducts.slice(0, index), ...selectedProducts.slice(index + 1),] : [...selectedProducts, productOBJ];
        setSelectedProducts(selProducts);
        const newTag = addTag({
            originalTagId: ans.tagIDs[0], ...(tags[ans.tagIDs[0]] ?? {}), name: ans.answer, products: [...selProducts],
        });
        addEvents("question", "update", {
            quesIdx: activeQuestionIndex,
            questionId: questions[activeQuestionIndex].id,
            answers: [...questions[activeQuestionIndex].answers.slice(0, idx), {
                ...questions[activeQuestionIndex].answers[idx], tagIDs: [newTag.id],
            }, ...questions[activeQuestionIndex].answers.slice(idx + 1),],
        });

    };

    const selectAll = () => {
        let selProducts = [];
        if (!selectAllProducts) {
            selProducts = [...finalProductUIAfterFilter, ...selectedProducts];
        }
        setSelectedProducts(selProducts);
        const newTag = addTag({
            originalTagId: ans.tagIDs[0], ...(tags[ans.tagIDs[0]] ?? {}), name: ans.answer, products: [...selProducts],
        });
        addEvents("question", "update", {
            quesIdx: activeQuestionIndex,
            questionId: questions[activeQuestionIndex].id,
            answers: [...questions[activeQuestionIndex].answers.slice(0, idx), {
                ...questions[activeQuestionIndex].answers[idx], tagIDs: [newTag.id],
            }, ...questions[activeQuestionIndex].answers.slice(idx + 1),],
        });
        setSelectAllProducts(!selectAllProducts);
    };

    const productsUI = [// ...(allProducts || []),
        ...(allProducts?.filter((p) => !selectedProducts?.map((p) => p.id)?.includes(p.id)) || []),];
    // console.log(productsUI,'productsUI')
    const finalProductUIAfterFilter = productsUI?.filter((p) => p?.name?.toLowerCase()?.includes(searchInput?.toLowerCase()));

    return (<div
        style={{
            filter: disabled ? "grayscale(80%)" : "",
            opacity: disabled ? "0.5" : "",
            pointerEvents: disabled ? "none" : "",
        }}>
        <div style={{position: "relative", margin: "20px 0 0px"}}>
            <SearchIcon
                style={{
                    width: "18px", height: "16px", position: "absolute", top: 12, left: 8, stroke: "#75787E",
                }}
            />
            {searchInput?.length > 1 && (<IoClose
                size={16}
                style={{
                    position: "absolute", top: 12, left: 219, fill: "#96989b", cursor: "pointer",
                }}
                onClick={() => onSearchProducts("")}
            />)}
            <TextInput
                className="added-product-input-box"
                height={40}
                paddingLeft={32}
                placeholder="Search Product"
                value={searchInput}
                onChange={(e) => onSearchProducts(e.target.value)}
                size="large"
                width={253}
                borderRadius={8}
            ></TextInput>
        </div>
        <Pane
            display="flex"
            gap={16}
            marginBottom={26}
            borderTop="1px solid lightgrey"
            style={{
                border: "none", maxHeight: "300px", overflow: "hidden", marginTop: 20, marginBottom: -2,
            }}
            className="create-selected-items-section"
        >
            <div className="products-container">
                <div style={{height:"170px"}}>
                    <SelectedItem
                        item={{name: "All products "}}
                        border={false}
                        addOrRemoveSelectedCollection={() => {
                            selectAll();
                        }}
                        addOrRemoveSelectedProduct={() => {
                            selectAll();
                        }}
                        type="products"
                        selected={selectAllProducts || finalProductUIAfterFilter?.length === 0}
                    />
                    {finalProductUIAfterFilter?.length === 0 && (<div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80%",
                            flexDirection: "column",
                        }}
                    >
                        <AllProductsIcon/>
                        <p style={{color: "#75787E", marginTop: "8px"}}>
                            All products selected
                        </p>
                    </div>)}
                    {finalProductUIAfterFilter
                        .filter((p) => !selectedProducts?.map((p) => p.id)?.includes(p.id))
                        ?.map((product, idx) => (<SelectedItem
                            item={product}
                            addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                            selectedProducts={[]}
                            type="product"
                        />))}
                </div>
                <div className="separator"></div>
                <div style={{height:"170px"}}>
                    <p>
                        Selected Products |{" "}
                        <span
                            style={{
                                fontSize:"12px",
                                color: "#75787E",
                            }}
                        >{`${selectedProducts?.length}/${allProducts?.length} Products `}</span>
                    </p>
                    {selectedProducts?.map((product, idx) => (<SelectedItem
                        item={product}
                        addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                        selectedProducts={selectedProducts}
                        type="product"
                    />))}
                </div>
            </div>
            {" "}
        </Pane>
    </div>)
}

export default OnboardingAddProductsWizard;