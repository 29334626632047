import {Client} from "Client";
import {toaster} from "evergreen-ui";
import {OpenReplay} from "utils/OpenReplay";
import {updateObject} from "utils/utls";
import create from "zustand";
import {devtools} from "zustand/middleware";
import {mockupDesign, mockupProduct, mockupProducts,} from "../utils/mockupProducts";

const initialState = {
    design: {},
    product: null,
    allRecommenderEventsSaved: true,
    showUnsavedChangesPopup: false,
    multiProducts: [],
    afterActionRedirect: "",
    recommendationDesign: {},
    noProductsYet: false,
    activeTab: "recommendation",
    loading: false,
};

export const useDesign = create()(
    devtools(
        (set, get) => ({
            ...initialState,
            setActiveTab: (tab) =>
                set({activeTab: tab === get().activeTab ? undefined : tab}),
            togglePopupState: () => {
                set({showUnsavedChangesPopup: !get().showUnsavedChangesPopup});
            },
            setRedirect: (v) => {
                set({afterActionRedirect: v});
            },
            getDesign: async () => {
                set({
                    loading: true,
                });
                const expID = window.location.pathname.split("/").at(-2);

                const promises = [
                    Client.get(`emp/sampleProduct?multiple=false&experienceID=${expID}`),
                    Client.get(`emp/brand/design`),
                    Client.get(`emp/sampleProduct?multiple=true&experienceID=${expID}`),
                ];

                const results = await Promise.all(
                    promises.map((p) => p.catch((e) => e))
                );
                const noProducts = !results[0]?.data?.length > 0;

                set({
                    design: results[1]?.data ? results[1]?.data : mockupDesign,
                    product: results[0]?.data || mockupProduct,
                    multiProducts:
                        results[2]?.data?.length > 0 ? results[2]?.data : mockupProducts,
                    loading: false,
                    allRecommenderEventsSaved: true, // No changes made at all... so true.
                    noProductsYet: !noProducts,
                });
            },
            product: undefined,
            setDesign: (path, value) => {
                set({
                    design: updateObject(get()?.design, value, path),
                });

                if (get().allRecommenderEventsSaved) {
                    set({allRecommenderEventsSaved: false});
                }
            },
            setAllRecommenderEventsSaved: (value) => {
                set({allRecommenderEventsSaved: value});
            },
            reset: () => {
                set(initialState);
            },
            saveDesign: async () => {
                set({
                    loading: true,
                    allRecommenderEventsSaved: true,
                });
                Client.post(`emp/brand/design`, get().design).then(() => {
                    set({
                        loading: false,
                    });
                    toaster.success("The design was successfully saved.");
                });
            },
        }),
        {
            name: "editor-design", // unique name
        }
    )
);
