import {Spinner} from "evergreen-ui";
import OnboardingBackButton from "./OnboardingBackButton";
import {useOnboarding} from "../../../stores/use-onboarding";
import previewURL from "../../../utils/previewURL";
const NavigationButtons = ({
   disablePrimaryButton,
   handleNextButtonClick,
   handleSkipToEditorClick,
   primaryButtonText="Next step",
   secondaryButtonText = "",
   style = {},
   nextButtonLoading,
   showPreviewButton,
   previewExperience
}) => {
    const step = useOnboarding(s => s.step);



    return(
        <div className="onboarding-step-buttons-container" style={style}>
            {step >= 2 && <OnboardingBackButton/>}
            <p
                className="onboarding-step-text-button"
                onClick={handleSkipToEditorClick}
            >
                {secondaryButtonText}
            </p>
            {showPreviewButton &&
                <p
                    className="onboarding-step-preview"
                    onClick={previewExperience}
                >Preview</p>}
            <p
                className="onboarding-step-button-primary"
                id={disablePrimaryButton && "disabled-button"}
                onClick={handleNextButtonClick}
            >
                {nextButtonLoading ? <Spinner size={22} color={"white"} /> : primaryButtonText}
            </p>
        </div>
    )
}

export default NavigationButtons;