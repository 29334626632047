import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {OpenReplay} from 'utils/OpenReplay';


OpenReplay.init();
const root = ReactDOM.createRoot(document.getElementById('root'));
// Have to disable StrictMode for now, because it disables the react-beautiful-dnd for quiz answer options drag and drop
root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
