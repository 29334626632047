import {useEffect} from "react";

const useClickOutside = (ref, callback) => {

    const handleClick = e => {
        if (e.target.className === "color" || e.target.className === "colorTag" || e.target.tagName === "svg" || e.target.tagName === "path") return;
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};

export default useClickOutside;