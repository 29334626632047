import CustomSelect from "../../../components/Forms/CustomSelect";
import {fontOptions} from "../../../Options/Options";
import useEditor from "../../../stores/use-editor";
import {useEffect, useState} from "react";

const FontPickerWrapper = ({
  heading,
  item,
  handleGetFontFile,
  recommender = false,
}) => {
  const { experience } = useEditor();
  const [recommenderFontOptions, setRecommenderFontOptions] = useState(fontOptions);

    useEffect(() => {
      if (!recommender) return;

      const existingBrandFonts = Object?.keys(experience?.design?.fonts || []);
      const fonts = [];
      existingBrandFonts?.map(key => {
          if (fontOptions.find(obj => obj.value === experience?.design?.fonts[key] || experience?.design?.fonts[key].includes("https://"))) return;
          fonts.push({
              label: experience?.design?.fonts[key]?.split("/")?.at(-1),
              value: experience?.design?.fonts[key],
          });
      });

        setRecommenderFontOptions([...fonts,...fontOptions])
  },[experience?.design?.fonts]);

  if (recommender) {
    return (
      <div className="fontContainer">
        {/* @ts-ignore */}
        <CustomSelect
          options={recommenderFontOptions}
          value={
            experience?.design?.fonts?.[heading + "_" + item]?.indexOf("s3://") === -1 &&
            experience?.design?.fonts?.[heading + "_" + item]?.indexOf("https://") === -1 ?
                recommenderFontOptions?.find(
                  (v) =>
                    v.value === experience?.design?.fonts?.[heading + "_" + item]
                )
              : experience?.design?.fonts?.[heading + "_" + item]
              ? fontOptions.slice(-1)[0]
              : undefined
          }
          placeHolder="Select font"
          formSelect
          onChange={async (option) => {
            handleGetFontFile(option, heading, item);
          }}
        />
      </div>
    );
  }
  return (
    <div className="fontContainer">
      {/* @ts-ignore */}
      <CustomSelect
        options={fontOptions}
        value={
          experience?.design?.fonts?.[heading + "_" + item]?.indexOf(
            "s3://"
          ) === -1 &&
          experience?.design?.fonts?.[heading + "_" + item]?.indexOf(
            "https://"
          ) === -1
            ? fontOptions.find(
                (v) =>
                  v.value === experience.design?.fonts?.[heading + "_" + item]
              )
            : experience.design?.fonts?.[heading + "_" + item]
            ? fontOptions.slice(-1)[0]
            : undefined
        }
        placeHolder="Select font"
        name="limitAnswers"
        formSelect
        onChange={async (option) => {
          handleGetFontFile(option, heading, item);
        }}
      />
    </div>
  );
};

export default FontPickerWrapper;
