import React, { forwardRef } from "react";
import Info from "../Info/Info";
import InfoWithTooltip from "components/InfoWithTooltip/InfoWithTooltip";

import "./CustomInputs.scss";

function CustomInput(
  {
    type,
    value,
    from,
    error,
    showErrorMessage = false,
    styleErrorMessage = {},
    name,
    placeHolder,
    label,
    units,
    sign,
    uppercase,
    width,
    size = "medium",
    onClick,
    onChange,
    onBlur,
    isEditMode = "true",
    max,
    height,
    className,
    optional,
    required,
    tooltip,
    maxLength,
    defaultValue,
    forceStyle,
    disabled = false,
  },
  ref
) {
  let displayValue =
    units && value ? value + " " + units : value && !units ? value : "-";

  return (
    <label
      className={`field ${units ? "with-units" : ""} field-${size} ${
        className ? className : ""
      }`}
      style={{ width: width, marginTop: "10px", ...(forceStyle || {}) }}
    >
      {label && (
        <span
          className="field-label"
          style={{ display: "flex", alignItems: "center" }}
        >
          {label}
          {optional && isEditMode && (
            <span className="field-label-opt">(optional)</span>
          )}
          {tooltip && <InfoWithTooltip tooltipDOM="adminAPI" text={tooltip} />}
        </span>
      )}

      {isEditMode && (
        <div className={`field-input-container`} onClick={onClick}>
          <input
            ref={ref}
            max={max}
            maxLength={maxLength}
            onClick={onClick}
            type={type}
            className={`field-input ${error && "field-input-error"}`}
            placeholder={placeHolder}
            value={value}
            defaultValue={defaultValue}
            sign={""}
            onChange={onChange}
            onBlur={onBlur}
            onPaste={onChange}
            name={name}
            disabled={disabled}
            style={{ height: height }}
            {...(required && { required: "required" })}
          />
          {units && <span className="field-units">{units}</span>}
          <span className="field-info">
            <Info title={label} trigger="HOVER" pos="BOTTOM" />
            {sign}
          </span>

          {isEditMode && maxLength && (
            <span
              className="field-info"
              style={{
                top: -10,
                padding: "3px 7px",
                borderRadius: 10,
                background: "white",
                border: "1px solid #dbe1e7",
              }}
            >
              {value.length}/{maxLength}
            </span>
          )}
          {showErrorMessage && (
            <p className="field-error-message" style={{ ...styleErrorMessage }}>
              {error}
            </p>
          )}
        </div>
      )}
      {!isEditMode && (
        <span className="field-value">
          {(from = "result-page" ? defaultValue : displayValue)}
        </span>
      )}
      {uppercase && (
        <div className="input-property-container">
          {isEditMode && (
            <span className="input-property">Uppercase letters</span>
          )}
          {isEditMode && (
            <span className="input-property">Hyphen as space</span>
          )}
        </div>
      )}
    </label>
  );
}

export default forwardRef(CustomInput);
