import CropDialog from "pages/EditorPage/components/Editor/Dialog/CropDialog";
import React, {useState} from "react";
import imgIcon from "../../assets/images/imgIcon.svg";
import editIcon from "../../assets/icons/edit-image.svg";
import "react-image-crop/src/ReactCrop.scss";
import imageCompression from "browser-image-compression";
import useFetchFromS3 from "utils/useFetchFromS3";
import ImageGalleryModal from "./ImageGalleryModal";

function CustomTextArea({
                            autoFocus = false,
                            placeHolder,
                            label,
                            name,
                            width,
                            charLimit,
                            value,
                            defaultValue,
                            disabled,
                            onImageChange,
                            onChange,
                            readOnly,
                            onFocus,
                            optional,
                            onClick,
                            ref,
                            icon,
                            onBlur,
                            srt,
                            required,
                            onMouseDown,
                            removeLabel,
                            okTrigger,
                            showCharLimit = true,
                            imgValue,
                            forceStyle = {},
                        }) {
    const [content, setContent] = useState(defaultValue ?? value ?? "");
    const filePublicURL = useFetchFromS3(imgValue, srt);
    const [showImageGalleryModal, setShowImageGalleryModal] = useState(false)

    const setFormattedContent = (event) => {
        if (event.target.value.length > charLimit) return;
        let text = event.target.value;
        event.target.style.height = `${event.target.scrollHeight}px`;

        setContent(text.slice(0, charLimit));
        if (onChange) onChange(event);
    };

    return (<label
        onClick={onClick}
        className="field field-large"
        style={{width: width, ...forceStyle.wrapper}}
    >
        {!removeLabel && (<span className="field-label">
          {label}
            {optional && <span className="field-label-opt">(optional)</span>}
            {okTrigger && (<span className="field-label-ok-trigger">{okTrigger}</span>)}
        </span>)}
        {!readOnly ? (<div className="field-input-container">
          <textarea
              style={{...forceStyle, ...(forceStyle?.input || {})}}
              className="field-input textarea"
              placeholder={placeHolder}
              onMouseDown={onMouseDown}
              autoFocus={autoFocus}
              name={name}
              defaultValue={defaultValue}
              onBlur={onBlur}
              disabled={disabled}
              value={value}
              onFocus={onFocus}
              onChange={(event) => setFormattedContent(event)}
              required={required}
              maxLength={charLimit}
              ref={ref}
          />
            {charLimit && showCharLimit && (<span
                className="field-counter"
                style={icon ? {right: "22%"} : {}}
            >
              {content.length}/{charLimit}
            </span>)}{" "}
            {icon && (<>
            
            <span
                className="field-icon"
                style={{
                    backgroundImage: `url(${filePublicURL})`,
                    zIndex:1
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setShowImageGalleryModal(true)
                }}
            >
              <label
                  htmlFor={"image" + name}
                  style={{
                      cursor: "pointer",
                  }}
              >
                {imgValue ? (<img
                    src={editIcon}
                    alt=""
                    style={{height: "15px", width: "15px"}}
                />) : (<img src={imgIcon} alt=""/>)}
              </label>
            </span>
                <ImageGalleryModal defaultSelected={imgValue} onImageSelect={({s3Url, publicUrl}) => {
                    onImageChange({s3Url, publicUrl})
                }} shown={showImageGalleryModal} setShown={setShowImageGalleryModal}/>
            </>)}
        </div>) : (<p className="field-value" style={{whiteSpace: "pre"}}>
            {value}
        </p>)}{" "}
    </label>);
}

export default CustomTextArea;
