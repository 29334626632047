export const QuesTypes=[
    { label: "Selection", value: "Selection" },
    { label: "Image Selection", value: "Image Selection" },
    { label: "Cluster", value: "Cluster" },
    { label: "Range", value: "Range" },
    { label: "Rating", value: "Rating" },
]
export const fontOptions = [
    { label: "Inter", value: "gFont://Inter" },
    { label: "Roboto", value: "gFont://Roboto" },
    { label: "Lato", value: "gFont://Lato" },
    { label: "Custom", value: "Custom" },
  ];
  export const Symbol = [
    { label: "None", value: "None" },
    { label: "Presents", value: "Presents" },
    { label: "Currency", value: "Currency" },
  ];

export const experiencesOptions = [
    { label: "Quiz", value: "QUIZ" },
];

export const placementsOptions = [
    { label: "Home Page", value: "HOME_PAGE" },
    { label: "Tags Selection", value: "TAGS_SELECTION" },
];

export const implementationOptions = [
    { label: "Pop up", value: "POPUP" },
    { label: "Banner", value: "BANNER" },
    { label: "Product grid", value: "PRODUCT_GRID" },
    { label: "Manual", value: "MANUAL_POPUP" },
    { label: "Embedded Element", value: "EMBEDDED_PAGE" },
];