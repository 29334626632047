import validator from "validator";

const isOnboardingPropertyHasErrors = (type, inputName, value) => {
  if (!type) return false;

  if (
    type === "WOOCOMMERCE" &&
    inputName === "key" &&
    value?.slice(0, 3) !== "ck_" &&
    value?.length > 0
  )
    return "Invalid consumer key.";

  if (
    type === "WOOCOMMERCE" &&
    inputName === "secret" &&
    value?.slice(0, 3) !== "cs_" &&
    value?.length > 0
  )
    return "Invalid consumer secret.";

  if (
    type === "SHOPIFY" &&
    inputName === "token" &&
    value?.slice(0, 6) !== "shpat_" &&
    (value?.length > 0 || value === undefined)
  )
    return "Invalid Shopify API key.";

  if (
    inputName === "username" &&
    value &&
    value.length > 0 && 
    !validator?.isEmail(value)
  )
    return "Invalid email.";
  if (inputName === "brandName" && value?.length <= 1)
    return "Invalid Brand Name";
  if (
    inputName === "fullName" &&
    (!value?.split(" ")[0] || !value?.split(" ")[1]) &&
    value?.length > 0
  )
    return "Invalid full name.";
};

export default isOnboardingPropertyHasErrors;
