import {Dialog, Pane} from "evergreen-ui";

const DeleteExpModal = ({deleteExp,isDeleteExpModalOpen,setIsDeleteExpModalOpen}) => {


    return(
        <Pane>
            <Dialog
                isShown={isDeleteExpModalOpen}
                onCloseComplete={() => setIsDeleteExpModalOpen(false)}
                width="500px"
                hasFooter={false}
                hasHeader={false}
                topOffset={window.innerHeight / 3}
                containerProps={{ borderRadius: "16px" }}
                contentContainerProps={{ padding: "2rem" }}
            >
                <div className="dialog-content">
                    <h4>Delete an experience</h4>
                    <p>Are you sure you want to delete this experience?</p>
                </div>

                <div className="container">
                    <button
                        onClick={() => setIsDeleteExpModalOpen(false)}
                        className="cancel dialog-btn"
                        style={{
                            color: "#75787E",
                            marginRight: "16px",
                            cursor: "pointer",
                            background: "none",
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="confirm dialog-btn"
                        style={{
                            color: "#fff",
                            background: "#E45447",
                            marginRight: "16px",
                            cursor: "pointer",
                        }}
                        onClick={deleteExp}
                    >
                        Delete
                    </button>
                </div>
            </Dialog>
        </Pane>
    )
}

export default DeleteExpModal;