import {ObjectID} from "bson";
import {useEditorEvents} from "../stores/use-editor-events";
import useEditor from "../stores/use-editor";
import {useSwiper} from "stores/use-swiper";
import useFirstQuestion from "./useFirstQuestion";
import useCustomTags from "stores/use-custom-tags";

const useDeleteProperties = () => {
  const { addEvents } = useEditorEvents();
  const {
    activeQuestionIndex,
    changeActiveQuestionIndex,
    questions,
    experience,
    experience: { design },
    deleteCustomTag,
  } = useEditor();
  const { setShowDeleteDialog } = useEditor();
  const { activeSentenceID, tagTypes, activeSentenceIndex, activeTagID } =
    useSwiper();
  const { changeFirstQuestion } = useFirstQuestion();
  const { removeTag } = useCustomTags();
  const deleteQuestion = async (indexOfActiveQuestionParam) => {
    const activeQuestionIndexFinal =
      indexOfActiveQuestionParam ?? activeQuestionIndex;
    const thisQuestionOBJ = questions[activeQuestionIndexFinal];
    const starredAsFirstQuestion =
      design?.firstQuestionID === thisQuestionOBJ?.id;
    console.log("activeQuestionIndexFinal", activeQuestionIndexFinal);
    await addEvents("design", "update", {
      questionsToAdd: experience?.design?.questionsToAdd.filter(
        (qid) => qid !== thisQuestionOBJ.id
      ),
    });
    await addEvents("design", "update", {
      [`questions/${thisQuestionOBJ.id}`]: undefined,
    });
    await addEvents("question", "delete", {
      quesIdx: activeQuestionIndexFinal,
    });
    await changeActiveQuestionIndex(undefined);
    if (questions.length === 1) {
      addEvents("question", "create", {
        type: "SELECTION",
        id: new ObjectID().toString(),
      });
    }

    if (starredAsFirstQuestion) {
      changeFirstQuestion(questions[activeQuestionIndexFinal + 1]?.id);
    }

    setShowDeleteDialog(false);
  };

  const deleteSentence = async () => {
    const tagTypeOBJ = tagTypes.find((item) => item?.id === activeSentenceID);
    await addEvents("design", "update", {
      tagTypesToIgnore: [
        ...(experience?.design?.tagTypesToIgnore || []),
        tagTypeOBJ?.type,
      ],
    });
    setShowDeleteDialog(false);
  };
  const deleteTag = async () => {
    const tagTypeOBJ = tagTypes[activeSentenceIndex];
    await deleteCustomTag(tagTypeOBJ?.type, activeTagID);
  };

  const deleteAnswer = async (ans, idx) => {
    removeTag(ans?.tagIDs[0]);
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/${ans?.id}`]: undefined,
    });

    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: activeQuestionIndex,
      answers: [
        ...questions[activeQuestionIndex].answers.slice(0, idx),
        ...questions[activeQuestionIndex].answers.slice(idx + 1),
      ],
    });
  };

  return { deleteQuestion, deleteAnswer, deleteSentence, deleteTag };
};

export default useDeleteProperties;
