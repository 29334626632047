import {Pane, Text} from "evergreen-ui";
import {toTitleCase} from "utils/utls";

export function SelectedItem({
                                 item,
                                 addOrRemoveSelectedProduct,
                                 selectedProducts = [],
                                 type,
                                 label = "name",
                                 primaryKey = "id",
                                 subTitle = "",
                                 selected,
                                 forceSelected,
                                 border = true,
                             }) {
    const itemName = typeof item === "string" ? item.split("-").join(" ") : item?.[label];

    let displayName = toTitleCase(itemName);
    try {
        displayName = decodeURI(toTitleCase(itemName))
    } catch (e) {

    }


    return (<Pane
        display="flex"
        alignItems="center"
        className="selected-item"
        gap={"11px"}
        width={"100%"}
        borderBottom={border ? "1px solid  #DBE1E7" : ""}
        paddingY={type === "collection" ? "16px" : "6px"}
        onClick={() => {
            addOrRemoveSelectedProduct(item);
        }}
    >
        <div className="products-checkbox-container">
            {type === "product" || type === "collection" ? ((selectedProducts?.find((obj) => obj?.[primaryKey] === item?.[primaryKey]) || forceSelected) &&
                <div className="products-checkbox"></div>) : selected ? (
                <div className="products-checkbox"></div>) : (<div/>)}
        </div>
        {(item?.image || item?.images) && (<img
            src={localStorage.getItem("INDUSTRY") === "SASS" ? "https://s3.eu-central-1.amazonaws.com/static.skeep.io/images/plan.svg" : item?.image ?? item.images[0] ?? ""}
            alt=""
            style={{width: "36px", height: "36px", borderRadius: "5px"}}
        />)}

        <Text width="60%" fontSize="small">
            {displayName}<span className="subtitle">{subTitle}</span>
            <br/>
        </Text>
    </Pane>);
}
