import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Autosuggest from "react-autosuggest";

import { Client } from "Client";
import "./CustomInputs.scss";

const theme = {
  container: "field field-tags",
  containerOpen: "field open",
  input: "field-input",
  inputOpen: "field-input open",
  inputFocused: "field-input focused",
  suggestionsContainer: "tags-autosuggest__suggestions-container",
  suggestionsContainerOpen: "tags-autosuggest__suggestions-container--open",
  suggestionsList: "tags-autosuggest__suggestions-list",
  suggestion: "tags-autosuggest__suggestion",
  suggestionFirst: "tags-autosuggest__suggestion--first",
  suggestionHighlighted: "tags-autosuggest__suggestion--highlighted",
  sectionContainer: "tags-autosuggest__section-container",
  sectionContainerFirst: "tags-autosuggest__section-container--first",
  sectionTitle: "tags-autosuggest__section-title",
};
function CustomTagsSearchV2(
  {
    placeHolder,
    width,
    label,
    api,
    labelKey = "name",
    onChange,
    onCreateNew,
    controlledTags,
    onBlur,
    clearOnSelect = true,
    defaultValue = [],
    onSetNewTag,
  },
  ref
) {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [tags, setTags] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const getSuggestionValue = (suggestion) => {
    return suggestion.id;
  };
  const renderSuggestion = (suggestion) => {
    // eslint-disable-next-line no-eval
    return <div className="div-sug">{eval(`suggestion.${labelKey}`)}</div>;
  };

  useEffect(() => {
    if (controlledTags)
      setTags(
        onCreateNew
          ? [...controlledTags, { name: "+ Add tag" }]
          : controlledTags
      );
  }, [controlledTags, onCreateNew]);


  const handleChange = (event, { newValue, method }) => {
    const value =
      suggestions.find((val) => val.id === newValue)?.type ?? newValue;
    if (newValue !== "+ Add tag") {
      setValue(value);
      if(onSetNewTag){
        onSetNewTag(value);
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      clear: () => {
        setSuggestions([]);
        setValue("");
        setTags([]);
      },
    };
  });

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length < 3) return;
    setLoading(true);
    try {
      let data = (await Client.get(api + `?query=${value}`))?.data;
      if (onCreateNew) {
        data = [...data, { name: "+ Add tag" }];
      }
      setSuggestions(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    if (clearOnSelect) {
      setSuggestions([]);
      setValue("");
    }
  };

  return (
    <div className="tags-container">
      <div className="field-label">{label}</div>

      <div className="tag-search" onBlur={onBlur}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          renderSuggestion={renderSuggestion}
          inputProps={{
            value,
            onChange: handleChange,
            placeholder: placeHolder,
            style: {
              width: width + " !important",
            },
          }}
          onSuggestionSelected={(e, data) => {
            if (data.suggestion.name === "+ Add tag") onCreateNew();
            else {
              if (!tags.find((t) => t.id === data.suggestion.id)) {
                const values = [...tags, data.suggestion];
                setTags(values);
                onChange(
                  values.filter((v) => v.name !== "+ Add tag"),
                  data.suggestion
                );
              }
            }
          }}
          theme={theme}
          renderSuggestionsContainer={(params) =>
            loading ? (
              <div className="input-loading">
                <div className="loader"></div>
              </div>
            ) : (
              <div {...params.containerProps}>{params.children}</div>
            )
          }
        />
      </div>
    </div>
  );
}

export default forwardRef(CustomTagsSearchV2);
