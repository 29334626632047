import "./InsightsPage.scss"

const InsightsPage = () => {

    return(
        <div className="app-page f-center">
            Insights Page
        </div>
    )
}

export default InsightsPage;