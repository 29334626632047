import "./DropdownWithCheckbox.scss";

function MenuWithCheckboxes({options,setActiveOptions,activeOptions,position,keywordSearch}) {
    const withSubLabel = !!options[0]?.subLabel;

    const onClickOption = (optionOBJ) => {
        if (activeOptions.includes(optionOBJ.value)) {
            setActiveOptions(activeOptions.filter(option => option !== optionOBJ.value))
        } else {
            setActiveOptions(curr => [...curr, optionOBJ.value])
        }
    };

    return (
        <div className="dropdown-container" style={{...position || {}}}>
            {options?.map(optionOBJ => {
                const activeOption = activeOptions.includes(optionOBJ.value);
                const splitLabel = optionOBJ.label?.split(keywordSearch);

                return (
                    <div className="dropdown-option" onClick={e => onClickOption(optionOBJ)}>
                        <div className="dropdown-checkbox">
                            <div
                                className={`dropdown-checkbox-inner ${activeOption ? "dropdown-checkbox-inner-active" : ""}`}/>
                        </div>
                        <div>
                            {keywordSearch ? (
                                <>
                                    <span>{splitLabel[0]}</span>
                                    <span style={{color: "#017CF8"}}>{keywordSearch}</span>
                                    <span>{splitLabel[1]}</span>
                                </>
                            ) : (
                                <p>{optionOBJ?.label}</p>
                            )}

                            {withSubLabel && <p className="dropdown-option-sub-label">{optionOBJ?.subLabel}</p>}
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default MenuWithCheckboxes;