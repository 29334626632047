import {Client} from "Client";
import {Dialog, Pane} from "evergreen-ui";
import {useState} from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";
import "./Dialog.scss";

const DeleteTagDialog = ({
  product,
  tag,
  setTag,
  fetchProducts,
  setProduct,
}) => {
  const doNotShowDialogStorage =
    localStorage.getItem("doNotShowAgainDeleteTagDialog") === "true";
  const [doNotShowAgainTemporary, setDoNotShowAgainTemporary] = useState(false);

  const toggleDoNotShowAgain = () => {
    if (doNotShowAgainTemporary) {
      setDoNotShowAgainTemporary(false);
      return localStorage.setItem("doNotShowAgainDeleteTagDialog", "false");
    }

    setDoNotShowAgainTemporary(true);
    return localStorage.setItem("doNotShowAgainDeleteTagDialog", "true");
  };

  async function removeTag() {
    const response = await Client.put(`emp/products/${product.id}/tags`, {
      tags: [
        ...product.customTags.map((t) => t?.tagID),
        ...product.tags
          .filter((t) => t.tagID !== tag.tagID)
          .map((t) => t?.tagID),
      ],
    });

    setTag(undefined);
    setProduct({ ...product, tags: response?.data?.tags });
    fetchProducts();
  }

  if (doNotShowDialogStorage && product && tag) {
    removeTag();
    return null;
  }

  return (
    <Pane>
      <Dialog
        isShown={!doNotShowDialogStorage && product && tag}
        onCloseComplete={() => setTag(undefined)}
        width="560px"
        hasFooter={false}
        hasHeader={false}
        minHeightContent={200}
        topOffset={window.innerHeight / 3}
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
      >
        <div className="dialog-content" style={{ height: 120 }}>
          <h4>Remove Tag</h4>
          <p>Are you sure you want to remove the tag from the product?</p>
        </div>

        <div className="container">
          <div
            className="do-not-show-again-container"
            onClick={toggleDoNotShowAgain}
          >
            <Checkbox checked={doNotShowAgainTemporary} />
            <p>Do not show me again</p>
          </div>
          <button
            onClick={() => setTag(undefined)}
            className="cancel dialog-btn"
            style={{
              color: "#75787E",
              marginRight: "16px",
              cursor: "pointer",
              background: "none",
            }}
          >
            Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={removeTag}
            style={{
              color: "#fff",
              background: "#E45447",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            Yes, remove
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default DeleteTagDialog;
