import React, {useEffect, useState} from "react";
import Button from "components/Button/Button";
import Header from "components/Header/Header";
import "./UserManagementPage.scss";
import {useNavigate} from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import add from "../../assets/icons/plus.svg";
import add_disabled from "../../assets/icons/plus-disabled.svg";
import trash from "../../assets/icons/trash.svg";
import warning from "../../assets/icons/user-info-warning.svg";
import {Client} from "Client";
import useAuth from "stores/auth";
import UnsuccessfulLoadData from "components/UnsuccessfulLoadData/UnsuccessfulLoadData";

import info from "../../assets/icons/info-black-error.svg";
import exit from "../../assets/icons/exit-black-error.svg";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import {ReactComponent as InfoSign} from "../../assets/icons/info-tag.svg";

export function Status({status}) {
  return (
    <div
      className={
        status === "DISABLED"
          ? "user-status-container-disabled"
          : status === "INVITATION_SENT"
            ? "user-status-container-purple"
            : "user-status-container"
      }
    >
      <p className="user-status">
        {status === "DISABLED"
          ? "Disabled"
          : status === "INVITATION_SENT"
            ? "Invitation Sent"
            : "Active"}
      </p>
    </div>
  );
}

const UserManagementPage = () => {
  const {minimized} = useAuth();

  const navigate = useNavigate();

  const [teamMembers, setTeamMembers] = useState([]);
  const [fullNameError, setFullNameError] = useState(false);

  const [teamMemberMax, setTeamMemberMax] = useState(1);

  const [addTeamMember, setAddTeamMember] = useState(false);
  const [deleteMember, setDeleteMember] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamEmail, setTeamEmail] = useState("");
  const [disableEmailSent, setDisableEmailSent] = useState([]);

  const [selectedMember, setSelectedMember] = useState("");
  const [rootUserID, setRootUserID] = useState("");

  const [deleteIconHovered, setDeleteIconHovered] = useState(false);
  const [deleteIconHoveredItem, setDeleteIconHoveredItem] = useState(null);

  const [emailSentAgain, setEmailSentAgain] = useState(null);

  const [memberExist, setMemberExist] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [loading, setLoading] = useState(true);

  const [unsuccessfulLoad, setUnsuccessfulLoad] = useState(false);

  const [hasError, setHasError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("add user");

  const [showTour, setShowTour] = useState(false);
  const [tourCurrentIndex, setTourCurrentIndex] = useState(0);

  function handleCurrentTourIndex(index) {
    setTourCurrentIndex(index);
  }

  function handleOpenTour(value) {
    setTourCurrentIndex(0);
    setShowTour(value);
  }

  function checkPlan(plan) {
    switch (plan) {
      case "BASIC":
        return 1;
      case "PREMIUM":
        return 2;
      case "GROWTH":
        return 2;
      case "PLATINUM":
        return 4;
      case "PLATINUM_NEW":
        return 4;
      case "SKEEPX":
        return 5;
      default:
        return 1;
    }
  }

  useEffect(() => {
    init();
  }, []);

  function init() {
    setLoading(true);
    setUnsuccessfulLoad(false);
    Client.get("/emp/brand")
      .then((res) => {
        setRootUserID(res.data.rootUserID);
        if (res.data.billing !== null) {
          if (res.data.billing?.customPlanID) {
            if (res.data.seats === null) {
              setTeamMemberMax(checkPlan(res.data.billing.plan));
            }else{
              setTeamMemberMax(res.data.seats);
            }
          } else {
            setTeamMemberMax(checkPlan(res.data.billing.plan));
          }
          setTimeout(() => setLoading(false), 3000);
        }
        Client.get("/emp/users")
          .then((res) => {
            setTeamMembers(res.data);
          })
          .catch(() => {
            setLoading(false);
            setUnsuccessfulLoad(true);
          });
      })
      .catch(() => {
        setLoading(false);
        setUnsuccessfulLoad(true);
      });
  }

  const handleAddMember = () => {
    setHasError(false);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(teamEmail)) {
      const data = {
        username: teamEmail?.toLowerCase()?.trim(),
        fullName: teamName,
      };
      Client.post("/emp/users", data)
        .then((res) => {
          setTeamEmail("");
          setTeamName("");
          setAddTeamMember(false);
          init();
        })
        .catch(() => {
          setErrorMessage("add user");
          setHasError(true);
        });
    } else {
      setEmailInvalid(true);
    }
  };

  function handleDeleteMember(id) {
    setSelectedMember(id);
    setDeleteMember(true);
  }

  const handleTeamNameChange = (value) => {
    setTeamName(value.target.value);
  };

  const handleTeamEmailChange = (value) => {
    setEmailInvalid(false);
    const exists = teamMembers.some(
      (member) => member.email === value.target.value
    );
    if (exists) {
      setMemberExist(true);
    } else {
      setMemberExist(false);
    }
    setTeamEmail(value.target.value);
  };

  const handleCancelAdd = () => {
    setTeamName("");
    setTeamEmail("");
    setAddTeamMember(false);
  };

  const handleDeleteMemberCancel = () => {
    setSelectedMember("");
    setDeleteMember(false);
  };

  const handleDeleteMemberConfirm = () => {
    setHasError(false);
    Client.delete(`/emp/users/${selectedMember}`)
      .then((res) => {
        setSelectedMember("");
        setDeleteMember(false);
        init();
      })
      .catch(() => {
        setErrorMessage("delete user");
        setHasError(true);
      });
  };

  function handleHover(i) {
    setDeleteIconHovered(true);
    setDeleteIconHoveredItem(i);
  }

  function handleHoverFinished() {
    setDeleteIconHovered(false);
    setDeleteIconHoveredItem(null);
  }

  const handleEmailSend = (i) => {
    const data = {
      username: teamMembers[i]?.email,
      fullName: teamMembers[i]?.fullName,
    };

    Client.post("/emp/users/resend", data)
      .then((res) => {
        setAddTeamMember(false);
        init();
      })
      .catch(() => {
        setHasError(true);
      });
    setDisableEmailSent([...disableEmailSent, i]);
    setEmailSentAgain(i);
  };

  useEffect(() => {
    if (
      (!teamName?.split(" ")[0] || !teamName?.split(" ")[1]) &&
      teamName?.length > 0
    ) {
      setFullNameError(true);
    } else {
      setFullNameError(false);
    }
  }, [teamName]);

  const BrandTeamName = localStorage.getItem("SKP_BRAND")
  return (
    <div className="user-page">
      {/* {showTour && <TourPopup tourCurrentIndex={tourCurrentIndex} handleCurrentTourIndex={handleCurrentTourIndex} handleOpenTour={handleOpenTour} />} */}
      {deleteMember && (
        <div
          className={
            minimized
              ? "user-delete-member-pop-container-minimized"
              : "user-delete-member-pop-container"
          }
        >
          <div className="user-delete-member-pop">
            <p className="user-delete-member-pop-header">Delete Member</p>
            <p className="user-delete-member-pop-info">
              Are you sure you want to delete this member?
            </p>
            <div className="user-delete-member-pop-buttons-container">
              <button
                onClick={handleDeleteMemberCancel}
                className="user-delete-member-pop-cancel-button"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteMemberConfirm}
                className="user-delete-member-pop-confirm-button"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <Header name="User Management" handleOpenTour={handleOpenTour}/>
      {loading ? (
        <LoadingPage/>
      ) : (
        <div className="user-content">
          <div style={{display: "flex", alignItems: "center"}}>

            <p className="user-header">{BrandTeamName}</p>
            <p
              className={
                teamMembers.length === teamMemberMax
                  ? "user-top-right-text user-top-right-text-warning"
                  : "user-top-right-text"
              }
            >
              | {teamMembers.length}/{teamMemberMax} members
            </p>
          </div>
          {teamMembers.length >= teamMemberMax && (
            <div className="infoNotifError">
              <InfoSign fill="#F4B037"/>
              <p className="user-limit-warning">
                Your plan seems to have reached its maximum number of
                members, please{" "}
                <span
                  onClick={() => navigate("/billing/plans")}
                  style={{
                    textDecoration: "underline",
                    color: "#017CF8",
                    cursor: "pointer",
                  }}
                >
                      upgrade
                    </span>{" "}
                or delete users to make space available.
              </p>
            </div>
          )}
          {/* <p className="user-subheader">
            View and manage users in your account
          </p> */}
          {unsuccessfulLoad ? (
            <UnsuccessfulLoadData/>
          ) : (
            <>
              <button
                onClick={() => setAddTeamMember(true)}
                disabled={teamMembers.length >= teamMemberMax || loading}
                className="user-page-add-container"
              >
                <img
                  alt=""
                  className="user-page-add"
                  src={
                    teamMembers.length >= teamMemberMax || loading
                      ? add_disabled
                      : add
                  }
                />
                <p
                  className={
                    teamMembers.length >= teamMemberMax || loading
                      ? "user-page-add-text user-page-add-text-disabled"
                      : "user-page-add-text"
                  }
                >
                  Add Team Member
                </p>
              </button>
              <TableContainer sx={{maxWidth: 800}}>
                <Table sx={{maxWidth: 800}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{fontSize: "15px", fontFamily: "Satoshi", textAlign: "center"}}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{fontSize: "15px", fontFamily: "Satoshi", textAlign: "center"}}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{fontSize: "15px", fontFamily: "Satoshi", textAlign: "center"}}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{fontSize: "15px", fontFamily: "Satoshi", textAlign: "center"}}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{maxWidth: 800}}>
                    {teamMembers.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Satoshi",
                            color: "#75787E",
                            textAlign: "center"
                          }}
                        >
                          {row.fullName}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Satoshi",
                            color: "#75787E",
                            textAlign: "center"
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Satoshi",
                            color: "#75787E",
                            textAlign: "center",
                          }}
                        >
                          <Status status={row.status ? row.status : "Active"}/>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Satoshi",
                            color: "#75787E",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "table-cell",
                          }}
                        >
                          {row.status === "INVITATION_SENT" && (
                            <button
                              className="user-icon-container"
                              disabled={disableEmailSent.includes(i)}
                              onClick={() => handleEmailSend(i)}
                            >  
                             <span className="resend-email">
                               Resend Email  
                              </span>
                            </button>
                          )}
                          {disableEmailSent.includes(i) &&
                            row.status === "INVITATION_SENT" && (
                              <p className="email-sent">Email sent</p>
                            )
                          }
                          {!row?.root && (
                            <button
                              className={`user-icon-container ${row.id}`}
                              onClick={() => handleDeleteMember(row.id)}
                            >
                              <img
                                alt=""
                                src={
                                  deleteIconHovered &&
                                  deleteIconHoveredItem === i
                                    ? trash
                                    : trash
                                }
                                onMouseEnter={() => handleHover(i)}
                                onMouseLeave={() => handleHoverFinished()}
                              />
                            </button>
                          )}

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {addTeamMember && (
                <div className="add-team-container">
                  <div className="add-team-name-container">
                    <div style={{display: "flex"}}>
                      <span className="add-team-tag">Full Name</span>
                      {fullNameError && (
                        <span
                          className="add-team-tag"
                          style={{marginLeft: "10px", color: "#E45447"}}
                        >
                          Last name is missing
                        </span>
                      )}
                    </div>
                    <input
                      type="text"
                      className="add-team-name-input"
                      placeholder="Add Name"
                      onChange={handleTeamNameChange}
                    />
                  </div>
                  <div className="add-team-email-container">
                    <p className="add-team-tag">Email</p>
                    <input
                      type="email"
                      className="add-team-email-input"
                      placeholder="Add Email"
                      onChange={handleTeamEmailChange}
                    />
                  </div>
                  <Button
                    type="BUTTON"
                    text="Cancel"
                    onClick={handleCancelAdd}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      width: "60px !important",
                      background: "transparent",
                      color: "#75787E",
                    }}
                  />
                  <Button
                    type="SUBMIT"
                    onClick={handleAddMember}
                    disabled={
                      teamEmail === "" ||
                      fullNameError ||
                      teamName === "" ||
                      memberExist ||
                      emailInvalid
                    }
                    text="Send Invitation"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      width: "167px",
                    }}
                  />
                </div>
              )}
              {hasError && (
                <div style={{width: "928px", position: "relative"}}>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: 0,
                      padding: "15px 10px",
                      backgroundColor: "rgba(255, 49, 4, 0.38)",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      alt=""
                      src={exit}
                      style={{
                        width: "7px",
                        height: "7px",
                        position: "absolute",
                        top: 8,
                        right: 8,
                      }}
                      onClick={() => setHasError(false)}
                    />
                    <div style={{display: "flex", flexDirection: "row"}}>
                      <img
                        alt=""
                        src={info}
                        style={{width: "14px", height: "14px"}}
                      />
                      <p
                        style={{
                          maxWidth: "230px",
                          padding: "0 10px",
                          fontSize: "10px",
                        }}
                      >
                        Seems like you tried to {errorMessage} and it didn't
                        work. Please try again.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {emailInvalid && (
                <div className="user-limit-warning-container">
                  <img alt="" src={warning} className="user-limit-warning-icon"/>
                  <div className="user-limit-warning-text-container">
                    <p className="user-limit-warning-normal">
                      You have entered an invalid email address.
                    </p>
                  </div>
                </div>
              )}
              {memberExist && (
                <div className="user-limit-warning-container">
                  <img alt="" src={warning} className="user-limit-warning-icon"/>
                  <div className="user-limit-warning-text-container">
                    <p className="user-limit-warning-normal">
                      This team member already exists.
                    </p>
                  </div>
                </div>
              )}

            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserManagementPage;
