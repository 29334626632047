import React from "react";
import {ReactComponent as Arrow} from "./leftArrow.svg";

const SliderArrow = ({ fill, type, onClick, blur = false ,currentSlide}) => {
  return (
    <>
      {blur && (
        <div className={`blur-white ${type === "next" ? "right" : ""}`}></div>
      )}
      {
      ( type==="previous" && currentSlide!==0  ||  type==="next" && currentSlide!==1) &&
      <span
        onClick={onClick}
        style={{
          background: "#017CF8",
          width: "20px",
          height: "20px",
          borderRadius: "100%",
          color: "#fff",
          position: "absolute",
          transform: type === "previous" && "rotate(180deg)",
          zIndex: 1,
          top: "25%",
          cursor: "pointer",
          left: type === "next" ? "97%" : "-9px",
        }}
        className={"slider-arrow"}
      >
        <Arrow style={{ position: "relative", left: "8px", top: "-2px" }} />
      </span>
      }
    </>
  );
};

export default SliderArrow;
