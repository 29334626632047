import { useEffect, useState } from "react";
import { toaster } from "evergreen-ui";
import { useOnboarding } from "../../../stores/use-onboarding";
import { ReactComponent as Hint } from "../../../assets/icons/hint.svg";
import NavigationButtons from "../components/NavigationButtons";
import { Client } from "../../../Client";
import useEditorActions from "../../../hooks/useEditorActions";
import { useNavigate } from "react-router-dom";
import useEditor from "../../../stores/use-editor";
import useExperiencePage from "../../../hooks/useExperiencePage";
import { useDesign } from "../../../stores/design";
import useOnboardingAnimation from "../../../hooks/useOnboardingAnimation";
import { updateDesign } from "../../../utils/onboardingDesignToExpDesign";
import LoadingText from "../components/LoadingText";
import SearchInput from "../components/SearchInput";
import ColorWidget from "../components/ColorWidget";
import delay from "../../../utils/delay";
import StepHeader from "./StepHeader";

const OnboardingStep1 = () => {
  const [showColorsWizard, setShowColorsWizard] = useState(false);
  const [totalProductsScanned, setTotalProductsScanned] = useState(0);
  const [refetch, setRefetch] = useState(null);

  const { isProductsShown, setIsProductsShown } = useOnboardingAnimation();
  const {
    setProductsForAnimation,
    setCreateExpLoading,
    createExpLoading,
    setImageForNonEnglish,
    imageForNonEnglish,
  } = useOnboarding();
  const { setStep, step, design: onboardingDesign } = useOnboarding();
  const {
    experience,
    fetchExperience,
    changeActiveQuestionIndex,
    fetchQuestions,
    changeTabs,
  } = useEditor();
  const { setIsEditing } = useExperiencePage();
  const { getDesign } = useDesign();

  useEffect(() => {
    if (!isProductsShown) return;

    Client("/experiences").then((res) => {
      if (res?.data?.length) {
        const firstExpID = res?.data[0]?.id;

        fetchExperience(firstExpID);
        changeTabs("experience-content");
        setIsEditing(res?.data[0]);
        getDesign();
        changeActiveQuestionIndex(0);
        fetchQuestions(firstExpID, 5);
      } else {
        setTimeout(() => setRefetch(Math.random()), 3000);
      }
    });
  }, [createExpLoading, refetch]);

  useEffect(() => {
    if (!experience.id || !createExpLoading) return;

    Client.put(
      `/emp/experiences/${experience?.id}/design`,
      updateDesign(experience, onboardingDesign)
    )
      .then(async () => {
        await delay(3000);
        setStep(step + 1);
      })
      .catch((err) => {
        toaster.danger("Failed to update experience, please try again later.");
      });
  }, [experience, createExpLoading]);

  useEffect(() => {
    Client.post(`/emp/products?page=${0}`, {
      names: [],
      tags: [],
      types: [],
      retrieveAll: true,
    }).then((response) => {
      if (response?.data?.length > 0) {
        setTotalProductsScanned(response?.data?.length);
        setProductsForAnimation(
          [
            ...response?.data?.slice(-(response?.data?.length % 3)),
            ...Array(3 - (response?.data?.length % 3 || 3)).keys(),
          ]?.slice(-3)
        );
        setIsProductsShown(true);
        if (!imageForNonEnglish)
          setImageForNonEnglish(response?.data?.[0]?.images?.[0]);
      }

      if (!isProductsShown) {
        setTimeout(() => setRefetch(Math.random()), 3000);
      }
    });
  }, [createExpLoading, refetch]);

  if (createExpLoading)
    return <LoadingText totalProductsScanned={totalProductsScanned} />;
  if (showColorsWizard)
    return (
      <ColorsWizard
        setCreateExpLoading={setCreateExpLoading}
        setShowColorsWizard={setShowColorsWizard}
      />
    );
  return <CollectionsPicker setShowColorsWizard={setShowColorsWizard} />;
};

export default OnboardingStep1;

const CollectionsPicker = ({ setShowColorsWizard }) => {
  const setCollectionOBJ = useOnboarding((s) => s.setCollectionOBJ);
  const { scanningMethod, setScanningMethod } = useOnboarding();
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const filterOutSmallAndLargeCollections = collections?.filter(
    (col) => col?.products_count > 5 && col?.products_count < 70
  );
  const verifyCollectionsAfterFilter =
    filterOutSmallAndLargeCollections?.length === 0
      ? collections
      : filterOutSmallAndLargeCollections;
  const filteredCollectionNames =
    searchInput?.length === 0
      ? verifyCollectionsAfterFilter
      : verifyCollectionsAfterFilter?.filter((col) =>
          col.title?.toLowerCase()?.includes(searchInput?.toLowerCase())
        );
  const { createExperience } = useEditorActions();
  const navigate = useNavigate();

  useEffect(() => {
    Client.get("/emp/collections")
      .then((res) => {
        setCollections(res.data);
      })
      .catch(() => {
        //toaster.warning("Failed to get product collections.");
      });
  }, []);

  const handleNextButtonClick = async () => {
    if (!selectedCollection) return;
    setCollectionOBJ(
      collections?.find((c) => c?.handle === selectedCollection?.handle)
    );

    await createExperience({
      selected: "collections",
      products: null,
      collections: [selectedCollection?.handle],
      collectionIDs: [selectedCollection?.id],
      experienceOBJ: { name: "My first experience", type: "QUIZ" },
      setShow: () => null,
      onCreate: () => setShowColorsWizard(true),
      showToast: false,
    });
  };

  const handleScanProducts = async () => {
    await Client.put("/emp/brand/variants").then((res) => {
      console.log("res", res);
    });
  };

  return (
    <div className="onboarding-step">
      <StepHeader />
      <div className="onboarding-titles-container">
        <h2>Let’s create your first experience together</h2>
        <p>
          Here you can get a glimpse of our platform and see the magic happening
          in a snap.
        </p>
      </div>
      <h3 className="scanning-question">
        What is your preferred method of scanning?
      </h3>
      <div
        className="scanning-collection-item"
        onClick={() => {
          if(scanningMethod!=="products"){
            handleScanProducts();
            setScanningMethod("products")}}
          }
         
      >
        <div className="checkbox-container checkbox-container-circle">
          {scanningMethod === "products" && (
            <div className="checkbox-inner-box checkbox-inner-box-circle" />
          )}
        </div>
        <p className="scanning-title">By products</p>
      </div>
      <div
        className="scanning-collection-item"
        onClick={() => {
          if(scanningMethod!=="variants"){
          handleScanProducts();
          setScanningMethod("variants");}
        }}
      >
        <div className="checkbox-container checkbox-container-circle">
          {scanningMethod === "variants" && (
            <div className="checkbox-inner-box checkbox-inner-box-circle" />
          )}
        </div>
        <p className="scanning-title">By variants</p>
      </div>
      <h3>Choose one collection to scan</h3>
      <div className="collections-selection-container">
        <SearchInput
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <div className="collection-list">
          {filteredCollectionNames?.map((collection) => {
            return (
              <div
                className="collection-item"
                onClick={() => setSelectedCollection(collection)}
              >
                <div className="checkbox-container checkbox-container-circle">
                  {selectedCollection?.handle === collection?.handle && (
                    <div className="checkbox-inner-box checkbox-inner-box-circle" />
                  )}
                </div>
                <p>
                  {collection.title}{" "}
                  {collection?.products_count && (
                    <span style={{ fontSize: 12, marginLeft: 5, opacity: 0.7 }}>
                      {" "}
                      {collection?.products_count} products
                    </span>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <NavigationButtons
        disablePrimaryButton={!selectedCollection?.handle}
        handleNextButtonClick={handleNextButtonClick}
        secondaryButtonText=""
        handleSkipToEditorClick={() => {
          navigate("/experiences");
        }}
      />
    </div>
  );
};

export const ColorsWizard = ({ setCreateExpLoading, setShowColorsWizard }) => {
  const design = useOnboarding((state) => state.design);
  const { experience, resetInitialState } = useEditor();
  const navigate = useNavigate();

  const handleNextButtonClick = () => {
    setCreateExpLoading(true);
    setShowColorsWizard(false);
  };

  return (
    <div className="onboarding-step space-around">
      <div className="onboarding-titles-container">
        <h2>Set up your brand's design</h2>
        <p>Make the quiz colors match your brand’s colors</p>
      </div>
      <div className="colors-wizard-content">
        <div className="colors-widgets-container">
          {Object.keys(design)?.map((propName) => {
            return <ColorWidget key={propName} title={propName} />;
          })}
        </div>
        <p className="hint-text">
          <Hint />
          No worries! You can update colors later.
        </p>
      </div>

      <NavigationButtons
        handleNextButtonClick={handleNextButtonClick}
        secondaryButtonText=""
        handleSkipToEditorClick={() => {
          resetInitialState();
          navigate(`/experiences/edit/${experience?.id}/content`);
        }}
      />
    </div>
  );
};
