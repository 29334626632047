import {useEffect, useState} from "react";
import {Client} from "../Client";
import uploadToS3 from "../utils/uploadToS3";
import useEditor from "../stores/use-editor";
import {useSwiper} from "stores/use-swiper";

const useTagImage = ({tagOBJ,tagTypeOBJ}) => {
    const {experience} = useEditor();
    const {tagsImagesEdited,setTagImage,addToTagTypesToUpdate,tagsImages}=useSwiper();
    const [chosenTagImage, setChosenTagImage] = useState("");
    const customTags = experience?.design?.customTags;
    const [defaultTagTypeImage, setDefaultTagTypeImage] = useState("");
    const tagImageFromDesign = customTags?.[tagOBJ?.type]?.tags?.[tagOBJ?.tagID];
    const tagImageAfterEdit = tagsImagesEdited?.find(tagImageEditOBJ => tagImageEditOBJ?.tagID === tagOBJ?.tagID)?.newImagePublic;
    const imageToDisplay = tagImageAfterEdit || tagImageFromDesign || null;

    useEffect(() => {
        if (imageToDisplay?.includes("s3://")) {
            Client.post("emp/view/file", { s3Path: imageToDisplay }).then(res => {
                setChosenTagImage(res.data)
            })
        } else {
            setChosenTagImage(imageToDisplay)
        }
    }, [imageToDisplay]);


    useEffect(() => {
        Client.post("emp/view/file", { s3Path: tagsImages[tagTypeOBJ?.type] }).then(res => {
            setDefaultTagTypeImage(res.data)
        })
    },[tagsImages?.[tagTypeOBJ?.type]]);

    const uploadTagImage = async (file,updatePreview) => {
        const s3PathFetched = await uploadToS3(file);
        const {data:publicURL} = await Client.post("emp/view/file", { s3Path: s3PathFetched });
        setTagImage({type:tagOBJ.type,tagID:tagOBJ?.tagID,newImageS3Path:s3PathFetched,newImagePublic:publicURL});
        addToTagTypesToUpdate(tagTypeOBJ);
        updatePreview(publicURL);
    };

    const deleteTagImage = () => {
        setTagImage({type:tagOBJ.type,tagID:tagOBJ?.tagID,newImageS3Path:"",newImagePublic:""});
        addToTagTypesToUpdate(tagTypeOBJ);
    }

    return {uploadTagImage,deleteTagImage,chosenTagImage,defaultTagTypeImage}
}

export default useTagImage;