import React from "react";
import Logo from "../../../assets/images/logo.webp";
import ShopifyLogo from "../../../assets/icons/shopify.svg";
import WoocommerceLogo from "../../../assets/images/woocommerceLogo.webp";
// import {ReactComponent as AuthSVG} from "../../../assets/icons/authnew.svg";
import "./Welcome.scss";

function Welcome({ type = "SIGN_IN" }) {
  const title = "Welcome to Skeep";
  const subTitle =
    "Guide shoppers to the perfect products through personalized   on-site experiences, automatically.";

  return (
    <div className={"auth-welcome auth-welcome-50-width"}>
      <div className="auth-welcome-logos">
        <img src={Logo} width={"130px"} />
        {type?.type === "SHOPIFY" ? (
          <img src={ShopifyLogo} width={"120px"} />
        ) : type?.type === "WOOCOMMERCE" ? (
          <img src={WoocommerceLogo} width={"120px"} />
        ) : (
          ""
        )}
      </div>

      <div className="auth-welcome-wrapper">
        <div className="auth-welcome-content">
          {/* <AuthSVG /> */}

          {/* <h1 className="auth-welcome-title">{title}</h1> */}
          <div
            className="auth-welcome-desc"
            // dangerouslySetInnerHTML={{ __html: subTitle }}
          >
          
            {/* {subTitle} */}
          </div>
        </div>
        <div className="auth-welcome-content-small">
          <div className="auth-welcome-company">© Skeep Inc. 2023</div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
