import { Client } from "Client";
import { useEffect, useRef, useState } from "react";
import { BiLoader } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { useClickAway, useDebounce } from "react-use";

export default function DropdownFilter({
  values = [],
  name,
  onChange,
  dataKey = "name",
  secondaryKey,
  allowIncomplete = false,
  allProducts,
  endpoint = "",
}) {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(values);
  const [editing, setEditing] = useState(false);
  const [list, setList] = useState([]);
  const ref = useRef();
  const handleSearch = (value, clear = true) => {
    onChange(value);
    clear && setQuery("");
    clear && setEditing(false);
  };

  const handleClose = () => {
    setQuery("");
    setList([]);
    setEditing(false);
  };

  const allProductsTagId = allProducts
    ?.map((pr) => pr?.tags?.map((item) => item?.tagID))
    ?.flat(1);

  useDebounce(
    () => {
      if (query.length >= 2 && endpoint) {
        setLoading(true);
        Client.get(`${endpoint}?query=${query}`)
          .then((r) => {
            setList(r.data);
          })
          .then(() => {
            setLoading(false);
          });
      } else {
        setList([]);
      }
    },
    200,
    [query]
  );
  useClickAway(ref, () => {
    setQuery("");
    setList([]);
    setEditing(false);
  });
  useEffect(() => {
    setSelected(values);
  }, [values]);
  const queryRegex = new RegExp(query, "gi");

  const handleQuery = (name) => {
    const idx = name.toLowerCase().indexOf(query.toLowerCase());
    if (idx !== -1) {
      let newItem = (
        <p className="product-search-tag-list-api-name">
          {name.substring(0, idx)}
          <span style={{ color: "#017CF8" }}>
            {name.substr(idx, query.length)}
          </span>
          {name.substring(idx + query.length)}
        </p>
      );
      return newItem;
    }
  };
  return (
    <div ref={ref} style={{ position: "relative" }}>
      {editing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <input
              type="text"
              placeholder={`Search by ${name}`}
              className="products-search"
              value={query}
              autoFocus
              onKeyDown={(e) => {
                e.key === "Enter" && allowIncomplete && handleSearch(query);
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
            <FaArrowRight
              onClick={() => {
                allowIncomplete && handleSearch(query);
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 5,
                top: 0,
                bottom: 0,
                margin: "auto",
                fill: "#017CF8",
              }}
            />
            {loading ? (
              <BiLoader
                color="#161F2D"
                style={{
                  position: "absolute",
                  left: "23px",
                  top: "7px",
                  animation: "rotation 2s infinite linear",
                }}
              />
            ) : (
              <IoIosSearch
                color="#161F2D"
                style={{ position: "absolute", left: "23px", top: "7px" }}
              />
            )}
          </div>
          <IoCloseOutline
            color="#75787E"
            size={20}
            style={{ marginLeft: "5px", cursor: "pointer" }}
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      ) : (
        <div
          className="products-filters"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditing(true);
          }}
        >
          <p
            style={{
              textTransform: "capitalize",
            }}
            className="products-filters-text"
          >
            {name}
          </p>
        </div>
      )}
      {list.length > 0 && (
        <div
          className="product-search-tag-list-api-container"
          style={{
            zIndex: 11,
          }}
        >
          {name === "tag"
            ? list
                ?.filter((item) => allProductsTagId?.includes(item.id))
                .filter((a) => a.type !== "CUSTOM" && a.type !== "PRODUCT_TYPE")
                ?.sort((a, b) => {
                  if (a.name.match(queryRegex) && b.name.match(queryRegex)) {
                    return a.name.length - b.name.length;
                  } else if (a.name.match(queryRegex)) {
                    return -1;
                  } else if (b.name.match(queryRegex)) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                ?.map((item) => {
                  let has = false;
                  if (typeof item === "string")
                    has = selected.find((s) => s === item);
                  else if (typeof item === "object")
                    has = selected.find((s) => s.id === item.id);
                  return (
                    <div className="product-search-tag-list-api-box">
                      <div
                        className="products-checkbox-container"
                        style={{
                          marginTop: "8px",
                          minWidth: "16px",
                          minHeight: "16px",
                        }}
                        onClick={() => {
                          handleSearch(item, false);
                        }}
                      >
                        {has && <div className="products-checkbox"></div>}
                      </div>

                      <div className="product-search-type-list-api-info-container">
                        {item.name.match(queryRegex) ? (
                          handleQuery(item.name)
                        ) : (
                          <p className="product-search-tag-list-api-name">
                            {item.name}
                          </p>
                        )}
                        {/*
                  {[...item[dataKey]].map((char) => {
                    console.log(item.name,'item')
                 
                    return (
                      <p
                        className="product-search-tag-list-api-name"
                        style={{
                          color: query.includes(char) ? "#017CF8" : "#000000",
                        }}
                      >
                        {char}
                      </p>
                    );
                  })} */}
                        {secondaryKey && (
                          <p
                            style={{ width: "100%" }}
                            className="product-search-tag-list-api-type"
                          >
                            {item[secondaryKey]
                              ?.toLowerCase()
                              ?.replaceAll("_", " ")}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })
            : list
                .sort((a, b) => {
                  if (a.name.match(queryRegex) && b.name.match(queryRegex)) {
                    return a.name.length - b.name.length;
                  } else if (a.name.match(queryRegex)) {
                    return -1;
                  } else if (b.name.match(queryRegex)) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((item) => {
                  let has = false;
                  if (typeof item === "string")
                    has = selected.find((s) => s === item);
                  else if (typeof item === "object")
                    has = selected.find((s) => s.id === item.id);
                  return (
                    <div className="product-search-tag-list-api-box">
                      <div
                        className="products-checkbox-container"
                        style={{
                          marginTop: "8px",
                          minWidth: "16px",
                          minHeight: "16px",
                        }}
                        onClick={() => {
                          handleSearch(item, false);
                        }}
                      >
                        {has && <div className="products-checkbox"></div>}
                      </div>

                      <div className="product-search-type-list-api-info-container">
                        {item.name.match(queryRegex) ? (
                          handleQuery(item.name)
                        ) : (
                          <p className="product-search-tag-list-api-name">
                            {item.name}
                          </p>
                        )}
                        {/*
                  {[...item[dataKey]].map((char) => {
                    console.log(item.name,'item')
                 
                    return (
                      <p
                        className="product-search-tag-list-api-name"
                        style={{
                          color: query.includes(char) ? "#017CF8" : "#000000",
                        }}
                      >
                        {char}
                      </p>
                    );
                  })} */}
                        {secondaryKey && (
                          <p
                            style={{ width: "100%" }}
                            className="product-search-tag-list-api-type"
                          >
                            {item[secondaryKey]
                              ?.toLowerCase()
                              ?.replaceAll("_", " ")}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
        </div>
      )}
    </div>
  );
}
