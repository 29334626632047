import {Client} from "Client";
import CreateTagPopup from "components/CreateTagPopup/CreateTagPopup";
import {useMemo} from "react";
import DropdownEdit from "./DropdownEdit";

const AddTag = ({
  selectedProduct,
  allProducts,
  onEditChange,
}) => {
  const defaultList = useMemo(
    () =>
      selectedProduct?.tags.filter(
        (pt) => pt.type !== "PRODUCT_TYPE" && pt.type !== "CUSTOM"
      ) ?? [],
    [selectedProduct]
  );


  

  return (
    <DropdownEdit
      endpoint="emp/tags"
      name="Tags"
      allProducts={allProducts}
      onEditChange={onEditChange}
      title="Add Tags"
      createName="Tag"
      from="add-tag"
      onSave={async (values) => {
       await Client.put(`emp/products/${selectedProduct.id}/tags`, {
          tags: [
            ...selectedProduct.customTags.map((t) => t?.tagID),
            ...values,
            ...defaultList.map((t) => t?.tagID),
          ],
        })
        onEditChange(false);
      }}
      multiple
      createPopup={(setSelected, setList, setShowPopup) => (
        <CreateTagPopup
          onClose={() => {
            setShowPopup(false);
          }}
          onSave={async(tags) => {
            setSelected((p) => [...p, ...tags.map((t) => t.id)]);
            setList((p) => [...p, ...tags]);
            setShowPopup(false);

            await Client.put(`emp/products/${selectedProduct.id}/tags`, {
              tags: [
                ...selectedProduct.customTags.map((t) => t?.tagID),
                tags?.[0]?.id,
                ...defaultList.map((t) => t?.tagID),

              ],
            })
            onEditChange(false);
          }}
        />
      )}
      secondaryKey="type"
      disabled={false}
    />
  );
};

export default AddTag;
