import {Pane} from "evergreen-ui";
import {useOnboarding} from "../../../stores/use-onboarding";
import useEditorActions from "../../../hooks/useEditorActions";
import useEditor from "../../../stores/use-editor";
import {useNavigate} from "react-router-dom";

const StepHeader = () => {
    const navigate = useNavigate();
    const step = useOnboarding(s => s.step);
    const {handleSaveChanges} = useEditorActions();
    const {showDemoBtn} =useOnboarding();
    const {experience, resetInitialState} = useEditor();
    const handleSkipToEditorClick = () => {
        handleSaveChanges(true, false)
            .then(() => {
                resetInitialState();
                navigate(`/experiences/edit/${experience?.id}/content`)
            });
    }

    const onClickBookDemo = () => {
        return window.open("https://meetings-eu1.hubspot.com/omer-cohen/onboarding-to-skeep", "_blank");
    }

    return(
        <Pane display="flex" alignItems="center" justifyContent="space-between">
            <p className="step-indicator">Step {step}/5</p>
            <Pane display="flex" alignItems="center" gap={10}>
                {step < 5 && !showDemoBtn &&
                <p className="book-a-demo-onboarding"  onClick={onClickBookDemo}>Book onboarding meeting</p>}
                {step !== 1 && <p className="skip-to-dashboard" onClick={handleSkipToEditorClick}>Skip to editor</p>}
            </Pane>
        </Pane>
    )
}

export default StepHeader;