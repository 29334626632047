import {Question} from "../../../../stores/Types/EditorTypes";
import {EdgeData, NodeData} from "reaflow";

interface Node {
  getId: () => string;
  getNodesAndEdges: () => { nodes: NodeData[], edges: EdgeData[] }
}

class QuestionNode implements Node {
  private readonly _question: Question;
  private readonly _connectedNode: QuestionNode;
  private _selectedAnswers: string[] = [];
  private readonly _id: string;
  private readonly isMultipleSelection: boolean;

  getQuestion(): Question {
    return this._question;
  }

  getId() {
    return this._id;
  }

  getConnectedNode(): QuestionNode {
    return this._connectedNode;
  }

  getSelectedAnswers(): string[] {
    return this._selectedAnswers;
  }

  getIsMultipleSelection(): boolean {
    return this.isMultipleSelection;
  }

  constructor(question: Question, isMultipleSelection: boolean, connectedNode?: QuestionNode) {
    this._question = question;
    this._id = question.id;
    this.isMultipleSelection = isMultipleSelection;
    this._connectedNode = connectedNode;
  }

  setSelectedAnswer(answerId: string) {
    if (this.isMultipleSelection && !this._selectedAnswers.includes(answerId)) {
      this._selectedAnswers.push(answerId);
    }
    if (!this.isMultipleSelection) {
      this._selectedAnswers = [answerId];
    }
  }


  getNodesAndEdges(): { nodes: NodeData[], edges: EdgeData[] } {
    if (this._connectedNode) {
      // @ts-ignore
      return {
        nodes: [{
          id: this._question.id,
          width: this._question?.question.length > 52 ? 420 : 300,
          height: 50,
          data: this._question,
          ports: [{
            id: `in-${this._question.id}`, side: "NORTH", hidden: true, height: 0, width: 0
          }, {
            id: `out-${this._question.id}`, side: "SOUTH", hidden: true, height: 0, width: 0
          },],
          //   @ts-ignore
        }, ...this._question.answers.map((a) => ({
          width: a?.answer.length > 12 ? 140 : 100,
          height: 40,
          id: a.id,
          data: {...a, questionObj: this._question},
          ports: [{
            id: `in-${a.id}`, side: "NORTH", hidden: true, height: 0, width: 0
          }, {
            id: `out-${a.id}`, side: "SOUTH", hidden: true, height: 0, width: 0
          },],
        })),], edges: [...this._question.answers.map((a) => ({
          id: `${this._question.id}-${a.id}`,
          from: this._question.id,
          fromPort: `out-${this._question.id}`,
          toPort: `in-${a.id}`,
          to: a.id,
        })), ...this._connectedNode.getSelectedAnswers().map(selectedAnswer => ({
          id: `${selectedAnswer}-${this._question.id}`,
          from: selectedAnswer,
          to: this._question.id,
          fromPort: `out-${selectedAnswer}`,
          toPort: `in-${this._question.id}`,
        })),]
      }
    }
    return {
      nodes: [{
        id: this._question.id,
        width: this._question?.question.length > 52 ? 420 : 300,
        height: 50,
        data: this._question,
        ports: [{id: `in-${this._question.id}`, side: "NORTH", hidden: true, height: 0, width: 0}, {
          id: `out-${this._question.id}`, side: "SOUTH", hidden: true, height: 0, width: 0
        },],
        //   @ts-ignore
      }, ...this._question.answers.map((a) => ({
        id: a.id,
        width: a?.answer.length > 12 ? 140 : 100,
        height: 40,
        data: {...a, questionObj: this._question},
        ports: [{
          id: `in-${a.id}`, side: "NORTH", hidden: true, height: 0, width: 0
        }, {
          id: `out-${a.id}`, side: "SOUTH", hidden: true, height: 0, width: 0
        },],
      })),], edges: [...this._question.answers.map((a) => ({
        id: `${this._question.id}-${a.id}`,
        from: this._question.id,
        to: a.id,
        fromPort: `out-${this._question.id}`,
        toPort: `in-${a.id}`,
      })),],
    }
  }

}

class ProductNode implements Node {
  private readonly _products;
  private readonly _connectedNode: QuestionNode;

  getId() {
    return "products";
  }

  getProducts() {
    return this._products;
  }

  getConnectedNode(): QuestionNode {
    return this._connectedNode;
  }

  constructor(products, connectedNode: QuestionNode) {
    this._products = products;
    this._connectedNode = connectedNode;
  }

  // @ts-ignore
  getNodesAndEdges() {
    console.log({
      nodes: [{
        id: "products",
        width: 600,
        height: this._products.length > 1 ? 220 : 150,
        data: this._products,
        ports: [{
          id: "in-products", side: 'NORTH', hidden: true, height: 0, width: 0
        },],
      },], edges: [...this._connectedNode.getSelectedAnswers().map(selectedAnswer => ({
        id: `${selectedAnswer}-products`,
        from: selectedAnswer,
        to: "products",
        toPort: `in-products`,
        fromPort: `out-${selectedAnswer}`,
      })),],
    })
    return {
      nodes: [{
        id: "products",
        width: 600,
        height: this._products.length > 1 ? 220 : 150,
        data: this._products,
        ports: [{
          id: "in-products", side: 'NORTH', hidden: true, height: 0, width: 0
        },],
      },], edges: [...this._connectedNode.getSelectedAnswers().map(selectedAnswer => ({
        id: `${selectedAnswer}-products`,
        from: selectedAnswer,
        to: "products",
        toPort: `in-products`,
        fromPort: `out-${selectedAnswer}`,
      })),],
    };

  }
}

export default class LogicTreeView {
  private static nodes: Node[] = [];

  private constructor() {
  }

  static addQuestion = (question: Question, isMultipleSelection: boolean, connectedNode?: QuestionNode) => {
    const node = new QuestionNode(question, isMultipleSelection, connectedNode);
    this.nodes.push(node);
  }

  static selectAnswer = (question: Question, selectedAnswer: string) => {
    const nodeIndex = this.nodes.findIndex(n => n.getId() === question.id);
    const node = this.nodes[nodeIndex] as QuestionNode;
    node.setSelectedAnswer(selectedAnswer);
    this.nodes = this.nodes.slice(0, nodeIndex + 1);
  }

  static addProducts = (products, connectedNode: QuestionNode) => {
    const node = new ProductNode(products, connectedNode);
    console.log(node);
    // @ts-ignore
    this.nodes.push(node);
  }

  static resetTree = () => {
    this.nodes = [];
  }

  static getAllSelectedAnswers() {
    let selectedAnswers = []
    for (let node of this.nodes) {
      if (node instanceof QuestionNode) {
        selectedAnswers = [...selectedAnswers, ...node.getSelectedAnswers()]
      }
    }
    return selectedAnswers;
  }

  static getNodes = () => {
    return this.nodes;
  }

  static findNode = (id: string) => {
    return this.nodes.find(n => n.getId() === id);
  }

  static getAllNodesAndEdges = () => {
    let nodes: NodeData[] = [];
    let edges: EdgeData[] = [];
    console.log(this.nodes,"nodes")
    for (let node of this.nodes) {
      const {nodes: inNodes, edges: inEdges} = node.getNodesAndEdges();
      nodes = [...nodes, ...inNodes];
      edges = [...edges, ...inEdges]
    }
    return {
      edges,
      nodes
    }
  }

}