import {Button} from 'evergreen-ui';
import GuidencePopup from "pages/ProductPage/components/GuidencePopup";
import "./ExploreButton.scss";
import {FaPlayCircle} from "react-icons/fa";
import {useState} from 'react';

export default function ExploreButton ({videoUrl, name}) {
    const [showGuidancePopup, setShowGuidancePopup] = useState(false
		// localStorage.getItem("SHOW_EXPLORE_PAGE") &&
		// 	JSON.parse(localStorage.getItem("SHOW_EXPLORE_PAGE")).includes(name)
		// 	? false
		// 	: true
	);
    return (
		<>
			<Button
				appearance="primary"
				className="explore-button"
				iconBefore={<FaPlayCircle />}
				onClick={() => setShowGuidancePopup(true)}>
				Watch Tutorial
			</Button>
			<GuidencePopup
				show={showGuidancePopup}
				setShow={setShowGuidancePopup}
				videoContent={true}
				videoUrl={videoUrl}
				name={name}
			/>
		</>
	);
}