import { Client } from "Client";
import Button from "components/Button/Button";
import { Popover } from "evergreen-ui";
import { useEffect, useRef, useState } from "react";
import { BiLoader } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { IoAlertCircleOutline, IoCloseOutline } from "react-icons/io5";
import { useClickAway } from "react-use";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add-icon.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-icon-new.svg";
import { styles } from "./Styles";
import "./DropdownEdit.scss";

export default function DropdownEdit({
  disabled,
  name = "",
  from = "",
  endpoint = "",
  allProducts,
  dataKey = "name",
  secondaryKey = "",
  title = "",
  multiple = false,
  createPopup = undefined,
  onSave,
  createName = "",
  onEditChange = (editing) => {},
  label = <AddIcon className="add-icon" stroke="#017CF8" />,
}) {
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [message, setMessage] = useState(false);
  const ref = useRef();
  const productTypePopUp = name === "Product Type";
  const showCreatePopup = query.length > 2 && list?.length === 0 && !loading;

  function handleChange(e) {
    setMessage(false);
    const query = e.target.value;
    setQuery(query);
    if (query.length >= 2 && endpoint) {
      setLoading(true);
      Client.get(`${endpoint}?query=${query}`)
        .then((r) => {
          setList(r.data.map((tagOBJ) => ({ ...tagOBJ, tagID: tagOBJ.id })));
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setList([]);
    }
  }

  function hide() {
    setEditing(false);
    setQuery("");
    setList([]);
    setSelected([]);
    setMessage(false);
  }

  useClickAway(ref, () => {
    hide();
  });

  useEffect(() => {
    if (editing) {
      setSelected([]);
      setList([]);
    }
  }, [editing]);

  useEffect(() => {
    setQuery("");
  }, [showPopup]);

  useEffect(() => {
    onEditChange(editing);
  }, [editing, onEditChange]);
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMessage(true);
  };

  const queryRegex = new RegExp(query, "gi");

  const handleQuery = (name) => {
    const idx = name.toLowerCase().indexOf(query.toLowerCase());
    if (idx !== -1) {
      let newItem = (
        <p className="product-search-tag-list-api-name">
          {name.substring(0, idx)}
          <span style={{ color: "#017CF8" }}>
            {name.substr(idx, query.length)}
          </span>
          {name.substring(idx + query.length)}
        </p>
      );
      return newItem;
    }
  };

  const tagsList = list
    .filter(
      (a) =>
        multiple &&
        !selected?.find((s) => s === a.tagID) &&
        a.type !== "CUSTOM" &&
        a.type !== "PRODUCT_TYPE"
    )
    .sort((a, b) => {
      if (a.name.match(queryRegex) && b.name.match(queryRegex)) {
        return a.name.length - b.name.length;
      } else if (a.name.match(queryRegex)) {
        return -1;
      } else if (b.name.match(queryRegex)) {
        return 1;
      } else {
        return 0;
      }
    });

  const productTagId = allProducts
    ?.map((pr) => pr?.tags?.map((item) => item.tagID))
    .flat(1);

  // const produtTagsId = selectedProduct?.tags
  //   ?.map((item) => item?.tagID)
  //   ?.flat(1);

  const filteredTagsList = tagsList.filter((el) =>
  productTagId.includes(el.tagID)
  );

  const listToShow = from === "add-tag" ? filteredTagsList : tagsList;

  return (
    <>
      <Popover
        onOpen={() => setEditing(true)}
        content={({ close }) => {
          return (
            <div ref={ref} style={{ position: "relative" }}>
              <div
                style={{
                  ...styles.popUpContainer,
                  height:
                    from === "product type" && showCreatePopup
                      ? "271px"
                      : "453px",
                }}
              >
                <div style={styles.headerContainer}>
                  <p style={styles.header}>{title || "Edit " + name}</p>
                  <IoCloseOutline
                    color="#75787E"
                    size={20}
                    onClick={() => {
                      close();
                      hide();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {name === "Product Type" && (
                  <div style={styles.popUpAlertContainer}>
                    <IoAlertCircleOutline color="#F4B037" size={25} />
                    <p style={styles.popUpAlert}>
                      Please note, once you change product type, some of the
                      tags will be removed.
                    </p>
                  </div>
                )}
                <div style={{ position: "relative", marginTop: "15px" }}>
                  {loading ? (
                    <BiLoader
                      color="#161F2D"
                      style={{
                        position: "absolute",
                        left: "10px",
                        zIndex: 15,
                        top: "10px",
                        animation: "rotation 2s infinite linear",
                      }}
                    />
                  ) : (
                    <>
                      <SearchIcon
                        color="#161F2D"
                        style={{
                          position: "absolute",
                          left: "10px",
                          zIndex: 15,
                          top: "11px",
                        }}
                      />
                    </>
                  )}

                  <input
                    type="text"
                    className="search-input"
                    placeholder={`Search`}
                    style={styles.inputStyle}
                    autoFocus
                    onChange={handleChange}
                    value={query}
                  />
                  <IoCloseOutline
                    color="#75787E"
                    onClick={() => {
                      setQuery("");
                      setList([]);
                    }}
                    size={24}
                    style={styles.closeIcon}
                  />
                </div>
                {message ? (
                  <p style={styles.productTypeMessage}>
                    Thanks, your suggestion has been sent to our data team
                  </p>
                ) : (
                  <>
                    {showCreatePopup &&
                      (from === "product type" ? (
                        <div style={styles.productTypeContainer}>
                          <p style={styles.productTypeParagraph}>
                            Can't find what you're looking for?
                          </p>
                        </div>
                      ) : (
                        <div style={styles.searchAlertContainer}>
                          <IoAlertCircleOutline
                            color="#F4B037"
                            size={15}
                            style={{ margin: "5px" }}
                          />
                          <div style={styles.searchAlertLeftContainer}>
                            <p style={styles.searchAlertLeftTop}>
                              {name} not found!
                            </p>
                            <p style={styles.searchAlertLeftTop}>
                              Don't worry, you can easily create your own{" "}
                              {name.toLowerCase()}
                            </p>
                          </div>
                        </div>
                      ))}

                    {showCreatePopup &&
                      !productTypePopUp &&
                      (from === "product type" ? (
                        <>
                          <button
                            style={styles.productTypeBtn}
                            onClick={handleClick}
                          >
                            Send us your suggestions
                          </button>
                        </>
                      ) : (
                        <div
                          style={styles.popUpCreateTagContainer}
                          onClick={() => {
                            setShowPopup(true);
                            hide();
                          }}
                        >
                          <BsPlusLg color="#017CF8" size={12} />
                          <p style={styles.popUpCreateTag}>
                            Create {createName}
                          </p>
                        </div>
                      ))}

                    {!showCreatePopup &&
                      (multiple ? (
                        <div style={styles.popUpListContainer}>
                          {selected?.length > 0 && (
                            <p style={styles.popUpSelectedTagHeader}>
                              Selected Tags
                            </p>
                          )}
                          {list
                            .filter(
                              (a) =>
                                multiple && selected?.find((s) => s === a.tagID)
                            )
                            .map((type) => {
                              const isSelected =
                                selected?.findIndex((s) => s === type.tagID) !==
                                -1;
                              return (
                                <div
                                  style={{
                                    ...styles.popUpList,
                                    backgroundColor:
                                      !multiple && isSelected
                                        ? "#017CF8"
                                        : "#E4F1FF",
                                  }}
                                  key={type.tagID}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (multiple) {
                                      setSelected((p) => {
                                        const index = p.findIndex(
                                          (a) => a === type.tagID
                                        );
                                        if (index === -1)
                                          return [...p, type.tagID];
                                        else {
                                          const newP = [...p];
                                          newP.splice(index, 1);
                                          return newP;
                                        }
                                      });
                                    } else setSelected([type.tagID]);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    {multiple && (
                                      <div style={styles.checkboxContainer}>
                                        {isSelected && (
                                          <div style={styles.checkbox}></div>
                                        )}
                                      </div>
                                    )}{" "}
                                    <div>
                                      <p
                                        style={{
                                          ...styles.popUpListUp,
                                          color:
                                            !multiple && isSelected
                                              ? "#FFFFFF"
                                              : "#000000",
                                        }}
                                      >
                                        {type[dataKey]}
                                      </p>
                                      {secondaryKey && (
                                        <p
                                          style={{
                                            ...styles.popUpListDown,
                                            textTransform: "capitalize",
                                            color:
                                              !multiple && isSelected
                                                ? "#FFFFFF"
                                                : "#75787E",
                                          }}
                                        >
                                          {type[secondaryKey]
                                            ?.toLowerCase()
                                            ?.replaceAll("_", " ")}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {list.length > 0 && (
                            <p style={styles.popUpSelectedTagHeader}>
                              Unselected Tags
                            </p>
                          )}
                          {listToShow?.map((type) => {
                            const isSelected =
                              selected?.findIndex((s) => s === type.tagID) !==
                              -1;
                            return (
                              <div
                                style={{
                                  ...styles.popUpList,
                                  backgroundColor:
                                    !multiple && isSelected
                                      ? "#017CF8"
                                      : "#E4F1FF",
                                }}
                                key={type.tagID}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  if (multiple) {
                                    const index = selected.findIndex(
                                      (a) => a === type.tagID
                                    );
                                    setSelected((p) => {
                                      if (index === -1) {
                                        return [...p, type.tagID];
                                      } else {
                                        const newP = [...p];
                                        newP.splice(index, 1);
                                        return newP;
                                      }
                                    });
                                  } else setSelected([type.tagID]);
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {multiple && (
                                    <div
                                      style={{
                                        ...styles.checkboxContainer,
                                        marginRight: "5px",
                                      }}
                                    >
                                      {isSelected && (
                                        <div
                                          className="products-checkbox"
                                          style={styles.checkbox}
                                        ></div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <div>
                                    {type[dataKey].match(queryRegex) ? (
                                      handleQuery(type[dataKey])
                                    ) : (
                                      <p className="product-search-tag-list-api-name">
                                        {type[dataKey]}
                                      </p>
                                    )}

                                    {secondaryKey && (
                                      <p
                                        style={{
                                          ...styles.popUpListDown,
                                          textTransform: "capitalize",
                                          color:
                                            !multiple && isSelected
                                              ? "#FFFFFF"
                                              : "#75787E",
                                        }}
                                      >
                                        {type[secondaryKey]
                                          ?.toLowerCase()
                                          ?.replaceAll("_", " ")}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div style={styles.popUpListContainer}>
                          {list?.map((type) => {
                            const isSelected =
                              selected?.findIndex((s) => s === type.tagID) !==
                              -1;
                            return (
                              <div
                                style={{
                                  ...styles.popUpList,
                                  backgroundColor:
                                    !multiple && isSelected
                                      ? "#017CF8"
                                      : "#E4F1FF",
                                }}
                                key={type.tagID}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  if (multiple) {
                                    setSelected((p) => {
                                      const index = p.findIndex(
                                        (a) => a === type.tagID
                                      );
                                      if (index === -1)
                                        return [...p, type.tagID];
                                      else {
                                        const newP = [...p];
                                        newP.splice(index, 1);
                                        return newP;
                                      }
                                    });
                                  } else setSelected([type.tagID]);
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {multiple && (
                                    <div
                                      style={{
                                        ...styles.checkboxContainer,
                                        marginRight: "5px",
                                      }}
                                    >
                                      {isSelected && (
                                        <div
                                          className="products-checkbox"
                                          style={styles.checkbox}
                                        ></div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <div>
                                    <p
                                      style={{
                                        ...styles.popUpListUp,
                                        color:
                                          !multiple && isSelected
                                            ? "#FFFFFF"
                                            : "#000000",
                                      }}
                                    >
                                      {type[dataKey]}
                                    </p>
                                    {secondaryKey && (
                                      <p
                                        style={{
                                          ...styles.popUpListDown,
                                          textTransform: "capitalize",
                                          color:
                                            !multiple && isSelected
                                              ? "#FFFFFF"
                                              : "#75787E",
                                        }}
                                      >
                                        {type[secondaryKey]
                                          ?.toLowerCase()
                                          ?.replaceAll("_", " ")}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ))}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: "100%",
                        flexGrow: 1,
                      }}
                    >
                      {/* @ts-ignore */}
                      {from === "product type" && list?.length === 0 ? (
                        ""
                      ) : (
                        <Button
                          onClick={async () => {
                            if (onSave) {
                              setSaving(true);
                              await onSave(Array.from(selected));
                              setSaving(false);
                              hide();
                              // navigate(0);
                            }
                            close();
                          }}
                          type="SUBMIT"
                          text={saving ? "Saving..." : "Save"}
                          disabled={selected?.length === 0 || saving}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        }}
        minHeight={from === "product type" ? 271 : 453}
        minWidth={318}
        statelessProps={{
          borderRadius: 16,
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <p
            // className="products-filter-with-background-text"
            style={{
              color: !disabled ? "#FFFFFF" : "#75787E",
            }}
          >
            {label}
          </p>
        </div>
      </Popover>
      {showPopup && createPopup(setSelected, setList, setShowPopup)}
    </>
  );
}
