import BillingButton from "components/BillingButton/BillingButton";
import React from "react";
import "./BillingPlanToggleOpen.scss";
import freeSmallImage from "../../assets/icons/free-small-image.svg";
import basicSmallImage from "../../assets/icons/basic-small-image-new.png";
import premiumSmallImage from "../../assets/icons/premium-small-image-new.png";
import platinumSmallImage from "../../assets/icons/platinum-small-image-new.png";
import skeepxSmallImage from "../../assets/icons/skeepx-small-image-new.png";

import freeSmallCurrentImage from "../../assets/icons/free-small-image-new.png";
import basicSmallCurrentImage from "../../assets/icons/basic-small-image-current.svg";
import premiumSmallCurrentImage from "../../assets/icons/premium-small-image-current.svg";
import platinumSmallCurrentImage from "../../assets/icons/platinum-small-image-current.svg";
import skeepxSmallCurrentImage from "../../assets/icons/skeepx-small-image-current.svg";

import freeSmallDisabledImage from "../../assets/icons/free-small-image-disabled.svg";
import basicSmallDisabledImage from "../../assets/icons/basic-small-image-disabled.svg";
import premiumSmallDisabledImage from "../../assets/icons/premium-small-image-disabled.svg";
import platinumSmallDisabledImage from "../../assets/icons/platinum-small-image-disabled.svg";

export default function BillingPlanToggleOpen({
  info,
  disabledButton,
  disabledPlan,
  getSelectedPlan,
  selected,
  currentPlan,
  billing,
  goToPayment,
}) {
  const free_small = info.name === "Free";
  const free_small_current = info.name === "Free" && currentPlan;
  const free_small_disabled = info.name === "Free" && disabledPlan;
  const basic_small = info.name === "Basic";
  const basic_small_current = info.name === "Basic" && currentPlan;
  const basic_small_disabled = info.name === "Basic" && disabledPlan;
  const premium_small = info.name === "Growth";
  const premium_small_current = info.name === "Premium" && currentPlan;
  const premium_small_disabled = info.name === "Premium" && disabledPlan;
  const platinum_small = info.name === "Platinum";
  const platinum_small_current = info.name === "Platinum" && currentPlan;
  const platinum_small_disabled = info.name === "Platinum" && disabledPlan;
  const skeepx_small_current =
    (info.name === "SkeepX" && currentPlan) ||
    (info.name === "SkeepX" &&
      billing.skeepXPending !== null &&
      billing.skeepXPending);

  return (
    <div
      className={
        disabledPlan
          ? "app-toggle-card-disabled"
          : (currentPlan && !info.show_pricing) ||
            (!info.show_pricing &&
              billing.skeepXPending !== null &&
              billing.skeepXPending)
          ? "app-toggle-card-skeepx"
          : currentPlan
          ? "app-toggle-card-current " + billing?.plan + "_CARD"
          : selected && info.show_pricing
          ? "app-toggle-card-selected"
          : "app-toggle-card"
      }
    >
      <img
        src={
          free_small_disabled
            ? freeSmallDisabledImage
            : free_small_current
            ? freeSmallCurrentImage
            : free_small
            ? freeSmallImage
            : basic_small_disabled
            ? basicSmallDisabledImage
            : basic_small_current
            ? basicSmallCurrentImage
            : basic_small
            ? basicSmallImage
            : premium_small_disabled
            ? premiumSmallDisabledImage
            : premium_small_current
            ? premiumSmallCurrentImage
            : premium_small
            ? premiumSmallImage
            : platinum_small_disabled
            ? platinumSmallDisabledImage
            : platinum_small_current
            ? platinumSmallCurrentImage
            : platinum_small
            ? platinumSmallImage
            : skeepx_small_current
            ? skeepxSmallCurrentImage
            : skeepxSmallImage
        }
       
        className={
          skeepx_small_current?"app-toggle-card-image-skeepx"
          :
          `app-toggle-card-image`}
      />
      {info.name === "SkeepX" ? (
        <p className="app-toggle-card-header-skeepx">{info.name}</p>
      ) : (
        <p
          className={
            disabledPlan
              ? "app-toggle-card-header-disabled"
              : "app-toggle-card-header"
          }
        >
          {info.name}
        </p>
      )}
      {info.name === "SkeepX" ? (
        <p className="app-toggle-card-no-pricing">Contact sales</p>
      ) : (
        <p
          className={
            disabledPlan
              ? "app-toggle-card-pricing-disabled"
              : "app-toggle-card-pricing"
          }
        >
          <b className="app-toggle-card-bold">${info.pricing} </b>/ per month
        </p>
      )}
      <BillingButton
        style={{ cursor: disabledButton ? "" : "pointer" }}
        goToPayment={goToPayment}
        disabledButton={disabledButton}
        disabledPlan={disabledPlan}
        getSelectedPlan={getSelectedPlan}
        selected={selected}
        name={info.name}
        currentPlan={!currentPlan}
        has_pricing={info.show_pricing}
        text={
          !info.show_pricing &&
          billing.skeepXPending !== null &&
          billing.skeepXPending
            ? "In review"
            : currentPlan
            ? "Current Plan"
            : selected && info.show_pricing
            ? "Selected"
            : billing?.hasCustomPlan
            ? "Select"
            : "Contact us"
        }
      />
    </div>
  );
}
