import CustomTagsInput from "components/CustomTags/CustomTagsInput";
import CustomTagsSearchV2 from "components/CustomTags/CustomTagsSearchV2";
import CustomImageInput from "components/Forms/CustomImageInput";
import CustomTextArea from "components/Forms/CustomTextArea";
import {majorScale, minorScale, Pane, Text, Tooltip} from "evergreen-ui";
import {useState} from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import uploadToS3 from "utils/uploadToS3";
import {ReactComponent as InfoSign} from "../../../../../../../assets/icons/info-tag.svg";
import Info from "../../../../../../../assets/icons/info-black.svg";
import GuidancePopup from '../../../../Editor/GuidancePopup'

export default function Rating({save}) {
  const { addEvents } = useEditorEvents();
  const {
    questions,
    experience: design,
    activeQuestionIndex,
    setShowAddTagDialog,
    tagsMap,
    addToTagMap,
    deleteTag,
    changeEditAnswerIndex
  } = useEditor();

  const [showGuidencePopup, setShowGuidencePopup] = useState(
    localStorage.getItem("SKP_SHOW_TAGS_GUIDANCE") ? false : true
  );

  const editQuesIndex = design?.questions?.indexOf(
    (ques) => ques.id === questions[activeQuestionIndex].id
  );
  const [s3Path, setS3Path] = useState();

  const uploadImage = async (file) => {
    const s3PathFetched = await uploadToS3(file);
    setS3Path(s3PathFetched);
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/image`]: s3PathFetched,
    });
  };



  return (
    <div className="answers">
      <div>
        <div className="inputs">
          {/* @ts-ignore */}

          <CustomTextArea
            placeHolder={"Lowest"}
            width={"40%"}
            label={"Min Value"}
            value={editQuesIndex && design.questions[editQuesIndex].minLabel}
            onBlur={(e) =>
              addEvents("design", "update", {
                [`questions/${questions?.[activeQuestionIndex]?.id}/minLabel`]:
                  e.target.value,
              })
            }
            charLimit={40}
          />
          {/* @ts-ignore */}
          <CustomTextArea
            placeHolder={"Highest"}
            width={"40%"}
            label={"Max Value"}
            value={editQuesIndex && design.questions[editQuesIndex].maxLabel}
            onBlur={(e) =>
              addEvents("design", "update", {
                [`questions/${questions?.[activeQuestionIndex]?.id}/maxLabel`]:
                  e.target.value,
              })
            }
            charLimit={40}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <div className="answer-inputs">
        <Text
          display="flex"
          alignItems="center"
          marginTop={majorScale(3)}
          color="black"
          size={minorScale(3)}
        >
          Choose tags for each groups
          <img className="info" style={{width:"14px", height:"13.07px",marginLeft:"9px"}} src={Info} alt="" 
          onClick={() => {
            setShowGuidencePopup(true);
          }} />
        </Text>
        
        <Pane
          display="flex"
          justifyContent="space-between"
          marginTop={majorScale(1)}
        >
          <Text color="black" flex={1} size={minorScale(3)}>
            Rating Groups
          </Text>
          <Text flex={1} color="black" size={minorScale(3)}>
            Tags
          </Text>
        </Pane>
        {questions?.[activeQuestionIndex]?.answers?.map((ans, idx) => (
          <div key={idx}>
            <Pane
              display="flex"
              gap={majorScale(2)}
              style={{ position: "relative" }}
            >
              {/* @ts-ignore */}
              <CustomTextArea
                value={questions[activeQuestionIndex]?.answers?.[idx]?.answer}
                name={"Answer" + (idx + 1)}
                width={"45%"}
                disabled
                // @ts-ignore
                onChange={(e) => {}}
              />
              {/* @ts-ignore */}
              <CustomTagsSearchV2
                api={"emp/tags"}
                placeHolder="Add relevant tags"
                onChange={(tags) => {
                  changeEditAnswerIndex(idx)
                  tags.forEach(t=>addToTagMap(t));
                  addEvents("question", "update", {
                    questionId: questions[activeQuestionIndex].id,
                    quesIdx: activeQuestionIndex,
                    answers: [
                      ...questions[activeQuestionIndex].answers.slice(0, idx),
                      { ...ans, tagIDs: tags.map((t) => t.id) },
                      ...questions[activeQuestionIndex].answers.slice(idx + 1),
                    ],
                  });
                }}
                onCreateNew={() => {
                  setShowAddTagDialog(true);
                }}
              />
            </Pane>
            {ans.tagIDs?.length > 0 && (
              <div className="tag-inputs">
                 {ans.tagIDs.map((tagID) => (
                  // @ts-ignore
                  <CustomTagsInput
                    key={tagID}
                    tag={tagsMap.find((tag) => tag.id === tagID)?.name}
                    deleteTag={() => {

                      deleteTag(tagsMap.find((tag) => tag.id === tagID).id);
                    }}
                  />
                ))}
                <Tooltip
                  statelessProps={{
                    backgroundColor: "#E4F1FF",
                    color: "black",
                  }}
                  content={
                    <p>
                      <small>
                        Verify that the tags selected match your answer, if not,
                        search for relevant tags or create tags using the
                        "search tags" field.
                      </small>
                    </p>
                  }
                >
                  <InfoSign fill="#E46054" stroke="#E46054" />
                </Tooltip>
              </div>
            )}
          </div>
        ))}
{!save && <span className="answerError"><InfoSign fill="#F4B037"/>Please validate that at least one tag is added</span>}

      </div>
      <Pane marginTop={16}>
        <div className="selection-inputs">
          {/* @ts-ignore */}
          <CustomImageInput
            label="Side Image"
            accept={"image/png"}
            onImageChange={uploadImage}
            value={s3Path}
            placeHolder="Choose an image"
          />
        </div>
      </Pane>
      <GuidancePopup show={showGuidencePopup} setShow={setShowGuidencePopup}/>
    </div>
  );
}
