export const TAGS = {
  page: {
    background: "color",
    border: null,
    text: null,
    font: "font",
  },
  selectedElements: {
    background: "color",
    border: "color",
    text: null,
  },
  bottomBar: {
    background: "color",
    border: "color",
    text: "color",
  },
  ctaButton: {
    background: "color",
    border: "color",
    text: "color",
  },
};
