import PlacementWizard from "../PlacementWizard/PlacementWizard";

const ExpPlacementsModalStep2 = ({
  editExpOBJ,
  changeExpPath,
  setIsPathSet,
}) => {
  const setSelectedPlacement = (obj) => {
    changeExpPath("path", obj?.handle);
    setIsPathSet(true);
  };


  return (
    <div>
      <PlacementWizard
        setSelectedPlacement={setSelectedPlacement}
        selectedPlacementPath={editExpOBJ?.path}
      />
    </div>
  );
};

export default ExpPlacementsModalStep2;
