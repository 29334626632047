
export default function QuizIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="22" rx="5" fill="#E4F1FF"/>
            <rect x="5" y="5" width="5" height="5" rx="1" fill="black"/>
            <rect x="12" y="5" width="5" height="5" rx="1" fill="black"/>
            <rect x="12" y="12" width="5" height="5" rx="1" fill="black"/>
            <rect x="5" y="12" width="5" height="5" rx="1" fill="black"/>
            <g clipPath="url(#clip0_4459_47095)">
                <path d="M14.6004 15.1027C14.3231 15.3801 13.8733 15.3801 13.596 15.1027L13.2946 14.8013C13.1837 14.6904 13.1837 14.5105 13.2947 14.3995C13.4056 14.2886 13.5855 14.2886 13.6964 14.3996L14.0982 14.8013L15.3035 13.596C15.4145 13.485 15.5944 13.485 15.7053 13.596C15.8163 13.7069 15.8163 13.8868 15.7053 13.9978L14.6004 15.1027Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_4459_47095">
                    <rect width="3" height="3" fill="white" transform="translate(13 13)"/>
                </clipPath>
            </defs>
        </svg>

    )
}