import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import useAuth from "stores/auth";
import Button from "../../Button/Button";
import CustomInput from "../../Forms/CustomInput";
import "./SignIn.scss";
import {ReactComponent as Eye} from "../../../assets/icons/eye.svg";
import {ReactComponent as EyeClosed} from "../../../assets/icons/eye-closed.svg";

import {checkAutofill} from "utils/utls";

import info from "../../../assets/icons/info-black-error.svg";
import exit from "../../../assets/icons/exit-black-error.svg";

export default function SignIn() {
  const [disable, setDisable] = useState(true);
  const emailRef = useRef();
  const passwordRef = useRef();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { login, error , errorUnauthorized , resendVerificationEmail , errorVerificationWasResent,emailVerificationWasResent} = useAuth();

  const [hasError, setHasError] = useState(false);

  const fireVerificationEmail = () => {
    resendVerificationEmail(formData.email,formData.password)
  }
  useEffect(() => {
    useAuth.setState({ error: false });
  }, [window.location]);


  const handleInputChange = (e) => {
    let name = e.target.name;
    setFormData({ ...formData, [name]: e.target.value });
  };

  const handleRememberMe = (e) => {
    const isChecked = e.target.checked;

    setRememberMe(isChecked);
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        checkAutofill(emailRef?.current) &&
        checkAutofill(passwordRef?.current)
      ) {
        setDisable(false);
      } else if (formData.email !== "" && formData.password !== "") {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, 1000);
  }, [formData.email, formData.password]);

  const handleSubmitSignIn = (e) => {
    setHasError(false);
    e.preventDefault();
    try {
      login(formData.email, formData.password);
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <div className="signup-page">
      <div className="auth-signin auth-form-container">
        <form className="form-container">
          <h2 className="auth-title-new">Hi, Welcome Back!</h2>
          <p className="auth-subtitle" style={{ marginBottom: "80px" }}>
            Sign in to see your updates.
          </p>

          <CustomInput
            ref={emailRef}
            label="Email"
            placeHolder="Enter email"
            name="email"
            size="large"
            value={formData.email}
            type="email"
            onChange={handleInputChange}
          />

          <CustomInput
            ref={passwordRef}
            label="Password"
            placeHolder="Enter Password"
            name="password"
            type={visiblePassword ? "text" : "password"}
            size="large"
            value={formData.password}
            units={
              visiblePassword ? (
                <EyeClosed
                  fill="#AEB2BA"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Eye
                  fill="#AEB2BA"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  style={{ cursor: "pointer" }}
                />
              )
            }
            onChange={handleInputChange}
          />
          <label className="platform-radio remember-me-container">
            <input
              type="checkbox"
              name="platforms"
              value="Popup"
              onChange={handleRememberMe}
              style={{
                cursor: "pointer",
                width: "15px",
                height: "15px",
                borderRadius: "4px",
              }}
              checked={rememberMe}
            />
            <span className="remember-me">Remember me</span>
          </label>

          <div style={{ width: "430px", margin: "25px 0 12px 10px" }}>
            <Link className="auth-link auth-link-new" to="/reset-password">
              Forgot password
            </Link>
          </div>

          {error && !errorUnauthorized && (
            <div className="field error-field">Incorrect email or password</div>
          )}

          {errorUnauthorized && !errorVerificationWasResent && (
              <div className="unauthorized-container">
                <p className="field error-field">Your email address is not verified, a verification email was sent.</p>
                <p onClick={fireVerificationEmail} className="auth-link" style={{textDecoration:"underline",fontSize:12}}>Send me again</p>
              </div>
          )}

          {errorVerificationWasResent && (
              <div className="unauthorized-container">
                <p className="field error-field">There was a problem sending the email.</p>
                <p onClick={fireVerificationEmail} className="auth-link" style={{textDecoration:"underline",fontSize:12}}>Send me again</p>
              </div>
          )}

          {emailVerificationWasResent && (
              <div className="unauthorized-container">
                <p onClick={fireVerificationEmail} className="auth-link" style={{fontSize:12}}>Email was sent successfully.</p>
              </div>
          )}

          <Button
            text="Log in"
            type="SUBMIT"
            style={{
              minWidth: "430px",
              height: "56px",
              padding: "0",
              margin: "5px 0px 0 0",
            }}
            disabled={disable}
            onClick={handleSubmitSignIn}
          />
          {hasError && (
            <div style={{ width: "430px", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 0,
                  padding: "15px 10px",
                  backgroundColor: "rgba(255, 49, 4, 0.38)",
                  borderRadius: "8px",
                }}
              >
                <img
                  src={exit}
                  style={{
                    width: "7px",
                    height: "7px",
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                  onClick={() => setHasError(false)}
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img src={info} style={{ width: "14px", height: "14px" }} />
                  <p
                    style={{
                      maxWidth: "230px",
                      padding: "0 10px",
                      fontSize: "10px",
                    }}
                  >
                    Seems like you tried to login and it didn't work. Please try
                    again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
