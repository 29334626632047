import { Pane } from "evergreen-ui";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-gray.svg";
import { ReactComponent as Arrow } from "../../assets/icons/leftArrow.svg";

const ExpPlacementsModalHeader = ({
  setIsPlacementsModalOpen,
  setEditExpOBJ,
  defaultEditOBJ,
  step,
  setStep,
}) => {
  return (
    <>
      <Pane
        display="flex"
        justifyItems="center"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <h1 className="placement-heading">
          Placement settings{" "}
          <a
            href="https://support.skeep.io/change-implementation-type"
            target={"_blank"}
          >
            <InfoIcon classname="info" />
          </a>
        </h1>
        <p
          onClick={() => {
            setIsPlacementsModalOpen(undefined);
            setEditExpOBJ(defaultEditOBJ);
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 14,
            right: 16,
            fontSize: 18,
            color: "grey",
          }}
        >
          &times;
        </p>
        {(step === 2 || step === 3) && (
          <p
            onClick={() => {
              setStep(step - 1);
            }}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 18,
              left: 48,
              fontSize: 13,
              color: "grey",
            }}
          >
            <Arrow
              style={{
                stroke: "grey",
                transform: "rotate(180deg)",
                marginRight: 4,
              }}
            />
            {"  Back"}
          </p>
        )}{" "}
      </Pane>

      <h3>Choose where and how to show the quiz in your store</h3>
    </>
  );
};

export default ExpPlacementsModalHeader;
