import {useEffect} from "react";

function useOutsideAlerter(ref, closeColorPicker) {
    useEffect(() => {
        function handleClickOutside(event) {
            // if (
            //     event.target.classList.contains("color-widget-box") ||
            //     event.target.classList.contains("color-circle")
            // ) return;

            if (ref.current && !ref.current.contains(event.target)) {
                closeColorPicker();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref])
}

export default useOutsideAlerter;