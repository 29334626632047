import CustomImageInput from "components/Forms/CustomImageInput";
import InfoWithTooltip from "components/InfoWithTooltip/InfoWithTooltip";
import { Button, Pane } from "evergreen-ui";
import { ReactComponent as Info } from "../../../../../../../assets/icons/info-black.svg";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import useEditor from "stores/use-editor";
import { useEditorEvents } from "stores/use-editor-events";
import { ReactComponent as InfoSign } from "../../../../../../../assets/icons/info-tag.svg";
import SelectionAnswer from "./SelectionAnswer";
import Switch from "../../../../../../../components/Switch/Switch";
import { checkImageSelection } from "utils/utls";
import ColorPickerWrapper from "pages/EditorPage/components/ColorPickerWrapper";
import { ObjectId } from "bson";
import {useLocation} from "react-router-dom";

export default function Selection({ imageSelection, save }) {
  const {
    questions,
    activeQuestionIndex,
    changeEditAnswerIndex,
    experience: { design },
    colorPicker,
    setColorPicker,
    setScrollAnswer,
  } = useEditor();
  const location = useLocation();
  const fromOnboardingPage = location.pathname.includes("onboarding");

  const { addEvents } = useEditorEvents();
  const { experience } = useEditor();

  const uploadImage = async ({ s3Url }, type = "image") => {
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/${type}`]: s3Url,
    });
  };

  const items = questions?.[activeQuestionIndex]?.answers?.map((ans, idx) => (
    <SelectionAnswer
      key={ans.id}
      ans={ans}
      idx={idx}
      imageSelection={imageSelection}
    />
  ));

  const isImageSelection =
    checkImageSelection(questions[activeQuestionIndex], design) ||
    imageSelection;
  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };
  return (
    <div>
      <div className="answer">
        <div className="labels" style={{ marginTop: 0 }}>
          <div className="answers-title">
            Answers
            <InfoWithTooltip
              text={
                "You can connect products to these answers to include them in AI recommendation logic"
              }
              icon={<Info />}
              textStyle={{
                minWidth: "300px",
                color: "#75787E",
                textAlign: "center",
              }}
            />
          </div>
        </div>
        {activeQuestionIndex !== undefined && (
          <Pane
            display="flex"
            alignItems="center"
            gap={16}
            marginTop={10}
            className="allow-multi-answers"
          >
            <div>Allow multiple answers</div>
            <Switch
              switchVal1={"enabled"}
              switchVal2={"disabled"}
              defaultValue={
                design?.questions[questions[activeQuestionIndex].id]
                  ?.multipleSelection === "enabled"
                  ? "enabled"
                  : "disabled"
              }
              onChange={async (enabled) => {
                await addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/multipleSelection`]:
                    enabled,
                });
              }}
            />
          </Pane>
        )}
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (
              isNaN(destination?.index) ||
              source.index === destination?.index
            )
              return;
            let answers = JSON.parse(
              JSON.stringify(questions[activeQuestionIndex].answers)
            );
            answers.splice(
              destination.index,
              0,
              answers.splice(source.index, 1)[0]
            );
            addEvents("question", "update", {
              quesIdx: activeQuestionIndex,
              answers,
              questionId: questions[activeQuestionIndex].id,
            });
          }}
        >
          {!save && (
            <span className="answerError">
              <InfoSign fill="#F4B037" />
              To provide an accurate product recommendation, each answer should
              be connected with at least 1 product
            </span>
          )}
          <Droppable droppableId="dnd-list-1" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Pane marginTop={16}>
        <Button
          // disabled={
          //   questions[activeQuestionIndex]?.type === "SELECTION" ||
          //   questions[activeQuestionIndex]?.type === "IMAGE_SELECTION"
          //     ? questions[activeQuestionIndex]?.answers?.length >= 6
          //     : questions[activeQuestionIndex]?.answers?.length >= 12
          // }
          onClick={() => {
            addEvents("question", "update", {
              questionId: questions[activeQuestionIndex].id,
              quesIdx: activeQuestionIndex,
              answers: [
                ...questions[activeQuestionIndex].answers,
                {
                  id: new ObjectId().toString(),
                  answer: "",
                  tagIDs: [],
                },
              ],
            });
            changeEditAnswerIndex(
              questions[activeQuestionIndex]?.answers?.length
            );
            setScrollAnswer("true");
          }}
          color="#017CF8"
          size="large"
          fontFamily="Satoshi"
          appearance="minimal"
        >
          + Add Answer
        </Button>
      </Pane>

      {isImageSelection ? (
        <Pane
          marginY="16px"
          display="flex"
          alignItems="center"
          gap="8px"
          className="colorTag-holder"
        >
          <span
            style={{
              width: "auto",
            }}
            className="field-label"
          >
            Side Color
          </span>
          <Pane maxWidth="65px" marginY="10px" minHeight="52px">
            <ColorPickerWrapper
              key={questions[activeQuestionIndex]?.id}
              type={""}
              prefix="questions"
              designOBJ={design}
              defaultColor="#fff"
              heading={questions[activeQuestionIndex]?.id}
              item={"sideColor"}
              handleColor={handleColor}
            />
          </Pane>
        </Pane>
      ) : (
          !fromOnboardingPage &&
        <Pane marginY={20} marginX={20}>
          <p className="side-image-title">
            Side Image
            <InfoWithTooltip
              text={
                "The image will apply to all devices. You can change your mobile image for this question"
              }
              icon={
                <Info
                  style={{ marginLeft: "6px", width: "11px", height: "11px" }}
                />
              }
              textStyle={{
                minWidth: "300px",
                color: "#75787E",
                textAlign: "center",
              }}
            />
          </p>
          <div className="selection-inputs">
            {/* @ts-ignore */}
            <CustomImageInput
            //   label="Side Image"
            label="Default"
              onChange={uploadImage}
              accept={"image/png , image/jpg, image/jpeg"}
              defaultValue={
                experience?.design?.questions?.[
                  questions[activeQuestionIndex]?.id
                ]?.image??""
              }
              onDelete={() => {
                addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/image`]:
                    undefined,
                });
              }}
              placeHolder="Choose an image"
            />
            <CustomImageInput
              label="For Mobile "
              optional={true}
              onChange={(arg) => uploadImage(arg, "mobileImage")}
              accept={"image/png , image/jpg, image/jpeg"}
              defaultValue={
                experience?.design?.questions?.[
                  questions[activeQuestionIndex]?.id
                ]?.mobileImage??""
              }
              onDelete={() => {
                addEvents("design", "update", {
                  [`questions/${questions[activeQuestionIndex].id}/mobileImage`]:
                    undefined,
                });
              }}
              placeHolder="Choose an image"
            />
          </div>
        </Pane>
      )}
    </div>
  );
}
