import {Pane} from "evergreen-ui";
import useEditor from "../../../stores/use-editor";
import {useEditorEvents} from "../../../stores/use-editor-events";
import {ReactComponent as Delete} from "../../../assets/icons/delete.svg";
import {ReactComponent as QuesIcon} from "../../../assets/icons/quesIcon.svg"
import {useOnboarding} from "../../../stores/use-onboarding";
import {DEFAULT_QUESTION} from "../../../utils/finalVariables";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import {debounceInput} from "../../../utils/utls";

const QuestionTitleBar = ({showDeleteButton,questionIndex}) => {
    const {questions,activeQuestionIndex,changeActiveQuestionIndex} = useEditor();
    const {addEvents} = useEditorEvents();

    const onDeleteQuestion = () => {
        addEvents("question", "delete", {
            quesIdx: activeQuestionIndex,
            questionId: questions[activeQuestionIndex].id,
        });

        if (activeQuestionIndex === questions?.length - 1) {
            changeActiveQuestionIndex(questions?.length - 2)
        }
    }

    return(
        <Pane display="flex" alignItems="center" marginBottom={36} position={"relative"}>
            {showDeleteButton && <Delete fill="#017CF8" onClick={onDeleteQuestion} className="delete-button" />}
            <Pane borderRadius={4} height={20} width={20} background={"#C7E3FF"} display="flex"
                  alignItems="center" justifyContent="center">
                <QuesIcon fill="#017CF8"/>
            </Pane>
            <div style={{position: "relative", width: "85%"}}>
                <input
                    defaultValue={questions[questionIndex]?.question}
                    onChange={debounceInput(e => {
                        addEvents("question", "update", {
                            quesIdx: questionIndex,
                            questionId: questions[questionIndex].id,
                            question: e.target.value,
                        })
                    }, 500)}
                    className="onboarding-question"
                    placeholder="Type here your first question here"
                />
                <p className="onboarding-question-indicator">{questions[questionIndex]?.question.length}/150</p>
            </div>
        </Pane>
    )
}

export default QuestionTitleBar;