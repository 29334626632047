/* eslint-disable jsx-a11y/alt-text */
import {Button, Dialog, DotIcon, majorScale, minorScale, Pane, Text,} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import Slide1 from "../../../../assets/images/guidence-popup/slide-1.svg";
import Slide2 from "../../../../assets/images/guidence-popup/slide-2.svg";
import Slide3 from "../../../../assets/images/guidence-popup/slide-3.svg";
import Slide4 from "../../../../assets/images/guidence-popup/slide-4.svg";
import Slide5 from "../../../../assets/images/guidence-popup/slide-5.svg";

const TOTAL_SLIDES = 5;
const SLIDES = [
  {
    text: "Our AI scans your product pages and generates tags from the product information",
    image: Slide1,
  },
  {
    text: "Tags come in two types: a single tag that describes the product type (What is it?) and multiple tags that describe its features",
    image: Slide2,
  },
  {
    text: "Tags are used to calculate and present accurate product recommendations for your users",
    image: Slide3,
  },
  {
    text: "Your edits are kept in the DB and will help improving the AI’s machine precision",
    image: Slide4,
  },
  {
    text: "Every new tag that you create needs to be connected to at least one product so that our AI will be able to use it",
    image: Slide5,
  },
];

function GuidencePopup({
  show,
  setShow,
  videoContent = false,
  videoUrl,
  name,
}) {
  const [active, setActive] = useState(0);
  useEffect(() => {
    return () => {
      setActive(0);
    };
  }, [show]);
  let names = JSON.parse(localStorage.getItem("SHOW_EXPLORE_PAGE")) || [];
  names.push(name);

  return (
    <Dialog
      width={"700px"}
      isShown={show}
      onCloseComplete={() => {
        setShow(false);
        videoContent
          ? localStorage.setItem("SHOW_EXPLORE_PAGE", JSON.stringify(names))
          : localStorage.setItem("SKP_SHOW_TAGS_GUIDANCE", "yes");
      }}
      header={
        <Pane display="flex" gap={16} flexDirection="column">
          {!videoContent && (
            <div>
              {Array(TOTAL_SLIDES)
                .fill(0)
                .flatMap((_, i) => (
                  <DotIcon
                    cursor="pointer"
                    onClick={() => {
                      setActive(i);
                    }}
                    size={majorScale(3)}
                    marginLeft={-minorScale(1)}
                    color={i === active ? "#017CF8" : "#F3F5F7"}
                    key={i}
                  />
                ))}
            </div>
          )}
          {!videoContent && (
            <Text fontSize={20} fontWeight={500}>
              Welcome to your scanned products section!
            </Text>
          )}
          <Button
            onClick={() => {
              setShow(false);
            }}
            appearance="minimal"
            position="absolute"
            top={majorScale(2)}
            fontSize={majorScale(3)}
            right={majorScale(2)}
          >
            &times;
          </Button>
        </Pane>
      }
      hasFooter={false}
    >
      <Pane
        display="flex"
        height={450}
        flexDirection="column"
        alignItems="center"
      >
        {videoContent ? (
          <>
            <iframe
              style={{ paddingBottom: "20px", height: "100%" }}
              width="100%"
              height="100%"
              src={videoUrl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </>
        ) : (
          <>
            <img
              style={{
                flex: 1,
                objectFit: "contain",
                maxWidth: "100%",
              }}
              src={SLIDES[active].image}
            />
            <Text
              fontSize={18}
              fontWeight={400}
              marginTop={majorScale(2)}
              textAlign="center"
              color="#040404"
            >
              {SLIDES[active].text}
            </Text>
          </>
        )}
      </Pane>
      {!videoContent && (
        <Pane marginY={25} display="flex" gap={8} justifyContent="flex-end">
          {active > 0 && (
            <Button
              size="large"
              appearance="minimal"
              onClick={() => {
                setActive((prev) => prev - 1);
              }}
            >
              {"Back"}
            </Button>
          )}
          <Button
            className="popup-primary-button"
            size="large"
            fontSize={majorScale(2)}
            fontWeight="lighter"
            background="#017CF8"
            appearance="primary"
            autoFocus
            paddingX={40}
            onClick={() => {
              active === SLIDES.length - 1
                ? setShow(false)
                : setActive((prev) => prev + 1);
            }}
          >
            {active === SLIDES.length - 1 ? "Got it!" : "Next"}
          </Button>
        </Pane>
      )}
    </Dialog>
  );
}

export default GuidencePopup;
