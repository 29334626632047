import {Pane, Popover, Text, TextInput} from "evergreen-ui";
import {ImplementationTabs, PathMenu} from "../../pages/ExperiencesPage/components/ExpPlacementsModal";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-gray.svg";
import Info from "../Info/Info";
import Checkbox from "../Checkbox/Checkbox";
const ExpPlacementsModalStep1 = ({isShopifyUser,embedded,isSkeepXAndPopup,editExpOBJ,changeExp,experience,setIsPlacementsModalOpen,setIsManuelSetUp,isManuelSetUp}) => {
    const delayValue = (editExpOBJ?.delay ?? experience?.locations?.[0]?.settings?.delay) > 100 ? 100 : editExpOBJ?.delay ?? experience?.locations?.[0]?.settings?.delay;

    const onClickManualPopup = () => {
        changeExp("implementationType", "MANUAL_POPUP");
        setIsManuelSetUp(true);
    };

    const onClickDelay = () => {
        changeExp("implementationType", "POPUP");
        setIsManuelSetUp(false);
    }

    return(
        <>
            <p className="shopify-sub-heading" style={{ marginBottom: 10 }}>
                How would you like to embed your experience?
            </p>
            <ImplementationTabs
                editExpOBJ={editExpOBJ}
                changeExp={changeExp}
                experience={experience}
                setIsPlacementsModalOpen={setIsPlacementsModalOpen}
            />
            <div className="content-container">
                {!isShopifyUser && (
                    <div className="path-container">
                        <div className="url-container">
                            <p>
                                URL &nbsp;
                                <Popover
                                    bringFocusInside
                                    statelessProps={{zIndex: 999, background: "transparent", boxShadow: "none", marginTop: "-30px", marginLeft: "180px",}}
                                    content={
                                        <Text style={{fontSize: "10px", padding: "6px", background: "#E4F1FF", borderRadius: "5px",}}>
                                            Insert the word you would like the experience to
                                            appear in from the URL
                                        </Text>
                                    }
                                >
                                    <InfoIcon />
                                </Popover>
                            </p>
                        </div>
                        <p className="home-para">For ‘Home page’ type ‘Homepage‘</p>
                        <PathMenu
                            experience={experience}
                            editExpOBJ={editExpOBJ}
                            setEditExp={changeExp}
                        />
                    </div>
                )}

                {!embedded && (
                    <div style={{position: "relative", alignItems: "center", justifyContent: "space-between", width: "170px", marginTop: "26px", marginBottom: 3,}}>
                        <div
                            style={{ alignSelf: "left", width: "100%", cursor: "pointer" }}
                            onClick={() => window.open("https://support.skeep.io/change-implementation-type", "_blank")}
                        >
                            <Info width={12} height={12} />
                        </div>
                        <Pane display="flex" alignItems="center" onClick={onClickDelay}>
                            {isSkeepXAndPopup && <Checkbox checked={!isManuelSetUp} />}
                            <span className="delay-text" style={{ display: "inline-block" , marginLeft: isSkeepXAndPopup ? "10px" : ""}}>Appear after &nbsp;&nbsp;{" "}</span>
                            <TextInput
                                className="experience-row"
                                marginY={8}
                                marginX={10}
                                maxWidth="50px"
                                width="50px"
                                placeholder="3"
                                value={delayValue ?? 3}
                                onChange={(e) => {
                                    changeExp("delay", e.target.value);
                                }}
                            />
                            <p className="delay-text">&nbsp;&nbsp;&nbsp;seconds</p>
                        </Pane>
                        {isSkeepXAndPopup && (
                            <Pane
                                display="flex" alignItems="center"
                                onClick={onClickManualPopup}
                            >
                                <Checkbox checked={isManuelSetUp}/>
                                <p className="delay-text" style={{ display: "inline-block" , marginLeft: isSkeepXAndPopup ? "10px" : ""}}>I want to embed the popup manually</p>
                            </Pane>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default ExpPlacementsModalStep1;