import {Step, StepIcon, StepLabel, Stepper} from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import useEditor from "../../../stores/use-editor";
import { styled } from '@mui/material/styles';
import {useOnboarding} from "../../../stores/use-onboarding";
import {useEffect, useState} from "react";

const OnboardingStepper = () => {
    const {questions,activeQuestionIndex,changeActiveQuestionIndex} = useEditor();
    //const steps = [{},{},{},{},{}].map((obj,index) => `Q${index + 1}`)
    //const [ steps, setSteps ] = useState(questions.map((q,i) => `Q${i + 1}`))

    /*useEffect(() => {
        questions.map((q,i) => `Q${i + 1}`)
    }, [questions])*/

    return(
        <Stepper alternativeLabel activeStep={activeQuestionIndex} connector={<ColorlibConnector />}>
            {questions.map((label,index) => (
                <Step key={`Q${index + 1}`}>
                    <StepIcon icon={
                        <div
                            className={`onboarding-question-step ${index <= activeQuestionIndex ? "onboarding-question-step-active" : ""}`}
                            onClick={() => changeActiveQuestionIndex(index)}
                        >
                            Q{index + 1}
                        </div>
                    }/>
                </Step>
            ))}
        </Stepper>
    )
}

export default OnboardingStepper;

const lineColor = 'linear-gradient( 90deg,rgb(1,124,248) 10%,rgb(199,227,255) 80%)';
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#017CF8"
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:"#017CF8"
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));