import {produce} from "immer";

export const updateDesign = (experience, onboardingDesign) => {
    return produce(experience?.design, draftDesign => {
        draftDesign.answer.border = onboardingDesign.buttons;
        draftDesign.answer.background = onboardingDesign.background;
        draftDesign.answer.text = onboardingDesign.buttons;

        draftDesign.selectedAnswer.background = onboardingDesign.buttons;
        draftDesign.selectedAnswer.border = onboardingDesign.buttons;
        draftDesign.selectedAnswer.text = onboardingDesign.background;

        draftDesign.question.background = onboardingDesign.background;
        draftDesign.question.text = onboardingDesign.text;

        draftDesign.general.background = onboardingDesign.background;
        draftDesign.general.text = onboardingDesign.text;

        draftDesign.navigationButton.background = onboardingDesign.buttons;
        draftDesign.navigationButton.text = onboardingDesign.background;

        draftDesign.introScreen = "disabled";
        draftDesign.email.enabled = "disabled";
    });
}