export const experiencesImplementations = [{
    label:"Embedded Element",
    value:"EMBEDDED_PAGE"
},{
    label:"Popup",
    value:"POPUP"
}
// ,{
//     label:"Banner",
//     value:"BANNER"
// },{
//     label:"Sticky banner",
//     value:"STICKY_BANNER"
// },{
//     label:"Floating button",
//     value:"FLOATING_BUTTON"
// }
];

export const experiencesPositions = [{
    label:"Top",
    value:"TOP"
},{
    label:"Top-Left",
    value:"TOP-LEFT"
},{
    label:"Top-Right",
    value:"TOP-RIGHT"
},{
    label:"Center",
    value:"CENTER"
},{
    label:"Center-Left",
    value:"CENTER-LEFT"
},{
    label:"Center-Left",
    value:"CENTER-LEFT"
},{
    label:"Center-Right",
    value:"CENTER-RIGHT"
},{
    label:"Bottom",
    value:"BOTTOM"
},{
    label:"Bottom-Left",
    value:"BOTTOM-LEFT"
},{
    label:"Bottom-Right",
    value:"BOTTOM-RIGHT"
}];