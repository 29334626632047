import {Client} from "Client";
import moment from "moment/moment";
import create from "zustand";
import {devtools} from "zustand/middleware";

const useCache = create()(
  devtools(
    (set, get) => ({
      cache: {},
      getValue: async (key) => {
        const cache = get().cache;
        if (cache[key]) {
          const params = new URLSearchParams(cache[key]);
          const creationDate = moment.utc(params["X-Amz-Date"]);
          const expiresInSecs = Number(params["X-Amz-Expires"]);

          const expiryDate = creationDate.add(expiresInSecs, "seconds");
          const isExpired = expiryDate < moment.utc();

          if (isExpired) {
            const { data: publicURL } = await Client.post("emp/view/file", {
              s3Path: key,
            });
            set({
              cache: {
                ...cache,
                [key]: publicURL,
              },
            });
            return publicURL;
          } else return cache[key];
        } else {
          const { data: publicURL } = await Client.post("emp/view/file", {
            s3Path: key,
          });
          set({
            cache: {
              ...cache,
              [key]: publicURL,
            },
          });
          return publicURL;
        }
      },
    }),
    {
      name: "image-cache",
    }
  )
);

export default useCache;
