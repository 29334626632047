import {useEffect, useState} from "react";
import {useOnboarding} from "../stores/use-onboarding";

const useOnboardingAnimation = () => {
    const {productsForAnimation} = useOnboarding();
    const [animationFired, setAnimationFired] = useState(false);
    const [countProductToShow, setCountProductToShow] = useState(0);
    const [isProductsShown, setIsProductsShown] = useState(false);

    useEffect(() => {
        const fireProducts = async () => {
            if (animationFired && countProductToShow === Math.min(3, productsForAnimation?.length)) {
                setIsProductsShown(true);
                return;
            }

            setAnimationFired(true);

            if (countProductToShow === 0) {
                setCountProductToShow(1);
            }

            if (countProductToShow === 1) {
                setCountProductToShow(2);
            }

            if (countProductToShow === 2) {
                setCountProductToShow(3);
            }

        };

        if (productsForAnimation.length > 1) fireProducts();
    }, [productsForAnimation?.length]);

    return {isProductsShown,setIsProductsShown}
}

export default useOnboardingAnimation;
