import React from 'react'

function InfoSVG({tooltipDOM}) {
  return (
    <svg className="infoSVG" data-tip data-for={tooltipDOM} width="14" height="14" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4ZM4 3.75C4.41421 3.75 4.75 4.08579 4.75 4.5V6C4.75 6.41421 4.41421 6.75 4 6.75C3.58579 6.75 3.25 6.41421 3.25 6V4.5C3.25 4.08579 3.58579 3.75 4 3.75ZM4.75 2C4.75 1.58579 4.41421 1.25 4 1.25C3.58579 1.25 3.25 1.58579 3.25 2V2.001C3.25 2.41521 3.58579 2.751 4 2.751C4.41421 2.751 4.75 2.41521 4.75 2.001V2Z" fill="#AEB2BA"/>
    </svg>
  )
}

export default InfoSVG