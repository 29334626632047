import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Canvas, Node } from "reaflow";
import "./LogicView.scss";
import { ReactComponent as ExpandIcon } from "../../../../assets/icons/expand.svg";
import { ReactComponent as ExpandOutIcon } from "../../../../assets/icons/expand-out.svg";
import { ReactComponent as ZoomIn } from "../../../../assets/icons/add-icon-logic.svg";
import { ReactComponent as ZoomOut } from "../../../../assets/icons/zoom-out-icon.svg";
import useEditor from "../../../../stores/use-editor";
import { ReactComponent as QuesIcon } from "../../../../assets/icons/quesIcon.svg";
import { Client } from "../../../../Client";
import LogicTreeView from "./LogicTreeView";

const LogicView = forwardRef(function ({ style }, fRef) {
  const ref = useRef();
  const experience = useEditor((state) => state.experience);
  const questions = useEditor((state) => state.questions);
  const subTab = useEditor((state) => state.subTab);
  const [nodesAndEdges, setNodesAndEdges] = useState({
    nodes: [],
    edges: [],
  });
  const [zoom, setZoom] = useState(0.8);
  const [expand, setExpand] = useState(false);
  const {
    experience: { design },
  } = useEditor();
  useImperativeHandle(fRef,()=>({
    initView
  }))
  const isMultipleSelection = (id) => {
    if (design?.questions?.[id]?.multipleSelection === "enabled") {
      return true;
    } else {
      return false;
    }
  };

  const getQuestion = useCallback(async () => {
    const payload = {
      answers: LogicTreeView.getAllSelectedAnswers(),
      products: [],
    };

    let questionURL = `experiences/${experience?.id}/question`;

    const { data } = await Client.post(questionURL, payload);
    return data;
  }, [experience?.id]);

  const initView = () => {
    LogicTreeView.resetTree();
    getQuestion().then((question) => {
      LogicTreeView.addQuestion(question, isMultipleSelection(question.id));
      setNodesAndEdges(LogicTreeView.getAllNodesAndEdges());
    });
  };

  useEffect(() => {
    initView();
  }, [subTab, questions, design.firstQuestionID]);

  useEffect(() => {
    setTimeout(() => {
      ref.current.centerCanvas();
    }, 500);
  }, [expand]);

  useEffect(() => {
    const origScroll = ref.current.scrollXY;
    ref.current.containerRef.current.scrollTo(
      origScroll[0] + (LogicTreeView.getNodes().length - 1) * 60,
      origScroll[1] + (LogicTreeView.getNodes().length - 1) * 100
    );
  }, [nodesAndEdges.nodes]);

  return (
    <div
      className="logic-wrapper"
      style={{
        ...style,
        top:60,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        width: expand ? "200%" : "100%",
        height: expand ? "800px" : "445px",
        maxHeight: "80vh",
        margin: expand&&"auto", 
        padding: 16,
        zIndex: expand ? 21 : 20,
        backgroundColor: "#fff",
        borderRadius: expand ? "20px" : "0px",
        transition: "1s",
        bottom: expand ? "100px" : "32px",
        right: expand ? "80px" : "",
      }}
    >
      <div
        className="upper-container"
        style={{ width: expand ? "97%" : "95%" }}
      >
        <button
          className="reset-button"
          disabled={LogicTreeView.getNodes().length === 1}
          onClick={initView}
        >
          Restart
        </button>
        {!expand && (
          <div className="note-container">
            <p>Click any answer to simulate the logic</p>
          </div>
        )}
        <div className="expand-icon-contanier">
          {expand ? (
            <ExpandOutIcon
              className="expand-icon"
              onClick={() => setExpand(false)}
            />
          ) : (
            <ExpandIcon
              className="expand-icon"
              onClick={() => setExpand(true)}
            />
          )}
        </div>
      </div>
      <Canvas
        ref={ref}
        height="100%"
        width="100%"
        nodes={nodesAndEdges.nodes}
        zoom={zoom}
        onZoomChange={setZoom}
        direction={"DOWN"}
        edges={nodesAndEdges.edges}
        node={
          <Node>
            {(event) => (
              <foreignObject
                className="foreign-container"
                height={event.height}
                width={event.width}
                x={0}
                y={0}
              >
                {event.node.id !== "products" ? (
                  <div
                    className={`question-wrapper ${
                      LogicTreeView.getAllSelectedAnswers().includes(
                        event.node.id
                      )
                        ? "active"
                        : ""
                    } ${event.node.data.answer ? "answer" : ""} ${
                      event.node.data.question ? "question" : ""
                    }`}
                    onClick={() => {
                      if (event.node.data.answer) {
                        LogicTreeView.selectAnswer(
                          event.node.data.questionObj,
                          event.node.data.id
                        );
                        getQuestion().then((question) => {
                          if (question.id) {
                            LogicTreeView.addQuestion(
                              question,
                              isMultipleSelection(question.id),
                              LogicTreeView.findNode(
                                event.node.data.questionObj.id
                              )
                            );
                          } else if (question.matchingProducts) {
                            LogicTreeView.addProducts(
                              question.matchingProducts,
                              LogicTreeView.findNode(
                                event.node.data.questionObj.id
                              )
                            );
                          }
                          setNodesAndEdges(LogicTreeView.getAllNodesAndEdges());
                        });
                      }
                    }}
                  >
                    <>
                      {event.node.data.question && (
                        <div className="quesIcon">
                          <QuesIcon fill="#017CF8" />
                        </div>
                      )}

                      <span>
                        {event.node.data.question ?? event.node.data.answer}
                      </span>
                    </>
                  </div>
                ) : (
                  <ProductNode event={event} />
                )}
              </foreignObject>
            )}
          </Node>
        }
      />
      <div className="zoom-btn-contanier">
        <div
          className="zoom-out-container"
          onClick={() => ref.current.zoomOut()}
        >
          <ZoomOut className="zoom-out-icon" />
        </div>
        <div className="zoom-in-container" onClick={() => ref.current.zoomIn()}>
          <ZoomIn className="zoom-in-icon" />
        </div>
      </div>
    </div>
  );
});

export default LogicView;

function ProductNode({ event }) {
  const hasAdditional = event.node.data?.length > 1;
  return (
    <div className="matching-products-container">
      <div className="title">Matching products</div>
      <div className="first-container">
        <p className="title">Main recommendation</p>
        <div className="recommendation-content-container">
          <div className="recommendation-content" style={{borderBottom:!hasAdditional?"none":"1px solid #dbe1e7"}}>
            <img
              src={event.node.data?.[0]?.images?.[0]}
              className="recommendation-image"
              alt=""
            />
            <p>{event.node.data?.[0]?.name}</p>
          </div>
        </div>
      </div>
      {hasAdditional && (
        <div className="additional-container">
          <p className="title">Additional </p>
          <div className="additional-sub-container">
            {event.node.data?.[1] && (
              <div className="first-container">
                <img
                  src={event.node.data?.[1]?.images?.[0]}
                  className="additional-image"
                  alt=""
                />
                <p className="additional-title">{event.node.data?.[1]?.name}</p>
              </div>
            )}{" "}
            <div className="divider"></div>
            {event.node.data?.[2] && (
              <div className="second-container">
                <img
                  src={event.node.data?.[2]?.images?.[0]}
                  className="additional-image"
                  alt=""
                />
                <p className="additional-title">{event.node.data?.[2]?.name}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
