import {useElementSize, useMediaQuery} from "@mantine/hooks";
import React, {useLayoutEffect, useRef, useState} from "react";
import {useDebounce} from "react-use";
import {useDesign} from "stores/design";
import useEditor from "stores/use-editor";
import useCache from "stores/use-cache";
import {s3ToPreview} from "utils/utls";
import {ReactComponent as Desktop} from "../../../../assets/icons/desktop.svg";
import {ReactComponent as Mobile} from "../../../../assets/icons/mobile.svg";
import {ReactComponent as MobileBrowser} from "../../../../assets/images/drawing-mobile.svg";
import {ReactComponent as Browser} from "../../../../assets/images/drawing.svg";
import {useLocation} from "react-router-dom";
import Button from "components/Button/Button";
import useCustomTags from "../../../../stores/use-custom-tags";
import {useSwiper} from "stores/use-swiper";
import useAuth from "../../../../stores/auth";
import UpgradePlanModal from "../../../../components/UpgradePlanModal/UpgradePlanModal";
import previewURL from "utils/previewURL";
import ToggleLiveExpButton from "pages/ExperiencesPage/components/ToggleLiveExpButton";
import {
  basic, free, platinum, premium, skeepx,
} from "../../../../utils/billingPricing";
import useExperiencePage from "../../../../hooks/useExperiencePage";
import UpgradeBillingModal from "../../../ExperiencesPage/components/UpgradeBillingModal";
import {useOnboarding} from "../../../../stores/use-onboarding";
import LogicView from "../LogicView/LogicView";

const Preview = ({
                   designView = false,
                   loading,
                   fromOnboarding,
                   events,
                   handleSaveChanges,
                   allRecommenderEventsSaved,
                   previewOf,
                   handlePublishClick,
                   disable,
                   editExpOBJ,
                   isEditing,
                   brand,
                 }) => {
  const [isUpgradePlanModalVisible, setIsUpgradePlanModalVisible] = useState(false);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const location = useLocation();
  const isSwiper = location.pathname.includes("swiper");
  const isOnboarding = location.pathname.includes("onboarding");
  const {ref: containerRef} = useElementSize();
  const {design, product: designProduct, multiProducts} = useDesign();
  const {activeDesignTab} = useEditor();
  const {tagTypesToUpdate} = useSwiper();
  const {editedQuestions, editedSideImages} = useOnboarding();

  const buttonsContainerWidthRef = useRef();
  const [activeTab, setActiveTab] = useState("desktop");
  const getValue = useCache((state) => state.getValue);
  const {tags: tagsEvents} = useCustomTags();
  const {accountOBJ} = useAuth();
  const lowPaidPlans = accountOBJ?.plan === "BASIC" || accountOBJ?.plan === "FREE";
  const {allExperiences} = useExperiencePage();
  const numberOfLiveExp = allExperiences.filter((e) => e.status === "LIVE")?.length;
  const listOfPlans = [free, basic, premium, platinum, skeepx];
  const planOBJ = listOfPlans?.find((planOBJ) => planOBJ?.name_uppercase === accountOBJ?.plan?.replaceAll(" ", "")?.toUpperCase());
  const limitOfQuizzesReached = planOBJ?.maxPublishedQuizzes && numberOfLiveExp >= planOBJ?.maxPublishedQuizzes;
  const smallDesktop = useMediaQuery("(max-width: 1500px)");

  const {
    activeQuestionIndex,
    selectedTagsForRemoval,
    questions,
    experience,
    emailDesign,
    product,
    tags,
    productDesign,
    recommendationDesign,
    previewTag,
    editAnswerIndex,
    scrollAnswer,
    subTab,
  } = useEditor();
  const isLogicView = subTab === "logic";
  const ref = useRef();
  let experienceHolder = JSON.parse(JSON.stringify(experience));
  if (experienceHolder.design?.questions) {
    experienceHolder.design.questions = {
      ...experienceHolder?.design?.questions, ...editedSideImages,
    };
  }
  const lvRef = useRef()
  const questionToSend = experience?.type === "QUIZ" ? questions[activeQuestionIndex] ?? questions[0] : questions[activeQuestionIndex] ?? experience?.design?.tags;

  useDebounce(() => {
    if (isLogicView) return;
    if ((questions?.[0] || isSwiper) && experience && !designView) {
      Promise.all([s3ToPreview(getValue, experienceHolder), s3ToPreview(getValue, questionToSend), s3ToPreview(getValue, previewTag),]).then(([exp, ques, prevTag]) => {
        // @ts-ignore
        window.skeep?.init({
          element: ref.current, preview: {
            implementation: "popup",
            experience: exp,
            question: {
              ...ques, tags: ques?.tags?.filter((t) => !experience?.design?.tagsToIgnore?.includes(t.id)),
            },
            scrollAnswer,
            activeDesignTab,
            editAnswerIndex: editAnswerIndex,
            previewTag: prevTag,
            subTab: subTab,
            emailDesign: activeDesignTab === "email",
            isResultsPageVisible: activeDesignTab === "recommendation",
            activeTab,
            product,
            tags: tags?.map((t) => t.name),
            selectedTagsForRemoval,
            multiProducts,
          },
        });
      });
    } else if (designView && designProduct && design) {
      Promise.all([
        s3ToPreview(getValue, design),
        s3ToPreview(getValue, previewTag),
      ]).then(([des, prevTag]) => {
        console.log(prevTag, "prevTag");
        // @ts-ignore
        window.skeep?.init({
          element: ref.current,
          preview: {
            activeTab,
            previewTag: prevTag,
            implementation: "popup",
            experience: {
              id: "design-id",
              design: {
                ...des,
              },
            },
            emailDesign: design?.email === "enabled",
            isResultsPageVisible: activeDesignTab === "recommendation",
            product: designProduct,
            multiProducts,
          },
        });
      });
    }
      },

      200,
    [
      activeDesignTab,
      designProduct,
      design,
      design?.recommendation,
      design?.page,
      designView,
      scrollAnswer,
      tags,
      questionToSend,
      activeQuestionIndex,
      emailDesign,
      experience,
      // experienceHolder?.design?.questions,
      getValue,
      product,
      productDesign,
      editAnswerIndex,
      questions,
      activeTab,
      recommendationDesign,
      selectedTagsForRemoval,
      previewTag,
      subTab
    ]
  );
  useLayoutEffect(() => {
    if (isLogicView) return;
    const element = ref.current;
    element.style.padding = `1px`;
    const containerElement = containerRef.current;
    // containerElement.style.padding = "20px";
    const listener = () => {
      if (activeTab === "desktop") buttonsContainerWidthRef.current = containerElement?.children
        .item(0)
        .getBoundingClientRect().width;
      const childRect = activeTab === "mobile" ? containerElement.children.item(0).getBoundingClientRect() : containerElement.children.item(0).getBoundingClientRect();
      if ((!designView && !emailDesign) || experience?.type !== "QUIZ") {
        const SCALE_DESKTOP = isOnboarding ? 0.9 : 0.65;
        const SCALE_MOBILE = 0.65;
        // @ts-ignore
        element.style.borderRadius = "unset";
        element.style.paddingTop = `unset`;
        element.style.width = (activeTab !== "mobile" ? childRect.width / SCALE_DESKTOP : childRect.width / SCALE_MOBILE) + "px";
        // @ts-ignore
        element.style.height = (activeTab !== "mobile" ? (childRect.height / SCALE_DESKTOP) * 1.3 : childRect.height / SCALE_MOBILE - 100) + "px";
        // @ts-ignore
        element.style.transform = activeTab !== "mobile" ? "scale(" + SCALE_DESKTOP + ")" : "scale(" + SCALE_MOBILE + ")";
      } else {
        const SCALE_DESKTOP = 0.65;
        const SCALE_MOBILE = 0.65;
        element.style.borderRadius = "4%";
        // element.style.paddingTop = `${
        //   (activeTab === "mobile" ? 0 : 0.165 / 0.4) * childRect.height
        // }px`;
        // @ts-ignore
        element.style.width = (activeTab !== "mobile" ? childRect.width / SCALE_DESKTOP : childRect.width / SCALE_MOBILE) + "px";
        // @ts-ignore
        element.style.height = (activeTab !== "mobile" ? childRect.height / SCALE_DESKTOP : childRect.height / SCALE_MOBILE - 160) + "px";
        // @ts-ignore
        element.style.transform = activeTab !== "mobile" ? "scale(" + SCALE_DESKTOP + ")" : "scale(" + SCALE_MOBILE + ")";
      }
    };
    if (element && containerElement?.parentElement) {
      const observer = new ResizeObserver(listener);
      observer.observe(containerElement.parentElement);

      return () => {
        observer.disconnect();
      };
    }
  }, [activeTab, containerRef, experience, designView, emailDesign, isLogicView]);

  const positionPreview = () => {
    const recommenderPage = location?.pathname === "/product-recommendation";
    if (activeTab === "mobile" && !recommenderPage) return {top: "", marginTop: "auto"};
    if (activeTab === "mobile" && recommenderPage) return {top: "", marginTop: 0};
    if (!lowPaidPlans && smallDesktop) return {top: "7%", marginTop: "auto"};
    if (!lowPaidPlans && !smallDesktop) return {top: "5%", marginTop: "auto"};
    return {top: 0, marginTop: "auto"};
  };

  const hasEvents = tagTypesToUpdate.length > 0 || events?.length >= 1 || Object.keys(tagsEvents).length >= 1;

  return (<>
      {!fromOnboarding && (<div
          className="editor-page-buttons"
          style={{
            width: buttonsContainerWidthRef.current, margin: "auto", marginTop: "15px", minWidth: "500px",
          }}
        >
          <button
            className="preview-btn"
            style={{cursor: "pointer"}}
            onClick={() => window.open(previewURL(experience, brand), "_blank")}
          >
            Preview
          </button>

          <ToggleLiveExpButton
            experience={experience}
            isEditing={isEditing}
            editExpOBJ={editExpOBJ}
            hoveredRow={true}
            handleClick={handlePublishClick}
            origin="editor"
            moreQuizClick={() => setIsUpgradePlanModalOpen(true)}
            disabled={limitOfQuizzesReached}
          />
        </div>)}
      {!fromOnboarding && (<div
          className="screen-size"
          style={{
            width: buttonsContainerWidthRef.current, margin: "auto", marginTop: "15px", minWidth: "500px",
          }}
        >
          {
subTab!=="logic"&&
          <div className="tab-container">
            <span
              className="tab"
              onClick={() => {
                setActiveTab("desktop");
              }}
              style={activeTab === "desktop" ? {
                background: "#017CF8", width: "50px", borderRadius: "6px 0px 0px 6px", padding: "1px",
              } : {
                background: "#E4F1FF", width: "50px", borderRadius: "6px 0px 0px 6px", padding: "1px",
              }}
            >
              <Desktop fill={activeTab === "desktop" ? "white" : "black"}/>
            </span>
            <span
              className="tab"
              onClick={() => setActiveTab("mobile")}
              style={activeTab === "mobile" ? {
                background: "#017CF8", width: "50px", borderRadius: "0px 6px 6px 0px", padding: "1px",
              } : {
                background: "#E4F1FF", width: "50px", borderRadius: "0px 6px 6px 0px", padding: "1px",
              }}
            >
              <Mobile fill={activeTab === "mobile" ? "white" : "black"}/>
            </span>
            <span style={{height: "100%"}}></span>
          </div>
          }
          <Button
            type="BUTTON"
            text={loading ? "Saving..." : "Save changes"}
            disabled={loading || !hasEvents}
            style={{position: "absolute", right: 0, height: "30px"}}
            onClick={async () => {
              await handleSaveChanges();
              lvRef.current.initView();
            }}
          />
        </div>)}
      {activeTab === "desktop" ? (<Browser
          ref={containerRef}
          style={{maxWidth: "100%", position: "relative", maxHeight: "100%"}}
        />) : (<MobileBrowser
          ref={containerRef}
          style={{position: "relative", maxWidth: "90%", maxHeight: "100%"}}
        />)}
      {isLogicView ? (<LogicView
          ref={lvRef}
          style={{
            ...positionPreview(),
            overflow: "hidden",
            position: "absolute",
            bottom: activeTab === "mobile" ? containerRef.current?.parentElement.getBoundingClientRect().bottom - containerRef.current?.children.item(0).getBoundingClientRect().bottom : fromOnboarding ? -46 : 32,
            margin: "auto",
            borderRadius: activeTab === "mobile" ? "0 0 42px 42px" : 0,
            transformOrigin: activeTab === "mobile" ? "bottom" : "center",
          }}
        />) : (<div
          style={{
            ...positionPreview(),
            position: "absolute",
            bottom: activeTab === "mobile" ? containerRef.current?.parentElement.getBoundingClientRect().bottom - containerRef.current?.children.item(0).getBoundingClientRect().bottom : fromOnboarding ? -46 : 32,
            margin: "auto",
            borderRadius: activeTab === "mobile" ? "0 0 42px 42px" : 0,
            transformOrigin: activeTab === "mobile" ? "bottom" : "center",
          }}
          className={activeTab === "mobile" ? "skeep-emp-preview" : ""}
          draggable={false}
          ref={ref}
        />)}
      {isUpgradePlanModalVisible && (<UpgradePlanModal
          closeModal={() => setIsUpgradePlanModalVisible(false)}
        />)}

      {isUpgradePlanModalOpen && (<UpgradeBillingModal
          isUpgradePlanModalOpen={isUpgradePlanModalOpen}
          closeModal={() => setIsUpgradePlanModalOpen(false)}
        />)}
    </>);
};

export default Preview;
