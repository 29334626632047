import useTagImage from "../../../../../hooks/useTagImage";
import {ReactComponent as Eye} from "assets/icons/eye.svg";
import {ReactComponent as TagsIcon} from "assets/icons/tagsicon.svg";
import {ReactComponent as Trash} from "assets/icons/trash.svg";
import CustomImageInput from "../../../../../components/Forms/CustomImageInput";
import useEditor from "../../../../../stores/use-editor";
import {useSwiper} from "stores/use-swiper";

const TagRow = ({ tagOBJ, tagTypeOBJ ,idx}) => {
  const { setPreviewTag, previewTag, deleteCustomTag,setShowDeleteAnswerDialog } = useEditor();
  const doNotShowDialogStorage = localStorage.getItem('doNotShowAgainDeleteDialog') === 'true';
  const {changeActiveTagID}=useSwiper();
  const {
    uploadTagImage,
    deleteTagImage,
    chosenTagImage,
    defaultTagTypeImage,
  } = useTagImage({ tagOBJ, tagTypeOBJ });
  const thisTagIsBeingPreviewd = previewTag?.tag?.id === tagOBJ.tagID;

  const onClickTagEye = (e,publicURL = null) => {
    setPreviewTag({
      image: publicURL || chosenTagImage || defaultTagTypeImage,
      sentence: tagTypeOBJ?.sentence?.replaceAll("%s", tagOBJ?.name)?.replaceAll("#", tagOBJ?.name),
      matchingProducts:  ["", "", ""],
      tag: {
        id: tagOBJ?.tagID,
        type: tagTypeOBJ.type,
        name: tagOBJ?.name,
      },
    });
  };

  const onDeleteTag = () => {
	changeActiveTagID(tagOBJ?.tagID)
	if (doNotShowDialogStorage) {
		deleteCustomTag(tagTypeOBJ?.type, tagOBJ?.tagID);
	  } else {
		setShowDeleteAnswerDialog(true,idx,tagOBJ?.id);
	  }
   
  };

  return (
		<>
			<div className="sentence-tag-row">
				<div className="sentence-tag-row-labels">
					<TagsIcon fill="#5C42E1" />
					<p style={{ width: "80px", wordBreak: "break-all" }}>
						{tagOBJ?.name}
					</p>
				</div>
				<div
					className="sentence-tag-row-labels"
					style={{ width: "60%" }}>
					<CustomImageInput
						visibleInfo={false}
						showConfirmText={false}
						onImageChange={(file) =>
							uploadTagImage(file, (publicURL) =>
								onClickTagEye(null, publicURL)
							)
						}
						accept={"image/png"}
						value={chosenTagImage}
						onDelete={deleteTagImage}
						placeHolder="Choose an image"
					/>
					{thisTagIsBeingPreviewd ? (
						<Eye
							fill={"#bebebe"}
							onClick={(e) => onClickTagEye(e, null)}
							style={{ cursor: "pointer" }}
						/>
					) : (
						<Eye
							fill={"#017cf8"}
							onClick={(e) => onClickTagEye(e, null)}
							style={{ cursor: "pointer" }}
						/>
					)}

					<Trash
						className="sentence-row-trash"
						onClick={onDeleteTag}
					/>
				</div>
			</div>
		</>
  );
};

export default TagRow;
