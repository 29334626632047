export const QUIZ = {
  question: {
    background: "gradientColor",
    border: null,
    text: "color",
    font: "font",
  },
  answer: {
    background: "color",
    border: "color",
    text: "color",
  },
  selectedAnswer: {
    background: "color",
    border: "color",
    text: "color",
  },
  bottomBar: {
    background: "color",
    border: null,
    text: null,
  },
  navigationButton: {
    background: "color",
    border: "color",
    text: "color",
  },
};
