import {ReactComponent as Arrow} from "assets/icons/arrow.svg";
import {ReactComponent as SentenceIcon} from "assets/icons/sentenceIcon.svg";
import useEditor from "stores/use-editor";
import {useEffect, useMemo, useRef} from "react";
import CustomImageInput from "../../../../../components/Forms/CustomImageInput";
import uploadToS3 from "../../../../../utils/uploadToS3";
import {ReactComponent as Trash} from "assets/icons/trash.svg";
import {ReactComponent as Alert} from "assets/icons/alert.svg";
import {ReactComponent as EyeClosed} from "assets/icons/eye-closed.svg";
import CustomInput from "../../../../../components/Forms/CustomInput";
import TagRow from "./TagRow";
import {useEditorEvents} from "../../../../../stores/use-editor-events";
import {Client} from "../../../../../Client";
import {useSwiper} from "stores/use-swiper";
import {ReactComponent as InfoBlack} from "assets/icons/info-black.svg";
import InfoSVG from "assets/icons/InfoSVG";

export default function Sentence({ idx, tagTypeOBJ }) {
  const doNotShowDialogStorage =
    localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
  const { addEvents } = useEditorEvents();
  const { setPreviewTag, setEmailDesign, experience, setShowDeleteDialog } =
    useEditor();
  const {
    activeSentenceID,
    addToTagTypesToUpdate,
    changeActiveSentence,
    sentencesTags,
    setSentence,
    tagsImages,
    setTagTypeImage,
  } = useSwiper();
  const thisSentenceIsOpen = tagTypeOBJ?.id === activeSentenceID;
  const titleRef = useRef();
  const tagsOfSentence = useMemo(
    () => sentencesTags[tagTypeOBJ?.type],
    [tagTypeOBJ?.id]
  );
  const defaultTagTypeImage = tagsImages[tagTypeOBJ?.type];
  const tagsOfTagType = tagsOfSentence?.filter(
    (tagOBJ) => !experience.design?.tagsToIgnore?.includes(tagOBJ?.tagID)
  );
  const sentenceInputValue = tagTypeOBJ?.sentence.replaceAll("%s", "#");
  const email = experience?.design?.email;
  const firstTagOfSentence = tagsOfTagType[0];

  useEffect(() => {
    if (thisSentenceIsOpen) return titleRef?.current?.focus();
  }, [thisSentenceIsOpen]);

  const onFocusTitleInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleActiveSentence = () => {
    // Toggle open/close sentence
    if (thisSentenceIsOpen) return changeActiveSentence("", "");
    changeActiveSentence(tagTypeOBJ?.id, idx);

    // If email modal is open -> close it.
    if (email?.enabled === "disabled") return;
    setEmailDesign(false);
    // Update preview -> show the first tag of this sentence
    setPreviewTag({
      image: firstTagOfSentence?.image || defaultTagTypeImage,
      sentence: tagTypeOBJ?.sentence
        ?.replaceAll("%s", tagsOfTagType?.name)
        ?.replaceAll("#", tagsOfTagType?.[0]?.name),
      matchingProducts: ["", "", ""],
      tag: {
        id: firstTagOfSentence?.tagID,
        type: firstTagOfSentence.type,
        name: firstTagOfSentence?.name,
      },
    });
  };

  const uploadImage = async (file) => {
    const s3PathFetched = await uploadToS3(file);
    const { data: publicURL } = await Client.post("emp/view/file", {
      s3Path: s3PathFetched,
    });

    setTagTypeImage({ type: tagTypeOBJ.type, s3Path: s3PathFetched });
    addToTagTypesToUpdate(tagTypeOBJ);

    setPreviewTag({
      image: publicURL,
      sentence: tagTypeOBJ?.sentence
        ?.replaceAll("%s", tagsOfTagType?.[0]?.name)
        ?.replaceAll("#", tagsOfTagType?.[0]?.name),
      matchingProducts: ["", "", ""],
      tag: {
        id: firstTagOfSentence?.tagID,
        type: firstTagOfSentence.type,
        name: firstTagOfSentence?.name,
      },
    });
  };

  const onDeleteSentence = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await changeActiveSentence(tagTypeOBJ?.id, idx);
    if (doNotShowDialogStorage) {
      addEvents("design", "update", {
        tagTypesToIgnore: [
          ...(experience?.design?.tagTypesToIgnore || []),
          tagTypeOBJ?.type,
        ],
      });
    } else {
      setShowDeleteDialog(true);
    }
  };

  return (
    <div className="question">
      <div
        className="title "
        onClick={handleActiveSentence}
        id={thisSentenceIsOpen && "sentence-title"}
      >
        <div className="title-upper-section">
          <div style={{ width: 20, height: 20, borderRadius: 4 }}>
            <div className="quesIcon">
              <SentenceIcon fill="#017CF8" />
            </div>
          </div>
          <CustomInput
            maxLength={90}
            value={sentenceInputValue}
            onClick={onFocusTitleInput}
            size="semi-large"
            error={!sentenceInputValue?.includes("#") && "Missing hashtag (#)"}
            showErrorMessage
            onChange={(e) => setSentence(idx, tagTypeOBJ, e.target.value)}
            isEditMode={thisSentenceIsOpen}
            className={thisSentenceIsOpen ? "" : "sentence-input-closed"}
            forceStyle={{
              maxWidth: "90% !important",
              width: "80%",
            }}
          />
          <Arrow
            style={thisSentenceIsOpen ? { transform: "rotate(180deg)" } : {}}
            className="arrow"
          />
          <Trash className="sentence-trash" onClick={onDeleteSentence} />
        </div>
        {thisSentenceIsOpen && (
          <div className="title-lower-section">
            <Alert
              style={{
                minHeight: 18,
                minWidth: 18,
                marginRight: 5,
              }}
            />
            <p>
              In a statement, the <span style={{ color: "#017cf8" }}>#</span>{" "}
              represents the tags associated with it. The statement <b>must</b>{" "}
              include it. Place the <span style={{ color: "#017cf8" }}>#</span>{" "}
              where you will use tags in the sentence
            </p>
          </div>
        )}
      </div>
      {thisSentenceIsOpen && (
        <div className="sentence-content-container ">
          <div
            className="sentence-tag-row "
            style={{ borderBottom: "1px solid #DBE1E7" }}
          >
            <div className="sentence-tag-row-labels">
              <p style={{ width: "80px", wordBreak: "break-all" }}>
                Statement Image
              </p>
              {/*the icons are not visible*/}
              <EyeClosed
                className="sentence-row-trash"
                style={{ visibility: "hidden" }}
              />
            </div>
            <div className="sentence-tag-row-labels" style={{ width: "60%" }}>
              <CustomImageInput
                visibleInfo={false}
                showConfirmText={false}
                onImageChange={uploadImage}
                accept={"image/png"}
                value={defaultTagTypeImage}
                // onDelete={() => {
                //     addEvents("design", "update", {
                //         [`questions/${questions[activeQuestionIndex].id}/image`]:
                //         undefined,
                //     });
                // }}
                placeHolder="Choose an image"
              />
              {/*the icons are not visible*/}
              <EyeClosed
                className="sentence-row-trash"
                style={{ visibility: "hidden" }}
              />
              <Trash
                className="sentence-row-trash"
                style={{ visibility: "hidden" }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "18px",
              gap: "5px",
            }}
          >
            <p
              style={{
                marginTop: "10px",
              }}
            >
              Related Tags
            </p>
            <InfoBlack></InfoBlack>
          </div>
          {tagsOfTagType?.map((tagOBJ, idx) => {
            return (
              <TagRow
                thisSentenceIsOpen={thisSentenceIsOpen}
                key={tagOBJ?.id}
                idx={idx}
                tagTypeOBJ={tagTypeOBJ}
                tagOBJ={tagOBJ}
              />
            );
          })}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "50px",
                width: "250px",
                backgroundColor: "#E4F1FF",
                padding: "10px",
                borderRadius: "5px",
                alignItems: "center",
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              {/* <div style={{ */}
              {/* // display: "flex", */}
              {/* // justifyContent: "center", */}
              {/* }}> */}
              <div
                style={{
                  height: "14px",
                  width: "14px",
                  color: "#DBE1E7",
                }}
              >
                <InfoSVG></InfoSVG>
              </div>
              <p
                style={{
                  color: "gray",
                }}
              >
                Maximum upload file size is 2MB, only PNG is allowed and image
                dimensions should be 1080*1350
              </p>
              {/* </div> */}
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #DBE1E7",
            }}
          ></div>
        </div>
      )}
    </div>
  );
}
