// @ts-nocheck
import CustomTextArea from "components/Forms/CustomTextArea";
import {useEffect, useRef, useState} from "react";
import {Draggable} from "react-beautiful-dnd";
import {BiMoveVertical} from "react-icons/bi";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import useCustomTags from "stores/use-custom-tags";
import uploadToS3 from "utils/uploadToS3";
import {checkImageSelection, getProductsForAnswer} from "utils/utls";
import {ReactComponent as Delete} from "../../../../../../../assets/icons/delete.svg";
import {DEFAULT_ANSWER} from "../../../../../../../utils/finalVariables";
import AddProductsDialog from "../../AddProductsDialog";
import useDeleteProperties from "../../../../../../../hooks/useDeleteProperties";
import AnswerConnectedProducts from "../../../AnswerConnectedProducts";
import {useOnboarding} from "../../../../../../../stores/use-onboarding";
import {debounceInput} from "../../../../../../../utils/utls";

export default function Answer({ ques, ans, idx }) {
  const { tags, addTag } = useCustomTags();
  const [showProductModal, setShowProductModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [isOver, setIsOver] = useState(false);
  const [noOfConnectedProducts, setNoOfConnectedProducts] = useState(null);
  const { deleteAnswer } = useDeleteProperties();

  const {
    experience,
    editAnswerIndex,
    questions,
    activeQuestionIndex,
    changeEditAnswerIndex,
    setScrollAnswer,
  } = useEditor();
  const { showEditAnswerDialog, setShowDeleteAnswerDialog } = useEditor();
  const [s3Path, setS3Path] = useState();
  const { addEvents } = useEditorEvents();
  const isImageSelection = checkImageSelection(
    questions[activeQuestionIndex],
    experience?.design
  );
  const [changed, setChanged] = useState(false);
  const doNotShowDialogStorage =
    localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
  const ref = useRef();

  const uploadAnswerImage = async ({s3Url}) => {
    addEvents("design", "update", {
      [`questions/${ques.id}/${ans?.id}`]: s3Url,
    });
  };

  useEffect(() => {
    //if (tags[ans.tagIDs?.[0]]) return;
    //if (editAnswerIndex===undefined) return;
    if (ans.tagIDs?.[0] && idx === editAnswerIndex && !showEditAnswerDialog) {
      let tagId = ans.tagIDs?.[0];
      if (tags[ans.tagIDs?.[0]]) {
        tagId = tags[ans.tagIDs?.[0]].originalTagId;
      }
      getProductsForAnswer(experience?.id, tagId).then((res) => {
        setProducts(res);
        if (tags[ans.tagIDs?.[0]]?.products) {
          setNoOfConnectedProducts(tags[ans.tagIDs?.[0]]?.products?.length);
        } else {
          setNoOfConnectedProducts(res.length);
        }
      });
    }
  }, [editAnswerIndex]);

  const imageSelection =
    ques?.type === "IMAGE_SELECTION" ||
    checkImageSelection(ques, experience?.design);

  const handleBlurAnswer = (e) => {
    setScrollAnswer("false");
    if (e.target.value === "") return;
    if (!changed) return;
    let tag;
    if (tags[ans.tagIDs[0]]) {
      tag = addTag({
        ...tags[ans.tagIDs[0]],
        name: e.target.value,
      });
    } else {
      tag = addTag({
        name: e.target.value,
        originalTagId: ans.tagIDs[0],
        products: products.map((p) => ({
          id: p.id,
          name: p.name,
          image: p?.image,
        })),
      });
    }

    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: activeQuestionIndex,
      answers: [
        ...questions[activeQuestionIndex].answers.slice(0, idx),
        {
          ...ans,
          answer: e.target.value,
          tagIDs: [tag.id],
        },
        ...questions[activeQuestionIndex].answers.slice(idx + 1),
      ],
    });
  };

  useEffect(() => {
    if (!showProductModal) {
      setIsOver(false);
      ref.current?.children?.[1]?.firstChild?.firstChild?.children?.[1]?.firstChild?.focus();
    }
  }, [showProductModal]);

  const editAnswer = debounceInput((e, ans, idx) => {
    setChanged(true);
    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: activeQuestionIndex,
      answers: [
        ...questions[activeQuestionIndex].answers.slice(0, idx),
        { ...ans, answer: e.target.value },
        ...questions[activeQuestionIndex].answers.slice(idx + 1),
      ],
    });
  }, 500);
  return (
    <Draggable key={ans.id} index={idx} draggableId={ans.id}>
      {(provided, snapshot) => {
        return (
          <div
            onMouseEnter={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
            onBlur={() => {
              if (isOver || showProductModal) return;
              changeEditAnswerIndex(undefined);
            }}
            className="answer"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={(el) => {
              provided.innerRef(el);
              ref.current = el;
            }}
          >
            <BiMoveVertical
              style={{
                marginTop: 32,
                minWidth: 15,
              }}
              size={16}
              color={"#A3A3A3"}
            />
            {idx === editAnswerIndex && !showEditAnswerDialog ? (
              <div style={{ width: "100%" }}>
                <div className="answer-search">
                  {imageSelection ? (
                    /* @ts-ignore */
                    <CustomTextArea
                      placeHolder={DEFAULT_ANSWER}
                      defaultValue={
                        ans.answer === DEFAULT_ANSWER ? "" : ans.answer
                      }
                      imgValue={
                        experience?.design?.questions?.[
                          questions[activeQuestionIndex]?.id
                        ]?.[ans?.id] ?? ""
                      }
                      name={"Answer" + (idx + 1)}
                      width={"45%"}
                      // @ts-ignore
                      onImageChange={uploadAnswerImage}
                      onChange={(e) => editAnswer(e, ans, idx)}
                      onBlur={handleBlurAnswer}
                      charLimit={40}
                      icon={true}
                    />
                  ) : (
                    /* @ts-ignore */
                    <CustomTextArea
                      width={"40%"}
                      placeHolder={ans.answer}
                      defaultValue={ans.answer}
                      // value={ans.answer}
                      charLimit={ques?.type === "CLUSTER" ? 20 : 40}
                      onChange={(e) => editAnswer(e, ans, idx)}
                      onBlur={handleBlurAnswer}
                    />
                  )}
                  <AnswerConnectedProducts
                    noConnectedProducts={noOfConnectedProducts === 0}
                    setShowProductModal={setShowProductModal}
                    noOfConnectedProducts={noOfConnectedProducts}
                    answerOBJ={ans}
                  />
                  {
                    idx>2&&
                  <Delete
                    fill="#017cf8"
                    width={20}
                    style={{ minWidth: 18, maxWidth: 18 }}
                    height={20}
                    onClick={() => deleteAnswer(ans, idx)}
                  />
                  }
                  <AddProductsDialog
                    show={showProductModal}
                    setShow={setShowProductModal}
                    products={products}
                    setNoOfConnectedProducts={setNoOfConnectedProducts}
                    ans={ans}
                    idx={idx}
                    setAnswersProductsCountLocal={() => null}
                  />
                </div>
              </div>
            ) : (
              <div className="answer-search">
                {isImageSelection ? (
                  <CustomTextArea
                    placeHolder={DEFAULT_ANSWER}
                    defaultValue={ans?.answer}
                    imgValue={
                      experience?.design?.questions?.[
                        questions[activeQuestionIndex]?.id
                      ]?.[ans?.id] ?? ""
                    }
                    // value={s3PathFetched}
                    width={"45%"}
                    onClick={(e) => {
                      changeEditAnswerIndex(idx);
                    }}
                      onImageChange={uploadAnswerImage}
                    disabled
                    charLimit={40}
                    icon={true}
                  />
                ) : (
                  <CustomTextArea
                    defaultValue={ans.answer}
                    width={"40%"}
                    disabled
                    onClick={(e) => {
                      changeEditAnswerIndex(idx);
                    }}
                    charLimit={ques?.type === "CLUSTER" ? 20 : 40}
                  />
                )}

                    <AnswerConnectedProducts
                      noConnectedProducts={noOfConnectedProducts === 0}
                      setShowProductModal={(v) => {
                        changeEditAnswerIndex(idx);
                        setShowProductModal(v);
                      }}
                      noOfConnectedProducts={noOfConnectedProducts}
                      answerOBJ={ans}
                    />

                {questions[activeQuestionIndex]?.answers?.length>2 && <Delete
                    fill="#017CF8"
                    style={{minWidth: 18, maxWidth: 18}}
                    onClick={() => {
                      if (doNotShowDialogStorage) {
                        deleteAnswer(ans, idx);
                      } else {
                        setShowDeleteAnswerDialog(true, idx, ans?.id);
                      }
                    }}
                />}
              </div>
            )}
          </div>
        );
      }}
    </Draggable>
  );
}
