import {Button} from "evergreen-ui";
import React, {useState} from "react";
import {ReactComponent as CreateIcon} from "../../assets/images/create-new.svg";
import CreateDialog from "./CreateDialog";
import "./ExperiencesPage.scss";
import {useNavigate} from "react-router-dom";

const NoExperiences = ({ fetchExperiencesPoller }) => {
  const [createExpLoading, setCreateExpLoading] = useState(false);
  const [isNewExpModalVisible, setIsNewExpModalVisible] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="no-exp-conatiner"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "179px",
        }}
      >
        <div>
          <CreateIcon />
          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "20px",
              marginTop: "21px",
            }}
          >
            Let's create something special together
          </p>

          <div
            className="n0-exp-page-container"
            style={{ textAlign: "center" }}
          >
            <CreateDialog
              show={isNewExpModalVisible}
              setShow={setIsNewExpModalVisible}
              onCreate={() => {

                fetchExperiencesPoller();
              }}
            />
            <Button
              appearance="primary"
              backgroundColor={"#017CF8"}
              color={"#FFF"}
              borderRadius={"7px"}
              height={"49px"}
              size="large"
              width={"246px"}
              marginY={"31px"}
              onClick={() => {
                if (createExpLoading) return;
                 setIsNewExpModalVisible(true);
              }}
            >
              Create an experience
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoExperiences;
