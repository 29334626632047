const inputStyle = {
  width: "230px",
  height: "40px",
  background: "#ffffff",
  border: "1px solid #dbe1e7",
  borderRadius: " 8px",
  padding: "0 10px 0 35px",
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
};
const popUpContainer = {
  position: "unset",
  top: "40px",
  right: "10px",
  width: "318px",
  // height: "453px",
  zIndex: "1",
  background: "#e4f1ff",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "20px 30px",
};
const popUpAlertContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: "5px",
};

const popUpAlert = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "10px",
  letterSpacing: "-0.02em",
  color: "#75787e",
  marginLeft: "5px",
};

const headerContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};
const header = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "-0.02em",
  color: "#0f0f0f",
};
const popUpCreateTagContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "10px",
  cursor: "pointer",
};
const popUpCreateTag = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "-0.02em",
  color: "#017cf8",
  marginLeft: "10px",
};

const popUpListContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  margin: "10px 0",
  width: "100%",
  maxHeight: "220px",
  overflowY: "scroll",
  paddingRight: "5px",
};
const popUpSelectedTagHeader = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "10px",
  lineHeight: "20px",
  letterSpacing: "-0.02em",
  color: "#75787e",
  marginTop: "10px",
};
const popUpList = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "230px",
  height: "56px",
  borderRadius: "4px",
  padding: "8px",
  borderBottom: "1px solid #dbe1e7",
  cursor: "pointer",
};
export const checkboxContainer = {
  minWidth: "16px",
  width: "16px",
  height: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#e4f1ff",
  border: "1.5px solid #017cf8",
  borderRadius: "4px",
  marginLeft: "5px",
  marginTop: "8px",
  marginRight: "12px",
};

const checkbox = {
  width: "8px",
  height: "8px",
  background: "#017cf8",
  borderRadius: "2px",
};
const popUpListUp = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  /* identical to box height, or 143% */ letterSpacing: "-0.02em",
};
const popUpListDown = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "20px",
  /* identical to box height, or 167% */ letterSpacing: "-0.02em",
  color: "#75787e",
};
const searchAlertContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: "10px",
};
const searchAlertLeftContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
};
const searchAlertLeftTop = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "10px",
  lineHeight: "24px",
  /* or 240% */ letterSpacing: "-0.02em",
  /* text/secondary */ color: "#75787e",
};
const searchAlertLeftBottom = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "10px",
  lineHeight: "24px",
  /* or 240% */ letterSpacing: "-0.02em",
  /* text/secondary */ color: "#75787e",
};

const closeIcon = {
  position: "absolute",
  right: "8px",
  marginTop: "8px",
  cursor: "pointer",
};

const productTypeContainer = {
  marginTop: "20px",
};

const productTypeParagraph = {
  fontFamily: "Satoshi",
  fontWeight: "400",
  fontSize: "12px",
  color: "#75787E",
};

const productTypeBtn = {
  width: "158px",
  height: "35px",
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#017CF8",
  color: "#FFFFFF",
  fontSize: "12px",
  fontFamily: "Satoshi",
  marginTop: "20px",
  fontStyle: "normal",
  padding: "3px 10px 3px 14px",
};

const productTypeMessage = {
  fontSize: 12,
  marginTop: 15,
  color: "#75787E",
  lineHeight: 2
};

export const styles = {
  searchAlertLeftBottom,
  searchAlertLeftTop,
  searchAlertLeftContainer,
  searchAlertContainer,
  productTypeMessage,
  popUpListDown,
  popUpListUp,
  checkboxContainer,
  popUpList,
  popUpSelectedTagHeader,
  inputStyle,
  popUpContainer,
  popUpAlertContainer,
  popUpAlert,
  headerContainer,
  header,
  popUpCreateTagContainer,
  popUpCreateTag,
  popUpListContainer,
  productTypeParagraph,
  productTypeContainer,
  productTypeBtn,
  checkbox,
  closeIcon,
};
