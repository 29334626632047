import { Client } from "Client";
import { MutableRefObject, useRef, useState } from "react";
import useEditor from "stores/use-editor";

const useGenerateQuestion = () => {
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState();
  const setExperience = useEditor(state=>state.setExperience);
  const [dictionary, setDictionary] = useState();
  const {setConnectedProductsLoading,setAnswersProductsCountFromServer,activeQuestionIndex,experience}=useEditor();
  const [started, setStarted] = useState(false);
  const intervalRef = useRef() as MutableRefObject<NodeJS.Timer>;

  const handleGenerate = async (
    questionTopic: string,
    toneOfVoice: string,
    experienceId: string,
    originalNoOfQuestions: number
  ) => {
    try {
      setLoading(true);
      await Client.post("emp/question/generate", {
        experienceID: experienceId,
        questionTopic: questionTopic,
        toneOfVoice: toneOfVoice,
      });
      const startedTime = new Date().valueOf();
      setStarted(true)
      intervalRef.current = setInterval(async () => {
        if (new Date().valueOf() - startedTime > 60 * 1000) {
          clearInterval(intervalRef.current);
          intervalRef.current = undefined;
          setStarted(false);
          return;
        }
        const res = await Client.get(
          `emp/experiences/${experienceId}/questions`
        );
        const { questions, dictionary } = res?.data || {};
        if (questions.length > originalNoOfQuestions) {
          const { data: exp } = await Client.get(`emp/experiences/${experienceId}`);
          setExperience(exp);
          setQuestion(questions.slice(-1)[0]);
          setDictionary(dictionary);
          setStarted(false);
          clearInterval(intervalRef.current);
          setConnectedProductsLoading(true);
          Client.get(
            `/emp/answers/products?experienceID=${experience?.id}&questionID=${questions[activeQuestionIndex]?.id}`
          ).then((res) => {
            if (res.data) setAnswersProductsCountFromServer(res.data);
            setConnectedProductsLoading(false);
          });
          intervalRef.current = undefined;
        }
      }, 5000);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return {
    loading,
    started,
    question,
    dictionary,
    handleGenerate,
  };
};

export default useGenerateQuestion;
