import { Button, Li, majorScale, Menu, Pane, Ul } from "evergreen-ui";
import { FaCheckCircle, FaEllipsisV } from "react-icons/fa";
import ellipsis from "../../assets/icons/ellipsis.svg";
import shopIcon from "../../assets/icons/shop.svg";
// import { ReactComponent as AddIcon } from "../../assets/icons/plus-sidebar.svg";
import { Skeleton } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/icons/add-brand-icon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/icons/log-out-icon-new.svg";
import { ReactComponent as StoreIcon } from "../../assets/icons/sidebar-store-icon.svg";
import { Client } from "../../Client";
import "./brand-widget.scss";

function BrandWidget({
  accountData,
  isShopifyUser,
  fromMinimizedMenu,
  showBrandWidget,
  children,
  minimized,
}) {
  return (
    <Pane
      className="brand-widget"
      display="flex"
      boxShadow="0 0 20px #0001"
      flexDirection="column"
      width={216}
      borderRadius={majorScale(1)}
      height={48}
      marginBottom={majorScale(1)}
      paddingY={majorScale(1)}
      paddingX={majorScale(2)}
      backgroundColor="white"
    >
      <Pane justifyContent="space-between" alignItems="center" display="flex">
        <Pane
          marginLeft="-3px"
          display="flex"
          alignItems="flex-start"
          gap={majorScale(1)}
        >
          {fromMinimizedMenu ? (
            <img src={shopIcon} />
          ) : (
            <StoreIcon
              style={{
                marginTop: 2,
              }}
            />
          )}
          {(accountData && (
            <div className="title">
              {accountData?.name?.length >= 16
                ? accountData?.name.slice(0, 13) + "..."
                : accountData?.name}
            </div>
          )) || (
            <Skeleton
              className="title"
              variant="text"
              width={100}
              height={35}
            />
          )}
        </Pane>
        {accountData && (
          <BrandMenu
            accounts={accountData?.userAccounts}
            fromMinimizedMenu={fromMinimizedMenu}
          >
            {children}
          </BrandMenu>
        )}
      </Pane>

      {/* {(accountData && (
        <Stat
          label="Engagements"
          value={`${repliesQuota}%`}
          percent={repliesQuota}
        />
      )) || (
        <Skeleton className="title" variant="text" width={"100%"} height={36} />
      )}
      {(accountData && (
        <Pane
          marginTop={8}
          className="label"
          justifyContent="space-between"
          display="flex"
        >
          <div style={{ color: "black" }}>Plan: {planName}</div>
          <div style={{ color: "black" }}>${billingInformation?.price}</div>
        </Pane>
      )) || (
        <Skeleton className="title" variant="text" width={"100%"} height={18} />
      )} */}
      {/* <Pane display="flex" justifyContent="flex-end">
        <NavLink to={isShopifyUser ? "/billing/plans" : "/billing"} className="upgrade-link" style={{ color: "#017CF8" }}>
          Upgrade Plan
        </NavLink>
      </Pane> */}
    </Pane>
  );
}
export function StoreMenu({ accountData, onLogOut, LeavingEditorListener }) {
  return (
    <Menu>
      <Ul>
        {accountData?.map((a) => {
          return (
            <BrandMenuItem
              key={a.name}
              onClick={() => handleChangeAccount(a.brandID)}
              id={a.brandID}
              //active={a.brandID === localStorage.getItem("SKP_BRANDID")}
            >
              <div className="store-name-btn">
                <StoreIcon style={{ marginLeft: "11px" }} />
                <span
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  {a.name}
                </span>
              </div>
            </BrandMenuItem>
          );
        })}

        <Li>
          <BrandAddAccount />
        </Li>
        <Li>
          <NavLink to="/login" onClick={onLogOut} className="sidebar-logout">
            <LeavingEditorListener to="/login">
              <div className="store-logout-btn">
                <LogOutIcon style={{ marginLeft: "10px" }} />
                <span style={{ marginLeft: "18px" }}> Logout</span>
              </div>
            </LeavingEditorListener>
          </NavLink>
        </Li>
      </Ul>
      {/* <BrandMenuItem onClick={null}></BrandMenuItem> */}
    </Menu>
  );
}
export function Stat({ label, value, percent }) {
  let color = percent < 70 ? "#017CF8" : percent < 90 ? "#F4B037" : "#FF3120";
  return (
    <Pane marginTop={8}>
      <Pane className="label" justifyContent="space-between" display="flex">
        <div style={{ color: "black" }}>{label}</div>
        <div style={{ color: "black" }}>{value}</div>
      </Pane>
      <Pane>
        <div className="progress">
          <div
            className="progress-inner"
            style={{ width: percent + "%", backgroundColor: color }}
          ></div>
        </div>
      </Pane>
    </Pane>
  );
}

export function BrandMenuItem({ active = false, onClick, children }) {
  return (
    <Li onClick={onClick}>
      {children}
      {active && (
        <FaCheckCircle
          size={20}
          style={{
            marginRight: 8,
          }}
        />
      )}
    </Li>
  );
}

export function BrandAddAccount() {
  return (
    <div
      className="brand-add-account-container"
      onClick={() => window.open("https://apps.shopify.com/skeep", "_blank")}
    >
      <AddIcon
        style={{ marginLeft: "11px" }}
        height={20}
        className="brand-add-account-icon"
        stroke="#000000"
      />
      <p className="brand-add-account-text" style={{ marginLeft: "23px" }}>
        Add Store
      </p>
    </div>
  );
}

export const handleChangeAccount = (brandID) => {
  Client.get("/emp/account/exchange?brandID=" + brandID).then((res) => {
    localStorage.setItem("SKP_JWT", res.data.token);
    localStorage.setItem("SKP_BRANDID", res.data.brandID);
    localStorage.setItem("SKP_NAME", res.data.fullName);
    localStorage.setItem("SKP_BRAND", res.data.brandName);
    window.location.href = "/experiences";
  });
};

export function BrandMenu({ accounts, fromMinimizedMenu, children }) {
  return (
    <Button className="menu-button" title="Brand" appearance="minimal">
      {fromMinimizedMenu ? (
        <img src={ellipsis} />
      ) : (
        <FaEllipsisV size={16} color="#0F0F0F" />
      )}
    </Button>
  );
}

export default BrandWidget;
