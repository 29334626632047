import {Button, Dialog, majorScale, Text} from "evergreen-ui";
import CreateDialogButton from "pages/ExperiencesPage/CreateDialog/CreateDialogButton";
import {useState} from "react";
import useCustomTags from "stores/use-custom-tags";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import AddProductsWizard from "./AddProductsWizard";

const AddProductsDialog = ({
       show,
       setShow,
       ans,
       idx,
       products,
       setNoOfConnectedProducts,
       setAnswersProductsCountLocal
   }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const { questions, activeQuestionIndex} = useEditor();
    const {  addEvents,} = useEditorEvents();
    const { tags, addTag} = useCustomTags();

  const saveChanges = async () => {
    const newTag =  addTag({
      originalTagId: ans.tagIDs[0],
      ...(tags[ans.tagIDs[0]] ?? {}),
      name: ans.answer,
      products: [...selectedProducts],
    });
    await addEvents("question", "update", {
      quesIdx: activeQuestionIndex,
      questionId: questions[activeQuestionIndex].id,
      answers: [
        ...questions[activeQuestionIndex].answers.slice(0, idx),
        {
          ...ans,
          tagIDs: [newTag.id],
        },
        ...questions[activeQuestionIndex].answers.slice(idx + 1),
      ],
    });
    setAnswersProductsCountLocal({ [ans?.id]: selectedProducts?.length });
    setShow(false);
    setNoOfConnectedProducts(selectedProducts?.length);
  };

    return (
        <Dialog
            isShown={show}
            width="43vw"
            onCloseComplete={() => {
                setShow(false);
            }}
            overlayProps={{
                zIndex:21
            }}
         shouldCloseOnOverlayClick={false}
            hasFooter={false}
            hasHeader={false}
            contentContainerProps={{padding: "30px"}}
            containerProps={{
                // maxWidth: "831px",
                minHeight: 590,
                borderRadius: "16px",
            }}
        >
            <div
                className="create-experience-dialog"
                style={{
                    height: "100%",
                    minHeight: 500,
                    padding:0,
                    maxHeight: 550,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Button
                    onClick={() => {
                        setShow(false);
                    }}
                    appearance="minimal"
                    position="absolute"
                    top={majorScale(2)}
                    fontSize={majorScale(3)}
                    right={majorScale(2)}
                >
                    &times;
                </Button>
                <Text
                    style={{
                        fontSize: "24px",
                        color: "#000",
                        fontWeight: 500,
                        width: "100%",
                    }}
                >
                    Connect Products
                    <br/>{" "}
                </Text>
                <Text
                    color="#75787E"
                    paddingY="7px"
                    paddingBottom="0px"
                    fontSize="12px"
                >
                    Review and edit the products that are connected to this answer
                </Text>

                <AddProductsWizard
                    ans={ans}
                    idx={idx}
                    products={products}
                    setShow={setShow}
                    show={setShow}
                    setNoOfConnectedProducts={setNoOfConnectedProducts}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
                <CreateDialogButton
                    text={"Save Changes"}
                    onClick={async () => {
                        await saveChanges();
                    }}
                    style={{padding: "25px 30px",marginTop:"20px",borderRadius:"8px"}}
                />
            </div>
        </Dialog>
    );
};

export default AddProductsDialog;
