import Header from "components/Header/Header";
import EditorBackButton from "pages/EditorPage/components/EditorBackButton";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ResponsePlaceHolder from "../../assets/images/response-placeholder.png";
import Dots from "../../assets/icons/Dots.svg";
import BlueDot from "../../assets/icons/blue-dot.svg";
import "./ResponsePage.scss";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import { Client } from "Client";
import useAuth from "stores/auth";
ChartJS.register(ArcElement, Tooltip, Legend);
const colors = [
  "#7A1DBD",
  "#5C42E1",
  "#A89AEF",
  "#CEC6F6",
  "#E2DCFF",
  "#1A15FF",
  "#017CF8",
  "#1DD6FC",
  "#E4F1FF",
  "#F4B037",
  "#FFE4B4",
];
const ResponsePage = () => {
  const { id: experienceId } = useParams();
  const [loading, setLoading] = useState(true);
  const [experience, setExperience] = useState();
  const [responses, setResponses] = useState([]);
  const [noData, setNoData] = useState(false);
  const { setMinimized } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    setMinimized(true);
  }, []);

  useEffect(() => {
    if (experienceId) {
      setLoading(true);
      Client.get(`emp/responses/${experienceId}`)
        .then((res) => {
          setResponses(
            res.data?.responses?.map((response) => {
              response.activeQuestion = res?.data?.activeQuestions?.includes(
                response?.questionID
              );

              // Remove duplicates answers count from duplicated questions
              response.answers = response?.answers?.reduce(
                (accumulator, current) => {
                  if (
                    !accumulator.find((item) => item.answer === current.answer)
                  ) {
                    accumulator.push(current);
                  }
                  return accumulator;
                },
                []
              );
              // Add total replies property by summing all answers count
              let sum = function add(sumSoFar, item) {
                return sumSoFar + item.count;
              };
              response.totalCount = response.answers.reduce(sum, 0);
              return response;
            })
          );

          setLoading(false);
        })
        .catch(() => {
          setNoData(true);
          setLoading(false);
        });
    }
  }, [experienceId]);
  useEffect(() => {
    if (experienceId) {
      Client.get(`emp/experiences/${experienceId}`).then((res) => {
        setExperience(res.data);
      });
    }
  }, [experienceId]);
  const options = (indexOfResponse) => ({
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: "#E4F1FF",
        titleColor: "#AEB2BA",
        bodyColor: "#000000",
        footerColor: "#AEB2BA",
        callbacks: {
          title: (context) => {
            return "Answer";
          },
          beforeBody: (context) => {
            return context.raw;
          },
          footer: (context) => {
            const percent = Number(
              (context?.[0]?.raw / responses[indexOfResponse].totalCount) * 100
            ).toFixed(0);
            return percent + "%";
          },
          label: (context) =>
            // responses[indexOfResponse].answers.find(
            //   (answer) => answer?.count === context?.raw
            // )?.answer,
            context?.dataset?.label?.[context?.dataIndex],
        },
      },
    },
  });
  const data = (answers) => ({
    datasets: [
      {
        data: answers?.map((answer) => answer?.count),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        cutout: "80%",
        borderRadius: 15,
        // offset: 20,
        label: answers?.map((answer) => answer?.answer),
      },
    ],
  });
  const doughnutLabel = (totalCount) => ({
    id: "doughnutLabel",
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = " bold 30px Satoshi";
      ctx.fillStyle = "#000000";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(totalCount, xCoor, yCoor - 15);
      ctx.font = " 16px Satoshi";
      ctx.fillStyle = "#75787E";
      ctx.fillText(" Replies", xCoor, yCoor + 15);
    },
  });
  if (loading) return <LoadingPage />;

  return (
    <>
      <Header
        name={experience?.name}
        videoUrl={"https://www.youtube.com/watch?v=f7NwyBnIRTE"}
      />
      {experience?.status !== "LIVE" ? (
        <div className="banner">
          <p className="banner-description">
            You will be able to see the data once you publish your experience
          </p>
        </div>
      ) : noData ? (
        <div className="banner">
          <p className="banner-description">
            There are no data records for this experience at this time
          </p>
        </div>
      ) : (
        responses.length === 0 && (
          <div className="banner">
            <p className="banner-description">
              There are no data records for this experience at this time
            </p>
          </div>
        )
      )}
      {/* {experience?.status !== "LIVE" && (
        <div className="banner">
          <p className="banner-description">
            You will be able to see the data once you publish your experience
          </p>
        </div>
      )}
      {noData && (
        <div className="banner">
          <p className="banner-description">
            There are no data records for this experience at this time
          </p>
        </div>
      )}
      {responses.length === 0 && (
        <div className="banner">
          <p className="banner-description">
            There are no data records for this experience at this time
          </p>
        </div>
      )} */}
      <div className="response-page">
        <EditorBackButton
          styles={{
            marginTop: "20px",
            marginBottom: "10px",
          }}
          onClick={() => {
            navigate("/experiences");
          }}
        />
        <p className="title">Responses Experience</p>
        <p className="subtitle">View your customer's experience distribution</p>
        {experience?.status !== "LIVE" || noData || responses.length === 0 ? (
          <img
            src={ResponsePlaceHolder}
            className="response-placeholder"
            alt=""
          />
        ) : (
          <>
            <div className="cards-wrapper">
              {responses
                ?.filter((r) => r.activeQuestion)
                .map((response, idx) => {
                  const sortedAnswers = response?.answers?.sort(
                    (a, b) => b?.count - a?.count
                  );
                  return (
                    <>
                      <div
                        key={idx}
                        className={`cards-container ${
                          !response?.activeQuestion ? "not-active" : ""
                        }`}
                      >
                        <p className="question-number">
                          {"Question " + (idx + 1)}
                        </p>
                        <p
                          className="question"
                          style={{
                            direction: detectHebrew(response?.question)
                              ? "rtl"
                              : "ltr",
                          }}
                        >
                          {response?.question?.length > 60
                            ? response?.question?.slice(0, 60) + "..."
                            : response?.question}
                        </p>
                        <div className="donut-graph">
                          <Doughnut
                            data={data(response?.answers)}
                            options={options(idx)}
                            plugins={[doughnutLabel(response?.totalCount)]}
                          />
                          <div className="down-container">
                            <AnswerLabels answers={sortedAnswers} />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="delete-wrapper">
              <div>
                <p className="delete-heading">Deleted questions</p>
                <span className="delete-sub-heading">
                  The questions on the website were removed after the experience
                  was published
                </span>
              </div>
            </div>
            <div className="cards-wrapper">
              {responses
                ?.filter(
                  (ques) => !ques.activeQuestion && ques.question?.trim().length
                )
                ?.map((response, idx) => {
                  const sortedAnswers = response?.answers?.sort(
                    (a, b) => b?.count - a?.count
                  );
                  return (
                    <>
                      <div key={idx} className="cards-container">
                        <p className="question-number">
                          {!response.activeQuestion ? (
                            <span style={{ color: "#FF0000" }}>
                              Deleted question
                            </span>
                          ) : (
                            "Question " + (idx + 1)
                          )}
                        </p>
                        <p
                          className="question"
                          style={{
                            direction: detectHebrew(response?.question)
                              ? "rtl"
                              : "ltr",
                          }}
                        >
                          {response?.question?.length > 60
                            ? response?.question?.slice(0, 60) + "..."
                            : response?.question}
                        </p>
                        <div className="donut-graph">
                          <Doughnut
                            data={data(response?.answers)}
                            options={options(idx)}
                            plugins={[doughnutLabel(response?.totalCount)]}
                          />
                          <div className="down-container">
                            <AnswerLabels answers={sortedAnswers} />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};
const ANSWERS_PER_PAGE = 5;
function AnswerLabels({ answers = [] }) {
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(answers.length / ANSWERS_PER_PAGE);
  return (
    <>
      {answers
        .slice((page - 1) * ANSWERS_PER_PAGE, page * ANSWERS_PER_PAGE)
        ?.map((answerOBJ, answerIndex) => (
          <div key={answerIndex} className="labels-container">
            <div
              className="label-color"
              style={{
                backgroundColor: colors.slice(
                  (page - 1) * ANSWERS_PER_PAGE,
                  page * ANSWERS_PER_PAGE
                )?.[answerIndex],
              }}
            ></div>
            <p className="label-text">
              {answerOBJ?.answer?.length > 20
                ? answerOBJ?.answer.slice(0, 20) + "..."
                : answerOBJ?.answer}
            </p>
            <span className="divider" />
            <p className="count">{answerOBJ?.count}</p>
          </div>
        ))}
      {totalPages > 1 && (
        <div className="dots-pagination">
          {Array(totalPages)
            .fill()
            ?.map((_, idx) => (
              <>
                <img
                  key={idx}
                  onClick={() => setPage(idx + 1)}
                  src={page === idx + 1 ? BlueDot : Dots}
                  alt=""
                />
              </>
            ))}
        </div>
      )}
    </>
  );
}
export default ResponsePage;

const detectHebrew = (str) => {
  return /[\u0590-\u05FF]/.test(str);
};
