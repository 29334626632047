import {Dialog, Pane} from "evergreen-ui";
import {useState} from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";
import "./Dialog.scss";


let doNotShowDialogStorage =
    localStorage.getItem("doNotShowAgainDeleteProductDialog") && localStorage.getItem("doNotShowAgainDeleteProductDialog") !== "false";

const  DeleteProductDialog = ({ product, setProduct, deleteProduct,setForceRefetch }) => {
  
  const [doNotShowAgainTemporary, setDoNotShowAgainTemporary] = useState(false);
  const toggleDoNotShowAgain = () => {
    if (doNotShowAgainTemporary) {
      setDoNotShowAgainTemporary(false);
    }
    setDoNotShowAgainTemporary(true);
  };

  if(doNotShowDialogStorage){
    deleteProduct(product);
    return null;
  }

  return (
    <Pane>
      <Dialog
        isShown={product}
        onCloseComplete={() => setProduct(undefined)}
        width="500px"
        hasFooter={false}
        hasHeader={false}
        topOffset={window.innerHeight / 3}
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
      >
        <div className="dialog-content">
          <h4>Delete Product</h4>
          <p>
            Please note, while you’re deleting this product, it won’t longer be
            connected with the answers in the experience
          </p>
        </div>

        <div className="container">
          <div
            className="do-not-show-again-container"
            onClick={toggleDoNotShowAgain}
          >
            <Checkbox checked={doNotShowAgainTemporary} />
            <p>Do not show again</p>
          </div>
          <button
            onClick={() => setProduct(undefined)}
            className="cancel dialog-btn"
            style={{
              color: "#75787E",
              marginRight: "16px",
              cursor: "pointer",
              background: "none",
            }}
          >
            Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={async () => {
              await deleteProduct(product);
              if(doNotShowAgainTemporary) {
                localStorage.setItem("doNotShowAgainDeleteProductDialog", "true");
                doNotShowDialogStorage = true;
              }
              setForceRefetch(Math.random);
            }}
            style={{
              color: "#fff",
              background: "#E45447",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default DeleteProductDialog;
