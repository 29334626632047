import TagsIcon from "../pages/ExperiencesPage/components/TagsIcon";
import QuizIcon from "../pages/ExperiencesPage/components/QuizIcon";
import SwiperIcon from "../pages/ExperiencesPage/components/SwiperIcon";

const useExpTableProps = () => {

    const tableHeaders = [
        // "",
        "Experience Name",
        "Type",
        "Status",
        "Placed on",
        "Last edited",
        "",
    ];

    const displayStatus = (status) => {
        switch (status) {
            case "LIVE":
                return "Published";
            default:
                return "Unpublished";
        }
    }

    const experienceIcon = (type) => {
        switch (type) {
            case "TAGS_SELECTION":
                return <TagsIcon />;
            case "QUIZ":
                return <QuizIcon />;
            case "SWIPER":
                return <SwiperIcon />;
            default:
                return <QuizIcon />;
        }
    };

    const defaultEditOBJ = {
        name: "",
        implementationType: "",
        parentID: "",
        path: "",
        couponName: "",
        couponAmount: null,
    };

    const editorPrefix = (experience) => {
        if (experience?.type === "QUIZ") return "";
        if (experience?.type === "TAGS_SELECTION") return "tags-";
        return "swiper-";
    }


    return {
        tableHeaders,
        experienceIcon,
        defaultEditOBJ,
        displayStatus,
        editorPrefix
    }
}

export default useExpTableProps;