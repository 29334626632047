/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/Button/Button";
import React, {useEffect, useRef, useState} from "react";
import useAuth from "stores/auth";
import {useDesign} from "stores/design";
import Preview from "../EditorPage/components/Editor/Preview";
import Content from "./components/Content";
import "./ProductRecommendationPage.scss";
import {useNavigate} from "react-router-dom";
import recommendation1 from "../../assets/images/recommendation-image-1.png";
import recommendation2 from "../../assets/images/recommendation-image-2.png";
import recommendation3 from "../../assets/images/recommendation-image-3.png";
import info from "../../assets/icons/info-black.svg";
import exit from "../../assets/icons/exit-notification.svg";
import useEditor from "stores/use-editor";
import {LOCAL_STORAGE_PR_POP_UP} from "../../utils/finalVariables";
import EditorSavePopup from "../EditorPage/components/EditorSavePopup/EditorSavePopup";
import EditorBackButton from "../EditorPage/components/EditorBackButton";

export default function ProductRecommendationPage() {
  const navigate = useNavigate();
  const setMinimized = useAuth((state) => state.setMinimized);
  const designView = useEditor((state) => state.designView);
  const {
    afterActionRedirect,
    setRedirect,
    getDesign,
    loading,
    saveDesign,
    allRecommenderEventsSaved,
    showUnsavedChangesPopup,
    togglePopupState,
    setAllRecommenderEventsSaved,
    clearDesign,
  } = useDesign();
  const [showPopup, setShowPopup] = useState(false);
  const previewRef = useRef();
  const [recommendationCurrentIndex, setRecommendationCurrentIndex] =
    useState(0);

  useEffect(() => {
    // getDesign();
    handlePopup();
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.skeep?.destroyAll();
  }, [designView]);

  useEffect(() => {
    const minimized = useAuth.getState().minimized;
    setMinimized(true);
    return () => {
      setMinimized(minimized);
    };
  }, [setMinimized]);

  const { minimized } = useAuth();

  function handlePopup() {
    if (localStorage.getItem(LOCAL_STORAGE_PR_POP_UP) === "true") {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  }

  const recommendationList = [
    {
      id: 0,
      image: recommendation1,
      text: "We created a unified design for the product recommendation pages in order to make editing simple and provide a top-notch user experience",
    },
    {
      id: 1,
      image: recommendation2,
      text: "Review your product recommendation page on the right panel and edit it on the left panel",
    },
    {
      id: 2,
      image: recommendation3,
      text: "To navigate between pages, use the tabs bar ",
    },
  ];

  const handleBackButton = () => {
    if (allRecommenderEventsSaved) return navigate(-1);
    togglePopupState();
  };

  return (
    <div className="app-page editor-page pr-page">
      {showUnsavedChangesPopup && (
        <EditorSavePopup
          setShowBackAlert={togglePopupState}
          saveChangesAction={async () => {
            await saveDesign();
            setRedirect("");
            togglePopupState();
            navigate(afterActionRedirect ? afterActionRedirect : navigate(-1));
          }}
          onClickLeave={() => {
            togglePopupState();
            setAllRecommenderEventsSaved(true);
            navigate(afterActionRedirect ? afterActionRedirect : navigate(-1));
          }}
        />
      )}
      {showPopup && (
        <div
          className={
            minimized
              ? "product-recommendation-pop-container-minimized"
              : "product-recommendation-pop-container"
          }
        >
          <div className="product-recommendation-pop">
            <div className="product-recommendation-pop-upper-container">
              <div className="product-recommendation-pop-circle-container">
                {Array(3)
                  .fill(0)
                  .map((circle, i) => {
                    return (
                      <div
                        key={i}
                        className="product-recommendation-pop-circle"
                        style={{
                          backgroundColor:
                            recommendationCurrentIndex === i
                              ? "#017CF8"
                              : "#F3F5F7",
                        }}
                        onClick={() => setRecommendationCurrentIndex(i)}
                      ></div>
                    );
                  })}
              </div>
              <img
                src={exit}
                className="product-recommendation-pop-exit-icon"
                onClick={() => {
                  localStorage.setItem(LOCAL_STORAGE_PR_POP_UP, "true");
                  setShowPopup(false);
                }}
              />
            </div>
            <img
              src={recommendationList[recommendationCurrentIndex].image}
              className="product-recommendation-pop-image"
            />
            <p className="product-recommendation-pop-footer-text">
              {recommendationList[recommendationCurrentIndex].text}
            </p>
            <div className="product-recommendation-pop-button-container">
              {recommendationCurrentIndex !== 0 && (
                <Button
                  type="BUTTON"
                  text="Back"
                  style={{ backgroundColor: "transparent", color: "#75787E" }}
                  onClick={() =>
                    setRecommendationCurrentIndex(
                      recommendationCurrentIndex - 1
                    )
                  }
                />
              )}
              {recommendationCurrentIndex === recommendationList.length - 1 ? (
                <Button
                  type="SUBMIT"
                  text="Got it!"
                  onClick={() => {
                    setRecommendationCurrentIndex(0);
                    setShowPopup(false);
                    localStorage.setItem(LOCAL_STORAGE_PR_POP_UP, "true");
                  }}
                />
              ) : (
                <Button
                  type="SUBMIT"
                  text="Next"
                  onClick={() =>
                    setRecommendationCurrentIndex(
                      recommendationCurrentIndex + 1
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="fa-center app-page-header">
        <h1
          className="app-page-header-title title-edit"
          style={{ marginRight: "auto" }}
        >
          <span>Recommender</span>
        </h1>
        {/* <TourPopupTrigger handleOpenTour={handleOpenTour} /> */}
      </div>
      <div className="app-page-content">
        <EditorBackButton onClick={handleBackButton} />
        <div className="recommendation-header">
          <h1 className="page-title">Edit Results Page</h1>
          <img
            alt={"info"}
            style={{
              cursor: "pointer",
              marginLeft: -8,
              marginTop: -8,
            }}
            src={info}
            onClick={() => {
              setShowPopup(true);
            }}
          />
        </div>

        <h3 className="page-subheading">
          Review and refine how your product recommendation pages will look
          like.
        </h3>
        <div className="editor-container">
          <div className="editor" id="editor-recommender">
            <div className="tabs-content">
              <Content />
            </div>
          </div>
          <div className="dragMe"></div>
          <div className="preview" ref={previewRef}>
            <Preview previewOf={"recommendation"} loading={loading} designView={true} allRecommenderEventsSaved={allRecommenderEventsSaved} handleSaveChanges={saveDesign}/>
          </div>
        </div>
        <div className="buttons-container" onClick={() => {}}>
        </div>
      </div>

      {/* {showTour && (
        <TourPopup
          tourCurrentIndex={tourCurrentIndex}
          handleCurrentTourIndex={setTourCurrentIndex}
          handleOpenTour={handleOpenTour}
        />
      )} */}
    </div>
  );
}
