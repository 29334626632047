import {ReactComponent as Arrow} from "../../../assets/icons/arrow.svg";
import png from "../../../assets/images/png.webp";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import useEditor from "../../../stores/use-editor";
import useClickOutside from "../../../hooks/useClickOutside";
import {useEffect, useRef, useState} from "react";
import "../EditorPage.scss";
import {getColorTagBackground, willBeInView} from "utils/utls";

const ColorPickerWrapper = ({
  type,
  prefix,
  heading,
  item,
  heading2,
  handleColor,
  defaultColor,
  designOBJ,
  disableAlpha = true,
  obj,
}) => {
  const { colorPicker, setColorPicker } = useEditor();
  const colorPickerRef = useRef();
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    function listener() {
      if (colorPickerRef.current) {
        let top = colorPickerRef.current?.getBoundingClientRect().top + 60 ?? 0;
        if (!willBeInView(top)) top = top - 300;
        setPosition({
          top,
          left: colorPickerRef.current?.getBoundingClientRect().left ?? 0,
        });
      }
    }
    if (colorPickerRef.current) {
      const editorDiv = document.querySelector(".editor");
      let top = colorPickerRef.current?.getBoundingClientRect().top + 60 ?? 0;
      if (!willBeInView(top)) top = top - 300;
      setPosition({
        top,
        left: colorPickerRef.current?.getBoundingClientRect().left ?? 0,
      });

      editorDiv.addEventListener("scroll", listener);
      return () => {
        editorDiv.removeEventListener("scroll", listener);
      };
    }
  }, [item, colorPicker]);

  useClickOutside(colorPickerRef, () => {
    if (colorPicker) setColorPicker(null);
  });

  const finalColor = prefix
    ? heading2
      ? designOBJ?.[prefix]?.[heading]?.[heading2]?.[item]
      : designOBJ?.[prefix]?.[heading]?.[item]
    : designOBJ?.[heading]?.[item];
  const whiteColors = [
    "#FFF",
    "#fff",
    "#FFFF",
    "#ffff",
    "#FFFFF",
    "#fffff",
    "#FFFFFF",
    "#ffffff",
    "rgba(255, 255, 255, 0.0)",
  ];
  return (
    <div
      onClick={(e) => {
        handleColor(`${prefix}${heading}${item}`);
      }}
      className="colorTag"
      style={{ position: "relative" }}
      tabIndex={0}
      ref={colorPickerRef}
    >
      <span
        className="color"
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url(${png})`,
          backgroundSize: "cover",
          border: whiteColors.includes(finalColor) ? "1px solid #dbe1e7" : "",
        }}
      >
        <span
          className="color"
          style={{
            background: getColorTagBackground(finalColor) ?? defaultColor,
            width: "100%",
            height: "100%",
          }}
        />
      </span>
      <Arrow />
      <div
        style={{
          position: "absolute",
          left: type?.[heading]?.[item] === "gradientColor" ? 150 : 0,
          zIndex: 11,
        }}
      >
        {`${prefix}${heading}${heading2 ?? ""}${item}` === colorPicker && (
          <ColorPicker
            position={position}
            disableAlpha={disableAlpha}
            path={`${prefix ? prefix + "/" : ""}${heading}/${
              heading2 ? heading2 + "/" : ""
            }${item}`}
            item={
              heading2
                ? type?.[heading]?.[heading2]?.[item]
                : type?.[heading]?.[item]
            }
          />
        )}
      </div>
    </div>
  );
};

export default ColorPickerWrapper;
