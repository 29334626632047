import {updateObject} from "utils/utls";
import {ObjectID} from "bson";
import create from "zustand";
import {devtools} from "zustand/middleware";
import {EditorEventsState} from "./Types/EditorTypes";
import {OpenReplay} from "utils/OpenReplay";

export const useEditorEvents = create<EditorEventsState>()(
    devtools(
        (set, get) => ({
            events: [],

            /**
             *
             * @param event The event you want to mark as complete
             */
            completeEvent: (event) => {
                const index = get().events.indexOf(event);
                set({
                    events: [
                        ...get().events.slice(0, index),
                        {...event, status: "completed"},
                        ...get().events.slice(index + 1),
                    ],
                });
            },

            playEvents: (
                experience,
                updateExp,
                questions,
                updateQuestions,
                updateTags
            ) => {
                get()
                    .events.filter((e) => e.status !== "completed")
                    .forEach((event) => {
                        switch (event.key) {
                            case "design": {
                                const obj = updateObject(
                                    experience.design,
                                    Object.values(event.metadata)[0],
                                    Object.keys(event.metadata)[0]
                                );
                                experience.design = obj;
                                updateExp(experience);
                            }
                                break;

                            case "experience":
                                const obj = updateObject(
                                    experience,
                                    Object.values(event.metadata)[0],
                                    Object.keys(event.metadata)[0]
                                );
                                updateExp(obj);
                                break;
                            case "question":
                                if (event.type === "create") {
                                    const data = event.metadata;
                                    questions.push({
                                        id: data.id.toString(),
                                        question: "",
                                        type: data.type.toUpperCase(),
                                        isNew: true,
                                        answers: [
                                            {
                                                id: new ObjectID().toString(),
                                                answer: data.answer ?? "",
                                                tagIDs: [new ObjectID().toString()],
                                            },
                                            {
                                                id: new ObjectID().toString(),
                                                answer: data.answer ?? "",
                                                tagIDs: [new ObjectID().toString()],
                                            },
                                            {
                                                id: new ObjectID().toString(),
                                                answer: data.answer ?? "",
                                                tagIDs: [new ObjectID().toString()],
                                            },
                                            {
                                                id: new ObjectID().toString(),
                                                answer: data.answer ?? "",
                                                tagIDs: [new ObjectID().toString()],
                                            },
                                        ],
                                        origin: "BRAND",
                                    });
                                    updateQuestions([...questions], true);
                                } else if (event.type === "update") {
                                    const {quesIdx, ...data} = event.metadata;
                                    questions[quesIdx] = {
                                        ...questions[quesIdx],
                                        ...data,
                                    };
                                    updateQuestions([...questions], false);
                                } else if (event.type === "delete") {
                                    const index = event.metadata.quesIdx;
                                    questions = [
                                        ...questions.slice(0, index),
                                        ...questions.slice(index + 1),
                                    ];
                                    updateQuestions([...questions], false);
                                }
                                break;

                            case "tags":
                                if ((event.type = "update")) {
                                    const selectedTagsForRemoval = event.metadata;
                                    updateTags(selectedTagsForRemoval);
                                }
                                break;

                            default:
                                return;
                        }
                        get().completeEvent(event);
                    });
            },

            addEvents: (key, type, metadata) => {
                set({
                    events: [
                        ...get().events,
                        {
                            key,
                            type,
                            metadata,
                        },
                    ],
                });
            },

            clearEvents: () => {
                set({
                    events: [],
                });
            },
        }),
        {
            name: "use-editor-events", // unique name
        }
    )
);
