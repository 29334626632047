import ExploreButton from 'components/ExploreButton/ExploreButton';
import React from 'react'
import classes from "../../styles/OverviewPage.module.css";

export default function Header({name,handleOpenTour, showTour = true, videoUrl=""}) {
  return (
		<div className={classes.contentHeaderContainer}>
			<p className={classes.contentHeaderName}>{name} </p>
			{/* {showTour && <TourPopupTrigger handleOpenTour={handleOpenTour} />} */}
			{name === "Products" && (
				<ExploreButton
					videoUrl={"https://www.youtube.com/embed/30F-tafVII0"}
					name="Products"
				/>
			)}
		</div>
  );
}
