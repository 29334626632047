import {useState} from "react";
import {useOnboarding} from "../../../stores/use-onboarding";

const WelcomeStep = () => {
    const [ isShowCalender, setIsShowCalender ] = useState(false);
    const {setStep,step,setShowDemoBtn,showDemoBtn} = useOnboarding();


    return (
        <div className="onboarding-step">
            <div className="onboarding-titles-container" style={{ marginTop: '183px',width: "427px" ,fontFamily:"Satoshi" }}>
                <h1 style={{fontWeight:"700",lineHeight:"34px"}}>Welcome and thank you for choosing Skeep</h1>
                <p style={{ marginTop: '10px', fontSize: '20px',width: "547px",fontWeight:"500",lineHeight:"34px" }}>In a few easy steps, you’ll have a unique experience you can implement right away.
                    </p>
                <p style={{fontSize: '20px',width: "557px",fontWeight:"500",lineHeight:"34px" }}>

                For quick setup, we offer free onboarding meetings on demand
                </p>
            </div>
            <div className="collections-selection-container" style={{ display: 'flex', flexDirection: 'row', marginTop: isShowCalender ? '2vh' : '5vh' }}>
                <p
                    className="onboarding-step-button-primary"
                    style={{ backgroundColor: 'white', color: '#017CF8', border: '1px solid #017CF8' }}
                    //id={disablePrimaryButton && "disabled-button"}
                    onClick={() => setStep(1)}
                >
                    Let's get started!
                </p>
                <p
                    className="onboarding-step-button-primary"
                    //id={disablePrimaryButton && "disabled-button"}
                    style={isShowCalender ? { visibility: 'hidden' } : {}}
                    onClick={() => {setIsShowCalender(true);setShowDemoBtn(true)}}
                >
                    Book onboarding meeting
                </p>
            </div>
            { isShowCalender && <iframe style={{ minHeight: '53vh', border: 'none'  }} src="https://meetings-eu1.hubspot.com/omer-cohen/onboarding-to-skeep?embed=true"/> }
        </div>
    )
}

export default WelcomeStep;
