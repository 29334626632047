import {Button} from "evergreen-ui";
import React, {useMemo} from "react";
import useEditor from "../../../../stores/use-editor";
import useCustomTags from "../../../../stores/use-custom-tags";

const AnswerConnectedProducts = ({setShowProductModal, noConnectedProducts, noOfConnectedProducts, answerOBJ}) => {
    const {
        answersProductsCountFromServer,
        answersProductsCountLocal,
        connectedProductsLoading,
    } = useEditor();
    const {tags} = useCustomTags();
    const finalProductCount = useMemo(() => {
        if(connectedProductsLoading) return "-";
        if (tags[answerOBJ.tagIDs[0]]) return tags[answerOBJ.tagIDs[0]].products.length;
        if (answersProductsCountLocal[answerOBJ?.id]) return answersProductsCountLocal[answerOBJ?.id];
        if (answersProductsCountFromServer[answerOBJ?.id]) return answersProductsCountFromServer[answerOBJ?.id];
    }, [noOfConnectedProducts, answersProductsCountLocal, answersProductsCountFromServer, answerOBJ?.id, connectedProductsLoading])

    return (
        <div
            className="connect-products-answer-container"
            style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <Button
            border="none"
            color="white"
            background="#017CF8"
            borderRadius={7}
            appearance="primary"
            marginTop={10}
            onClick={() => setShowProductModal(true)}
        >
            Connect products
        </Button>
        <p className="connected-products-counter" style={{
            fontSize: 11, marginTop: 5, color: !finalProductCount ? "#F4B037" : ""
        }}>{finalProductCount ?? "0"} Connected Products</p>
    </div>)
}

export default AnswerConnectedProducts;