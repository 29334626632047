import {Button} from "evergreen-ui";
import React from "react";

const CreateDialogButton = ({
  text,
  style = {},
  onClick = () => {},
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      appearance="none"
      marginLeft="auto"
      display="flex"
      background={disabled ? "#F3F5F7" : "#017CF8"}
      color={disabled ? "#75787E" : "#fff"}
      border="none"
      fontSize="16px"
      paddingX="30px"
      paddingY="20px"
      paddingRight="0px"
      style={{ ...style }}
    >
      {text}
    </Button>
  );
};

export default CreateDialogButton;
