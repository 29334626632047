import {Client} from "Client";
import {Dialog, Pane} from "evergreen-ui";
import {useEffect, useState} from "react";
import useEditor from "stores/use-editor";

const LiveSaveDialog = ({ handleChanges, showDialog }) => {
  const [show, setShow] = useState(showDialog);
  const [doNotShow, setDoNotShow] = useState(false);
  const { experience, changeKeyValue } = useEditor();
  const liveModal = localStorage.getItem("showLiveModal");

  useEffect(() => {
    setShow(showDialog);
  }, [showDialog]);

  return (
    <Pane className={"dialog"}>
      <Dialog
        isShown={show && liveModal !== "false"}
        onCloseComplete={() => setShow(false)}
        title="Edit Answer"
        hasFooter={false}
        hasHeader={false}
        width="540px"
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
        topOffset={window.innerHeight / 2 - 200}
      >
        <div className="dialog-content">
          <h4 style={{ fontWeight: 500 }}>One last thing…</h4>
          <p
            style={{
              padding: "20px 0 40px 0",
              color: "#000",
              lineHeight: "24px",
            }}
          >
            We see you are done editing, Would you like to take the experience
            back to live?
          </p>
        </div>
        <div className="container" style={{ margin: " -10px -25px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "40px",
            }}
          >
            <div
              className="products-checkbox-container"
              style={{ height: "20px", width: "20px", borderRadius: "6px" }}
              onClick={() => {
                setDoNotShow(!doNotShow);
              }}
            >
              {doNotShow && (
                <div
                  className="products-checkbox"
                  style={{ height: "10px", width: "10px" }}
                ></div>
              )}
            </div>
            <p
              style={{ marginLeft: "6px", fontSize: "14px", color: "#75787E" }}
            >
              Do not show me again
            </p>
          </div>
          <button
            onClick={() => {
              setShow(false);
              handleChanges();
              localStorage.setItem(
                "showLiveModal",
                doNotShow ? "false" : "true"
              );
            }}
            className="cancel dialog-btn"
            style={{
              color: "#017CF8",
              marginRight: "16px",
              cursor: "pointer",
              background: "none",
            }}
          >
            No, I’ll do it later
          </button>
          <button
            className="confirm dialog-btn"
            onClick={async () => {
              changeKeyValue("status", "LIVE");
              setShow(false);
              handleChanges();
              localStorage.setItem(
                "showLiveModal",
                doNotShow ? "false" : "true"
              );
              await Client.put(`/emp/experiences/${experience?.id}/status`, {
                status: "LIVE",
              });
            }}
            style={{
              background: "#017CF8",
              marginRight: "16px",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            Yes
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};
export default LiveSaveDialog;
