
export default function TagsIcon() {
    return (
        <svg width="45" height="45" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="35.8738" height="35.8738" rx="6" fill="#CEC6F6"/>
            <rect x="3.9375" y="13.9995" width="7.43725" height="7.43725" rx="3.71863" fill="#F4B037"/>
            <rect x="3.9375" y="23.6243" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
            <rect x="3.9375" y="4.37476" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
            <rect x="14.437" y="13.9995" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
            <rect x="14.437" y="23.6243" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
            <rect x="14.437" y="4.37476" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
            <rect x="24.9365" y="13.9995" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
            <rect x="24.9365" y="23.6243" width="7.43725" height="7.43725" rx="3.71863" fill="#F4B037"/>
            <rect x="24.9365" y="4.37476" width="7.43725" height="7.43725" rx="3.71863" fill="white"/>
        </svg>
    )
}