import { Pane, toaster } from "evergreen-ui";
import { useEffect, useState } from "react";
import ExpPlacementTypeSlider from "../../ExperiencesPage/components/ExpPlacementTypeSlider";
import useEditor from "../../../stores/use-editor";
import { Client } from "../../../Client";
import NavigationButtons from "../components/NavigationButtons";
import { useNavigate } from "react-router-dom";
import previewURL from "../../../utils/previewURL";
import delay from "../../../utils/delay";
import PlacementWizard from "../../../components/PlacementWizard/PlacementWizard";
import StepHeader from "./StepHeader";
import EmbeddedPage from "../../../assets/images/embedded-page.png";

const defaultEditOBJ = {
  name: "",
  implementationType: "",
  parentID: "",
  path: "",
  couponName: "",
  couponAmount: null,
};

const SelectPlacements = () => {
  const navigate = useNavigate();
  const { experience, resetInitialState } = useEditor();
  const [editExpOBJ, setEditExpOBJ] = useState(defaultEditOBJ);
  const implementationType =
    editExpOBJ?.implementationType ||
    experience?.locations[0]?.implementationType;
  const [brand, setBrand] = useState({});
  const [showEmbeddedGuide, setShowEmbeddedGuide] = useState(false);
  const embeddedPage = "EMBEDDED_PAGE" === implementationType;

  useEffect(() => {
    Client("/emp/brand").then((res) => {
      setBrand(res.data);
    });
  }, []);

  const handleCtaClick = async () => {
    if (embeddedPage && !showEmbeddedGuide) return setShowEmbeddedGuide(true);

    // IF POPUP
    await updateEXP({ editExpOBJ, experience });

    Client.put(`/emp/experiences/${experience?.id}/status`, {
      status: "LIVE",
    })
      .then(() => {
        navigate(`/experiences`);
      })
      .catch(() => {
        toaster.danger("Failed to publish experience, please try again later.");
      });
  };

  const changeExp = (keyName, value) => {
    setEditExpOBJ((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };

  const handleSkipToEditorClick = () => {
    updateEXP({ editExpOBJ, experience })
      .then(() => {
        resetInitialState();
        navigate(`/experiences/edit/${experience?.id}/content`);
      })
      .catch(() => {
        toaster.danger("Failed to update experience, please try again later.");
      });
  };
  const defaultValueTweaked =
    editExpOBJ?.path === "/" ? "Homepage" : editExpOBJ?.path;

  const previewExperience = () => {
    window.open(previewURL(experience, brand), "_blank");
  };

  return (
    <div
      className="onboarding-step"
      style={{ justifyContent: "flex-start", gap: 6 }}
    >
      <StepHeader />
      <div className="onboarding-titles-container">
        <h2>Last but not least,</h2>
        <p>You can view the preview and implement the quiz anywhere in your website</p>
      </div>

      <PlacemenetsContent
        implementationType={implementationType}
        changeExp={changeExp}
        editExpOBJ={editExpOBJ}
        embeddedPage={embeddedPage}
        showEmbeddedGuide={showEmbeddedGuide}
        defaultValueTweaked={defaultValueTweaked}
      />

      <NavigationButtons
        primaryButtonText={
          embeddedPage && !showEmbeddedGuide ? "Next" : "Publish quiz"
        }
        handleNextButtonClick={handleCtaClick}
        handleSkipToEditorClick={handleSkipToEditorClick}
        secondaryButtonText=""
        // disablePrimaryButton={!selectedCollection}
        style={{ marginTop: "auto" }}
        showPreviewButton
        previewExperience={previewExperience}
      />
    </div>
  );
};

export default SelectPlacements;

const PlacemenetsContent = ({
  editExpOBJ,
  showEmbeddedGuide,
  changeExp,
  implementationType,
  embeddedPage,
  defaultValueTweaked,
}) => {
  const codeSnippet = ' <div id="quiz" style="height:580px;width:100%" /> ';
  const [codeWasCopied, setCodeWasCopied] = useState(false);

  const copyCodeSnippet = () => {
    navigator.clipboard.writeText(codeSnippet).then(async (r) => {
      setCodeWasCopied(true);
      await delay(4000);
      setCodeWasCopied(false);
    });
  };

  if (showEmbeddedGuide) {
    return (
      <Pane display="flex" flexDirection="column" gap={20} marginTop={20}>
        <p style={{ fontWeight: 600 }}>Follow the steps</p>
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/1icYmZ8c-mE"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe> */}
        <img src={EmbeddedPage} alt="" />
        <div className="embedded-bullets" onClick={copyCodeSnippet}>
          <p>
            1. Go to your Shopify store and pick the page where you want the
            experience to appear, it optional to create a new page in your store
            for the experience.
          </p>
          <p>
            2. Copy
            {codeWasCopied ? (
              <span className="code-snippet" id="copied-snippet">
                Code snippet copied!
              </span>
            ) : (
              <span className="code-snippet">
                {codeSnippet} <CopySVG />
              </span>
            )}
            and paste it in the content.
          </p>
        </div>
      </Pane>
    );
  }

  return (
    <>
      <div className="">
        <p
          style={{
            marginBottom: 10,
            marginTop:10,
            fontSize: 16,
            color: "black",
            fontWeight: 600,
          }}
        >
         How would you like to place your quiz?
        </p>
        <ExpPlacementTypeSlider
          onChange={(type) => changeExp("implementationType", type)}
          selected={implementationType}
          trimmedPlacements
        />
      </div>
      <div className="">
        <p
          style={{
            marginBottom: 10,
            fontSize: 16,
            color: "black",
            fontWeight: 600,
          }}
        >
         Select the place for the quiz
        </p>
        <PlacementWizard
        embeddedPage={embeddedPage}
        showEmbeddedGuide={showEmbeddedGuide}
          setSelectedPlacement={(obj) => {
            changeExp("path", obj?.handle);
          }}
          selectedPlacementPath={editExpOBJ?.path}
        />
      </div>
    </>
  );
};

const CopySVG = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="black"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 0C3.02208 0 0 3.02208 0 6.75V12.75C0 13.1642 0.335786 13.5 0.75 13.5C1.16421 13.5 1.5 13.1642 1.5 12.75V6.75C1.5 3.85051 3.85051 1.5 6.75 1.5H12.75C13.1642 1.5 13.5 1.16421 13.5 0.75C13.5 0.335786 13.1642 0 12.75 0H6.75ZM7.75 4C5.67893 4 4 5.67893 4 7.75V13.75C4 15.8211 5.67893 17.5 7.75 17.5H13.75C15.8211 17.5 17.5 15.8211 17.5 13.75V7.75C17.5 5.67893 15.8211 4 13.75 4H7.75ZM5.5 7.75C5.5 6.50736 6.50736 5.5 7.75 5.5H13.75C14.9926 5.5 16 6.50736 16 7.75V13.75C16 14.9926 14.9926 16 13.75 16H7.75C6.50736 16 5.5 14.9926 5.5 13.75V7.75Z"
      />
    </svg>
  );
};

const updateEXP = async ({ editExpOBJ, experience }) => {
  return Client.put(`emp/experiences/${experience?.id}`, {
    name: editExpOBJ?.name || experience?.name,
    locations: [
      {
        path: editExpOBJ?.path || experience.locations[0].path,
        implementationType:
          editExpOBJ.implementationType ||
          experience.locations[0].implementationType,
        settings: {
          parentID:
            editExpOBJ?.implementationType ||
            experience.locations[0].implementationType === "EMBEDDED_PAGE"
              ? "quiz"
              : null,
          delay:
            editExpOBJ?.delay ||
            experience.locations[0]?.settings?.delay ||
            null,
        },
      },
    ],
    coupon: {
      name: editExpOBJ?.couponName || experience.coupon.name,
      amount: editExpOBJ?.couponAmount || experience.coupon.amount,
    },
  });
};
