import React, {useEffect, useState} from "react";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {Client} from "../../Client";
import "./CustomTags.scss";

function CustomTagsInput({
  tag,
  deleteTag,
  index,
  notEdit = false,
  isRewardType,
  tagOK = false,
  forceStyle = {},
}) {
  const [rewardName, setRewardName] = useState("");

  useEffect(() => {
    const getReward = async () => {
      const { data } = await Client.get(`/rewards/${tag}`);
      setRewardName(data?.code);
    };

    if (isRewardType) getReward();
  }, []);

  return (
    <div
      className={`tag-input ${!notEdit ? "edit" : ""} ${
        tagOK ? "tags-OK" : ""
      }`}
      style={{
        ...forceStyle,
        ...tagOK ? {
        backgroundColor:"#F0FAEB",
          color:"#5BB531"
        } : {}}}
    >
      <span className="tag-input-value">{rewardName ? rewardName : tag}</span>
      {!notEdit && (
        <span className="tag-input-close" onClick={() => deleteTag(index)}>
          <Close />
        </span>
      )}
    </div>
  );
}

export default CustomTagsInput;
