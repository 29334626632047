import { Dialog, Pane, toaster } from "evergreen-ui";
import "./ExpPlacementsModal.scss";
import {
  basic,
  free,
  platinum,
  premium,
  skeepx,
} from "../../../utils/billingPricing";
import changeBrandsPlan from "../../../utils/changeBrandsPlan";
import BillingPlansCardNew from "../../../components/BillingPlansCardNew/BillingPlansCardNew";
import useAuth from "../../../stores/auth";

const UpgradeBillingModal = ({ closeModal, isUpgradePlanModalOpen, from }) => {
  const { accountOBJ } = useAuth();
  const listOfPlans = [free, basic, premium, platinum, skeepx];
  const indexOfCurrentPlan = listOfPlans?.findIndex(
    (planOBJ) =>
      planOBJ?.name_uppercase ===
      accountOBJ?.plan?.replaceAll(" ", "")?.toUpperCase()
  );

  return (
    <>
      <Dialog
        isShown={isUpgradePlanModalOpen}
        width="fit-content"
        onCloseComplete={() => {
          closeModal();
        }}
        topOffset="160px"
        hasFooter={false}
        hasHeader={false}
        overlayProps={{
          zIndex:21
        }}
        contentContainerProps={{ padding: "30px" }}
        containerProps={{
          // maxWidth: "700px",
          minHeight:"600px",
          borderRadius: "16px",
        }}
      >
        <div className="exp-placements-modal">
          <Pane
            display="flex"
            flexDirection="column"
            justifyItems="center"
            width="100%"
          >
            <p
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 24,
                right: 24,
                fontSize: 24,
                color: "grey",
                zIndex: "100",
              }}
              onClick={closeModal}
            >
              &times;
            </p>
            <h1 className="placement-heading">
              {from === "placement" ? "Upgrade plan" : "Limit reached"}
            </h1>
            <p style={{ fontSize: 14, color: "grey", textAlign: "start" }}>
              {from === "placement"
                ? "Upgrade to one of the following plans to unlock this feature"
                : "You’ve reached to the limit of published experience. to have more please upgrade your plan"}
            </p>
            <div className="billing-plans-modal-list">
              {listOfPlans
                ?.slice(indexOfCurrentPlan + (from === "placement" ? 2 : 1))
                ?.map((planOBJ) => (
                  <BillingPlansCardNew
                    key={planOBJ?.name}
                    info={planOBJ}
                    disabledButton={false}
                    type="MINI-CARD"
                    style={{
                      card: {
                        height: 240,
                      },
                      button: {
                        marginTop: "auto",
                      },
                    }}
                    miniCardSubtitle={
                      (planOBJ?.maxPublishedQuizzes || "Unlimited") +
                      " published experience"
                    }
                    // currentPlan={platinum.name_uppercase === billingInformation.plan}
                    // billing={billingInformation}
                    // selected={platinum.name === selectedPlanName}
                    handleSelectedPlan={(selected) =>
                      changeBrandsPlan({
                        selected,
                        billingInformation: accountOBJ,
                        setDisableButton: () => null,
                        init: () => {
                          toaster.success("Plan changed successfully");
                          closeModal();
                        },
                      })
                    }
                  />
                ))}
            </div>
            <p
              className="cta-link"
              style={{ color: "#007CF8" }}
              onClick={() => {
                window.location.href = "/billing/plans?table=true";
              }}
            >
              Compare all features
            </p>
          </Pane>
        </div>
      </Dialog>
      {/* <div className="billing-placements-modal-overlay" /> */}
    </>
  );
};

export default UpgradeBillingModal;
