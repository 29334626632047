import React, {useEffect, useState} from "react";
import "./BillingPlanPageNew.scss";
import moment from "moment";
import {
    allPlans,
    basic,
    calcExtraEng,
    free,
    orderOfPlans,
    platinum,
    premium,
    skeepx,
} from "../../utils/billingPricing";

//components
import Header from "components/Header/Header";

import {useNavigate, useSearchParams} from "react-router-dom";

import {Client} from "Client";
import LoadingPage from "../LoadingPage/LoadingPage";
import BillingPlansCardNew from "components/BillingPlansCardNew/BillingPlansCardNew";
import BillingPlansToggleOpen from "components/BillingPlansToggleOpen/BillingPlansToggleOpen";
import useAuth from "stores/auth";
import UnsuccessfulLoadData from "components/UnsuccessfulLoadData/UnsuccessfulLoadData";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import changeBrandsPlan from "../../utils/changeBrandsPlan";

export default function BillingPlansPageNew() {
  const { minimized } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [billingInformation, setBillingInformation] = useState([]);
  const [isDowngradeModelVisible, setIsDowngradeModelVisible] = useState(false);
  const [selectedPlanName, setSelectedPlanName] = useState("");
  const [confirmedSelectedPlan, setConfirmedSelectedPlan] = useState(false);
  const [disabledButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [toggleOpen, setToggleOpen] = useState(searchParams.get("table"));
  const [unsuccessfulLoad, setUnsuccessfulLoad] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const [tourCurrentIndex, setTourCurrentIndex] = useState(0);
  const [selectedPlanOBJ, setSelectedPlanOBJ] = useState({});
  const [currentPlanOBJ, setCurrentPlanOBJ] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(()=>{
   setToggleOpen(true)
  },[])
  
  function handleCurrentTourIndex(index) {
    setTourCurrentIndex(index);
  }

  function handleOpenTour(value) {
    setTourCurrentIndex(0);
    setShowTour(value);
  }

  const handleSelectedPlan = (plan) => {
    if (!(plan === "SkeepX" && billingInformation?.hasCustomPlan)) {
     setConfirmedSelectedPlan(true);
    }

    // Check if user is downgrading his plan, if so, open modal
    const indexOfCurrentPlan = orderOfPlans?.indexOf(billingInformation?.plan);
    const indexOfSelectedPlan = orderOfPlans?.indexOf(plan?.toUpperCase());

    if (indexOfSelectedPlan < indexOfCurrentPlan) {
      const selectedPlanOBJ = Object.keys(allPlans)
        .map((planName) => allPlans[planName])
        .find((planOBJ) => planOBJ.name_uppercase === plan.toUpperCase());
      setSelectedPlanName(plan);
      setSelectedPlanOBJ(selectedPlanOBJ);
      setIsDowngradeModelVisible(true);
    } else {
      setSelectedPlanName(plan);
      changeBrandsPlan({selected:plan, billingInformation, setDisableButton, init});
    }
  };

  useEffect(() => {
    setUnsuccessfulLoad(false);
    init();
    setTimeout(() => setLoading(false), 3000);
  }, []);

  function init() {
    Client.get("/emp/billing")
      .then((res) => {
        setBillingInformation(res.data);
        
        setDisableButton(false);
        const grabCurrentPlanOBJ = Object.keys(allPlans)
          .map((planName) => allPlans[planName])
          .find(
            (planOBJ) =>
              planOBJ.name_uppercase === res?.data?.plan?.toUpperCase()
          );
        setCurrentPlanOBJ(grabCurrentPlanOBJ);
      })
      .catch((error) => {
        setDisableButton(false);
        setUnsuccessfulLoad(true);
      });

    Client.get("/emp/users").then((res) => {
      setTeamMembers(res.data);
    });
  }

  const saveChanges = (option) => {
    if (selectedPlanName !== "") {
      setConfirmedSelectedPlan(option);
    } else {
      navigate(-1);
    }
  };

  const handleToggleOpen = () => {
    setToggleOpen(false);
  };

  function handleBackButton() {
    if (selectedPlanName === "SkeepX") {
      setSelectedPlanName("");
      setConfirmedSelectedPlan(false);
    } else {
      navigate(-1);
    }
  }

  return (
    <div className="app-page">
      {isDowngradeModelVisible && (
        <DowngradeModal
          minimized={minimized}
          selectedPlanOBJ={selectedPlanOBJ}
          teamMembers={teamMembers}
          billingInformation={billingInformation}
          changeBrandsPlan={(selected) => changeBrandsPlan({selected, billingInformation, setDisableButton, init})}
          selectedPlanName={selectedPlanName}
          setSelectedPlanName={setSelectedPlanName}
          currentPlanOBJ={currentPlanOBJ}
          setTeamMembers={setTeamMembers}
          setIsDowngradeModelVisible={setIsDowngradeModelVisible}
        />
      )}
      {confirmedSelectedPlan && selectedPlanName === "SkeepX" && (
        <div
          className={
            minimized
              ? "app-billing-plan-pop-container-minimized"
              : "app-billing-plan-pop-container"
          }
        >
          <div
            className={
              selectedPlanName === "SkeepX" && !billingInformation?.hasCustomPlan
                ? "app-billing-plan-pop app-billing-plan-pop-skeepx"
                : "app-billing-plan-pop"
            }
          >
            {selectedPlanName !== "SkeepX" ? (
              <p className="app-billing-plan-header">Save changes</p>
            ) : (
              <p className="app-billing-plan-header">
                Welcome to{" "}
                <span className="app-billing-plan-header app-billing-plan-header-skeepx">
                  SkeepX!
                </span>
              </p>
            )}
            <p className="app-billing-plan-info">
              {selectedPlanName !== "SkeepX"
                ? 'Click on "Confirm" button to save your changes or click "Go back"'
                : "Our team will contact you shortly to match a customized plan for you"}
            </p>
            <div className="app-billing-plan-buttons-container">
              <button
                onClick={() => handleBackButton()}
                className="app-billing-plan-cancel-button"
              >
                Go back
              </button>
              <button
                onClick={() => {
                  changeBrandsPlan(selectedPlanName);
                  setConfirmedSelectedPlan(false);
                }}
                className="app-billing-plan-confirm-button"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      {/* {showTour && (
        <TourPopup
          tourCurrentIndex={tourCurrentIndex}
          handleCurrentTourIndex={handleCurrentTourIndex}
          handleOpenTour={handleOpenTour}
        />
      )} */}
      <Header name="Billing" handleOpenTour={handleOpenTour} />
      {loading ? (
        <LoadingPage />
      ) : unsuccessfulLoad ? (
        <UnsuccessfulLoadData />
      ) : toggleOpen ? (
        <BillingPlansToggleOpen
          billing={billingInformation}
          toggleOpen={toggleOpen}
          handleToggleOpen={handleToggleOpen}
          disabledButton={disabledButton}
          currentPlan={billingInformation.plan}
          selected={selectedPlanName}
          handleSelectedPlan={handleSelectedPlan}
        />
      ) : (
        <>
          <div className="app-billing-header-container">
            <p className="app-billing-header">
              {billingInformation?.hasCustomPlan ? `This plan is tailor made${" "}` : `Choose the plan that is right${" "}`}
              <span className="app-billing-header-italic">for you</span>
            </p>
          </div>
          <div className="app-billing-card-container">
            {
             (billingInformation?.plan==="Skeep X"||billingInformation?.hasCustomPlan) ?
              <BillingPlansCardNew
              disabledButton={disabledButton}
              currentPlan={skeepx.name_uppercase === billingInformation.plan}
              info={skeepx}
              billing={billingInformation}
              selected={skeepx.name === selectedPlanName}
              handleSelectedPlan={handleSelectedPlan}
            />:
<>


            <BillingPlansCardNew
              disabledButton={disabledButton}
              currentPlan={premium.name_uppercase === billingInformation.plan}
              info={premium}
              billing={billingInformation}
              selected={premium.name === selectedPlanName}
              handleSelectedPlan={handleSelectedPlan}
            />
            <BillingPlansCardNew
              disabledButton={disabledButton}
              currentPlan={platinum.name_uppercase === billingInformation.plan}
              info={platinum}
              billing={billingInformation}
              selected={platinum.name === selectedPlanName}
              handleSelectedPlan={handleSelectedPlan}
            />
            <BillingPlansCardNew
              disabledButton={disabledButton}
              currentPlan={skeepx.name_uppercase === billingInformation.plan}
              info={skeepx}
              billing={billingInformation}
              selected={skeepx.name === selectedPlanName}
              handleSelectedPlan={handleSelectedPlan}
            />
            </>
            }
          </div>
          <div className="app-billing-toggle-container">
            <button
              className="app-billing-toggle-button"
              onClick={() => setToggleOpen(true)}
            >
              Compare plan features
            </button>
          </div>
        </>
      )}
    </div>
  );
}
const DowngradeModal = ({
  setIsDowngradeModelVisible,
  setTeamMembers,
  minimized,
  selectedPlanOBJ,
  teamMembers,
  billingInformation,
  changeBrandsPlan,
  selectedPlanName,
  setSelectedPlanName,
  currentPlanOBJ,
}) => {
  const teamMembersLength = teamMembers?.length;
  const selectedPlanLessSeatsThanCurrentTeam =
    selectedPlanOBJ?.users_max < teamMembersLength;
  const thisUser = localStorage.getItem("EMAIL");
  const numberOfUsersNeedToBeDeleted =
    teamMembersLength - selectedPlanOBJ?.users_max;
  const nextBillingDate = moment(
    new Date(billingInformation?.endBillingPeriod)
  ).format("LL");

  const handleDeleteMemberConfirm = (memberID) => {
    Client.delete(`/emp/users/${memberID}`)
      .then((res) => {
        setTeamMembers(
          teamMembers.filter((memberOBJ) => memberOBJ.id !== memberID)
        );
      })
      .catch(() => {
        alert("There was a problem deleting this member.");
      });
  };

  const percentageChangeOfEngagement = () => {
    var discount =
      1 - selectedPlanOBJ.max_engagements / currentPlanOBJ.max_engagements;
    var option = {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };

    var formatter = new Intl.NumberFormat("en-US", option);
    return formatter.format(discount);
  };

  return (
    <div
      className={
        minimized
          ? "app-billing-plan-pop-container-minimized"
          : "app-billing-plan-pop-container"
      }
    >
      <div className={"app-billing-plan-pop app-billing-plan-pop-downgrade"}>
        <p className="app-billing-plan-header">
          Change plan - {selectedPlanOBJ.name} ${selectedPlanOBJ.pricing}
        </p>
        {/*<p className='app-billing-plan-info' style={{marginTop:"10px"}}>Please note that the chosen plan "{selectedPlanOBJ.name}" includes only {selectedPlanOBJ.max_engagements} engagements, {percentageChangeOfEngagement()} less than what you currently have{selectedPlanLessSeatsThanCurrentTeam && `, and only ${selectedPlanOBJ?.users_max} user${selectedPlanOBJ?.users_max > 1 ?"s":""} - to continue please choose ${teamMembersLength - selectedPlanOBJ?.users_max} user${teamMembersLength - selectedPlanOBJ?.users_max > 1?"s":""} to deactivate.`}</p>*/}
        {billingInformation?.engagements > selectedPlanOBJ?.max_engagements && (
          <div>
            <p style={{ fontWeight: 600, marginTop: 20 }}>Additional Payment</p>
            <p className="app-billing-plan-info">
              You currently have {billingInformation?.engagements} engagements,{" "}
              {billingInformation?.engagements -
                selectedPlanOBJ?.max_engagements}{" "}
              more than the new plan max engagements of{" "}
              {selectedPlanOBJ?.max_engagements}, therefor you will be charged $
              {selectedPlanOBJ?.price_per_over_engagement} for each extra
              engagement, in total of:{" "}
              <span style={{ color: "#E45447", fontWeight: 600 }}>
                {" "}
                {billingInformation?.engagements -
                  selectedPlanOBJ?.max_engagements}{" "}
                x {selectedPlanOBJ?.price_per_over_engagement} ={" "}
                {calcExtraEng(
                  billingInformation?.startBillingPeriod,
                  billingInformation?.engagements,
                  selectedPlanOBJ?.max_engagements,
                  selectedPlanOBJ?.price_per_over_engagement
                )}
              </span>
            </p>
          </div>
        )}
        {selectedPlanLessSeatsThanCurrentTeam && (
          <>
            <p style={{ fontWeight: 600, marginTop: 20 }}>Manage Users</p>
            {numberOfUsersNeedToBeDeleted !== 0 && (
              <p className="app-billing-plan-info">
                Please note that the billing plan that you've selected "
                {selectedPlanOBJ?.name}" has max {selectedPlanOBJ?.users_max}{" "}
                user,your brand currently have {teamMembersLength} users. Please
                remove {numberOfUsersNeedToBeDeleted}{" "}
                {numberOfUsersNeedToBeDeleted > 1 ? "More" : ""} member
                {numberOfUsersNeedToBeDeleted > 1 ? "s" : ""}.
              </p>
            )}
            <div className="billing-user-card-grid">
              {teamMembers?.map((memberOBJ) => {
                return (
                  <div className="billing-user-card">
                    <div className="billing-user-card-property-box">
                      <h5>Name</h5>
                      <p style={{ color: "gray" }}>{memberOBJ?.fullName}</p>
                    </div>
                    <div className="billing-user-card-property-box">
                      <h5>Email</h5>
                      <p style={{ color: "gray" }}>{memberOBJ?.email}</p>
                    </div>
                    {(thisUser !== memberOBJ?.email || !memberOBJ.root) && (
                      <div className="billing-user-card-property-box">
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          color="#E45447"
                          style={{ cursor: "pointer", width: "fit-content" }}
                          onClick={() =>
                            handleDeleteMemberConfirm(memberOBJ?.id)
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {numberOfUsersNeedToBeDeleted !== 0 && (
          <p style={{ marginBottom: 20 }}>
            To continue, click on the button below. Change will apply after your
            next billing date {nextBillingDate}.
          </p>
        )}
        <div className="app-billing-plan-buttons-container">
          <button
            onClick={() => {
              setIsDowngradeModelVisible(false);
              setSelectedPlanName("");
            }}
            className="app-billing-plan-cancel-button"
            style={{
              width: "150px",
              cursor: "pointer",
            }}
          >
            Keep current plan
          </button>
          <button
            onClick={() => {
              if (
                selectedPlanLessSeatsThanCurrentTeam &&
                numberOfUsersNeedToBeDeleted !== 0
              )
                return;
              changeBrandsPlan(selectedPlanName);
            }}
            className="app-billing-plan-confirm-button"
            style={{
              width: "150px",
              cursor: "pointer",
              backgroundColor:
                selectedPlanLessSeatsThanCurrentTeam &&
                numberOfUsersNeedToBeDeleted !== 0
                  ? "gray"
                  : "",
            }}
          >
            Change plan
          </button>
        </div>
      </div>
    </div>
  );
};
