import "./PlacementWizard.scss";
import { useState } from "react";
import { Pane, Position, Tooltip } from "evergreen-ui";
import usePlacements from "./usePlacements";
import SearchInput from "../../pages/Onboarding/components/SearchInput";
import useAuth from "stores/auth";
import { Loader } from "@mantine/core";
const PlacementWizard = ({
  setSelectedPlacement,
  selectedPlacementPath,
  embeddedPage,
  showEmbeddedGuide,
}) => {
  const { allExperiences } = useAuth();
  const [activeTab, setActiveTab] = useState(
    selectedPlacementPath?.includes("collections")
      ? COLLECTION
      : selectedPlacementPath?.includes("products")
      ? PRODUCT_PAGE
      : PAGE
  );

  const [searchInput, setSearchInput] = useState("");
  const { placements, loading, reload, setReload } = usePlacements({
    activeTab,
  });
  const listUI = searchInput
    ? placements[activeTab].filter((obj) =>
        obj?.title?.toLowerCase().includes(searchInput.toLowerCase())
      )
    : placements[activeTab];

  const setHandle = (obj) => {
    if (activeTab === COLLECTION) return `/collections/${obj.handle}`;
    if (activeTab === PAGE && obj?.title !== "Homepage")
      return `/pages/${obj.handle}`;
    if (activeTab === PRODUCT_PAGE) return `/products/${obj.handle}`;
    return obj.handle;
  };

  const filterExperiences = allExperiences?.filter(
    (experience) => experience.status !== "ARCHIVED"
  );

  const liveExperiencesPaths = filterExperiences
    ?.filter((el) => el.status === "LIVE")
    ?.map((el) => el.locations?.[0]?.path);

  const modifiedPaths = liveExperiencesPaths?.reduce(
    (acc, cur) => {
      if (cur.startsWith("/pages")) {
        return {
          ...acc,
          page: [...acc["page"], cur.split("/pages/")[1]],
        };
      } else if (cur.startsWith("/collections")) {
        return {
          ...acc,
          collection: [...acc["collection"], cur.split("/collections/")[1]],
        };
      } else if (cur.startsWith("/products")) {
        return {
          ...acc,
          product: [...acc["product"], cur.split("/products/")[1]],
        };
      } else {
        return acc;
      }
    },
    {
      page: [],
      collection: [],
      product: [],
    }
  );

  const handleReload = () => {
    setReload(true);
  };



  return (
    <>
      <div className="placement-wizard">
        <div className="create-exp-tabs-group">
          <button
            name={COLLECTION}
            onClick={(e) => {
              setActiveTab(e.target.name);
              setReload(false);
            }}
            className={
              activeTab === COLLECTION ? "create-exp-tabs-group-active" : ""
            }
          >
            Collection page
          </button>
          <button
            name={PRODUCT_PAGE}
            onClick={(e) => {
              setActiveTab(e.target.name);
              setReload(false);
            }}
            className={
              activeTab === PRODUCT_PAGE ? "create-exp-tabs-group-active" : ""
            }
          >
            Product page
          </button>
          <button
            name={PAGE}
            onClick={(e) => {
              setActiveTab(e.target.name);
              setReload(false);
            }}
            className={activeTab === PAGE ? "create-exp-tabs-group-active" : ""}
          >
            Other
          </button>
          {embeddedPage && !showEmbeddedGuide && (
            <Tooltip
              position={Position.RIGHT}
              statelessProps={{
                backgroundColor: "#E4F1FF",
                color: "black",
                fontSize: 14,
              }}
              content={
                <p>
                  <small>
                    Click here to sync pages if a new page is created for the
                    quiz.
                  </small>
                </p>
              }
            >
              <p onClick={handleReload} className="onboarding-hyperlink">
                Reload sitemap
              </p>
            </Tooltip>
          )}
        </div>
        <SearchInput
        from="onboarding"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <div className="placement-container-selection">
       
          {loading ? (
            loading && <Loader size="sm" />
          ) : selectedPlacementPath !== "" ? (
            <>
              {listUI
                .filter((el) => setHandle(el) === selectedPlacementPath)
                .map((obj) => (
                  <Pane
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      setSelectedPlacement({
                        ...obj,
                        handle: setHandle(obj),
                      });
                    }}
                    className={"placement-item"}
                  >
                    <div className="checkbox-container">
                      {setHandle(obj) === selectedPlacementPath && (
                        <div className="checkbox-inner-box" />
                      )}
                    </div>

                    <p>{obj?.title}</p>
                  </Pane>
                ))}
              {listUI
                .filter((el) => setHandle(el) !== selectedPlacementPath)
                .map((obj) => (
                  <Pane
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      setSelectedPlacement({
                        ...obj,
                        handle: setHandle(obj),
                      });
                    }}
                    className={"placement-item"}
                  >
                    <div className="checkbox-container">
                      {setHandle(obj) === selectedPlacementPath && (
                        <div className="checkbox-inner-box" />
                      )}
                    </div>

                    <p>{obj?.title}</p>
                  </Pane>
                ))}
            </>
          ) : (
            listUI
              // ?.filter(
              //   (item) => !modifiedPaths?.[activeTab]?.includes(item?.handle)
              // )
              ?.map((obj, index) => (
                <Pane
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    setSelectedPlacement({
                      ...obj,
                      handle: setHandle(obj),
                    });
                  }}
                  className={"placement-item"}
                >
                  <div className="checkbox-container">
                    {setHandle(obj) === selectedPlacementPath && (
                      <div className="checkbox-inner-box" />
                    )}
                  </div>

                  <p>{obj?.title}</p>
                </Pane>
              ))
          )}
        </div>
      </div>
    </>
  );
};
export default PlacementWizard;
export const COLLECTION = "collection";
export const PAGE = "page";
export const PRODUCT_PAGE = "product";
