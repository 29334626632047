import {Dialog, Pane} from "evergreen-ui";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import "./Dialog.scss";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import {useEffect, useState} from "react";
import useDeleteProperties from "../../../../../hooks/useDeleteProperties";

const DeleteAnswerDialog = () => {
  const { activeQuestionIndex, questions } = useEditor();
  const {
    showDeleteAnswerDialog,
    setShowDeleteAnswerDialog,
    deleteAnswerIndex,
    deleteAnswerId,
    experience,
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const doNotShowDialogStorage =
    localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
  const [doNotShowAgainTemporary, setDoNotShowAgainTemporary] = useState(
    doNotShowDialogStorage
  );
  const [doNotShowAgainFromStorage, setDoNotShowAgainFromStorage] = useState(
    doNotShowDialogStorage
  );
  const { deleteAnswer, deleteTag } = useDeleteProperties();

  useEffect(() => {
    const doNotShowDialogStorageRefetch =
      localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
    setDoNotShowAgainFromStorage(doNotShowDialogStorageRefetch);
  }, [showDeleteAnswerDialog]);
  const toggleDoNotShowAgain = () => {
    if (doNotShowAgainTemporary) {
      setDoNotShowAgainTemporary(false);
      return localStorage.setItem("doNotShowAgainDeleteDialog", "false");
    }

    setDoNotShowAgainTemporary(true);
    return localStorage.setItem("doNotShowAgainDeleteDialog", "true");
  };

  return (
    <Pane>
      <Dialog
        isShown={showDeleteAnswerDialog}
        onCloseComplete={() =>
          setShowDeleteAnswerDialog(false, undefined, undefined)
        }
        width="540px"
        hasFooter={false}
        overlayProps={{
          zIndex:21
        }}
        hasHeader={false}
        topOffset={window.innerHeight / 3}
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
      >
        <div className="dialog-content">
          <h4>Delete Answer</h4>
          <p>
            Please note, if you delete the answer, it might affect the
            percentage of the recommendation.
          </p>
        </div>

        <div className="container">
          <div
            className="do-not-show-again-container"
            onClick={toggleDoNotShowAgain}
          >
            <Checkbox checked={doNotShowAgainTemporary} />
            <p>Do not show again</p>
          </div>
          <button
            onClick={() =>
              setShowDeleteAnswerDialog(false, undefined, undefined)
            }
            className="cancel dialog-btn"
            style={{
              color: "#75787E",
              marginRight: "16px",
              cursor: "pointer",
              background: "none",
            }}
          >
            No, Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={() => {
              if (experience?.type === "QUIZ") {
                deleteAnswer(
                  questions[activeQuestionIndex]?.answers[deleteAnswerIndex],
                  deleteAnswerIndex
                ).then(() => {
                  setShowDeleteAnswerDialog(false, undefined, undefined);
                });
              } else if (experience?.type === "SWIPER") {
                deleteTag().then(() =>
                  setShowDeleteAnswerDialog(false, undefined, undefined)
                );
              }
            }}
            style={{
              color: "#fff",
              background: "#E45447",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default DeleteAnswerDialog;
