import { Client } from "Client";
import { Button, majorScale, Pane, TextInput } from "evergreen-ui";
import { useEffect, useState } from "react";
import { SelectedItem } from "./SelectedItem";
import { IoClose } from "react-icons/io5";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-icon-new.svg";
import { ReactComponent as CreateIcon } from "../../../../assets/icons/new-create-magic-icon.svg";
import { ReactComponent as AllProductsIcon } from "../../../../assets/images/all-product-selected.svg";

export function Step2({
  savedList,
  setSavedList,
  onCreateExp,
  selected,
  setSelected,
  setShow,
}) {
  const [queryProductsText, setQueryProductsText] = useState("");
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectAllProducts, setSelectAllProducts] = useState(false);
  const [selectAllCollections, setSelectedAllCollections] = useState(false);

  const finalCollectionUIAfterFilter = collections?.filter(
    (c) =>
      c?.title?.toLowerCase()?.includes(queryProductsText?.toLowerCase()) &&
      !selectedCollections.includes(c)
  );

  const productsUI = [
    ...(savedList?.products || []),
    ...(products?.filter(
      (p) => !savedList?.products?.map((p) => p.id)?.includes(p.id)
    ) || []),
  ];
  const finalProductUIAfterFilter = productsUI?.filter(
    (p) =>
      p?.name?.toLowerCase()?.includes(queryProductsText?.toLowerCase()) &&
      !selectedProducts.includes(p)
  );

  const toggleSelectAll = () => {
    setSelectedProducts(products);
    setSavedList({ ...savedList, products });
  };

  useEffect(() => {
    Client.post(`/emp/products?page=${0}`, {
      names: [],
      tags: [],
      types: [],
      retrieveAll: true,
    }).then((res) => {
      setProducts(res.data);
    });

    Client.get("/emp/collections").then((res) => {
      setCollections(res.data);
    });
  }, []);

  const addOrRemoveSelectedProduct = (productOBJ) => {
    if (selectedProducts?.includes(productOBJ)) {
      const finalProducts = savedList.products?.filter(
        (item) => item?.id !== productOBJ?.id
      );
      setSelectAllProducts(false);

      setSelectedProducts((prev) =>
        prev.filter((productItem) => productItem.id !== productOBJ.id)
      );
      setSavedList({
        ...savedList,
        products: savedList.products.filter(
          (productItem) => productItem.id !== productOBJ.id
        ),
      });
    } else {
      setSelectedProducts((prev) => [...prev, productOBJ]);
      setSavedList({
        ...savedList,
        products: [...savedList.products, productOBJ],
      });
      finalProductUIAfterFilter.length === 1 && setSelectAllProducts(true);
    }
  };

  const addOrRemoveSelectedCollection = (collection) => {
    if (selectedCollections?.includes(collection)) {
      setSelectedAllCollections(false);
      setSelectedCollections((prev) =>
        prev.filter((item) => item !== collection)
      );
      setSavedList({
        ...savedList,
        collections: savedList.collections.filter(
          (item) => item !== collection
        ),
      });
    } else {
      setSelectedCollections([...selectedCollections, collection]);
      setSavedList({
        ...savedList,
        collections: [...savedList?.collections, collection],
      });
      finalCollectionUIAfterFilter.length === 1 &&
        setSelectedAllCollections(true);
    }
  };

  const selectAll = () => {
    if (selectAllProducts) {
      setSelectedProducts([]);
      setSavedList({
        ...savedList,
        products: [],
      });
    } else {
      setSavedList({
        ...savedList,
        products: finalProductUIAfterFilter,
      });

      setSelectedProducts([...selectedProducts, ...finalProductUIAfterFilter]);
    }
  };

  const SelectAllCollections = () => {
    if (selectAllCollections) {
      setSelectedCollections([]);

      setSavedList({
        ...savedList,
        collections: [],
      });
    } else {
      setSavedList({
        ...savedList,
        collections: finalCollectionUIAfterFilter,
      });
      setSelectedCollections([
        ...selectedCollections,
        ...finalCollectionUIAfterFilter,
      ]);
    }
  };
  const onClickTab = (e) => {
    const { name } = e.target;
    setSelected(name);
    setQueryProductsText("");
  };

  const disableCreateButton =
    (selected === "products" && selectedProducts.length < 5) ||
    (selected !== "products" && savedList.collections?.length < 1);
  return (
    <>
      <Pane
        display="flex"
        gap="40px"
        zIndex={12}
        alignItems="center"
        paddingTop="23px"
        paddingBottom="15px"
      >
        <Button
          onClick={() => {
            setShow(false);
          }}
          appearance="minimal"
          position="absolute"
          top={majorScale(2)}
          fontSize={majorScale(3)}
          right={majorScale(2)}
        >
          &times;
        </Button>
        <div className="create-exp-tabs-group">
          <button
            name="collection"
            onClick={onClickTab}
            className={
              selected === "collection" ? "create-exp-tabs-group-active" : ""
            }
          >
            Collections
          </button>
          <button
            name="products"
            onClick={onClickTab}
            className={
              selected === "products" ? "create-exp-tabs-group-active" : ""
            }
          >
            Products
          </button>
        </div>
      </Pane>
      <div style={{ position: "relative", marginLeft: 0 }}>
        <SearchIcon
          size={16}
          style={{
            position: "absolute",
            top: 12,
            left: 8,
            stoke: "#161F2D",
          }}
        />
        {queryProductsText?.length > 1 && (
          <IoClose
            size={16}
            style={{
              position: "absolute",
              top: 12,
              left: 172,
              fill: "#96989b",
              cursor: "pointer",
            }}
            onClick={() => setQueryProductsText("")}
          />
        )}
        <TextInput
          className="step2-search-input"
          paddingLeft={28}
          placeholder="Search"
          value={queryProductsText}
          onChange={(e) => {
            setQueryProductsText(e.target.value);
            setSelectAllProducts(false);
          }}
          size="large"
          width={200}
          borderRadius={8}
        ></TextInput>
      </div>

      <Pane
        // display="flex"
        gap={16}
        marginY={10}
        borderTop="1px solid lightgrey"
        style={{ border: "none", overflowY: "hidden", paddingBottom: 80 }}
        className="create-selected-items-section"
      >
        {selected !== "products" ? (
          <div className="collection-container">
            <div>
              <SelectedItem
                item={{ name: "Collections" }}
                border={false}
                addOrRemoveSelectedProduct={() => {
                  SelectAllCollections();
                  setSelectedAllCollections(!selectAllCollections);
                }}
                type="collections"
                selected={selectAllCollections}
              />
              {finalCollectionUIAfterFilter.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80%",
                    flexDirection: "column",
                  }}
                >
                  <AllProductsIcon />
                  <p style={{ color: "#75787E", marginTop: "8px" }}>
                    All collections selected
                  </p>
                </div>
              )}
              {/* <div
                className="selected-items-section"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflowY: "auto",
                  height: "240px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    borderBottom:
                      selected === "collection" && "1px solid #DBE1E7",
                  }}
                ></div> */}
              {finalCollectionUIAfterFilter
                ?.filter((p) => !selectedCollections?.includes(p))
                .map((collection) => (
                  <SelectedItem
                    item={collection}
                    label="title"
                    primaryKey={"handle"}
                    addOrRemoveSelectedProduct={(item) =>
                      addOrRemoveSelectedCollection(item)
                    }
                    type="collection"
                    subTitle={collection?.products_count + " Products"}
                    // selected={savedList?.collections?.includes(
                    //   collectionName
                    // )}
                    selectedProducts={[]}
                  />
                ))}
              {/* </div> */}
              {/* <CreateDialogButton
              text={
                (savedList?.collections?.length ?? 0) +
                "/4 Connected Collections"
              }
              style={{
                background: "none",
                color: "#75787E",
                fontSize: "14px",
                marginLeft: "-22px",
              }}
            /> */}
            </div>
            <div className="seprator"></div>
            <div>
              <p>
                Selected Collections |
                <span
                  style={{
                    color: "#75787E",
                  }}
                >
                  {selectAllCollections
                    ? selectedCollections?.length + " Collections"
                    : selectedCollections?.length + " Collections"}
                </span>
              </p>
              {selectedCollections?.map((collection, idx) => (
                <SelectedItem
                  item={collection}
                  primaryKey={"handle"}
                  label="title"
                  addOrRemoveSelectedProduct={addOrRemoveSelectedCollection}
                  selectedProducts={selectedCollections}
                  type="collection"
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="products-container">
            <div>
              <SelectedItem
                item={{ name: "Products" }}
                border={false}
                addOrRemoveSelectedProduct={() => {
                  selectAll();
                  setSelectAllProducts(!selectAllProducts);
                }}
                type="products"
                selected={selectAllProducts}
              />

              {finalProductUIAfterFilter.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80%",
                    flexDirection: "column",
                  }}
                >
                  <AllProductsIcon />
                  <p style={{ color: "#75787E", marginTop: "8px" }}>
                    All product selected
                  </p>
                </div>
              )}

              {finalProductUIAfterFilter
                .filter((p) => !selectedProducts?.includes(p))
                ?.map((product, idx) => (
                  <SelectedItem
                    item={product}
                    addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                    selectedProducts={[]}
                    type="product"
                  />
                ))}
            </div>

            <div className="seprator"></div>

            <div>
              <ProductsIndicator
                selectedProducts={selectedProducts}
                disableCreateButton={disableCreateButton}
              />
              {selectedProducts?.map((product, idx) => (
                <SelectedItem
                  item={product}
                  addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                  selectedProducts={selectedProducts}
                  type="product"
                />
              ))}
            </div>
          </div>
        )}

        <Pane
          display="flex"
          position="absolute"
          bottom="2rem"
          right="2rem"
          alignItems="center"
        >
          <Button
            appearance="none"
            className={`create-exp-btn ${
              disableCreateButton && "create-exp-btn-disabled"
            }`}
            width="166px"
            iconBefore={
              <CreateIcon
                className="create-exp-btn-svg"
                fill={disableCreateButton ? "gray" : "#fff"}
              />
            }
            onClick={onCreateExp}
            disabled={disableCreateButton}
          >
            Generate
          </Button>
        </Pane>
      </Pane>
    </>
  );
}

const ProductsIndicator = ({ selectedProducts, disableCreateButton }) => {
  if (selectedProducts.length < 5) {
    return (
      <p>
        Selected Products |{" "}
        <span style={{ color: "#E45447" }}>Minimum 5 products</span>
      </p>
    );
  }

  return (
    <p>
      Selected Products |{" "}
      <span style={{ color: disableCreateButton ? "#E45447" : "#75787E" }}>
        {disableCreateButton
          ? selectedProducts?.length + "/5 Products"
          : selectedProducts?.length + " Products"}
      </span>
    </p>
  );
};
