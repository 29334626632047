// @ts-nocheck

import { Client } from "Client";
import { updateObject } from "utils/utls";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { EditorState, InitialState } from "./Types/EditorTypes";

const initialState: InitialState = {
  experience: {},
  activeDesignTab: "",
  subActiveTab: "",
  subTab: "content",
  connectedProductsLoading: false,
  tabs: "experience-content",
  showEditorUnsavedPopup: false,
  emailDesign: false,
  recommendationDesign: true,
  tagColor: { variable: "", color: "" },
  colorPicker: null,
  activeQuestionIndex: undefined,
  activeQuestionId: undefined,
  editAnswerIndex: undefined,
  answerError: false,
  originalQuestions: [],
  questions: [],
  product: {},
  products: [],
  selectedTagsForRemoval: [],
  productDesign: false,
  tagsMap: {},
  isEditing: false,
  answersProductsCountFromServer: {},
  answersProductsCountLocal: {},
  tags: [],
  newQuestion: false,
  scrollAnswer: "",
  showLiveModal: true,
  colorError: undefined,
  buttonShapeTab: "",
  colorHistory: [],
  //delete dialog states
  showDeleteDialog: false,
  showDeleteAnswerDialog: false,
  deleteAnswerIndex: undefined,
  questionsGenerated: true,
  deleteAnswerId: undefined,
  // Swiper properties
  previewTag: {
    image: "",
    sentence: "",
    matchingProducts: [],
    tag: {
      id: "",
      type: "",
      name: "",
    },
  },
};

const useEditor = create<EditorState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      resetInitialState: () => {
        set(initialState);
      },
      setColorError: (v) => {
        set({ colorError: v });
      },
      setScrollAnswer: (v) => {
        set({ scrollAnswer: v });
      },
      setShowDeleteAnswerDialog: (show, ansIdx, ansId) => {
        set({
          showDeleteAnswerDialog: show,
          deleteAnswerIndex: ansIdx,
          deleteAnswerId: ansId,
        });
      },
      setIsEditing: (isEditing) => set({ isEditing }),
      setShowDeleteDialog: (show) =>
        set({
          showDeleteDialog: show,
        }),

      setShowLiveModal: (show) => {
        set({ showLiveModal: show });
      },
      setColorHistory: (newColorArr) => {
        // set({ colorHistory: [...get().colorHistory, newColor] });
        set({ colorHistory: newColorArr });
      },
      changeTabs: (v) => {
        set({ tabs: v });
        if (v.includes("email")) {
          set({ activeDesignTab: "email" });
        } else if (v.includes("results")) {
          set({ activeDesignTab: "recommendation" });
        } else if (v.includes("intro")) {
          set({ activeDesignTab: "intro" });
        } else {
          set({ activeDesignTab: "" });
        }
      },
      setActiveSubTab: (v) => {
        set({ activeDesignTab: v });
      },
      setSubTab: (v) => {
        set({ subTab: v });
      },
      setButtonShapeTab: (v) => {
        set({ buttonShapeTab: v });
      },
      setConnectedProductsLoading: (loading:boolean)=> set({
        connectedProductsLoading: loading
      }),
      setAnswersProductsCountFromServer: (v) => {
        set({ answersProductsCountFromServer: v });
      },
      setAnswersProductsCountLocal: (v) => {
        set({
          answersProductsCountLocal: {
            ...get().answersProductsCountLocal,
            ...v,
          },
        });
      },
      setAnswerError: (v) => {
        set({ answerError: v });
      },

      setPreviewTag: (obj) => {
        set({ previewTag: obj });
      },
      setUnsavedPopup: (v) => {
        set({ showEditorUnsavedPopup: v });
      },
      deleteCustomTag: (tagType, tagID) => {
        set({
          experience: {
            ...get().experience,
            design: {
              ...get().experience.design,
              tagsToIgnore: [
                ...(get().experience?.design?.tagsToIgnore || []),
                tagID,
              ],
            },
          },
        });
      },

      setColorPicker: (v) => {
        set({
          colorPicker: v,
        });
      },

      setDesign: (path, value) => {
        set({
          experience: {
            ...get().experience,
            design: updateObject(get()?.experience?.design, value, path),
          },
        });
      },

      updateBgImageId: (prevId, newId) => {
        if (prevId === newId) return;
        if (get().experience.design?.questions?.[prevId]) {
          get().setDesign(`questions/${newId}`, {
            ...get().experience.design.questions[prevId],
          });
          get().setDesign(`questions/${prevId}`, undefined);
        }
      },

      changeQuestionId: (prevId, newId) => {
        const index = get().questions.findIndex((q) => q.id === prevId);
        get().updateQuestions(
          [
            ...get().questions.slice(0, index),
            {
              ...get().questions[index],
              id: newId,
            },
            ...get().questions.slice(index + 1),
          ],
          false
        );
      },
      changeActiveQuestionIndex: (index) => {
        set({ scrollAnswer: "" });
        if (get().answerError) {
          return;
        }
        set({
          activeQuestionIndex: index,
          editAnswerIndex: undefined,
          emailDesign: false,
          productDesign: false,
          recommendationDesign: false,
          activeQuestionId: get().questions[index]?.id,
        });
      },
      changeEditAnswerIndex: (index) => {
        set({
          editAnswerIndex: index,
        });
      },
      clearExperience: () => {
        set({
          experience: undefined,
        });
      },
      fetchExperience: async (id) => {
        const { data: exp } = await Client.get(`emp/experiences/${id}`);
        set({
          experience: {
            ...exp,
            design: {
              ...exp.design,
              email: {
                ...exp.design.email,
              },
            },
          },
        });

        if (!exp.design?.questionsToAdd?.length) {
          set({ questionsGenerated: false });
        }
        if (exp.type === "QUIZ" || exp.type === "SWIPER") {
          if (exp.productIDs[0]) {
            const { data: product } = await Client.get(
              `emp/products/${exp.productIDs[0]}`
            );
            set({
              product,
            });
          }
        } else if (exp.type === "TAGS_SELECTION") {
          const { data: tags } = await Client.post(
            `experiences/${exp?.id}/tags?numberOfTags=50`,
            {
              selectedTags: [],
              showRecommendation: false,
            }
          );
          set({
            tags: tags.tags,
            products: tags.matchingProducts,
            product: tags.matchingProducts?.[0],
          });
        }
      },
      changeKeyValue: (key, value) => {
        set({ experience: { ...get().experience, [key]: value } });
      },
      fetchQuestions: async (id, limitQuestions = 0) => {
        if (get().experience?.type === "QUIZ") {
          const res = await Client.get(`emp/experiences/${id}/questions`);
          const { questions, dictionary } = res?.data || {};
          const finalQuestions = limitQuestions
            ? questions.slice(0, limitQuestions)
            : questions;

          set({
            questions: finalQuestions,
            tagsMap: dictionary,
            originalQuestions: finalQuestions,
          });
        } else if (get().experience?.type === "TAGS_SELECTION") {
          const res = await Client.get(`emp/experiences/${id}/tags`);
          set({
            questions: res.data,
            originalQuestions: res.data,
          });
        }
      },
      setGeneratedQuestion: (question, dictionary) => {
        set({
          questions: [...get().questions.slice(0,-1), question],
          tagsMap: dictionary,
        });
      },
      updateExperience: (obj) => {
        set({
          // experience: _.merge(get().experience, obj),
          experience: { ...get().experience, ...obj },
        });
      },
      updateQuestions: (arr, isCreate = false) => {
        set({
          questions: [...arr],
        });
        if (isCreate)
          set({
            activeQuestionIndex: arr.length - 1,
          });
      },
      addToTagMap: (tag) => {
        if (get().tagsMap.findIndex((t) => t.id === tag.id) === -1)
          set({
            tagsMap: [...get().tagsMap, tag],
          });
      },

      deleteTag: (tag) => {
        set((state) => {
          const tags =
            state.questions[state.activeQuestionIndex].answers[
              state.editAnswerIndex
            ]?.tagIDs;
          const index = tags.findIndex((t) => t === tag.id);
          tags.splice(index, 1);
          return { ...state };
        });
      },
      setExperience: (exp) => {
        set({
          experience: {
            ...exp,
            design: {
              ...exp.design,
              email: {
                ...exp.design.email,
              },
            },
          },
        });
      },
      toggleEmailDesign: () => {
        set({
          emailDesign: !get().emailDesign,
          productDesign: false,
          recommendationDesign: false,
          activeQuestionIndex: undefined,
        });
      },
      setEmailDesign: (open) => {
        set({
          emailDesign: open,
          productDesign: false,
          recommendationDesign: false,
          // activeQuestionIndex: undefined,
        });
      },
      toggleRecommendationDesign: () => {
        set({
          emailDesign: false,
          productDesign: false,
          recommendationDesign: !get().recommendationDesign,
          activeQuestionIndex: undefined,
        });
      },
      toggleProductDesign: () => {
        set({
          emailDesign: false,
          productDesign: !get().productDesign,
          recommendationDesign: false,
          activeQuestionIndex: undefined,
        });
      },
      updateSelectedTagsForRemoval: (selectedTags) => {
        set({
          selectedTagsForRemoval: selectedTags,
        });
      },
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: "use-editor",
    }
  )
);

export default useEditor;
