import {useEffect, useState} from "react";
import {Client} from "../Client";
import {implementationOptions} from "../Options/Options";
import useAuth from "../stores/auth";

const useOverviewFilters = () => {
    const plan = useAuth(s => s.accountOBJ)?.plan;
    const isSkeepx = plan === "Skeep X"
    const [filtersLoaded, setFiltersLoaded] = useState(0)
    // EXP NAMES - ALL OPTIONS + ACTIVE (FILTERED OPTIONS)
    const [allLiveExpNamesOptions, setAllLiveExpNamesOptions] = useState([]);
    const [activeExpNames, setActiveExpNames] = useState([]);
    const [activeExpStatus,setActiveExpStatus]=useState([])

    // EXP PLACEMENTS - ALL OPTIONS + ACTIVE (FILTERED OPTIONS)
    const [allPlacements, setAllPlacements] = useState([]);
    const [activePlacements, setActivePlacements] = useState([]);

    // EXP IMPLEMENTATIONS - ALL OPTIONS + ACTIVE (FILTERED OPTIONS)
    const [activeImplementations, setActiveImplementations] = useState(implementationOptions.filter(pOBJ => pOBJ?.value !== "MANUAL_POPUP").map(pOBJ => pOBJ.value));


    // Filter UI Text
    const [expFilterText, setExpFilterText] = useState("All");
    const [pageFilterText, setPageFilterText] = useState("All");
    const [implFilterText, setImplFilterText] = useState("All");

    useEffect(() => {
        if (!isSkeepx) return;
        setActiveImplementations(implementationOptions.map(pOBJ => pOBJ.value))
    }, [isSkeepx]);

    useEffect(() => {
        Client("/experiences")
            .then((res) => {
                const onlyLiveName = res.data?.map(exp => ({name:exp?.name,id:exp.id}));
                const expNamesOptions = onlyLiveName.map(expObj => ({
                    label:expObj?.name,
                    value:expObj?.id,
                }));

                setAllLiveExpNamesOptions(expNamesOptions);
                setActiveExpNames(onlyLiveName.map(expObj => expObj.id));
                setActiveExpStatus(res?.data.map((el)=>el.status))
                setFiltersLoaded((prev) => prev + 1)
            })
    }, []);

    useEffect(() => {
        if (activeImplementations?.includes("MANUAL_POPUP")) {
            setActivePlacements(allPlacements?.map(pOBJ => pOBJ.value))
        }
    }, [activeImplementations]);


    useEffect(() => {
        Client("/emp/experiences/paths").then(res => {
            const resPlacements = res.data.filter(Boolean).map(path => {
                const label = path?.split("/")?.at(-1) ? path?.split("/")?.at(-1) :  "Home Page"
                return {
                    value: path,
                    label: decodeURI(label)
                }
            });

            let uniquePlacements = resPlacements.filter((v,i,a)=> a.findIndex(v2=>(v2.value===v.value))===i);

            setAllPlacements(uniquePlacements);
            setActivePlacements(uniquePlacements.map(pOBJ => pOBJ.value));
            setFiltersLoaded((prev) => prev + 1)
        })
    },[]);

    // Sync filter UI
    useEffect(() => {
        if (activeExpNames.length === allLiveExpNamesOptions.length) setExpFilterText("All");
        if (activeExpNames.length < allLiveExpNamesOptions.length) setExpFilterText(activeExpNames.length + " Selected");

        if (activePlacements.length === allPlacements.length) setPageFilterText("All");
        if (activePlacements.length < allPlacements.length) setPageFilterText(activePlacements.length + " Selected");


        const allImpTypes = isSkeepx ? implementationOptions.length : implementationOptions.filter(pOBJ => pOBJ?.value !== "MANUAL_POPUP")?.length;
        if (activeImplementations.length === allImpTypes) setImplFilterText("All");
        if (activeImplementations.length < allImpTypes) setImplFilterText(activeImplementations.length + " Selected");
        setFiltersLoaded((prev) => prev + 1)
    }, [activePlacements,activeExpNames,activeImplementations,allPlacements]);

    return {
        filtersLoaded,
        // Exp names
        allLiveExpNamesOptions,
        activeExpNames,
        setActiveExpNames,
        activeExpStatus,

        // Exp placements
        allPlacements,
        activePlacements,
        setActivePlacements,

        // Exo implementation'
        activeImplementations,
        setActiveImplementations,

        // Filter UI text
        expFilterText,
        pageFilterText,
        implFilterText
    };
}

export default useOverviewFilters;