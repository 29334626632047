import {convertArrayToObject} from "../utils/convertArrayToObject";
import {toaster} from "evergreen-ui";
import useEditor from "../stores/use-editor";
import {postEvents} from "../utils/postEvents";
import {useEditorEvents} from "../stores/use-editor-events";
import {useSwiper} from "../stores/use-swiper";
import useCustomTags from "../stores/use-custom-tags";
import {useState} from "react";
import {Client} from "../Client";

const useEditorActions = () => {
    const {experience, questions, updateBgImageId, setUnsavedPopup, selectedTagsForRemoval, changeQuestionId,} = useEditor();
    const { playEvents, events, addEvents, clearEvents } = useEditorEvents();
    const { tagTypesToUpdate, tagTypes, tagsImages, tagsImagesEdited } = useSwiper();
    const { tags, saveCustomTags , clearTags,addTag} = useCustomTags();
    const [liveSaveModal, setLiveSaveModal] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleSaveChanges(skipLiveCheck = false,showToaster = true) {
        const numberOfActions = events?.length
        if(!skipLiveCheck) {
            if (experience.status === "STOPPED" && !liveSaveModal) {
                return setLiveSaveModal(true);
            }
        }
        setUnsavedPopup(false);
        setLoading(true);
        const finalTagTypesArray = tagTypesToUpdate?.map((tagTypeID) => {
            const tagTypeOBJ = tagTypes.find(
                (tagTypeOBJ) => tagTypeOBJ.id === tagTypeID
            );
            const tagTypeFromDesign =
                experience?.design?.customTags[tagTypeOBJ?.type]?.image;
            return {
                type: tagTypeOBJ.type,
                sentence: tagTypeOBJ.sentence?.replaceAll("#", "%s"),
                image: tagsImages[tagTypeOBJ.type] || tagTypeFromDesign || null,
                tags: convertArrayToObject(
                    tagsImagesEdited
                        .filter((tagEditedOBJ) => tagEditedOBJ.type === tagTypeOBJ.type)
                        ?.map((obj) => ({ tagID: obj.tagID, image: obj.newImageS3Path })),
                    "tagID",
                    true
                ),
            };
        });

        const finalTagsDesign = convertArrayToObject(finalTagTypesArray, "type");

        try {
            await postEvents(
                events,
                questions,
                experience,
                updateBgImageId,
                changeQuestionId,
                finalTagsDesign,
                selectedTagsForRemoval
            );
            await saveCustomTags();
            clearEvents();
            setLoading(false);
            if (showToaster) {
                toaster.success("All changes were saved successfully");
            }

            //navigate(redirectTo);
        } catch (e) {
            toaster.danger("Some changes could not be saved");
        }
        clearTags();
        window.analytics?.track(
            "Experience Edited",
            {
                userId:  localStorage.getItem("SKP_USERID"),
                experience_name: experience?.name,
                email: localStorage.getItem("EMAIL"),
                edit_actions: numberOfActions
            },
            {
                context: {
                    groupId: localStorage.getItem("SKP_BRANDID"),
                },
            }
        );
    }


    const saveProductChanges = async ({selectedProducts,ans,activeQuestionIndex,idx,setAnswersProductsCountLocal,setShow,setNoOfConnectedProducts}) => {
        const newTag = addTag({
            originalTagId: ans?.tagIDs?.[0],
            ...(tags[ans?.tagIDs?.[0]] ?? {}),
            name: ans?.answer,
            products: [...selectedProducts],
        });
        addEvents("question", "update", {
            quesIdx: activeQuestionIndex,
            questionId: questions[activeQuestionIndex].id,
            answers: [
                ...questions[activeQuestionIndex].answers.slice(0, idx),
                {
                    ...ans,
                    tagIDs: [newTag?.id],
                },
                ...questions[activeQuestionIndex].answers.slice(idx + 1),
            ],
        });
        setAnswersProductsCountLocal({[ans?.id]: selectedProducts?.length});
        setShow(false);
        // setNoOfConnectedProducts(selectedProducts?.length);
    };


    const createExperience = async ({selected,products,collections,collectionIDs,experienceOBJ,setShow,onCreate, showToast= true}) => {
        Client.post(`emp/experiences`, {
            brandID: localStorage.getItem("SKP_BRANDID"),
            environment: window.location.hostname === "emp.skeep.io" ? "prod" : "dev",
            products: selected === "products" ? products.map((p) => p.id) : null,
            collections: selected !== "products" ? collections : null,
            collectionIDs,
            path: "/",
            name: experienceOBJ.name,
            implementationType: "POPUP",
            type: experienceOBJ.type,
        })
            .then((res) => {
                if(showToast)toaster.success("Creating your experience...", {
                    duration: 8,
                    description: `It will be ready in a few seconds`,
                });

                setShow(false);
                onCreate();
            })
            .catch((err) => {
                toaster.danger("There was a problem creating the experience.");
                setShow(false);
            });
    }

    return {createExperience,saveProductChanges,handleSaveChanges,loading,liveSaveModal, setLiveSaveModal};
}

export default useEditorActions;