import {useEffect} from "react";
import {useSwiper} from "stores/use-swiper";
import {Client} from "../Client";
import useEditor from "../stores/use-editor";

const useCustomTags = () => {
  const { experience } = useEditor();
  const { setSentences } = useSwiper();

  useEffect(() => {
    if (!experience?.id) return;

    Client.get(`/emp/experience/${experience?.id}/sentences`).then((res) => {
      const customTags = experience?.design?.customTags;
      const defaultTagTypes = res?.data.tagTypes;

      // Tag types
      const translateTagTypesFromDesign =
        customTags &&
        Object.keys(customTags)?.map((tagType) => {
          const tagTypeThatHasDesign = defaultTagTypes?.find(
            (tagTypeOBJ) => tagTypeOBJ?.type === tagType
          );
          if (tagTypeThatHasDesign?.id) {
            return {
              ...tagTypeThatHasDesign,
              sentence: customTags[tagType]?.sentence,
            };
          }
          return customTags[tagType];
        });

      const removeDefaultTagTypesThatHaveDesign = defaultTagTypes?.filter(
        (obj) =>
          !translateTagTypesFromDesign
            ?.map((tagTypeOBJ) => tagTypeOBJ.id)
            ?.includes(obj?.id)
      );
      let imagesFromDesign = {};
      customTags &&
        Object.keys(customTags)?.map((tagType) => {
          imagesFromDesign = {
            ...imagesFromDesign,
            [tagType]: customTags[tagType]?.image,
          };
        });

      setSentences({
        images: { ...res.data.images, ...imagesFromDesign },
        tagTypes: [
          ...(translateTagTypesFromDesign || []),
          ...(removeDefaultTagTypesThatHaveDesign || []),
        ],
        tags: res?.data.tags,
      });
    });
  }, [experience]);
};

export default useCustomTags;
