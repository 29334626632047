import React from "react";
import {checkThreshold, hexToRGB} from "utils/utls";

const useColorCheck = (colorArr) => {
  const finalArr = colorArr.filter((color) => color !== undefined);
  if (finalArr.length === 1) return "successful";
  const rgbArr = finalArr.map((color) => hexToRGB(color));
  const result = checkThreshold(rgbArr);
  return result;
};

export default useColorCheck;
