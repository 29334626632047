import {Pane} from "evergreen-ui";
import {useOnboarding} from "../../../stores/use-onboarding";

const OnboardingBackButton = () => {
    const {setStep,step} = useOnboarding();

    return(
        <Pane
            display="flex"
            alignItems="center"
            gap={6}
            style={{color:"#75787E",fontWeight:"500",cursor:"pointer",marginRight:"auto"}}
            onClick={() => setStep(step - 1)}
        >
            <Arrow style={{transform: 'rotate(90deg)'}} color="#75787E" />
            Back
        </Pane>
    )
}

export default OnboardingBackButton;

const Arrow = ({color,style}) => {

    return(
        <svg style={style} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6.5 7L12 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}