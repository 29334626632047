import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-gray.svg";
import { Pane, toaster } from "evergreen-ui";

const ExpPlacementsModalStep3 = ({
  embedded,
  editExpOBJ,
  isManuelSetUp,
  experience,
}) => {
  const expImpType = experience?.locations?.[0]?.implementationType;
  const finalImpType = editExpOBJ.implementationType || expImpType;

  const code = () => {
    if (finalImpType === "BANNER")
      return '<div id="quiz" style="height:400px;width:100%" /> ';
    if (finalImpType === "PRODUCT_GRID")
      return '<div id="quiz" style="height:100px;width:100%" /> ';
    return '<div id="quiz" style="height:580px;width:100%" /> ';
  };

  const manualPopupCodeSnippet = `window.skeep.triggerExperience("${localStorage.getItem(
    "SKP_BRANDID"
  )}", "${experience?.id}")`;

  const handleCopy = (e) => {
    navigator.clipboard.writeText(
      isManuelSetUp && !embedded ? manualPopupCodeSnippet : code()
    );
    toaster.success("Code copied successfully");
  };

  const onClickBannerText = () => {
    window.open("https://support.skeep.io/banner-implementation", "_blank");
  };

  const onClickProductGridText = () => {
    window.open(
      "https://support.skeep.io/product-grid-implementation",
      "_blank"
    );
  };


  return (
    <>
      <div className="placement-steps-container">
        {finalImpType === "BANNER" || finalImpType === "PRODUCT_GRID" ? (
          <p>
            Taking the following steps or{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={
                finalImpType === "BANNER"
                  ? onClickBannerText
                  : onClickProductGridText
              }
            >
              click here
            </span>{" "}
            for more instructions
          </p>
        ) : (
          <p>
            Taking the following steps{" "}
            <a
              href={"https://support.skeep.io/change-implementation-type"}
              target={"_blank"}
            >
              <InfoIcon />
            </a>
          </p>
        )}
      </div>

      <Instructions
        isManuelSetUp={isManuelSetUp}
        embedded={embedded}
        code={code}
        manualPopupCodeSnippet={manualPopupCodeSnippet}
        handleCopy={handleCopy}
        finalImpType={finalImpType}
      />
    </>
  );
};

export default ExpPlacementsModalStep3;

const Instructions = ({
  isManuelSetUp,
  embedded,
  handleCopy,
  finalImpType,
  code,
  manualPopupCodeSnippet,
}) => {
  if (isManuelSetUp && !embedded)
    return (
      <Pane>
        <div className="placement-steps">
          <p>
            1. Go to your Shopify store and pick the place where you want the
            trigger to appear
          </p>
          <p>2. Insert the code snippet on the relevant page</p>
          <span
            onClick={handleCopy}
            style={{
              background: "#E4F1FF",
              fontSize: 11,
              cursor: "pointer",
              marginTop: 10,
              width: "110%",
            }}
          >
            {manualPopupCodeSnippet}
            <Copy />
          </span>
        </div>
      </Pane>
    );

  if (finalImpType === "BANNER" || finalImpType === "PRODUCT_GRID")
    return (
      <div className="placement-steps">
        <p>
          1. Copy th code:
          <span
            style={{ background: "#E4F1FF", cursor: "pointer", marginLeft: 10 }}
            onClick={handleCopy}
          >
            {code()}
            <Copy />
          </span>
        </p>
        <p>2. Go to a Shopify admin account of your store</p>
        <p>3. Click on the Themes button → “edit code”</p>
        <p>4. Go to ‘Sections’ on the left size → Collection-template</p>
        {finalImpType === "BANNER" && (
          <p>
            5. Search your class header name, after the collection’s header
            paste the code in a new line
          </p>
        )}
        {finalImpType === "PRODUCT_GRID" && (
          <>
            <p>
              5. Search your class grid item, and add another class grid item{" "}
            </p>

            <p>6. Paste the code in the product card grid</p>
          </>
        )}
      </div>
    );

  return (
    <div className="placement-steps">
      <p>
        1. Go to your Shopify store and pick the page where you want the
        experience to appear, it is optional to create a new page in your store
        for the experience
        <p>
          2. Go the ‘Show HTML’ icon option on the right content section. Copy
          and paste the code below in the content field.
        </p>
        <span
          style={{ background: "#E4F1FF", cursor: "pointer" }}
          onClick={handleCopy}
        >
          {code()}
          <Copy />
        </span>
      </p>
    </div>
  );
};
