import React from 'react'
import classes from "../../styles/OverviewPage.module.css";
import InfoWithTooltip from "../InfoWithTooltip/InfoWithTooltip";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";

export default function OverviewSummary({header,tooltip,value,change}) {
    return (
        <div className={classes.summaryInfoContainer}>
            <div className={classes.summaryInfoTopContainer}>
                    <p className={classes.summaryInfoTopName}>{header}</p>
                    <InfoWithTooltip text={tooltip} width={250}/>
            </div>
            <div className={classes.summaryInfoBottomContainer}>
                <p className={classes.summaryInfoBottomInfo}>{value}</p>
                {change > 0 && <p className={classes.summaryInfoBottomPercentPositive}>{change * 100}% <IoMdArrowDropup size={20}/></p>}
                {change < 0 && <p className={classes.summaryInfoBottomPercentNeg}>{change * 100}% <IoMdArrowDropdown size={20}/></p>}
            </div>
        </div>
    )
}
