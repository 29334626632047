import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import "./Verification.scss";
import verificationImage from "../../../assets/images/verification-image.svg";

export default function Verification() {
  const location = useLocation();
  const navigate = useNavigate();

  const [newEmail, setNewEmail] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      if(location.state === null && localStorage.getItem('UNVERIFIED_EMAIL') === null) {
          navigate("/login")
      }
  },[])

  return (
    <div className="verification-page">
      <p className="verification-header">We've almost finished!</p>
        <img src={verificationImage} className="verification-image" />
 
      <p className="verification-subheader">
        A verification email has been sent to {location?.state?.email}
      </p>
      <p className="verification-subheader">
        Please check your inbox (spam or promotions) and follow the
        instructions!
      </p>

      <div className="verfication-container">
        {!changeEmail && (
          <div className="verification-footer-container">
            <p className="verification-footer-text">
              Didn't receive an email?
            </p>
            <a className="verification-footer-link">Send me again</a>
          </div>
        )}
        {!changeEmail && (
          <div className="verification-footer-container">
            <p className="verification-footer-text">
              Have another email address in mind?
            </p>
            <a className="verification-footer-link">Change email</a>
          </div>
        )}
      </div>

      {/*!changeEmail ? <div className='verification-footer-container'>
                <p className='verification-footer-text'>Have another email address in mind?</p>
                <a className='verification-footer-link'>Change email</a>
            </div>
            :
            /*<div className='verification-change-email-container'>
                <p className='verification-change-email-text'>Change email</p>
                <div className='verification-change-email-input-container'>
                    <input placeholder={location.state.email} value={newEmail} type="email" onChange={(e) => setNewEmail(e.target.value)} className='verification-change-email-input' />
                    <button className='verification-change-email-button'>Send</button>
                </div>
            </div>*/}
    </div>
  );
}
