import {OpenReplay} from "utils/OpenReplay";
import create from "zustand";
import {devtools} from "zustand/middleware";
import {InitialStateQuiz, QuizState} from "./Types/QuizTypes";

const initialState: InitialStateQuiz = {
    showEditDialog: false,
    showEditAnswerDialog: false,
    dontShowProductDialog: false,
};

export const useQuiz = create<QuizState>()(
    devtools(
        (set, get) => ({
            ...initialState,
            setDontShowProductDialog: (show) => {
                set({
                    dontShowProductDialog: show,
                });
            },

            setShowEditDialog: (show) => {
                set({
                    showEditDialog: show,
                });
            },

            setShowEditAnswerDialog: (show) =>
                set({
                    showEditAnswerDialog: show,
                }),
            reset: () => {
                set(initialState);
            },
        }),
        {
            name: "quiz",
        }
    )
);
