import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../stores/auth";
import { ReactComponent as Exit } from "../../../../assets/icons/exit-black-error.svg";
import Button from "../../../../components/Button/Button";

const EditorSavePopup = ({
  setShowBackAlert,
  saveChangesAction,
  onClickLeave,
  from,
}) => {
  const navigate = useNavigate();
  const { minimized } = useAuth();

  return (
    <div
      className={
        minimized ? "editor-pop-container-minimized" : "editor-pop-container"
      }
    >
      <div className="editor-pop" style={{height:from==="new-question"?"220px":"245px"}}>
        {
           from === "new-question"?
           "":
        <Exit
          style={{
            position: "absolute",
            top: 15,
            right: 15,
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
          onClick={() => setShowBackAlert(false)}
        />
        }
        {from === "new-question" ? (
          <p className="new-question-editor-pop-header" >Save your new question</p>
        ) : (
          <p className="editor-pop-header">
            Are you sure you want to leave editing mode?
          </p>
        )}
        {from === "new-question" ? (
          <p className="new-question-editor-pop-info" >
            Before you continue editing the quiz, please click "Save question"
          </p>
        ) : (
          <p className="editor-pop-info">
            Please click “Save” to save changes before you leave or “Leave” to
            cancel changes.{" "}
          </p>
        )}

        <div className="editor-pop-button-container">
          <Button
            text={from === "new-question" ? "Cancel" : "Leave"}
            type="BUTTON"
            style={{ backgroundColor: "transparent", color: "#75787E" }}
            onClick={onClickLeave}
          />
          <Button
            text={from === "new-question" ? "Save question" : "Save"}
            type="SUBMIT"
            onClick={saveChangesAction}
          />
        </div>
      </div>
    </div>
  );
};

export default EditorSavePopup;
