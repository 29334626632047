import "./ExperiencesPage.scss";
import CreateExperienceSection from "./components/CreateExperience";
import useExperiencePage from "../../hooks/useExperiencePage";
import ExperiencesPageTable from "./components/ExperiencesPageTable";
import { ReactComponent as Info } from "../../assets/icons/info-gray.svg";
import { useEffect, useState } from "react";
import GuidencePopup from "pages/ProductPage/components/GuidencePopup";
import { Button } from "evergreen-ui";
import {
  basic,
  free,
  platinum,
  premium,
  skeepx,
} from "../../utils/billingPricing";
import { ReactComponent as InfoIcon } from "../../assets/icons/note-info-icon.svg";
import useAuth from "../../stores/auth";
import TrialEndsHint from "../../components/TrialEndsHint/TrialEndsHint";
import { Client } from "Client";

const ExperiencesPageNew = () => {
  const {
    setForceFetch,
    unsuccessfulLoad,
    setUnsuccessfulLoad,
    isEditing,
    setIsEditing,
    loading,
    fetchExperiencesPoller,
    brand,
  } = useExperiencePage();
  const [isCreateNew, setIsCreateNew] = useState(false);
  const { accountOBJ, allExperiences } = useAuth();
  const [showGuidancePopup, setShowGuidancePopup] = useState(false);
  const [createExpLoading, setCreateExpLoading] = useState(false);
  const { setSubSidebar } = useAuth();
  const [isNewExpModalVisible, setIsNewExpModalVisible] = useState(false);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [failed, setFailed] = useState(false);
  const numberOfLiveExp = allExperiences.filter(
    (e) => e.status === "LIVE"
  )?.length;
  const listOfPlans = [free, basic, premium, platinum, skeepx];
  const planOBJ = listOfPlans?.find(
    (planOBJ) =>
      planOBJ?.name_uppercase ===
      accountOBJ?.plan?.replaceAll(" ", "")?.toUpperCase()
  );
  const { setAllExperiences } = useAuth();
  const limitOfQuizzesReached =
    planOBJ?.maxPublishedQuizzes &&
    numberOfLiveExp >= planOBJ?.maxPublishedQuizzes;
  const [shopifyUser, setShopifyUser] = useState(false);


  // useEffect(()=>{
  //   if (localStorage.getItem("SHOW_EXP_TUTORIAL") !== 'false') {
  //       setShowGuidancePopup(true);
  //       localStorage.setItem("SHOW_EXP_TUTORIAL", 'false');
  //     }
  // },[])

  useEffect(() => {
    setShopifyUser(accountOBJ?.integrations?.includes("SHOPIFY"));
  }, [accountOBJ?.integrations]);


  useEffect(() => {
    if (allExperiences.length === 0) {
      fetchExperiencesPoller();
    }
  }, [allExperiences]);

  return (
    <div className="app-page exp-page">
      <div className="fa-center app-page-header" id="experiences-page-header">
        <h1 className="app-page-header-title">
          Experiences{" "}
          <Info
            style={{ cursor: "pointer" }}
            onClick={() => setShowGuidancePopup(true)}
          />
        </h1>
        <p className="app-page-header-subtitle">
          Review and set your preferred experiences live. You would be able to
          see your performance data in the Overview section{" "}
        </p>
      </div>
      {/*<TrialEndsHint setIsUpgradePlanModalOpen={setIsUpgradePlanModalOpen}/>*/}
      <GuidencePopup
        show={showGuidancePopup}
        setShow={setShowGuidancePopup}
        videoContent={true}
        videoUrl={"https://www.youtube.com/embed/JH0m-swlre8"}
        name={"Experience"}
      />
      <div className="app-page-content">
        {/* {!loading && allExperiences.length === 0 && (
        <div className="info-box">
            <Settings1 />
            <p>
                We're creating your first experiences. It should be ready in a few
                minutes. In the meantime, take a look at our video tutorial for a
                better understanding of how to use this page.
            </p>
            <Settings2 />
        </div>
        )} */}
        {!loading && allExperiences.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {planOBJ?.maxPublishedQuizzes && (
                <p
                  className="limitations-text"
                  style={{
                    color: limitOfQuizzesReached ? "#E45447" : "#75787E",
                  }}
                >
                  {numberOfLiveExp}/{planOBJ?.maxPublishedQuizzes} Published
                  Quiz
                </p>
              )}
              {limitOfQuizzesReached && (
                <button
                  className="upgrade-button-exp-page"
                  onClick={() => setIsUpgradePlanModalOpen(true)}
                >
                  Upgrade plan
                </button>
              )}
            </div>
         
         {
          shopifyUser&&
              <Button
                appearance="primary"
                backgroundColor={createExpLoading ? "#EDEDED" : "#017CF8"}
                color={createExpLoading ? "#75787E" : "#FFF"}
                borderRadius={"7px"}
                height={"40px"}
                size="medium"
                marginRight={"2rem"}
                className="create-button"
                // disabled={isEditing}
                onClick={() => {
                  if (createExpLoading) return;
                  setIsNewExpModalVisible(true);
                  setFailed(false);
                  setSubSidebar(false);
                }}
              >
                Generate experience
              </Button>
         }
       
          </div>
        )}
        <CreateExperienceSection
          allExperiences={allExperiences}
          fetchExperiencesPoller={fetchExperiencesPoller}
          setCreateExpLoading={setCreateExpLoading}
          isNewExpModalVisible={isNewExpModalVisible}
          setIsNewExpModalVisible={setIsNewExpModalVisible}
          setFailed={setFailed}
          onCreate={() => {
            setIsCreateNew(true);
            const pollingResult = async () => {
              const res = await Client("/experiences");
              return await res.data;
            };

            const interval = setInterval(async () => {
              const res = await pollingResult();
              if (res.length === allExperiences.length + 1) {
                debugger;
                setAllExperiences(
                  res.sort((a, b) => {
                    if (a?.status === "LIVE") return -1;
                    return 1;
                  })
                );
                setIsCreateNew(false);
                clearInterval(interval);
              }
            }, 5000);
          }}
        />
        <ExperiencesPageTable
          isCreateNew={isCreateNew}
          allExperiences={allExperiences}
          loading={loading}
          fetchExperiencesPoller={fetchExperiencesPoller}
          unsuccessfulLoad={unsuccessfulLoad}
          setUnsuccessfulLoad={setUnsuccessfulLoad}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setForceFetch={setForceFetch}
          brand={brand}
          setFailed={setFailed}
          limitOfQuizzesReached={limitOfQuizzesReached}
          isUpgradePlanModalOpen={isUpgradePlanModalOpen}
          setIsUpgradePlanModalOpen={setIsUpgradePlanModalOpen}
        />

        {failed && (
          <div className="failed-note-container">
            <InfoIcon />
            <p>
              The collection you selected has a problem. Consider another
              collection or the relevant products.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperiencesPageNew;
