import CustomTextArea from "components/Forms/CustomTextArea";
import {useState} from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";

import CustomImageInput from "components/Forms/CustomImageInput";
import CustomRatingTag from "components/Forms/CustomRatingTag";
import {Pane} from "evergreen-ui";
import uploadToS3 from "utils/uploadToS3";

export default function Rating({ idx }) {
  const {
    experience,
    questions,
    activeQuestionIndex,
    setShowDeleteDialog,
    experience: { design },
  } = useEditor();
  const [s3Path, setS3Path] = useState();
  const { addEvents } = useEditorEvents();

  const uploadImage = async (file) => {
    const s3PathFetched = await uploadToS3(file);
    setS3Path(s3PathFetched);
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/image`]: s3PathFetched,
    });
  };

  const activeQuestionId = questions[activeQuestionIndex].id;

  return (
    <div className="question-content">
      <div className="question-input" style={{ width: "100%" }}>
        <div>
          <div className="inputs" style={{ border: "none" }}>
            {/* @ts-ignore */}

            <CustomTextArea
              placeHolder={"Lowest"}
              width={"40%"}
              label={"Min Value"}
              value={design.questions[activeQuestionId].minLabel}
              onChange={(e) =>
                addEvents("design", "update", {
                  questions: {
                    [questions[activeQuestionIndex].id]: {
                      minLabel: e.target.value,
                    },
                  },
                })
              }
              charLimit={40}
            />
            {/* @ts-ignore */}
            <CustomTextArea
              placeHolder={"Highest"}
              width={"40%"}
              label={"Max Value"}
              value={design.questions[activeQuestionId].maxLabel}
              onChange={(e) =>
                addEvents("design", "update", {
                  questions: {
                    [questions[activeQuestionIndex].id]: {
                      maxLabel: e.target.value,
                    },
                  },
                })
              }
              charLimit={40}
            />
          </div>
        </div>
      </div>

      {/* @ts-ignore */}
      <div className="inputs" style={{ marginTop: "2rem" }}>
        <CustomRatingTag />
      </div>

      <Pane marginY={16}>
        <div className="selection-inputs">
          {/* @ts-ignore */}
          <CustomImageInput
            label="Side Image"
            onImageChange={uploadImage}
            accept={"image/png"}
            onDelete={() => {
              addEvents("design", "update", {
                [`questions/${questions[activeQuestionIndex].id}/image`]:
                  undefined,
              });
            }}
            value={
              s3Path ??
              experience?.design?.questions?.[
                questions[activeQuestionIndex]?.id
              ]?.image
            }
            placeHolder="Choose an image"
          />
        </div>
      </Pane>
    </div>
  );
}
