import {useMemo, useState} from "react";
import {ReactComponent as Arrow} from "../../../assets/icons/back-button-blue.svg";
import CustomInput from "../../Forms/CustomInput";
import Button from "../../Button/Button";
import {GuestClient} from "../../../Client";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import "./ResetPassword.scss";
import validator from "validator";
import email_verification_send_image from "../../../assets/images/forget-password-image.svg";
// import { setError } from "redux/slices/graphSlice";
import {checkIfPasswordPass, passwordRestrictions,} from "utils/handleOnboardingPassword";
import CustomTagsInput from "components/CustomTags/CustomTagsInput";
import {ReactComponent as Eye} from "assets/icons/eye.svg";

export default function ResetPassword({ setJWT }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const srt = searchParams.get("t");
  const [email, setEmail] = useState("");
  const [inputError, setInputError] = useState("");
  const [confirmPasswordFail, setConfirmPasswordFail] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [visiblePasswords, setVisiblePasswords] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const validPassword = useMemo(
    () =>
      passwordRestrictions
        .map((rest) => checkIfPasswordPass(rest, passwords.newPassword))
        .every(Boolean),
    [passwords.newPassword]
  );

  const checkToken = async () => {
    try {
      await GuestClient.get(`/registrationToken/${srt}`);
    } catch (err) {
      if (err.response.status == 500) {
        setInvalidToken(true);
      }
    }
  };

  const handleCreateNewPassword = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = passwords;

    // Handle errors
    if (!newPassword || !confirmPassword) return;
    if (!validPassword) return;
    if (newPassword !== confirmPassword) return setConfirmPasswordFail(true);

    // await checkToken();

    try {
      await GuestClient.post(`/resetPassword?t=${srt}`, {
        password: newPassword,
      });
      navigate("/login");
    } catch (err) {}
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!email) return;
    // if (!validator.isEmail(email)) setError(true);

    try {
      const sentEmail = await GuestClient.post(
        `/requests/resetPassword?username=${email}`
      );
      setEmailHasBeenSent(true);
    } catch (err) {
      if (err.response.status == 404)
        return setInputError("An account with this email does not exist");
      setInputError("There was a problem, please try again later.");
    }
  };

  const handleChangePasswords = (e) => {
    const { name, value } = e.target;

    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const toggleVisiblePasswords = (name) => {
    setVisiblePasswords((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  if (srt)
    return (
      <div className="auth-signin auth-form-container create-password-container">
        <form className="form-container">
          <h2 className="auth-title-new">Create new password</h2>
          <p className="auth-subtitle auth-subtitle-new-two">
            Fill in your email address to reset your password
          </p>
          <CustomInput
            label="New password"
            placeHolder="Enter new password"
            name="newPassword"
            value={passwords.newPassword}
            type={visiblePasswords.newPassword ? "text" : "password"}
            showErrorMessage
            units={
              <Eye
                fill="#AEB2BA"
                onClick={() => toggleVisiblePasswords("newPassword")}
                style={{ cursor: "pointer" }}
              />
            }
            size="large"
            error={inputError}
            onChange={handleChangePasswords}
            forceStyle={{ marginBottom: "10px" }}
          />
          <div style={{ minHeight: "20px" }}></div>
          <CustomInput
            label="Confirm password"
            placeHolder="Enter password"
            name="confirmPassword"
            size="large"
            units={
              <Eye
                fill="#AEB2BA"
                onClick={() => toggleVisiblePasswords("confirmPassword")}
                style={{ cursor: "pointer" }}
              />
            }
            value={passwords.confirmPassword}
            type={visiblePasswords.confirmPassword ? "text" : "password"}
            showErrorMessage
            error={inputError}
            onChange={handleChangePasswords}
          />

          {confirmPasswordFail && (
            <div className="field error-field">
              Confirm password is not the same as new password.
            </div>
          )}

          {invalidToken && (
            <div className="field error-field">This link has expired.</div>
          )}
          <div className="tag-note-container">
            <div className="tag-note">
              The password must match the terms below
            </div>
            <div className="tags-wrap">
              {passwordRestrictions.map((restriction, index) => {
                return (
                  <CustomTagsInput
                    key={index}
                    tag={restriction}
                    index={index}
                    notEdit
                    tagOK={checkIfPasswordPass(
                      restriction,
                      passwords.newPassword
                    )}
                  />
                );
              })}
            </div>
          </div>

          <div className="auth-send-two">
            <Button
              text="Sign in"
              type="SUBMIT"
              style={{ width: "430px", height: "56px" }}
              disabled={
                passwords.newPassword !== passwords.confirmPassword ||
                !passwords.newPassword ||
                !passwords.confirmPassword ||
                !validPassword
              }
              onClick={handleCreateNewPassword}
            />
          </div>
        </form>
      </div>
    );

  if (!emailHasBeenSent)
    return (
      <div className="auth-signin auth-form-container">
        <form className="form-container">
          <h2 className="auth-title-new">Forgot Password</h2>
          <p className="auth-subtitle auth-subtitle-new">
            Fill in your email address to reset your password
          </p>
          <CustomInput
            label="Email"
            placeHolder="Enter email"
            name="email"
            value={email}
            type="email"
            showErrorMessage
            error={inputError}
            size="large"
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="auth-send">
            <Button
              text="Send recovery email"
              type="SUBMIT"
              style={{
                width: "430px",
                height: "56px",
                margin: inputError !== "" ? "25px 0 35px 0" : "0 0 35px 0",
              }}
              disabled={!email || !validator.isEmail(email)}
              onClick={handleResetPassword}
            />
          </div>
          <Link className="auth-back auth-underline" to="/login">
            <Arrow />
            Back to sign in
          </Link>
        </form>
      </div>
    );

  return (
    <div className="auth-signin auth-form-container"  >
      <form className="form-container">
        <h2 className="auth-title-new">Forgot Password?</h2>
        <p className="auth-subtitle auth-subtitle-two">
          Fill in your email address to reset your password
        </p>
        <img
          src={email_verification_send_image}
          className="auth-image-verification"
        />
        <p className="footer-header">Check your email</p>
        <p className="footer-subheader">
          We sent you a password reset link to the email you provided
        </p>
        {/* <Button
          text="Log in"
          type="SUBMIT"
          style={{
            // minWidth: "430px",
            height: "56px",
            padding: "0",
            margin: "20px",
          }}
          // disabled={disable}
          onClick={() => navigate("/login")}
        /> */}
      </form>
    </div>
  );
}
