import useEditor from "stores/use-editor";
import DeleteAnswerDialog from "../Dialog/DeleteAnswerDialog";
import DeleteDialog from "../Dialog/DeleteDialog";
import {ReactComponent as Delete} from "../../../../../assets/icons/delete.svg";
import EditAnswerDialog from "../Dialog/EditAnswerDialog";
import EditDialog from "../Dialog/EditDialog";
import AddQuestion from "./AddQuestion";
import PreviousQuestionContent from "./PreviousQuestionContent";
import useDeleteProperties from "../../../../../hooks/useDeleteProperties";
import FirstQuestionStar from "../FirstQuestionStar";


const Questions = () => {
  const { questions, experience, changeActiveQuestionIndex } = useEditor();
  const { setShowDeleteDialog, setIsEditing } = useEditor();
  const { deleteQuestion } = useDeleteProperties();
  const doNotShowDialogStorage =
    localStorage.getItem("doNotShowAgainDeleteDialog") === "true";
 

  return (
    <div>
      {questions
        ?.filter((q) => !experience?.design?.questionsToIgnore?.includes(q.id))
        .map((ques, index) => (
          <div key={ques.id} style={{ position: "relative" }}>
            {ques.isNew ? (
              <AddQuestion />
            ) : (
              <PreviousQuestionContent idx={index} />
            )}

              <Delete
                style={{
                  position: "absolute",
                  right: -32,
                  top: 16,
                  cursor: "pointer",
                }}
                fill={"#017cf8"}
                onClick={() => {
                  setIsEditing(false);
                  changeActiveQuestionIndex(index);
                  if (doNotShowDialogStorage) {
                    deleteQuestion(index);
                  } else {
                    setShowDeleteDialog(true);
                  }
                }}
              />
            <FirstQuestionStar key={ques.id} index={index} questionOBJ={ques} />
          </div>
        ))}

      {/* <Product />  */}
      <DeleteDialog />
      <EditDialog />
      <EditAnswerDialog />
      <DeleteAnswerDialog />
    </div>
  );
};

export default Questions;
