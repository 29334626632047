import CustomTextArea from "components/Forms/CustomTextArea";
import useEditor from "../../../../../stores/use-editor";
import {useEditorEvents} from "../../../../../stores/use-editor-events";
import "../TagsSelection/TagsSelection.scss";
import CustomImageInput from "../../../../../components/Forms/CustomImageInput";
import {debounceInput} from "../../../../../utils/utls";

const EditorQuizIntro = () => {
  const {
    experience: { design },
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const introDesign = design?.intro || {};
  const uploadImage = ({s3Url}) => {
    addEvents("design", "update", {
      [`intro/image`]: s3Url,
    });
  };

  return (
    <div className="editor-quiz-intro">
      <div className="data">
        <div className="data-holders">
          Title
          {/* @ts-ignore */}
          <CustomTextArea
            placeHolder={introDesign?.header}
            defaultValue={introDesign?.header}
            charLimit={70}
            onChange={debounceInput((e) =>
              addEvents("design", "update", {
                "intro/header": e.target.value,
              }),500)
            }
          />
        </div>
        {
          design?.animation==="disabled"&&
        <div className="data-holders">
        Subtitle
          {/* @ts-ignore */}
          <CustomTextArea
            placeHolder={introDesign?.subtitle}
            defaultValue={introDesign?.subtitle}
            charLimit={120}
            onChange={debounceInput((e) =>
              addEvents("design", "update", {
                "intro/subtitle": e.target.value,
              }),500)
            }
          />
        </div>
        }
        <div className="data-holders">
          CTA Button
          {/* @ts-ignore */}
          <CustomTextArea
            placeHolder={introDesign?.ctaButtonText}
            defaultValue={introDesign?.ctaButtonText}
            charLimit={35}
            onChange={debounceInput((e) =>
              addEvents("design", "update", {
                "intro/ctaButtonText": e.target.value,
              }),500)
            }
          />
        </div>
        <CustomImageInput
        from="intro"
          label="Side Image"
          accept={"image/png"}
          onChange={uploadImage}
          onDelete={() => {
            addEvents("design", "update", {
              [`intro/image`]: undefined,
            });
          }}
          defaultValue={introDesign?.image}
        />
      </div>
    </div>
  );
};

export default EditorQuizIntro;
