import { QUIZ } from "pages/EditorPage/design_templates";
import { useEffect, useState } from "react";
import useEditor from "stores/use-editor";
import { useEditorEvents } from "stores/use-editor-events";
import uploadToS3 from "utils/uploadToS3";
import { camelCaseToTitleCase } from "utils/utls";
import ColorPickerWrapper from "../ColorPickerWrapper";
import FontPickerWrapper from "../FontPickerWrapper";
import { ReactComponent as Info } from "../../../../assets/icons/info-gray.svg";
import "./DesignV2.scss";
import { Position, Tooltip } from "evergreen-ui";

export default function Design({ type = QUIZ }) {
  const {
    colorPicker,
    setColorPicker,
    experience,
    setEmailDesign,
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const email = experience?.design?.email;
  const [activeHeading, setActiveHeading] = useState(null);

  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };

  const pickerOpts = {
    types: [
      {
        description: "Fonts",
        accept: {
          "font/*": [".ttf"],
        },
      },
    ],
    excludeAcceptAllOption: true,
    multiple: false,
  };
  async function handleGetFontFile(option, heading, item) {
    if (option.value === "Custom") {
      try {
        let showFile = await window.showOpenFilePicker(pickerOpts);
        let file = await showFile[0].getFile();
        uploadToS3(file).then((res) => {
          addEvents("design", "update", {
            [`fonts/${heading + "_" + item}`]: res,
          });
        });
      } catch (e) {}
    } else {
      addEvents("design", "update", {
        [`fonts/${heading + "_" + item}`]: option.value,
      });
    }
  }

  useEffect(() => {
    setEmailDesign(activeHeading === "email" && email?.enabled === "enabled");
  }, [activeHeading, setEmailDesign, email?.enabled]);
  if (!experience) return null;

  return (
    <div style={{ width: "85%" }}>
      <div className="design-v2-rows">
        <div className="font-controller">
          {Object.keys(type).map((heading, idx) => {
            return Object.keys(type[heading]).flatMap((item, i) => {
              if (type[heading][item] === "font") {
                return (
                  <div key={idx + i + ""}>
                    <div className="design-v2-font-controllers">
                      <div className="design-v2-font-row">
                        <p className="design-v2-font-label">
                          {camelCaseToTitleCase("font")}
                        </p>
                        <FontPickerWrapper
                          heading={"general"}
                          item={item}
                          handleGetFontFile={handleGetFontFile}
                        />
                      </div>
                      <div className="corners-container">
                        <div className="corners-button-heading">
                          {camelCaseToTitleCase("Button shapes")}

                          <Tooltip
                            content={
                              <p>
                                <small>
                                  The shape of your buttons can either be
                                  rounded or squared
                                </small>
                              </p>
                            }
                            statelessProps={{
                              backgroundColor: "#E4F1FF",
                              color: " #75787E",
                              minWidth: "280px",
                              paddingTop: "13px",
                              paddingRight: "4px",
                              paddingBottom: "12px",
                              paddingLeft: "5px",
                              marginLeft: "60px",
                              fontSize: "12px",
                              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                            }}
                            position={Position.TOP}
                          >
                            <Info style={{ marginLeft: "6px" }} />
                          </Tooltip>
                        </div>
                        <div className="corners-tabs">
                          <div className="create-exp-tabs-group">
                            <button
                              name={"rounded"}
                              onClick={() => {
                                addEvents("design", "update", {
                                  ["buttonShape"]: "rounded",
                                });
                              }}
                              className={
                                experience?.design.buttonShape !== "squared"
                                  ? "design-exp-tabs-group-active"
                                  : ""
                              }
                            >
                              Rounded
                            </button>
                            <button
                              name={"squared"}
                              onClick={() => {
                                addEvents("design", "update", {
                                  ["buttonShape"]: "squared",
                                });
                              }}
                              className={
                                experience?.design.buttonShape === "squared"
                                  ? "design-exp-tabs-group-active"
                                  : ""
                              }
                            >
                              Squared
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            });
          })}
        </div>
        <div className="design-v2-colors-controller">
          <div className="design-property-row design-row-titles">
            <p className="design-property-name" />
            <div className="design-properties-titles design-properties">
              <p>Background</p>
              <p>Border</p>
              <p>Text</p>
            </div>
          </div>
          <div className="design-v2-rows">
            {Object.keys(type)
              .filter((heading) => heading !== "introScreen")
              .flatMap((heading) => (
                <div className="design-property-row">
                  <p className="design-property-name">
                    {camelCaseToTitleCase(heading)}
                  </p>
                  <div className="design-properties">
                    {Object.keys(type[heading]).map((item, idx) => {
                      if (type[heading][item] === "font") return null;
                      if (
                        type[heading][item] === "color" ||
                        type[heading][item] === "gradientColor"
                      ) {
                        return (
                          <ColorPickerWrapper
                            key={heading + type[heading][item] + idx}
                            type={type}
                            prefix=""
                            designOBJ={experience?.design}
                            heading={heading}
                            item={item}
                            handleColor={handleColor}
                          />
                        );
                      } else {
                        return (
                          <div
                            style={{
                              minWidth: "22px",
                              margin: "0px 21.5px",
                              color: "#DBE1E7",
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              height: "1px",
                              border: "1px solid #DBE1E7",
                            }}
                          ></div>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
