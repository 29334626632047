import { useEffect, useState } from "react";
import useEditor from "../../../../../stores/use-editor";
import { useEditorEvents } from "../../../../../stores/use-editor-events";
import { Pane } from "evergreen-ui";
import Switch from "../../../../../components/Switch/Switch";
import "./ExpIntro.scss";
import "../../../../../pages/EditorPage/EditorPage.scss";
import { useDesign } from "../../../../../stores/design";
import EditorQuizIntro from "../Quiz/EditorQuizIntro";
import EditorSwiperIntro from "../Quiz/EditorSwiperIntro";

const EditorExpIntro = ({ className }) => {
  const { experience, setPreviewTag } = useEditor();
  const { addEvents } = useEditorEvents();
  const { setDesign } = useDesign();
  const [isOpen, setIsOpen] = useState(false);
  const introDesign = experience?.design?.intro || {};

  const onClickTitle = () => {
    setPreviewTag({
      image: "",
      sentence: "",
      matchingProducts: [],
      tag: {
        id: "",
        type: "",
        name: "",
      },
    });
    setIsOpen(!isOpen);
  };


  return (
    <div className={className}>
      <div className="data">
        <div className="data-holders">
          <Pane
            display="flex"
            alignItems="center"
            gap={16}
            marginBottom={30}
            marginTop={26}
          >
            <div>Intro screen</div>
            <Switch
              switchVal1={"enabled"}
              switchVal2={"disabled"}
              onChange={(enabled) => {
                addEvents("design", "update", {
                  [`introScreen`]:
                    enabled === "enabled" ? "enabled" : "disabled",
                });
                setDesign(
                  "introScreen",
                  enabled === "enabled" ? "enabled" : "disabled"
                );
              }}
              defaultValue={
                experience?.design?.introScreen === "enabled"
                  ? "enabled"
                  : "disabled"
              }
            />
          </Pane>
          <Pane display="flex" alignItems="center" gap={22} marginBottom={30}>
            <div>Animation</div>
            <Switch
              switchVal1={"enabled"}
              switchVal2={"disabled"}
              onChange={(enabled) => {
                addEvents("design", "update", {
                  [`animation`]: enabled === "enabled" ? "enabled" : "disabled",
                });
                addEvents("design", "update", {
                  [`intro/subtitle`]: experience?.design?.intro?.subtitle ?? "Just answer a few questions, and we'll recommend products that fit your needs",
                });
                setDesign(
                  "animation",
                  enabled === "enabled" ? "enabled" : "disabled"
                );
              }}
              defaultValue={
                experience?.design?.animation === "disabled"
                  ? "disabled"
                  : "enabled"
              }
            />
          </Pane>
          {experience?.design?.introScreen === "enabled" && (
            <>
              {experience.type === "QUIZ" && <EditorQuizIntro />}
              {experience.type === "SWIPER" && <EditorSwiperIntro />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditorExpIntro;
