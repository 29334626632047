import { Client } from "Client";
import { Dialog } from "evergreen-ui";
import { useEffect, useState } from "react";
import useEditor from "stores/use-editor";
import CustomTagsSearch from "../../../components/CustomTags/CustomTagsSearch";
import "./ExpPlacementsModal.scss";
import ExpPlacementTypeSlider from "./ExpPlacementTypeSlider";
import useAuth from "../../../stores/auth";
import ExpPlacementsModalHeader from "../../../components/ExpPlacementsModal/ExpPlacementsModalHeader";
import ExpPlacementsModalStep1 from "../../../components/ExpPlacementsModal/ExpPlacementsModalStep1";
import ExpPlacementsModalStep2 from "../../../components/ExpPlacementsModal/ExpPlacementsModalStep2";
import ExpPlacementsModalStep3 from "../../../components/ExpPlacementsModal/ExpPlacementsModalStep3";
import ExpPlacementsModalFooter from "../../../components/ExpPlacementsModal/ExpPlacementsModalFooter";

const ExpPlacementsModal = ({
  experience,
  editExpOBJ,
  setEditExpOBJ,
  changeExp,
  setIsPlacementsModalOpen,
  saveEditedExperience,
  lastStepButtonText,
  handleForceFetch,
}) => {
  const { updateExperience } = useEditor();
  const accountOBJ = useAuth((s) => s.accountOBJ);
  const [isManuelSetUp, setIsManuelSetUp] = useState(false);
  const [step, setStep] = useState(1);
  const [isPathSet, setIsPathSet] = useState(false);
  const expImpType = experience?.locations?.[0]?.implementationType;
  const implementationType = editExpOBJ?.implementationType || expImpType;
  const embedded = implementationType !== "POPUP" && implementationType !== "MANUAL_POPUP";
  const isSkeepXAndPopup = accountOBJ?.plan === "Skeep X" && !embedded;
  let isShopifyUser = accountOBJ?.integrations?.includes("SHOPIFY");


  useEffect(() => {
    if (expImpType === "MANUAL_POPUP") {
      setIsManuelSetUp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience?.locations?.[0]?.implementationType]);

  const disableSaveButton = () => {
    if (step === 1) {
      if (
        !isShopifyUser &&
        !editExpOBJ.path &&
        !experience?.locations?.[0]?.path
      )
        return true;
      if (!embedded && isManuelSetUp) return false;
      if (!embedded && editExpOBJ?.delay === "") return true;
    }

    // if (step === 2 && !isPathSet) return true;
    return false;
  };

  const changeExpPath = (keyName, value) => {
    setEditExpOBJ((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };




  const publishExp = async () => {
    await saveEditedExperience();
    await delay(500);
    await Client.put(`/emp/experiences/${experience?.id}/status`, {
      status: "LIVE",
    }).then(() => {
      updateExperience({
        ...experience,
        status: "LIVE",
      });
    });

    if (handleForceFetch) handleForceFetch(experience?.id);
    setIsPlacementsModalOpen(undefined);
  };

  return (
    <>
      <Dialog
        isShown={!!experience?.id}
        width="605px"
        topOffset="20vh"
        onCloseComplete={() => setIsPlacementsModalOpen(undefined)}
        hasFooter={false}
        overlayProps={{
          zIndex:21
        }}
        shouldCloseOnOverlayClick={false}
        hasHeader={false}
        contentContainerProps={{ padding: "30px", overflow: "hidden" }}
        containerProps={{
          borderRadius: "16px",
          overflow: "hidden",
          minHeight: 460,
        }}
      >
        <div className="exp-placements-modal">
          <ExpPlacementsModalHeader
            step={step}
            setStep={setStep}
            setIsPlacementsModalOpen={setIsPlacementsModalOpen}
            setEditExpOBJ={setEditExpOBJ}
          />

          {step === 1 && (
            <ExpPlacementsModalStep1
              editExpOBJ={editExpOBJ}
              changeExp={changeExp}
              embedded={embedded}
              experience={experience}
              setIsPlacementsModalOpen={setIsPlacementsModalOpen}
              setIsManuelSetUp={setIsManuelSetUp}
              isManuelSetUp={isManuelSetUp}
              isSkeepXAndPopup={isSkeepXAndPopup}
              isShopifyUser={isShopifyUser}
            />
          )}

          {step === 2 && (
            <ExpPlacementsModalStep2
              editExpOBJ={editExpOBJ}
              expImpType={expImpType}
              changeExpPath={changeExpPath}
              setIsPathSet={setIsPathSet}
            />
          )}

          {step === 3 && (
            <ExpPlacementsModalStep3
              editExpOBJ={editExpOBJ}
              isManuelSetUp={isManuelSetUp}
              experience={experience}
              embedded={embedded}
            />
          )}

          <ExpPlacementsModalFooter
            step={step}
            setStep={setStep}
            disableSaveButton={disableSaveButton()}
            embedded={embedded}
            publishExp={publishExp}
            isManuelSetUp={isManuelSetUp}
            lastStepButtonText={lastStepButtonText}
            saveEditedExperience={saveEditedExperience}
            isShopifyUser={isShopifyUser}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ExpPlacementsModal;

export function PathMenu({ experience, editExpOBJ, setEditExp }) {
  const onPlacementChange = (v) => {
    if (v?.[0] === "Homepage") {
      v = "/";
    }
    setEditExp("path", v.length > 0 ? v[0] : null);
  };

  const defaultValue =
    editExpOBJ?.path == null || editExpOBJ?.path
      ? editExpOBJ?.path
      : experience?.locations?.[0]?.path;
  const defaultValueTweaked = defaultValue === "/" ? "Homepage" : defaultValue;

  return (
    <CustomTagsSearch
      placeHolder="Search path"
      noTags
      name="path"
      label={null}
      width={500}
      height={40}
      onTagChange={onPlacementChange}
      onlyOneTag={defaultValueTweaked}
      api="/emp/sitemap"
      type="GET-QUERY"
      style={{ marginBottom: 0 }}
    />
  );
}

export function ImplementationTabs({
  editExpOBJ,
  changeExp,
  experience,
  setIsPlacementsModalOpen,
}) {
  const implementationType =
    editExpOBJ?.implementationType ||
    experience?.locations[0]?.implementationType;

  return (
    <>
      <div className="placement-btn-container">
        <ExpPlacementTypeSlider
          onChange={(type) => changeExp("implementationType", type)}
          selected={implementationType}
          setIsPlacementsModalOpen={setIsPlacementsModalOpen}
        />
      </div>
    </>
  );
}

const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};
