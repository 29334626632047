import {Pane} from "evergreen-ui";
import useEditor from "../../../stores/use-editor";
import OnboardingStepper from "./Stepper";

const OnboardingSwiperHeader = ({title}) => {
    const {activeQuestionIndex, changeActiveQuestionIndex, questions, questionsGenerated} = useEditor();

    return (
        <Pane display="flex" alignItems="center" gap={20}>
            <button
                className={`onboarding-swiper-buttons ${activeQuestionIndex === 0 ? "disabled-button" : ""}`}
                disabled={activeQuestionIndex === 0}
                onClick={() => changeActiveQuestionIndex(activeQuestionIndex - 1)}
            >
                <Arrow/>
            </button>
            <div style={{width:"100%",paddingBottom:45}}>
                <OnboardingStepper/>
            </div>
            <button
                className={`onboarding-swiper-buttons ${questions.length - 1 === activeQuestionIndex ? "disabled-button" : ""}`}
                disabled={questions.length - 1 === activeQuestionIndex}
                onClick={() => changeActiveQuestionIndex(activeQuestionIndex + 1)}
            >
                <Arrow/>
            </button>
        </Pane>
    )
}

export default OnboardingSwiperHeader;

const Arrow = () => {
    return (
        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6.5 7L12 1" stroke="current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}