export const convertArrayToObject = (array, key,returnString = false) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        let {[key]: _, ...rest} = item;

        if (returnString) return {[item[key]]:rest.image};
        return {
            ...obj,
            [item[key]]: rest,
        };
    }, initialValue);
};