import React, {useState} from "react";
import {ReactComponent as TagsIcon} from "../../../../../../assets/icons/tagsicon.svg";
import {ReactComponent as Delete} from "../../../../../../assets/icons/delete.svg";
import {ReactComponent as ImgIcon} from "../../../../../../assets/icons/imageicon.svg";
import Switch from "components/Switch/Switch";
import "../TagsSelection.scss";
import CustomImageInput from "components/Forms/CustomImageInput";
import TagsDeleteDialog from "../../Dialog/TagsDeleteDialog";
import uploadToS3 from "utils/uploadToS3";
import ColorPickerWrapper from "../../../ColorPickerWrapper";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import CustomInput from "components/Forms/CustomInput";
import {useTags} from "stores/use-tags";

const TagComponent = ({ tag }) => {
  const {
    experience: { design },
  } = useEditor();
  const tagIdentifier = design.tagTypes?.[tag?.type]?.tags?.[tag?.id];
  const [tagType, setTagType] = useState(
    typeof tagIdentifier !== "string" || !tagIdentifier ? "image" : "color"
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { deleteCustomTag } = useEditor();
  const {tagsDeleteDialog}=useTags()
  const handleDeleteTag = () => {
    if (tagsDeleteDialog.includes("tagComponent")) {
      deleteCustomTag(tag?.id);
    } else {
      setShowDeleteDialog(true);
    }
  };
  return (
    <div className="tags-question-tagsHolder-tag">
      <div className="flex">
        <TagsIcon fill="#5C42E1" />
        <p style={{ width: "100px" }}> {tag.name}</p>
      </div>
      <TagSelector tagType={tagType} tag={tag} />
      <div className="flex">
        <Switch
          switchVal1={"image"}
          switchVal2={"color"}
          defaultValue={tagType}
          onChange={() =>
            tagType === "image" ? setTagType("color") : setTagType("image")
          }
        />
        <Delete fill="#017CF8" onClick={handleDeleteTag} />
      </div>

      <TagsDeleteDialog
        show={showDeleteDialog}
        setShow={setShowDeleteDialog}
        name={"tagComponent"}
        tag={tag}
      />
    </div>
  );
};
const TagSelector = ({ tagType, tag }) => {
  const [s3Path, setS3Path] = useState(tag?.image);
  const {
    experience: { design },
    colorPicker,
    setColorPicker,
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const uploadImage = async (file, path) => {
    const s3PathFetched = await uploadToS3(file);
    setS3Path(s3PathFetched);
    await addEvents("design", "update", {
      [`tagTypes/${tag.type}/tags/${tag?.id}`]: null,
    });
    addEvents("design", "update", {
      [path]: s3PathFetched,
    });
  };

  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };

  return (
    <>
      {tagType === "image" ? (
        <div
          className="tags-question-tagsHolder-tag-selector"
          style={{ justifyContent: s3Path && "flex-start" }}
        >
          <CustomImageInput
            visibleInfo={false}
            onImageChange={(e) =>
              uploadImage(e, `tagTypes/${tag.type}/tags/${tag?.id}/image`)
            }
            size="small"
            icon={<ImgIcon fill="red" />}
            accept={"image/png"}
            showConfirmText={false}
            tooltip={
              s3Path
                ? "File was uploaded successfully"
                : "Maximum upload file size is 2MB, only PNG is allowed"
            }
            onDelete={() => {
              setS3Path(null);
            }}
            value={s3Path}
          />
          {s3Path && (
            <CustomInput
              type="number"
              max={100}
              value={design?.tagTypes?.[tag.type]?.tags?.[tag?.id] ?? 100}
              onChange={(e) => {
                addEvents("design", "update", {
                  [`tagTypes/${tag.type}/tags/${tag?.id}/opacity`]: `${e.target.value}%`,
                });
              }}
            />
          )}
        </div>
      ) : (
        <ColorPickerWrapper
          key={"heading + type[heading][item] + idx"}
          type={"tag"}
          prefix="tagTypes"
          designOBJ={design}
          heading={`${tag.type}`}
          heading2={`tags`}
          item={tag.id}
          handleColor={() => handleColor(`tagTypes${tag.type}tags${tag.id}`)}
          disableAlpha={false}
          obj="[]"
        />
      )}
    </>
  );
};

export default TagComponent;
