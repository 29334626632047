import "./Checkbox.scss";

const Checkbox = ({checked}) => {

    return (
        <div className="checkbox-container">
            {checked && <div className="checkbox-inner-box"/>}
        </div>
    )
}

export default Checkbox;