import OnboardingSwiperHeader from "../components/OnboardingSwiperHeader";
import {Swiper, SwiperSlide} from "swiper/react";
import {useMediaQuery} from "@mantine/hooks";
import NavigationButtons from "../components/NavigationButtons";
import useEditor from "../../../stores/use-editor";
import SwiperInvisibleController from "../components/SwiperInvisibleController";
import {ReactComponent as QuesIcon} from "../../../assets/icons/quesIcon.svg";
import {ReactComponent as Check} from "../../../assets/icons/check.svg";
import {useEffect, useState} from "react";
import {getProductsForAnswer} from "../../../utils/utls";
import useCustomTags from "../../../stores/use-custom-tags";
import useEditorActions from "../../../hooks/useEditorActions";
import {useOnboarding} from "../../../stores/use-onboarding";
import {useNavigate} from "react-router-dom";
import OnboardingAddProductsWizard from "../components/OnboardingAddProductsWizard";
import StepHeader from "./StepHeader";
const ConnectProducts = () => {
    const {questions, experience, resetInitialState, activeQuestionIndex,questionsGenerated} = useEditor()
    const [products, setProducts] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState({});
    const [activeAnswerIndex, setActiveAnswerIndex] = useState(null);
    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const {tags} = useCustomTags();
    const {handleSaveChanges} = useEditorActions()
    const [selectedProducts, setSelectedProducts] = useState([]);
    const {step, setStep,design} = useOnboarding();
    const navigate = useNavigate();
    const smallDesktop = useMediaQuery("(max-width: 1500px)");
    // When selecting an answer ->
    // 1. Save changes of the answer the user di-selects .
    // 2. fetch the newly selected answer's products.

    useEffect(() => {
        // Get products for newly selected answer
        let tagId = selectedAnswer?.tagIDs?.[0];
        if (tags[selectedAnswer?.tagIDs?.[0]]) {
            tagId = tags[selectedAnswer.tagIDs?.[0]].originalTagId;
        }

        getProductsForAnswer(experience?.id, tagId)
            .then((res) => setProducts(res))
    }, [selectedAnswer]);
    useEffect(() => {
        setActiveAnswerIndex(0);
        setSelectedAnswer(questions?.[activeQuestionIndex]?.answers?.[0]);
        setProducts([]);
        setSelectedProducts([]);
    }, [activeQuestionIndex]);


    // When moving to a diff question ->
    // 1. Save changes of the selected answer
    // 2. clear selected products of an answer.

    const handleSkipToEditorClick = async () => {
        await handleSaveChanges(true,false);
        resetInitialState();
        navigate(`/experiences/edit/${experience?.id}/content`);
    }

    const handleNextButtonClick = async () => {
        if (nextButtonLoading) return;

        setNextButtonLoading(true);
        await handleSaveChanges(true,false)
        setStep(step + 1);
    }

    return (<div className="onboarding-step space-around">
        <StepHeader/>
        <div className="onboarding-titles-container">
            <h2 style={{marginTop:"-25px"}}>Connect products to provide accurate product recommendations </h2>
            <p>You can view and edit the products connected to each answer </p>
        </div>
        {questionsGenerated && <OnboardingSwiperHeader title="Choose an answer to connect"/>}
        <Swiper
            spaceBetween={50}
            slidesPerView={smallDesktop ? 0.262 : 0.19}
            width={100}
            allowTouchMove={false}
            className="swiper-container"
        >
            <div className="wall"/>
            <SwiperInvisibleController/>
            {questions?.map((question, questionIndex) => (<SwiperSlide key={question?.id}>
                <div className="questions-products-container">
                    <div className="questions-products-header">
                        <div className="questions-products-question">
                            <div className="question-icon"><QuesIcon fill="#017CF8"/></div>
                            <p>{question?.question}</p>
                        </div>
                        <div className="questions-products-header-answers">
                            {question?.answers?.map((answerOBJ, idx) => {
                                const activeAnswer = answerOBJ.id === selectedAnswer?.id && activeQuestionIndex === questionIndex;

                                return (<div
                                    className="answer-pill"
                                    id={activeAnswer ? "answer-pill-active" :""}
                                    style={{
                                        borderColor: design?.buttons,
                                        color: activeAnswer ? "white" : design?.buttons,
                                        backgroundColor: activeAnswer ? design?.buttons : ""
                                    }}
                                    onClick={() => {
                                        setSelectedAnswer(answerOBJ);
                                        setActiveAnswerIndex(idx);
                                    }}
                                >
                                    {activeAnswer && <Check fill={"white"}/>}
                                    {answerOBJ?.answer}
                                </div>)
                            })}
                        </div>

                    </div>
                    <OnboardingAddProductsWizard
                        idx={activeAnswerIndex}
                        ans={selectedAnswer}
                        products={products}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        disabled={!selectedAnswer?.id}
                        showTabs={false}
                    />
                </div>
            </SwiperSlide>))}
        </Swiper>


        <NavigationButtons
            // disablePrimaryButton={!selectedCollection}
            handleNextButtonClick={handleNextButtonClick}
            handleSkipToEditorClick={handleSkipToEditorClick}
            nextButtonLoading={nextButtonLoading}

        />
    </div>)
}

export default ConnectProducts;