import {Client} from "../Client";

function changeBrandsPlan(argOBJ) {
    const {selected,billingInformation,setDisableButton,init} = argOBJ;
    console.log("billingInformation",billingInformation)
    setDisableButton(true);
    const id = localStorage.getItem("SKP_BRANDID");
    const planName = selected.toUpperCase();
    if ((selected === "SkeepX" && billingInformation?.hasCustomPlan) || selected !== "SkeepX") {
        if (billingInformation.plan !== null) {
            Client.put(`/emp/payment/update?plan=${planName}`).then((res) => {
                return window.location.href = res.data;
            });
        } else {
            try {
                Client.get(`/emp/payment?plan=${planName}&brandID=${id}`).then(
                    (res) => {
                        window.location = res.data;
                    }
                );
            } catch (error) {}
        }
    } else {
        Client.post("emp/skeepx/register").then((res) => {
            init();
        });
    }
}

export default changeBrandsPlan;