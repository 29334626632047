export const passwordRestrictions = [
  "8 characters",
  "Uppercase letter",
  "Lowercase letter",
  "Digit",
];

export const checkIfPasswordPass = (restriction,password) => {
    if (restriction === "8 characters") {
      return password?.length >= 8;
    }
    if (restriction === "Uppercase letter") {
      return /[A-Z]/.test(password);
    }
    if (restriction === "Lowercase letter") {
      return /[a-z]/.test(password);
    }
    if (restriction === "Digit"){
      return /\d/.test(password);
    }
};


export const stringHasHebrew = (string) => /[\u0590-\u05FF]/.test(string);