import InfoWithTooltip from "components/InfoWithTooltip/InfoWithTooltip";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Delete } from "../../assets/icons/close.svg";
import { ReactComponent as UploadImage } from "../../assets/icons/uploadImage.svg";
import { ReactComponent as Edit } from "../../assets/icons/pen-solid.svg";
import "./CustomInputs.scss";
import "react-image-crop/src/ReactCrop.scss";
import "../../pages/EditorPage/EditorPage.scss";
import { useLocation } from "react-router-dom";
import ImageGalleryModal from "./ImageGalleryModal";
import { s3ToHttpLink, urlToS3Link } from "../../utils/utls";

export default function CustomImageInput({
  label,
  defaultValue,
  isEditMode = true,
  optional,
  placeHolder = "Upload an Image",
  imageDesc,
  onChange,
  name,
  size = "medium",
  required,
  onDelete = null,
  tooltip,
  disabled = false,
  icon,
  from,
}) {
  const [publicURL, setPublicURL] = useState("");
  const [s3Url, setS3Url] = useState("");
  const uploadButtonRef = useRef();
  const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const fromEditor = useLocation()?.pathname.includes("experiences");

  const deleteFile = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore
    uploadButtonRef.current.value = "";
    if (onDelete) onDelete(e, name);
    setPublicURL("");
    setS3Url("");
  };

  useEffect(() => {
    // setLoading(true)
    if (defaultValue?.split("s3://").length === 0) {
      setPublicURL(defaultValue);
      setS3Url(urlToS3Link(defaultValue));
    } else {
      setS3Url(defaultValue);
      s3ToHttpLink(defaultValue).then((res) => {
        setPublicURL(res);
      });
    }
    setLoading(false);
  }, [defaultValue]);

  const handlePlaceHolder = () => {
    const fileName = s3Url?.split("/")?.at(-1);
    if (s3Url && from !== "intro") return fileName;

    return placeHolder;
  };

  return (
    <div className={`field image-input-field field-${size}`}>
      {label && (
        <span className="field-label">
          {label}
          {optional && isEditMode && (
            <span className="field-label-opt" style={{ color: "#75787E" }}>
              (optional)
            </span>
          )}
          {tooltip && <InfoWithTooltip tooltipDOM="adminAPI" text={tooltip} />}
        </span>
      )}
      <label
        className="field-input-container field-input-container-image"
        style={{
          backgroundImage: publicURL ? `url(${publicURL})` : "",
          backgroundColor: !publicURL ? "white" : "",
        }}
      >
        <input
          name={name}
          style={{
            background: "transparent",
          }}
          ref={uploadButtonRef}
          className="field-input"
          required={required}
          onClick={() => {
            if (disabled) return;
            setShowImageGalleryModal(true);
          }}
          readOnly
        />
        {publicURL && (
          <p
            className="edit-image-text"
            onClick={() => setShowImageGalleryModal(true)}
          >
            <Edit />
            Edit
          </p>
        )}

        {loading ? (
          <p
            className="edit-image-text"
            onClick={() => setShowImageGalleryModal(true)}
            style={{
              backgroundColor: "transparent",
              color: "gray",
              border: "1px solid #DBE1E7",
              fontSize: 12,
            }}
          >
            Loading..
          </p>
        ) : (
          <>
            {!publicURL && (!placeHolder || fromEditor) && (
              <p
                className="edit-image-text"
                onClick={() => setShowImageGalleryModal(true)}
                style={{
                  backgroundColor: "transparent",
                  color: "gray",
                  border: "1px solid #DBE1E7",
                  fontSize: 12,
                }}
              >
                {handlePlaceHolder()}
              </p>
            )}

            {(!publicURL || !s3Url) && (
              <span className="icon">
                {icon ? icon : <UploadImage fill="#017CF8" />}
              </span>
            )}
          </>
        )}

        {publicURL && !disabled && (
          <div className="delete-image" onClick={(e) => deleteFile(e, name)}>
            <Delete />
          </div>
        )}
      </label>
      
      {imageDesc && <div className="field-desc">{imageDesc}</div>}

      <ImageGalleryModal
        onImageSelect={({ publicUrl, s3Url }) => {
          setPublicURL(publicUrl);
          setS3Url(s3Url);
          onChange({
            s3Url,
            publicUrl,
          });
        }}
        shown={showImageGalleryModal}
        defaultSelected={defaultValue}
        setShown={setShowImageGalleryModal}
      />
    </div>
  );
}
