import {Dialog, Pane} from "evergreen-ui";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {useQuiz} from "stores/use-quiz";

const EditDialog = () => {
  const { changeEditAnswerIndex, activeQuestionIndex, questions } = useEditor();
  const { showEditDialog, setShowEditDialog } = useQuiz();
  const { addEvents } = useEditorEvents();
  return (
    <Pane className={"dialog"}>
      <Dialog
        isShown={showEditDialog}
        onCloseComplete={() => setShowEditDialog(false)}
        title="Edit Answer"
        hasFooter={false}
        hasHeader={false}
        width="440px"
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
        topOffset={window.innerHeight / 3}
      >
        <div className="dialog-content">
          <h4>Edit Question</h4>
          <p>
            {" "}
            Changes in the experience content may affect the precision of the
            recommendations. Do you wish to proceed?{" "}
          </p>
        </div>

        <div className="container">
          <button
            onClick={() => {
              setShowEditDialog(false);
              changeEditAnswerIndex(undefined);
            }}
            className="cancel dialog-btn"
            style={{ color: "#75787E", marginRight: "16px", cursor: "pointer", background: 'none' }}
          >
            No, Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={() => {
              addEvents("question", "update", {
                questionId: questions[activeQuestionIndex].id,
                quesIdx: activeQuestionIndex,
                origin: "BRAND",
                isNew: false,
              });
              setShowEditDialog(false);
            }}
            style={{
              background: "#017CF8",
              marginRight: "16px",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            Yes, Continue
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default EditDialog;
