export const SWIPER = {
    page:{
        background: "gradientColor",
        font: "font",
        border: 'color',
        text: "color",
    },
    statement: {
        background: null,
        border:null,
        text: "color",
    },
    bottomBar: {
        background: "color",
        border:null,
        text: null,
    },
    introScreen:{
        enabled:"enabled",
    },
    navigationButton: {
        background: "color",
        border: "color",
        text: "color",
      },
}