import "./LoadingPage.scss"

const LoadingPage = () => {

    return(
        <div className="app-page-content" style={{ justifyContent: 'center', display: "flex", height: '80vh', alignItems: 'center' }}>
            <div className="dot-stretching"></div>
        </div>
    )
}

export default LoadingPage;