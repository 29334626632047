import {DEV} from "../Client";

const previewURL = (experienceOBJ, brand) => {
    const placement = experienceOBJ?.locations[0];
    if (DEV) {
        return (
            window.location.protocol +
            "//" +
            window.location.host +
            "/preview?sp=" +
            window.btoa(
                unescape(
                    encodeURIComponent(
                        `{"experienceID":"${
                            experienceOBJ.id
                        }","implementationType":"${placement?.implementationType?.toLowerCase()}","element":"${
                            placement?.settings?.parentID
                        }"}`
                    )
                )
            )
        );
    } else {
       return brand?.ecommerceURL +
        placement?.path +
        "?sp=" +
        window.btoa(
            unescape(
                encodeURIComponent(
                    `{"experienceID":"${
                        experienceOBJ.id
                    }","implementationType":"${placement?.implementationType?.toLowerCase()}","element":"${
                        placement?.settings?.parentID
                    }"}`
                )
            )
        );
    }
}
export default previewURL;