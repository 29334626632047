import React, {useLayoutEffect, useRef, useState} from "react";
import "./Info.scss";

function Info({ title, pos, trigger = "CLICK", width = "150" }) {
  const [isShow, SetShow] = useState(false);
  const [position , setPosition] = useState(pos);
  const refContainer = useRef(null);

  useLayoutEffect(() => {
    if (!isInViewport(refContainer.current)) {
      if(position === 'BOTTOM' || position === 'TOP') setPosition('RIGHT');
    }
    const onScroll = () => {
      SetShow(false);
    };
    // clean up code
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  // const toggle = useCallback(() => {
  //   return trigger === "CLICK" ? SetShow(!isShow) : undefined;
  // }, [isShow, close, open]);

  const handleOpenHover = () => {
    return trigger === "HOVER" ? SetShow(true) : undefined;
  };

  const handleCloseHover = () => {
    return trigger === "HOVER" ? SetShow(false) : undefined;
  };

  const Content = ({position}) => {
    return (
      <div
        className={`info-container-tooltip ${
          isShow ? "show" : "hide"
        } ${position}`}
      >
        <div
          className="info-container-tooltip-wrapper" ref={refContainer}
          style={{ minWidth: width + "px" }}
        >

        </div>
      </div>
    );
  };
  const isInViewport = (el) => {
    if(!el) return null;
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return null;
}

export default Info;
