import {Outlet} from "react-router-dom";
import Welcome from "../../components/Auth/Welcome/Welcome";
import "./AuthPage.scss";

function AuthPage(type = "SIGN_IN") {
  return (
    <div className="auth-page">
      <Welcome type={type} />
      <Outlet />
    </div>
  );
}

export default AuthPage;
