import React from "react";
import {ReactComponent as BackButton} from "../../../assets/icons/back-button.svg";

const EditorBackButton = ({onClick,styles}) => {


    return (
        <div
            onClick={onClick}
            style={{
                display: "inline-block",
                marginBottom: 10,
                cursor: "pointer",
                ...styles
            }}
        >
            <BackButton style={{ verticalAlign: "middle" }} />
            <span
                style={{
                    verticalAlign: "middle",
                    fontFamily: "Satoshi",
                    fontSize: "13px",
                }}
            >
            Back
          </span>
        </div>
    )
}

export default EditorBackButton;