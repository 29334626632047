import React from "react";
import "./BillingButton.scss";

export default function BillingButton({
  name,
  currentPlan,
  has_pricing,
  text,
  selected,
  getSelectedPlan,
  disabledPlan,
  disabledButton,
    style,
  goToPayment
}) {
  const disabled =
    !currentPlan || disabledPlan || disabledButton || text === "In review";
  return (
    <button
      style={{ cursor: disabled ? "" : "pointer" ,...style}}
      onClick={() => {
        getSelectedPlan(name);
      }}
      disabled={
        !currentPlan || disabledPlan || disabledButton || text === "In review"
      }
      className={
        disabledPlan
          ? "button-container-disabled"
          : (!currentPlan && !has_pricing) || text === "In review"
          ? "button-container-skeepx"
          : (!currentPlan || selected) && has_pricing
          ? "button-container-active"
          : has_pricing
          ? "button-container"
          : "button-container-no-pricing"
      }
    >
      <p
        className={
          disabledPlan
            ? "text-disabled"
            : (!currentPlan && !has_pricing) || text === "In review"
            ? "text-skeepx"
            : (!currentPlan || selected) && has_pricing
            ? "text-active"
            : "text"
        }
      >
        {text}
      </p>
    </button>
  );
}
