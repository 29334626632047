import React, {useRef, useState} from "react";
import {QUIZ} from "pages/EditorPage/design_templates";
import {camelCaseToTitleCase} from "utils/utls";
import {useEditorEvents} from "stores/use-editor-events";
import useEditor from "stores/use-editor";
import useClickOutside from "hooks/useClickOutside";
import uploadToS3 from "utils/uploadToS3";
import {useDesign} from "stores/design";
import ColorPickerWrapper from "../../EditorPage/components/ColorPickerWrapper";
import {DESIGN_RECOMMENDATION_KEY} from "../../../utils/finalVariables";

const RecommendationDesign = ({
  type = QUIZ,
  prefix = "",
  hideHeadings = false,
}) => {
  const {
    colorPicker,
    setColorPicker,
    experience: { design },
  } = useEditor();
  const { addEvents } = useEditorEvents();
  const [activeHeading, setActiveHeading] = useState(Object.keys(type)[0]);
  const colorPickerRef = useRef();
  const { design: recommendationDesign, activeTab, setDesign } = useDesign();
  useClickOutside(colorPickerRef, () => {
    if (colorPicker) setColorPicker(null);
  });

  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };
  const handleHeadingClick = (heading) => {
    if (activeHeading === heading) {
      setActiveHeading(null);
      return;
    }
    setActiveHeading(heading);
  };

  const pickerOpts = {
    types: [
      {
        description: "Fonts",
        accept: {
          "font/*": [".ttf"],
        },
      },
    ],
    excludeAcceptAllOption: true,
    multiple: false,
  };

  async function handleGetFontFile(option, heading, item) {
    if (option.value === "Custom") {
      try {
        let showFile = await window.showOpenFilePicker(pickerOpts);
        let file = await showFile[0].getFile();
        uploadToS3(file).then((res) => {
          addEvents("design", "update", {
            [`fonts/${heading + "_" + item}`]: res,
          });
          setDesign(`fonts/${heading + "_" + item}`, res);
        });
      } catch (e) {}
    } else {
      setDesign(`fonts/${heading + "_" + item}`, option.value);
      addEvents("design", "update", {
        [`fonts/${heading + "_" + item}`]: option.value,
      });
    }
  }

  return (
    <div style={{width:'85%'}} >
      <div className="design-v2-colors-controller">
        <p style={{ fontSize: 12 }}>Colors</p>
        <div className="design-property-row design-row-titles">
          <p className="design-property-name" />
          <div className="design-properties-titles design-properties">
            <p>Background</p>
            <p>Border</p>
            <p>Text</p>
          </div>
        </div>
        <div className="design-v2-rows">
          {Object.keys(type).flatMap((heading) => {
            if (
              !type[heading]?.color &&
              !type[heading]?.background &&
              !type[heading]?.border
            )
              return;
            return (
              <div className="design-property-row">
                <p className="design-property-name">
                  {camelCaseToTitleCase(heading)}
                </p>
                <div className="design-properties">
                  {Object.keys(type[heading]).map((item, idx) => {
                    if (
                      type[heading][item] === "color" ||
                      type[heading][item] === "gradientColor"
                    ) {
                      return (
                        <ColorPickerWrapper
                          key={heading + type[heading][item] + idx}
                          type={type}
                          prefix={DESIGN_RECOMMENDATION_KEY}
                          designOBJ={design}
                          heading={heading}
                          item={item}
                          handleColor={handleColor}
                        />
                      );
                    } else {
                      return (
                        <div
                          style={{
                            minWidth: "22px",
                            margin: "0px 21.5px",
                            color: "#DBE1E7",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "24px",
                            height: "1px",
                            border: "1px solid #DBE1E7",
                          }}
                        ></div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RecommendationDesign;
