import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SliderArrow from "../../../assets/icons/SliderArrow";
import embedded from "../../../assets/images/placement-slider/embedded.webp";
import banner from "../../../assets/images/placement-slider/banner.webp";
import banner_disabled from "../../../assets/images/banner-disabled.svg";
import popup from "../../../assets/images/placement-slider/popup.webp";
import product_grid from "../../../assets/images/placement-slider/product_grid.webp";
import product_grid_disabled from "../../../assets/images/product-grid-disabled.svg";
import { ReactComponent as InfoSign } from "../../../assets/icons/info-tag.svg";
import "./ExpPlacementTypeSlider.scss";
import { useEffect, useState } from "react";
import { Client } from "Client";
import { Tooltip } from "evergreen-ui";
import UpgradeBillingModal from "./UpgradeBillingModal";
import {ClassNames} from "@emotion/react";

const ExpPlacementTypeSlider = ({
  selected = "EMBEDDED_PAGE",
  onChange = (type) => console.info(type),
  trimmedPlacements,
  setIsPlacementsModalOpen,
}) => {
  const [billingInformation, setBillingInformation] = useState({});
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const lowPayingPlans = ["FREE","BASIC","GROWTH"].includes(billingInformation?.plan);

  let templates = [
    { key: "EMBEDDED_PAGE", img: embedded, label: "Embedded page" },
    {
      key: "POPUP",
      img: popup,
      label: "Pop-Up",
    },
    {
      key: "BANNER",
      img: lowPayingPlans ? banner_disabled : banner,
      label: "Banner",
    },
    {
      key: "PRODUCT_GRID",
      img: lowPayingPlans ? product_grid_disabled : product_grid,
      label: "Product grid",
    },
  ];

  templates = trimmedPlacements ? templates.slice(0, 2) : templates;

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: <SliderArrow blur type={"next"} currentSlide />,
    prevArrow: <SliderArrow blur type={"previous"} currentSlide />,
  };

  useEffect(() => {
    Client.get("/emp/billing")
      .then((res) => {
        setBillingInformation(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const isDisable = (key) => {
    if (lowPayingPlans && (key === "BANNER" || key === "PRODUCT_GRID")) {
      return true;
    } else {
      return false;
    }
  };

  const classNameType = (key) => {
      let tempSelected = selected;
      if (isDisable(key)) return "";
      if (tempSelected === "MANUAL_POPUP") {
        tempSelected = "POPUP"
      }
      if (tempSelected === key) return "selected";
  };

  return (
    <Slider className="exp-placement-slider" adaptiveHeight {...settings}>
      {templates.map((template) => (
        <div
          style={{ position: "relative" }}
          key={template.key}
          className={`type-slide-container ${classNameType(template.key)}`}
          onClick={() => {
            if (isDisable(template.key)) {
              return null;
            } else {
              onChange(template.key);
            }
          }}
        >
          {isDisable(template.key) ? (
            <Tooltip
              statelessProps={{
                height: "51px",
                marginTop: "5px",
                marginLeft: "60px",
                backgroundColor: "#FFE4B4",
                color: "#75787e",
                maxWidth: "276px",
              }}
              content={
                <div
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    letterSpacing: "-0.02em",
                    fontFamily: "Satoshi",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  <InfoSign
                    fill="#F4B037"
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 7,
                      border: "1px solid #f4b037",
                      borderRadius: "100%",
                      height: "13px",
                      width: "14px",
                    }}
                  />
                  This implementation is available for platinum plan and above
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "#017CF8",
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginRight: "2px",
                    }}
                    onClick={() => {
                      setIsUpgradePlanModalOpen(true);
                      // setIsPlacementsModalOpen(undefined);
                    }}
                  >
                    Upgrade
                  </span>
                  your plan to unlock.
                </div>
              }
            >
              <img
                style={{
                  cursor: "pointer",
                  width: "94%",
                  // position: "relative"
                }}
                src={template.img}
                alt=""
              />
            </Tooltip>
          ) : (
            <img
              style={{
                width: "94%",
              }}
              src={template.img}
              alt=""
            />
          )}

          <p>{template.label}</p>
        </div>
      ))}
      {isUpgradePlanModalOpen && (
        <UpgradeBillingModal
          from="placement"
          isUpgradePlanModalOpen={isUpgradePlanModalOpen}
          closeModal={() => setIsUpgradePlanModalOpen(false)}
        />
      )}
    </Slider>
  );
};

export default ExpPlacementTypeSlider;
