import CreateDialog from "../CreateDialog";

const CreateExperienceSection = ({
  onCreate,
  isNewExpModalVisible,
  setIsNewExpModalVisible,
  setFailed,
}) => {

  return (
    <div className="exp-page-create-container">
      <CreateDialog
        show={isNewExpModalVisible}
        setShow={setIsNewExpModalVisible}
        onCreate={onCreate}
        setFailed={setFailed}
      />
    </div>
  );
};

export default CreateExperienceSection;
