

export const mockupProducts = [
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
    {
        "id": "63a1b92d78c2f5bb17e5103d",
        "brandID": "",
        "name": "Product Name",
        "url": "",
        "externalID": "",
        "description": "Lorem ipsum dolor sit amet",
        "type": "638bb3d9445c15dc697aadd4",
        "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png"],
        "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
        "faq": [
            { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
            { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
            { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
            { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
            { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
            { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
            { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
            { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
            { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
            { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
        ],
        "reviews": {averageScore: 4.5, totalReviews: 555, testimonials: [{},{}]},
        "variants": [
            {
                "name": "Product Name",
                "price": "20.00",
                "currency": "USD",
                "cartLink": "",
                "externalID": "39602917474352",
                "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
                "options": ["TOTE BAG"]
            }
        ],
        "date": null,
        "lastModified": null
    },
]


export const mockupProduct = {
    "id": "63a1b92d78c2f5bb17e5103d",
    "brandID": "63a1b92044a2011be482c37d",
    "name": "TOTEBAG",
    "url": "https://myrite.co//products/totebag",
    "externalID": "6637608468528",
    "description": "\n\nWhite\n\n",
    "type": "638bb3d9445c15dc697aadd4",
    "images": ["https://i.ibb.co/GTvZS51/image-Placeholder.png","https://i.ibb.co/GTvZS51/image-Placeholder.png","https://i.ibb.co/GTvZS51/image-Placeholder.png"],
    "tags": ["Digestion", "Stress", "Gummy", "Multivitamin"],
    "faq": [
        { "question": "why does my body need health supplements ?", "answer": "Balanced and varied nutrition is vital for a happy and healthy life. However," },
        { "question": "what are the benefits of each products ?", "answer": "**ENERGY:** Our unique formulation will help you to fight fatigue, strengthen" },
        { "question": "can i take multiple products together ?", "answer": "Yes, absolutely. We have crafted our gummies so they can be used in" },
        { "question": "will glow gummies cause my body hair to grow longer too ?", "answer": "No, body hair does not grow in the same manner as the hair on your head. The" },
        { "question": "how should i store my gummies, and how long can i use them ?", "answer": "We recommend storing your rite. gummies in a cool, dry place at room" },
        { "question": "can color variations happen in my gummies ?", "answer": "Because we use only natural colors derived from fruits, vegetables and plants" },
        { "question": "are rite jars recyclable ?", "answer": "Absolutely! Our jars are made of 100% recyclable PET and our lids of 100%" },
        { "question": "how can i track my order ?", "answer": "You should have received a shipping confirmation email. If you did not receive" },
        { "question": "what forms of payment can i use ?", "answer": "We accept domestic & international credit cards including Visa, MasterCard and" },
        { "question": "how long will it take to receive my rite gummies ?", "answer": "**UAE Delivery**" }
    ],
    "reviews": null,
    "variants": [
        {
            "name": "TOTE BAG",
            "price": "20.00",
            "currency": "USD",
            "cartLink": "https://myrite.co//cart/39602917474352:1?discount=",
            "externalID": "39602917474352",
            "image": "https://i.ibb.co/GTvZS51/image-Placeholder.png",
            "options": ["TOTE BAG"]
        }
    ],
    "date": null,
    "lastModified": null
}

export const mockupDesign = {
    "recommendation": {
        "title": "Here are our recommendations, tailored to your needs.",
        "page": { "background": "#ededed", "text": "#000" },
        "elements": { "background": "#7D7D7D", "text": "#FFFFFF" }
    },
    "fonts": { "primary_font": "gFont://Montserrat" },
    "product": {
        "page": { "background": "#ededed", "text": "#000" },
        "elements": { "background": "#7D7D7D", "text": "#FFFFFF" },
        // "fixedBar": { "background": "#FFFF", "text": "#000" },
        // "fixedBarElements": { "background": "#7D7D7D", "text": "#FFFFFF", "border": "#FFF" },
        // "extraSection": { "background": "#7D7D7D", "text": "#FFFFFF" }
    }
}