import {Client} from "Client";
import {useEffect, useState} from "react";

const useFetchFromS3 = (s3Path, srt) => {
  const [filePublicURL, setFilePublicURL] = useState("");

  useEffect(() => {
    if (!s3Path) return setFilePublicURL("");

    const getPublicURL = async () => {
      if (srt) {
        const publicURL = await Client.post(
          "onboarding/view/file",
          { s3Path },
          {
            headers: {
              "X-SRT": srt,
            },
          }
        );
        setFilePublicURL(publicURL?.data);
      } else {
        const publicURL = await Client.post("emp/view/file", { s3Path });
        setFilePublicURL(publicURL?.data);
      }
    };

    getPublicURL();
  }, [s3Path, srt]);

  return filePublicURL;
};

export default useFetchFromS3;
