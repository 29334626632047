import "./IntegrationPage.scss";
import React, {useEffect, useState} from "react";
import Mailchimp from "../../assets/images/mailchimp.webp";
import Klaviyo from "../../assets/images/klaviyo.webp";
import Flashy from "../../assets/images/flashy.webp";
import {Client} from "../../Client";
import Shopify from "../../assets/images/shopify.webp";
import WooCommerce from "../../assets/images/woocommerce.webp";
import LoadingPage from "../LoadingPage/LoadingPage";
import useAuth from "stores/auth";

import InfoIcon from "../../assets/icons/info-gray.svg";
import Button from "components/Button/Button";
import UnsuccessfulLoadData from "components/UnsuccessfulLoadData/UnsuccessfulLoadData";
import Header from "components/Header/Header";
import {BiInfoCircle} from "react-icons/bi";
import {Pane} from "evergreen-ui";

const IntegrationPopUp = ({
  handlePublishApiKey,
  handlePrivateApiKey,
  handleApiKey,
  apiKey,
  handlePopup,
  integration,
  publishApiKey,
  privateApiKey,
  setShowPopUp,
  invalidPrivateKey,
  invalidPublicKey,
}) => {
  const { minimized } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleIntegrate = async () => {
    setLoading(true);
    if (integration.name === "Klaviyo" || integration.name === "Flashy") {
      try {
        await Client.post("/emp/marketing", {
          platform: integration.name === "Klaviyo" ? "KLAVIYO" : "FLASHY",
          platformCustomData: integration.name === "Klaviyo" ? {
            publicKey: publishApiKey,
            privateKey: privateApiKey
          } : {
            apiKey: apiKey,
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setShowPopUp(false);
        handlePopup();
        handlePublishApiKey("");
        handlePrivateApiKey("");
      }
    }

    if (integration.name === "WooCommerce") {
      try {
        await Client.post("/emp/ecommerce/WOOCOMMERCE", { consumerKey: publishApiKey, consumerSecret: privateApiKey });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setShowPopUp(false);
        handlePopup();
        handlePublishApiKey("");
        handlePrivateApiKey("");
      }
    }
  };

  return (
    <div
      className={
        minimized
          ? "integrations-pop-up-minimized"
          : "integrations-pop-up-container"
      }
    >
      <div className="integrations-pop-up-box">
        <p className="integrations-pop-up-header">API credentials</p>
        <p className="integrations-pop-up-subheader">
          Set up your credentials to integrate your account into{" "}
          {integration.name}
        </p>
        { integration.name === "Klaviyo" && (
        <div className="integrations-pop-up-input-container">
         <div className="integrations-pop-up-input">
            <div className="integrations-pop-up-input-header-container">
              <p className="integrations-pop-up-input-header-text">
                Private API Key
              </p>
              <img src={InfoIcon} />
            </div>
            <input
              type="text"
              value={privateApiKey}
              className="integrations-pop-up-input-type-left"
              onChange={(e) => handlePrivateApiKey(e.target.value)}
            />
            <Error error={invalidPrivateKey} />
          </div>
          <div className="integrations-pop-up-input">
            <div className="integrations-pop-up-input-header-container">
              <p className="integrations-pop-up-input-header-text">
                Public API Key
              </p>
              <img src={InfoIcon} />
            </div>
            <input
              type="text"
              value={publishApiKey}
              className="integrations-pop-up-input-type"
              onChange={(e) => handlePublishApiKey(e.target.value)}
            />
            <Error error={invalidPublicKey} />
          </div>
        </div>
        ) || integration.name === "Flashy" && (
            <div className="integrations-pop-up-input-container">
              <div className="integrations-pop-up-input">
                <div className="integrations-pop-up-input-header-container">
                  <p className="integrations-pop-up-input-header-text">
                    API Key
                  </p>
                  <img src={InfoIcon} />
                </div>
                <input
                    type="text"
                    value={apiKey}
                    className="integrations-pop-up-input-type"
                    onChange={(e) => handleApiKey(e.target.value)}
                />
              </div>

            </div> )
            || integration.name === "WooCommerce" && (
                <div className="integrations-pop-up-input-container">
                  <div className="integrations-pop-up-input">
                    <div className="integrations-pop-up-input-header-container">
                      <p className="integrations-pop-up-input-header-text">
                        Consumer Key
                      </p>
                      <img src={InfoIcon} />
                    </div>
                    <input
                        type="text"
                        value={privateApiKey}
                        className="integrations-pop-up-input-type-left"
                        onChange={(e) => handlePrivateApiKey(e.target.value)}
                    />
                    <Error error={invalidPrivateKey} />
                  </div>
                  <div className="integrations-pop-up-input">
                    <div className="integrations-pop-up-input-header-container">
                      <p className="integrations-pop-up-input-header-text">
                        Consumer Secret
                      </p>
                      <img src={InfoIcon} />
                    </div>
                    <input
                        type="text"
                        value={publishApiKey}
                        className="integrations-pop-up-input-type"
                        onChange={(e) => handlePublishApiKey(e.target.value)}
                    />
                    <Error error={invalidPublicKey} />
                  </div>
                </div>
            )
        }
        <p className="integrations-pop-up-footer">
          Need help getting the API Keys from {integration.name}?{" "}
          <a
            className="integrations-pop-up-link"
            href={integration.name === "Flashy"?"https://flashy.app/help/integrations/how-to-find-your-api-key/":integration.name === "WooCommerce"? "https://woocommerce.com/document/woocommerce-rest-api/":"https://help.klaviyo.com/hc/en-us/articles/7423954176283-How-to-create-a-private-API-key"}
            target="_blank"
          >
            Learn how
          </a>
        </p>
        <div className="integrations-pop-up-button-container">
          <Button
            text="Cancel"
            type="CANCEL"
            style={{ backgroundColor: "transparent", width: "90px" }}
            onClick={() => {
              handlePopup(false);
              handlePublishApiKey("");
              handlePrivateApiKey("");
            }}
          />
          <Button
            text="Integrate"
            type="SUBMIT"
            onClick={() => handleIntegrate()}
            loading={loading}
            disabled={
              loading ||
                (integration.name === "Klaviyo" && invalidPrivateKey || invalidPublicKey || !publishApiKey || !privateApiKey) &&
                (integration.name === "Flashy" && !apiKey)
            }
          />
        </div>
      </div>
    </div>
  );
};

const IntegrationPopUpDelete = ({
  integration,
  setLoading,
  setShowPopUpDelete,
  handleDeletePopup,
}) => {
  const { minimized } = useAuth();
  const handleUnlink = async () => {
    setLoading(true);
    try {
      await Client.delete("/emp/marketing", {});
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setShowPopUpDelete(false);
      handleDeletePopup();
    }
  };

  return (
    <div
      className={
        minimized
          ? "integrations-delete-pop-up-minimized"
          : "integrations-delete-pop-up-container"
      }
    >
      <div className="integrations-delete-pop-up-box">
        <p className="integrations-delete-pop-up-header">
          Disconnect Integration
        </p>
        <p className="integrations-delete-pop-up-details">
          Please note, you will be disconnected to {integration.name}. <br />
          Do you wish to disconnect?
        </p>
        <div className="integrations-delete-pop-up-button-container">
          <Button
            text="Cancel"
            type="CANCEL"
            style={{ backgroundColor: "transparent", width: "90px" }}
            onClick={() => setShowPopUpDelete(false)}
          />
          <Button
            text="Disconnect"
            type="BUTTON"
            onClick={() => handleUnlink()}
            style={{ backgroundColor: "#E45447" }}
          />
        </div>
      </div>
    </div>
  );
};

const IntegrationPage = () => {
  const [activeIntegrations, setActiveIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [privateApiKey, setPrivateApiKey] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [publishApiKey, setPublishApiKey] = useState("");
  const [invalidPublicKey, setInvalidPublicKey] = useState("");
  const [invalidPrivateKey, setInvalidPrivateKey] = useState("");

  const [integration, setIntegration] = useState("");

  const [unsuccessfulLoad, setUnsuccessfulLoad] = useState(false);

  const [showTour, setShowTour] = useState(false);
  const [tourCurrentIndex, setTourCurrentIndex] = useState(0);

  function handleCurrentTourIndex(index) {
    setTourCurrentIndex(index);
  }

  function handleOpenTour(value) {
    setTourCurrentIndex(0);
    setShowTour(value);
  }

  function handlePrivateApiKey(value) {
    const regex = new RegExp("pk_[a-z0-9]*|ck_[a-z0-9]*");
    if (value.length > 0)
      setInvalidPrivateKey(!regex.test(value) ? "Invalid Public Key" : "");
    setPrivateApiKey(value);
  }

  function handleApiKey(value) {
    setApiKey(value);
  }

  function handlePublishApiKey(value) {
    const regex = new RegExp("[A-Za-z0-9]{6}|cs_[a-z0-9]*");
    if (value.length > 0)
      setInvalidPublicKey(!regex.test(value) ? "Invalid Public Key" : "");
    setPublishApiKey(value);
  }

  function handlePopup(value) {
    setShowPopUp(value);
    if (value === false) {
      setIntegration("");
    } else {
      init();
    }
  }

  function handleDeletePopup(value) {
    setShowPopUpDelete(value);
    if (value === false) {
      setIntegration("");
    } else {
      init();
    }
  }

  function handleIntegrations(integration) {
    setIntegration(integration);
    if (integration.name === "Mailchimp" || integration.name === "Shopify") {
      window.location.href = integration.url;
    } else if (
      integration.name === "Klaviyo" ||
      integration.name === "Flashy" ||
      integration.name === "WooCommerce"
    ) {
      setShowPopUp(true);
    }
  }

  function handleDeletePopUp(integration) {
    setIntegration(integration);
    setShowPopUpDelete(true);
  }

  const integrations = [
    {
      name: "Klaviyo",
      description: "Send data from skeep to customer profiles in Klaviyo",
      logo: Klaviyo,
      url: "",
    },
    {
      name: "Mailchimp",
      description:
        "Make it easy to organize your contacts by adding new ones to your Mailchimp list and tagging them.",
      logo: Mailchimp,
      url: "https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=283038779951&redirect_uri=https%3A%2F%2Femp.skeep.io%2Fintegrations%2Fmailchimp",
    },
    {
      name: "Flashy",
      description: "Send data from skeep to customer profiles in Flashy",
      logo: Flashy,
      url: "",
    },
    {
      name: "Shopify",
      description:
        "Empower your Shopify storefront with Skeep guidance experiences.",
      logo: Shopify,
      url: "",
      type: "e-commerce",
    },
    { name: 'WooCommerce', description: 'Empower your WooCommerce storefront with Skeep guidance experiences.', logo: WooCommerce, url: '', type: "e-commerce" },
  ];

  const marketingType = ["Mailchimp", "Klaviyo", "Flashy"];
  const ecommerceType = ["Shopify", "WooCommerce"];

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setLoading(true);
    setUnsuccessfulLoad(false);
    Client.get("/emp/integrations")
      .then((res) => {
        setActiveIntegrations(res.data);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch(() => {
        setLoading(false);
        setUnsuccessfulLoad(true);
      });
  }

  if (loading)
    return (
      <div className="app-page">
        {/* {showTour && (
          <TourPopup
            tourCurrentIndex={tourCurrentIndex}
            handleCurrentTourIndex={handleCurrentTourIndex}
            handleOpenTour={handleOpenTour}
          />
        )} */}
        <Header name="Integrations" handleOpenTour={handleOpenTour} />
        <LoadingPage />
      </div>
    );

  return (
    <div className="app-page">
      {/* {showTour && (
        <TourPopup
          tourCurrentIndex={tourCurrentIndex}
          handleCurrentTourIndex={handleCurrentTourIndex}
          handleOpenTour={handleOpenTour}
        />
      )} */}
      <Header name="Integration" handleOpenTour={handleOpenTour} />
      <div className="app-page-content integrations">
        {showPopUpDelete && (
          <IntegrationPopUpDelete
            integration={integration}
            setLoading={setLoading}
            setShowPopUpDelete={setShowPopUpDelete}
            handleDeletePopup={handleDeletePopup}
          />
        )}
        {showPopUp && (
          <IntegrationPopUp
            handleApiKey={handleApiKey}
            apiKey={apiKey}
            setShowPopUp={setShowPopUp}
            privateApiKey={privateApiKey}
            publishApiKey={publishApiKey}
            handlePrivateApiKey={handlePrivateApiKey}
            handlePublishApiKey={handlePublishApiKey}
            handlePopup={handlePopup}
            integration={integration}
            invalidPrivateKey={invalidPrivateKey}
            invalidPublicKey={invalidPublicKey}
          />
        )}
        <div className="integrations-headline">
          <p className="integrations-headline-main">Manage Integrations</p>
          <p className="integrations-headline-sub">
            Save time and effort with these integrations
          </p>
        </div>

        {unsuccessfulLoad ? (
          <UnsuccessfulLoadData />
        ) : (
          <div className="integrations-container">
            {integrations?.filter((info) =>
              activeIntegrations.includes(info.name.toUpperCase())
            ).length > 0 && (
              <div className="integrations-connected-container">
                <p className="integrations-connected-header">
                  Connected Integrations
                </p>
              </div>
            )}
            {integrations?.filter((info) =>
              activeIntegrations.includes(info.name.toUpperCase())
            ).length > 0 &&
              integrations
                ?.filter((info) =>
                  activeIntegrations.includes(info.name.toUpperCase())
                )
                .map((integration) => {
                  return (
                    <div className="integrations-connected" key={integration.name}>
                      <div className="integrations-connected-top">
                        <div className="integrations-container-integration-logo">
                          <img src={integration.logo} />
                        </div>
                        <p className="integrations-connected-top-name">
                          {integration.name}
                        </p>
                      </div>
                      <p className="integrations-connected-description">
                        {integration.description}
                      </p>
                      {integration.name !== "Shopify" && integration.name !== "WooCommerce" && (
                        <div className="integrations-connected-button-container">
                          <button
                            className="integrations-connected-button"
                            onClick={() => handleDeletePopUp(integration)}
                          >
                            Disconnect
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}

            {integrations?.filter(
              (info) => !activeIntegrations.includes(info.name.toUpperCase())
            ).length > 0 && (
              <div className="integrations-all-container">
                <p className="integrations-all-header">All Integrations</p>
              </div>
            )}
            {integrations?.filter(
              (info) => !activeIntegrations.includes(info.name.toUpperCase())
            ).length > 0 &&
              integrations
                ?.filter(
                  (info) =>
                    !activeIntegrations.includes(info.name.toUpperCase())
                )
                .map((integration) => {
                  return (
                    <div className="integrations-all" key={integration.name}>
                      <div className="integrations-all-top">
                        <div className="integrations-container-integration-logo">
                          <img src={integration.logo} />
                        </div>
                        <p className="integrations-all-top-name">
                          {integration.name}
                        </p>
                      </div>
                      <p className="integrations-all-description">
                        {integration.description}
                      </p>
                      {/*{marketingType.includes(integration.name) || ecommerceType.includes(integration.name) ? null : <div className="integrations-all-button-container">*/}
                      {/*    <button className="integrations-all-button" onClick={() => handleIntegrations(integration)}>Connect</button>*/}
                      {/*</div>}*/}
                      <div className="integrations-all-button-container">
                        <button
                          className="integrations-all-button"
                          onClick={() => handleIntegrations(integration)}
                        >
                          Connect
                        </button>
                      </div>
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationPage;

const Error = function ({ error }) {
  return (
    error && (
      <Pane
        marginTop={4}
        display="flex"
        alignItems="center"
        gap={4}
        className="error"
      >
        <BiInfoCircle />
        {error}
      </Pane>
    )
  );
};
