import React, { useState, useEffect } from "react";
import useEditor from "stores/use-editor";
import { ReactComponent as Content } from "../../../assets/icons/editorcontent.svg";
import { ReactComponent as Design } from "../../../assets/icons/editordesgin.svg";
import { ReactComponent as Logic } from "../../../assets/icons/logic.svg";
import useAuth from "stores/auth";
import { Client } from "Client";

const Tabs = () => {
  const [tab, setTab] = useState("experience");
  const {
    experience,
    questions,
    activeQuestionIndex,
    setConnectedProductsLoading,
    setAnswersProductsCountFromServer,
  } = useEditor();
  // const [data, setData] = useState("content");
  const { changeTabs, setSubTab, subTab } = useEditor();
  const { setSubSidebar, setMinimized } = useAuth();
  const handleTabsChange = (type, val) => {
    setSubSidebar(false);
    setMinimized(true);
    if (type === "tab") {
      setTab(val);
      changeTabs(`${val}-${"content"}`);
      setSubTab("content");
    } else {
      setSubTab(val);
      changeTabs(`${tab}-${val}`);
    }
  };

  useEffect(() => {
    if (tab === "experience"|| subTab==="content") {
      setConnectedProductsLoading(true);
      Client.get(
        `/emp/answers/products?experienceID=${experience?.id}&questionID=${questions[activeQuestionIndex]?.id}`
      ).then((res) => {
        if (res.data) setAnswersProductsCountFromServer(res.data);
        setConnectedProductsLoading(false);
      });
    }
  }, [tab,subTab,experience?.id,questions[activeQuestionIndex]?.id]);

  return (
    <div className="editor-tabs-container">
      <div className="tabs" style={{ background: "white" }}>
        <span
          onClick={() => handleTabsChange("tab", "intro")}
          className={tab === "intro" ? "active" : undefined}
        >
          Intro
        </span>
        <div
          className="tabs-divider-container"
          style={{
            background: "#e3f1ff",
          }}
        >
          <div className="tabs-divider" />
        </div>
        <span
          onClick={() => handleTabsChange("tab", "experience")}
          className={tab === "experience" ? "active" : undefined}
        >
          Experience
        </span>

        <div
          className="tabs-divider-container"
          style={{
            background: "#e3f1ff",
          }}
        >
          <div className="tabs-divider" />
        </div>
        <span
          onClick={() => handleTabsChange("tab", "email")}
          className={tab === "email" ? "active" : undefined}
        >
          Email Collection
        </span>

        <div
          className="tabs-divider-container"
          style={{
            background: "#e3f1ff",
          }}
        >
          <div className="tabs-divider" />
        </div>

        <span
          onClick={() => handleTabsChange("tab", "results")}
          className={tab === "results" ? "active" : undefined}
        >
          Results Page
        </span>
      </div>

      <div
        className="tabs data-tabs"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span
            onClick={() => handleTabsChange("data", "content")}
            className={subTab === "content" ? "active-data" : undefined}
            style={{ fontWeight: 500 }}
          >
            <Content /> &nbsp; Content
          </span>

          <div className="tabs-divider-container">
            <div className="tabs-divider" />
          </div>

          <span
            onClick={() => handleTabsChange("data", "design")}
            className={subTab === "design" ? "active-data" : undefined}
            style={{ borderRadius: "0", fontWeight: 500 }}
          >
            <Design /> &nbsp; Design
          </span>
          {tab === "experience" ? (
            <>
              <div className="tabs-divider-container">
                <div className="tabs-divider" />
              </div>

              <span
                onClick={() => handleTabsChange("data", "logic")}
                className={subTab === "logic" ? "active-data" : undefined}
                style={{ borderRadius: "0", fontWeight: 500 }}
              >
                <Logic /> &nbsp; Logic
              </span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
