import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {ReactComponent as Settings1} from "../../assets/icons/product_skeleton.svg";
import {ReactComponent as Settings2} from "../../assets/icons/product_skeleton2.svg";
import {IoFilter} from "react-icons/io5";
import {FaChevronDown,} from "react-icons/fa";
import "./ProductPageSkeleton.scss";

const ProductPageSkeleton = () => {
  const listOfProducts = [1, 2, 3, 4, 5, 6, 7];
  function getRandomInt(max) {
    return Math.max(Math.floor(Math.random() * max),1);
  }
  console.info(getRandomInt(5))
  return (
    <div className="product-page-skeleton">
      <div className="info-box">
        <Settings1 />
        <p>
          Hold on tight! We’re working on it!
          Soon, you’ll be able to see all your scanned products along with our AI-generated tags!
        </p>
        <Settings2 />
      </div>

      <div className="filter" >
        <IoFilter color="#000000" size={25} />
        <div className="tags-skeleton">
                      <Skeleton
                        width={`70px`}
                        height={'29px'}
                        count={4}
                      />
                    </div>
      </div>
      <TableContainer style={{paddingBottom:'40px'}}>
        <Table aria-label="simple table" >
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderBottom: "none", width: "10%" }}
                className="products-table-header"
              >
                Products
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", width: "15%" }}
                className="products-table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", width: "10%" }}
                className="products-table-header"
              >
                Product ID
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", width: "12%" }}
                className="products-table-header"
              >
                Product Type
              </TableCell>
              <TableCell
                style={{ borderBottom: "none" }}
                className="products-table-header"
              >
                Tags
              </TableCell>
              <TableCell
                style={{ borderBottom: "none" }}
                className="products-table-header"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfProducts.map((product, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="products-table-tag">
                    <Skeleton width={"38px"} height={"38px"} />
                  </TableCell>
                  <TableCell className="products-table-tag">
                    <p>
                      <Skeleton width={"80%"} height={1} />
                      <Skeleton width={"80%"} height={1} />
                    </p>
                  </TableCell>
                  <TableCell className="products-table-tag">
                    <p>
                      <Skeleton width={"60%"} height={1} />
                    </p>
                  </TableCell>
                  <TableCell className="products-table-tag">
                    <p className="products-table-with-background-text">
                      <Skeleton width={"40%"} height={"32px"} />
                    </p>
                  </TableCell>
                  <TableCell className="products-table-tag">
                     

                      
                    <div className="tags-skeleton">
                      <Skeleton
                        width={`${getRandomInt(5) *10}%`}
                        height={'38px'}
                        count={getRandomInt(5)}
                      />
                      
                    
                   
                    </div>
                  </TableCell>
                  <TableCell width={'3%'}>
                  <FaChevronDown color={' #75787E'} style={{height:'10px'}}/>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductPageSkeleton;
