import ResultsContent from "./ResultsContent";
import React from "react";
import ResultsDesign from "./ResultsDesign";
import {useDesign} from "../../../stores/design";

const Content = () => {
  const { setActiveTab, activeTab } = useDesign();

  return (
    <div>
      <div className="tabs">
        <div
          className={activeTab === "recommendation" ? "active" : ""}
          onClick={() => {
            if (activeTab === "recommendation") {
              return;
            } else {
              setActiveTab("recommendation");
            }
          }}
        >
          Content
        </div>
        <div
          className={activeTab === "product" ? "active" : ""}
          onClick={() => {
            if (activeTab === "product") {
                return;
              } else {
                setActiveTab("product");
              }
          }}
        >
          Design
        </div>
      </div>
      {/*<Email/>*/}
      <ResultsContent />
      <ResultsDesign />
    </div>
  );
};

export default Content;
