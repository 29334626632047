import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header/Header.jsx";
import classes from "../../styles/OverviewPage.module.css";
import { IoChevronDownOutline } from "react-icons/io5";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { implementationOptions } from "../../Options/Options";
import icon from "../../assets/icons/info.svg";
import OverviewSummary from "../../components/OverviewSummary/OverviewSummary.jsx";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Client } from "Client.jsx";
import LoadingPage from "pages/LoadingPage/LoadingPage.jsx";
import moment from "moment/moment";
import OverViewNoData from "../../components/OverViewNoData/OverViewNoData";
import MenuWithCheckboxes from "../../components/Forms/MenuWithCheckboxes";
import { useDebounce, useSearchParam } from "react-use";
import { useNavigate } from "react-router-dom";
import useOverviewFilters from "../../hooks/useOverviewFilters";
import OverviewBox from "../../components/OverviewSummary/OverviewBox";
// import {MdDesktopWindows} from "react-icons/md";
import { ReactComponent as MdDesktopWindows } from "../../assets/icons/new-desktop-icon.svg";
import { FaMobileAlt, FaTabletAlt } from "react-icons/fa";
import Insights from "./Insights";
import useAuth from "../../stores/auth";
import { Pane, Position, Tooltip } from "evergreen-ui";
import useClickOutside from "../../hooks/useClickOutside";
import { ReactComponent as NoPublishExp } from "../../assets/images/no-publish-exp.svg";
import { ReactComponent as NoDataOverview } from "../../assets/images/no-overview-data-image.svg";
import InfoWithTooltip from "components/InfoWithTooltip/InfoWithTooltip.jsx";

let colors = [
  "#7A1DBD",
  "#5C42E1",
  "#A89AEF",
  "#E2DCFF",
  "#E4F1FF",
  "#017CF8",
  "#dbd5f8",
];

export default function OverviewPage() {
  const navigate = useNavigate();
  const {
    filtersLoaded,
    allLiveExpNamesOptions,
    setActiveExpNames,
    activeExpNames,
    activeExpStatus,
    allPlacements,
    activePlacements,
    setActivePlacements,
    activeImplementations,
    setActiveImplementations,
    expFilterText,
    pageFilterText,
    implFilterText,
  } = useOverviewFilters();
  const { setAccount, accountOBJ } = useAuth();
  const implementationRef = useRef();
  const [start, setStart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [isHoverPagesFilterButton, setIsHoverPagesFilterButton] =
    useState(false);
  const orderOfEvents = ["views", "engagements", "resultsPage", "purchases"];
  const pagesRef = useRef();
  useClickOutside(pagesRef, () => {
    if (isHoverPagesFilterButton) setIsHoverPagesFilterButton(false);
  });
  const [openDropdowns, setOpenDropdowns] = useState({
    experiences: false,
    placements: false,
  });
  const isAfterLogin = useSearchParam("f");
  // Filter states
  const [startDate, setStartDate] = useState(moment().subtract(30, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [previousPeriod, setPreviousPeriod] = useState({
    start: moment().subtract(2, "week"),
    end: moment().subtract(1, "week"),
  });
  const [overviewOBJ, setOverviewOBJ] = useState({});

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: 0,
  });
  const disablePagesFilterButton =
    activeImplementations.includes("MANUAL_POPUP");
  const implementationOptionsFinal =
    accountOBJ?.plan === "Skeep X"
      ? implementationOptions
      : implementationOptions.filter((obj) => obj.value !== "MANUAL_POPUP");

  useEffect(() => {
    if (accountOBJ?.plan) return;
    Client.get("/emp/billing").then((res) => {
      setAccount(res.data);
    });
  }, [localStorage.getItem("SKP_BRANDID")]);
  let experiences = [];
  const funnelModel = (funnelOBJ) => {
    if (!funnelOBJ?.views) return;
    experiences = Object.keys(funnelOBJ?.views || {});
    const final = {};
    orderOfEvents.forEach((interactionType) => {
      experiences?.forEach((expName) => {
        if (final[expName])
          final[expName]?.push(funnelOBJ?.[interactionType]?.[expName] ?? 0);
        else final[expName] = [funnelOBJ?.[interactionType]?.[expName]];
      });
    });
    Object.keys(final).forEach(expId=>{
        final[expId][3] = Math.min(final[expId][3],final[expId][2]);
    })
    return final;
  };

  funnelModel(overviewOBJ?.funnel);

  const onChangeDate = (change) => {
    if (change.start <= change.end) {
      setStartDate(change.start);
      setEndDate(change.end);
      setPreviousPeriod({
        start: change?.previousPeriod?.start,
        end: change?.previousPeriod?.end,
      });
    } else {
      setStartDate(change.end);
      setEndDate(change.start);
      setPreviousPeriod({
        start: change.previousPeriod.end,
        end: change.previousPeriod.start,
      });
    }
  };

  useEffect(() => {
    if (!activePlacements?.length > 0 && isAfterLogin) navigate("/experiences");
  }, [activePlacements]);

  useDebounce(
    () => {
      // if (
      //   filtersLoaded > 2 &&
      //   (!activePlacements?.length > 0 ||
      //     activeExpNames?.length === 0 ||
      //     activeImplementations?.length === 0)
      // )
      //   return;
      if (
        activeImplementations.includes("MANUAL_POPUP") &&
        activePlacements.length !== allPlacements.length
      ) {
        return;
      }

      if (
        filtersLoaded > 2 &&
        activeExpNames?.length !== 0 &&
        activePlacements?.length !== 0 &&
        activeImplementations?.length !== 0
      ) {
        setLoading(true);

        Client(
          `/emp/overview?experienceIDs=${String(activeExpNames)}&paths=${String(
            activePlacements
          )}&implementations=${String(
            activeImplementations
          )}&from=${startDate?.toISOString()}&to=${endDate?.toISOString()}`
        )
          .then((res) => {
            if (isAfterLogin && res.data.engagedExperiences === 0) {
              window.location.href = "/experiences";
            }

            // Sessions by page - decode URL string
            let sessionsByPageDecoded = {};
            Object.keys(res?.data?.sessionByPage)?.map((url) => {
              sessionsByPageDecoded[decodeURI(url)] =
                res?.data?.sessionByPage[url];
            });
            res.data.sessionByPage = sessionsByPageDecoded;

            // Handle conversion rate is bigger than 100%
            // than -> purchases should be equal to resultsPage
            if (res.data.conversionRate > 1) {
              res.data.conversionRate = 1;
              const resultsPage = res.data?.funnel?.resultsPage;
              const purchases = res.data?.funnel?.purchases;

              const firstKeyOfResults = Object.keys(resultsPage)?.[0];
              const maxAllowedPurchases = resultsPage[firstKeyOfResults];

              Object.keys(purchases)?.forEach((key) => {
                purchases[key] = maxAllowedPurchases;
              });
            }

            setOverviewOBJ(res.data);
            setCurrencyCode(res.data?.ecommerce?.currency || "USD");
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }

      /*Client(
              `/emp/overview?experienceIDs=${String(activeExpNames)}&paths=${String(
                activePlacements
              )}&implementations=${String(
                activeImplementations
              )}&from=${previousPeriod?.start?.toISOString()}&to=${previousPeriod.end?.toISOString()}`
            ).then((res) => {
              setPreviousPeriodOBJ(res.data);
            });*/
    },
    2000,
    [
      activeExpNames,
      activePlacements,
      activeImplementations,
      startDate,
      endDate,
      isAfterLogin,
      previousPeriod,
      filtersLoaded,
    ]
  );

  useEffect(() => {
    if (
      filtersLoaded > 2 &&
      activeExpNames?.length !== 0 &&
      activePlacements?.length !== 0 &&
      activeImplementations?.length !== 0
    )
      setLoading(true);
  }, [
    activeExpNames,
    activePlacements,
    activeImplementations,
    startDate,
    endDate,
    isAfterLogin,
    previousPeriod,
    filtersLoaded,
  ]);

  const dataToUI = (obj) => {
    return Object.keys(obj || {}).map((key, idx) => {
      return {
        label: key,
        categoryPercentage: 0.2,
        data: funnelModel(overviewOBJ?.funnel)?.[key],
        backgroundColor: colors[idx],
        borderWidth: 0,
        barThickness: 32,
        borderRadius: 10,
      };
    });
  };
  const { views } = overviewOBJ?.funnel || {};

  const statData = {
    labels: ["Views", "Engagement", "Finished", "Conversion"],
    datasets: dataToUI(views),
  };
  const statOptions = {
    scaleShowValues: true,
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        offset: "32px",
        color: "#FFFFFF",
        font: {
          weight: "normal",
          size: 8,
          family: "Satoshi",
        },
      },
      tooltip: {
        padding: 10,
        font: {
          weight: "normal",
          size: 8,
          family: "Satoshi",
        },
        backgroundColor: "#e3f1ff",
        titleColor: "black",
        bodyColor: "black",
        displayColors: false,
        enabled: true,
        callbacks: {
          title: (context) => context[0].label,
          label: function (context) {
            var data = context.dataset.data,
              currentValue = context.raw,
              total = Math.max(...data);

            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return currentValue + "       " + percentage + "%";
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        grouped: true,
      },
      x: {
        // max: overviewData?.statistics?.views + 10 || 1000,
        ticks: {
          autoSkip: false,
          stepSize: 100,
          font: {
            size: 14,
            family: "Satoshi",
          },
        }, // stacked: true,
        grid: {
          borderDash: [5, 10],
          lineWidth: 1,
          color: `#DBE1E7`,
          drawBorder: false,
        },
      },
    },
  };

  const statsPlugins = [ChartDataLabels];

  const convertToPercent = (double) => {
    return `${parseInt(`${double * 100}`)}%`;
  };

  return (
    <div className={classes.body}>
      {/* {showTour && <TourPopup tourCurrentIndex={tourCurrentIndex} handleCurrentTourIndex={handleCurrentTourIndex} handleOpenTour={handleOpenTour} />} */}

      <div className={classes.contentContainer}>
        <Header name="Analytics" />
        <div className={classes.filtersContainer}>
          <div className={classes.filterLeftContainer}>
            <p className={classes.filterHeader}>Filter by:</p>
            <p className={classes.filterTag}>Experience names:</p>
            <button
              className={classes.filterSelectButton}
              onClick={() => setOpenDropdowns({ experiences: true })}
              onBlur={() => setOpenDropdowns({ experiences: false })}
            >
              <p className={classes.filterSelectName}>{expFilterText}</p>
              <IoChevronDownOutline size={15} color="#75787E" />
              {openDropdowns?.experiences && (
                <MenuWithCheckboxes
                  options={allLiveExpNamesOptions}
                  activeOptions={activeExpNames}
                  setActiveOptions={setActiveExpNames}
                />
              )}
            </button>
            <Tooltip
              isShown={disablePagesFilterButton && isHoverPagesFilterButton}
              statelessProps={{
                backgroundColor: "#E4F1FF",
                color: "black",
                fontSize: "14px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                textAlign: "center",
              }}
              position={Position.BOTTOM}
              content={
                <p>
                  <small>
                    In order to filter by page, remove the "Manual"
                    implementation type filter
                  </small>
                </p>
              }
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{ display: "flex" }}
                  onMouseEnter={() => setIsHoverPagesFilterButton(true)}
                  onMouseLeave={() => setIsHoverPagesFilterButton(false)}
                  ref={pagesRef}
                >
                  <p
                    className={classes.filterTag}
                    style={{
                      color: disablePagesFilterButton ? "lightgrey" : "",
                    }}
                  >
                    Pages:
                  </p>
                  <button
                    className={classes.filterSelectButton}
                    onClick={() => setOpenDropdowns({ placements: true })}
                    onBlur={() => setOpenDropdowns({ placements: false })}
                    disabled={disablePagesFilterButton}
                    style={{
                      color: disablePagesFilterButton ? "lightgrey" : "",
                    }}
                  >
                    <p
                      className={classes.filterSelectName}
                      style={{
                        color: disablePagesFilterButton ? "lightgrey" : "",
                      }}
                    >
                      {pageFilterText}
                    </p>
                    <IoChevronDownOutline
                      size={15}
                      color={disablePagesFilterButton ? "lightgrey" : "#75787E"}
                    />
                    {openDropdowns?.placements && (
                      <MenuWithCheckboxes
                        options={allPlacements}
                        activeOptions={activePlacements}
                        setActiveOptions={setActivePlacements}
                      />
                    )}
                  </button>
                </div>
              </div>
            </Tooltip>
            <p className={classes.filterTag}>Implementation Type:</p>
            <button
              className={classes.filterSelectButton}
              onClick={() => setOpenDropdowns({ implementation: true })}
              onBlur={() => setOpenDropdowns({ implementation: false })}
            >
              <p className={classes.filterSelectName}>{implFilterText}</p>
              <IoChevronDownOutline size={15} color="#75787E" />
              {openDropdowns?.implementation && (
                <MenuWithCheckboxes
                  options={implementationOptionsFinal}
                  activeOptions={activeImplementations}
                  setActiveOptions={setActiveImplementations}
                />
              )}
            </button>
          </div>
          <DatePicker onChangeDate={onChangeDate} />
        </div>

        {loading ? (
          <LoadingPage />
        ) : overviewOBJ?.engagedExperiences !== 0 &&
          overviewOBJ?.engagedExperiences !== undefined ? (
          <>
            {overviewOBJ?.engagedExperiences === null ? (
              <div className={classes.noPublishedImage}>
                <NoPublishExp />
                <p className={classes.noPublishDescription}>
                There are no data records for the selected time period
                </p>
                <p className={classes.noPublishDescription2}>
                  Try a different time
                </p>
              </div>
            ) : (
              <>
                <div className={classes.overviewBoxesRow}>
                  <OverviewBox
                    label="Engaged Experiences"
                    value={overviewOBJ?.engagedExperiences}
                    tooltipText="The number of engaged experiences in the chosen time period"
                  />
                  <OverviewBox
                    label="Total Engagements"
                    value={overviewOBJ?.totalEngagements}
                    tooltipText="The number of customers that answered at least one question"
                  />
                  <OverviewBox
                    isEmailBox
                    label="Collected Emails"
                    value={`${
                      overviewOBJ?.collectedEmails
                        ? overviewOBJ?.collectedEmails
                        : 0
                    } Emails`}
                    tooltipText="The number of emails you collected with the quiz"
                  />
                  <OverviewBox
                    label="AVG time spent"
                    value={`${(
                      Math.round(overviewOBJ?.avgTimeSpent) / 60
                    ).toFixed(2)} Minutes`}
                    tooltipText="Average time customers spent on the quiz"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0 32px",
                  }}
                >
                  <div
                    style={{
                      flex: 0.75,
                      width: "75%",
                    }}
                  >
                    <div className={classes.chartTopContainer}>
                      <div className={classes.chartTop}>
                        <div className={classes.chartTopHeaderContainer}>
                          <p className={classes.chartTopHeader}>Funnel</p>
                          <div>
                            {dataToUI(views).map((v) => (
                              <div
                                style={{
                                  display: "flex",
                                  gap: 12,
                                  alignItems: "center",
                                  marginTop: 20,
                                  color: "#75787E",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: v.backgroundColor,
                                    width: 14,
                                    height: 14,
                                    borderRadius: "2px",
                                  }}
                                ></div>
                                <p>{overviewOBJ?.experienceNames[v.label]}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            width: 400,
                          }}
                        >
                          <Bar
                            data={statData}
                            options={statOptions}
                            plugins={statsPlugins}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.summaryContainer}>
                      <OverviewSummary
                        header="Experiences Revenue"
                        icon={icon}
                        value={`${formatter.format(overviewOBJ?.expRevenue)}`}
                        change={overviewOBJ?.expRevenueChange}
                        tooltip="The total amount of money expected from all experience"
                      />
                      <OverviewSummary
                        header="Engagement Rate"
                        icon={icon}
                        value={convertToPercent(overviewOBJ?.engagementRate)}
                        change={overviewOBJ?.engagementRateChange}
                        tooltip="Precent of interactions with experiences out of experiences impressions"
                      />
                      <OverviewSummary
                        header="Conversion Rate"
                        icon={icon}
                        value={convertToPercent(overviewOBJ?.conversionRate)}
                        change={overviewOBJ?.conversionRateChange}
                        tooltip="Purchases out of engagement"
                      />
                      <OverviewSummary
                        header="Average Order"
                        icon={icon}
                        value={`${
                          isNaN(overviewOBJ?.avgOrder)
                            ? 0
                            : formatter.format(overviewOBJ?.avgOrder)
                        }`}
                        tooltip="Average order value for users that interacted with one or more experiences"
                      />
                    </div>
                    <div className={classes.chartBottomContainer}>
                      <div className={classes.chartBottom}>
                        <p className={classes.chartBottomLabel}>
                          Sessions by device
                          <InfoWithTooltip
                            text={
                              "This shows the distribution of responses by devices"
                            }
                            position={Position.RIGHT}
                          />
                        </p>
                        <BarGraphContainer
                          label={"Desktop"}
                          icon={
                            <MdDesktopWindows
                              size={30}
                              color="#826EE8"
                              style={{ marginLeft: "4px" }}
                            />
                          }
                          percentage={
                            (overviewOBJ?.sessionByDevice?.DESKTOP ?? 0) * 100
                          }
                        />
                        <BarGraphContainer
                          label={"Mobile"}
                          icon={<FaMobileAlt size={30} color="#826EE8" />}
                          percentage={
                            (overviewOBJ?.sessionByDevice?.MOBILE ?? 0) * 100
                          }
                        />
                        <BarGraphContainer
                          label={"Tablet"}
                          icon={<FaTabletAlt size={30} color="#CEC6F6" />}
                          percentage={
                            (overviewOBJ?.sessionByDevice?.TABLET ?? 0) * 100
                          }
                        />
                      </div>
                      <div className={classes.separator}></div>
                      <div className={classes.chartBottomContainerDivider} />
                      <div className={classes.sessionsByPageContainer}>
                        <p className={classes.chartBottomLabel}>
                          Sessions by page
                          <InfoWithTooltip
                            text={"Here is a breakdown of responses by page"}
                            position={Position.RIGHT}
                          />
                        </p>
                        <div className={classes.chartBottomGraphsWrap}>
                          {overviewOBJ?.sessionByPage &&
                            Object.keys(overviewOBJ?.sessionByPage)?.map(
                              (key) => (
                                <BarGraphContainer
                                  key={key}
                                  label={key}
                                  percentage={
                                    overviewOBJ?.sessionByPage?.[key] * 100
                                  }
                                  width="46%"
                                />
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 0.25,
                      marginLeft: 4,
                    }}
                  >
                    <Insights insights={overviewOBJ} />
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className={classes.noPublishExp}>
            <p className={classes.noPublishMessage}>
              You will be able to see the data once you publish your experience
            </p>
            <NoDataOverview className={classes.noDataSvg} />
          </div>
        )}
      </div>
    </div>
  );
}

const BarGraphContainer = ({ label, icon, percentage, width }) => {
  return (
    <div className={classes.barGraphContainer} style={{ width }}>
      <div className={classes.barGraphHeader}>
        {icon}
        <p className={classes.barGraphLabel}>{label}</p>
        <p className={classes.barGraphPercent}>{percentage.toFixed(1)}%</p>
      </div>
      <div className={classes.barGraphGray}>
        <div
          className={classes.barGraphPurple}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
};
