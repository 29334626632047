import { ReactComponent as PauseIcon } from "../../../assets/icons/pause-icon.svg";
import { useState } from "react";
import { Tooltip } from "evergreen-ui";
import { ReactComponent as InfoSign } from "../../../assets/icons/info-tag.svg";
import UpgradeBillingModal from "./UpgradeBillingModal";

const ToggleLiveExpButton = ({
  experience,
  isEditing,
  hoveredRow,
  editExpOBJ,
  style,
  handleClick,
  origin,
  disabled,
  moreQuizClick,
}) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const publishDisableBtn =
    (isEditing && !experience?.locations?.[0]?.path) ||
    (isEditing && editExpOBJ?.path === null) ||
    (!isEditing && !experience?.locations?.[0]?.path) ||
    disabled;
  const onClickMoreQuiz = (e) => {
    e.preventDefault();
    e.stopPropagation();
    moreQuizClick();
  };

  return (
    <>
      {isUpgradePlanModalOpen && (
        <UpgradeBillingModal
          isUpgradePlanModalOpen={isUpgradePlanModalOpen}
          closeModal={() => setIsUpgradePlanModalOpen(false)}
        />
      )}

      <span>
        {experience?.status === "LIVE" ? (
          <button
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => setMouseEnter(false)}
            style={{ cursor: "pointer" }}
            className={`action-menu-buttons ${!experience && "disabled"} ${
              !hoveredRow && "invisible"
            } ${!style && "preview-btn"}`}
            onClick={handleClick}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PauseIcon
                fill={mouseEnter && origin !== "editor" ? "#fff" : "#017CF8"}
              />
              <span style={{ marginLeft: "8px" }}>Pause</span>
            </div>
          </button>
        ) : publishDisableBtn ? (
          <Tooltip
            position={origin !== "editor" ? "bottom" : "top"}
            statelessProps={{
              height: "51px",
              marginTop: "10px",
              backgroundColor: "#FFE4B4",
              color: "#75787e",
              maxWidth: "340px",
            }}
            content={
              <div style={{ marginLeft: "10px", fontSize: "12px" }}>
                <InfoSign
                  fill="#F4B037"
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 7,
                    border: "1px solid #f4b037",
                    borderRadius: "100%",
                    height: "13px",
                    width: "14px",
                  }}
                />{" "}
                You have reached your maximum published experiences.{" "}
                <span
                  onClick={() => {
                    setIsUpgradePlanModalOpen(true);
                  }}
                  style={{
                    textDecoration: "underline",
                    color: "#017CF8",
                    cursor: "pointer",
                  }}
                >
                  Upgrade
                </span>{" "}
                your plan to continue publishing
              </div>
            }
          >
            <button
              className={`action-menu-buttons ${!experience && "disabled"} ${
                !hoveredRow && "invisible"
              } ${!style && "publish-btn"}`}
              style={{
                ...style,
                backgroundColor: disabled ? "#F3F5F7" : undefined,
                color: disabled ? "gray" : undefined,
              }}
            >
              Publish
            </button>
          </Tooltip>
        ) : (
          <button
            className={`action-menu-buttons ${!experience && "disabled"} ${
              !hoveredRow && "invisible"
            } ${!style && "publish-btn"}`}
            onClick={handleClick}
            disabled={publishDisableBtn}
            style={{
              ...style,
              backgroundColor: disabled ? "#F3F5F7" : undefined,
              color: disabled ? "gray" : undefined,
            }}
          >
            Publish
            {disabled && (
              <p className="get-more-text" onClick={onClickMoreQuiz}>
                Get more quiz
              </p>
            )}
          </button>
        )}
      </span>
    </>
  );
};

export default ToggleLiveExpButton;
