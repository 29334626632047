const timestampToDate = (timestamp) => {
    const timeToDate = new Date(timestamp);
    const date = timeToDate.getDate();
    const month = timeToDate.getMonth() //Be careful! January is 0 not 1;
    const year = timeToDate.getFullYear();
    const dateMonthYear  =  date + "/" + (month+1) + "/" + year;

    return dateMonthYear
}

export function timeSince(dateString) {
    var seconds = Math.floor(((new Date().getTime()/1000) - (Date.parse(dateString) / 1000)))
    var interval = interval = seconds / 518400;
    if (interval > 1) return timestampToDate(Date.parse(dateString));
    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + " days ago";
    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + " hours ago";
    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + " minutes ago";
    return "Few seconds ago";
}

function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}