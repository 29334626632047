import {useEffect, useRef, useState} from "react";
import {Dialog, toaster} from "evergreen-ui";
import "./ImageGalleryModal.scss";
import {ReactComponent as CancelIcon} from "../../assets/icons/cancel-icon.svg";
import {ReactComponent as UploadIcon} from "../../assets/icons/new-upload-icon.svg";
import {ReactComponent as CheckIcon} from "../../assets/icons/check-icon-new.svg";
import {Client} from "Client";
import {compressImage, s3ToHttpLink, urlToS3Link} from "../../utils/utls";
import {useMediaGallery} from "stores/use-media-gallery";
import ReactCrop from "react-image-crop";
import uploadToS3 from "../../utils/uploadToS3";
import {imageCompression} from "../../utils/imageCompression";

const aspectRatio = 1080 / 1350;
const WIDTH = 720;
const HEIGHT = 320;

function fileToUrl(selectedFile) {
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      res(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
  });
}

function getImage(selectedFile) {
  return new Promise((res) => {
    const img = new Image();
    var objectUrl = URL.createObjectURL(selectedFile);
    img.onload = function () {
      URL.revokeObjectURL(objectUrl);
      res(this);
    };
    img.src = objectUrl;
  });
}

function getCroppedImg(image, pixelCrop, fileName, origImage) {
  const canvas = document.createElement("canvas");
  const scaleX = origImage.width / image.width;
  const scaleY = origImage.height / image.height;
  canvas.width = pixelCrop.width * scaleX;
  canvas.height = pixelCrop.height * scaleY;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(origImage, pixelCrop.x * scaleX, pixelCrop.y * scaleY, pixelCrop.width * scaleX, pixelCrop.height * scaleY, 0, 0, pixelCrop.width * scaleX, pixelCrop.height * scaleY);
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      file.name = fileName;
      resolve(file);
    }, "image/png", 100);
  });
}

const ImageGalleryModal = ({shown, setShown, onImageSelect, defaultSelected}) => {
  // const [images, setImages] = useState([]);
  const {galleryImages,setGalleryImages}=useMediaGallery()
  const [image, setImage] = useState(null)
  const [url, setUrl] = useState("");
  const [selected, setSelected] = useState(defaultSelected);
  const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px", width: 108, height: 135, x: 0, y: 0,
  });
  const [file, setFile] = useState(null);
  const ref = useRef();

  const handleChange = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      const maxAllowedSize = 2 * 1024 * 1024;
      if (e.target.files[0].size > maxAllowedSize) {
        // Here you can ask your users to load correct file
        toaster.danger("File is too large");
        return e.target.value = ''
      }
      setFile(e.target.files[0]);
      setUrl(await fileToUrl(e.target.files[0]));
      setShowCropper(true);
      setImage(await getImage(e.target.files[0]));
    } catch (e) {
      toaster.danger(e.message);
    }
  };
  useEffect(() => {
    if (shown) setSelected(defaultSelected);
  }, [shown])
  useEffect(() => {
    Client.get("emp/images").then((res) => {
      // @ts-ignore
      setGalleryImages(res.data?.map(image => ({
        publicUrl: image, s3Url: urlToS3Link(image)
      })));
    });
  }, []);


  return (<>
    <Dialog
      isShown={shown}
      title="Dialog title"
      width={WIDTH}
      minHeightContent={520}
      onCloseComplete={() => setShown(false)}
      confirmLabel="Custom Label"
      footer={false}
      hasFooter={false}
      overlayProps={{
        zIndex: 21
      }}
      shouldCloseOnOverlayClick={false}
      containerProps={{
        marginTop: "200px",
      }}
      hasHeader={false}
      contentContainerProps={{padding: "0px"}}
    >

      <div className="gallery-container" onClick={(e) => {
        e.stopPropagation()
      }}>
        <div onClick={e => e.preventDefault()} className="gallery-header">
          <p>Media Gallery</p>

          <CancelIcon onClick={() => setShown(false)}/>
        </div>
        <div className="content-container">
          {showCropper ? (<div style={{
            padding: "0px 20px", position: "relative", top: 40, display: "flex", flexDirection: "column", width: 355
          }}>
            <div style={{
              display: "flex", alignItems: "center", flex: 0.8, maxHeight: 320, justifyContent: "center"
            }}>
              <ReactCrop
                // @ts-ignore
                crop={crop}
                minHeight={100}
                minWidth={100}
                onChange={(c) => setCrop(c)}
                aspect={aspectRatio}
              >
                <img ref={ref} src={url} style={{
                  maxHeight: "300px",
                }}/>
              </ReactCrop>
            </div>
            <div>
              <button style={{
                background: "#eaeaea", color: "#333"
              }} className="image-gallery-dialog-crop-btn" onClick={() => {
                setShowCropper(false)
                setUrl("")
              }}>Cancel
              </button>
              <button className="image-gallery-dialog-crop-btn" onClick={() => {
                getCroppedImg(ref.current, crop, file.name, image).then(async (v) => {
                  const compressedImage = await imageCompression(v);
                  const data = await uploadToS3(compressedImage)
                  const publicURL = await s3ToHttpLink(data);
                  setSelected(data)
                  setGalleryImages([{
                    publicUrl: publicURL, s3Url: data
                  }, ...galleryImages]);
                  setShowCropper(false);
                });
              }}>Save
              </button>
            </div>
          </div>) : (<div style={{
            flex: 0.9, display: "inline-flex", flexDirection: "column", alignItems: "center",

          }} className="upload-image">
            <p>Upload Image</p>

            <div className="upload-field">
              <div>
                <UploadIcon/>
                <label
                  htmlFor="upload-btn"
                  className="upload-field-text"
                  style={{height: "100%"}}
                >
                  Upload an image
                </label>
                <input
                  type="file"
                  className="upload-btn"
                  onChange={handleChange}
                  style={{
                    height: "100%", position: "absolute", color: "transparent", cursor: "pointer", zIndex: 1
                  }}
                  accept={"image/png, image/jpeg, image/jpg"}
                />
              </div>
            </div>

            <div className="alert-container">
                <span className="alert-text">
                  Maximum upload file size is 2MB only PNG is allowed.
                </span>
            </div>
          </div>)}
          <div className="divider-container">
            <div className="divider"/>
          </div>

          <div className="used-recently-container">
            <p className="used-recently-text">Used Recently</p>
            <div className="image-container">
              {galleryImages.map((image, id) => (
                <RecentlyUsedImage selected={selected} setSelected={setSelected} key={image.s3Url}
                                   image={image} id={id}/>))}
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "right"}}>
          <button onClick={async (e) => {
            onImageSelect({
              publicUrl: await s3ToHttpLink(selected), s3Url: decodeURIComponent(selected)
            });
            setShown(false);
          }} disabled={!selected || showCropper} className="image-gallery-dialog-btn">Select
          </button>
        </div>
      </div>
    </Dialog>
  </>);
};

export default ImageGalleryModal;

function RecentlyUsedImage({image, selected, setSelected}) {
  return (<div style={{position: "relative"}}>
    <img
      className={selected === image.s3Url ? "used-recently-image-selected" : "used-recently-image"}
      src={image.publicUrl}
      alt="no img"
      onClick={() => {
        setSelected(p => {
          if (p === image.s3Url) return null;
          return image.s3Url;
        });
      }}
    />
    {selected === image.s3Url && <CheckIcon className="check-icon"/>}
  </div>);
}
