import {forwardRef} from "react";
import useEditor from "stores/use-editor";

const TitleEditor = forwardRef(({
  editable = false,
  value,
  onChange,
  limit = 150,
    forceStyle={}
},ref) =>{
  const { setScrollAnswer } = useEditor();

  if (editable) {
    return (
      <>
        <textarea
          ref={ref}
          onChange={(e) => {
            e.target.value.length <= limit && onChange(e);
          }}
          style={{
            backgroundColor: "transparent",
            width: "85%",
            position: "absolute",
            top: 0,
            height: "95%",
            color: "#0f0f0f",
            fontFamily: "Satoshi",
            left: 28,
            borderRadius: 8,
            borderColor: "#e0e0e0",
            padding: 8,
            paddingTop: 18,
            paddingLeft: 8,
            fontSize: 11,
            bottom: 0,
            resize: "none",
            fontWeight: 400,
            margin: "auto",
            ...forceStyle
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onFocus={() => setScrollAnswer("")}
          className="title-input"
          placeholder="Question title"
        >
          {value}
        </textarea>

        <span
          style={{
            position: "absolute",
            bottom: 4,
            color: "#75787e",
            right: "15%",
          }}
        >
          {value.length}/{limit}
        </span>
      </>
    );
  }
  return (
    <div
      style={{
        width: "75%",
        whiteSpace: "normal",
      }}
    >
      {value}
    </div>
  );
})

export default TitleEditor
