import {useSwiper} from "swiper/react";
import useEditor from "../../../stores/use-editor";
import {useEffect} from "react";

const SwiperInvisibleController = () => {
    const swiper = useSwiper();
    const {activeQuestionIndex} = useEditor();

    useEffect(() => {
        swiper.slideTo(activeQuestionIndex);
    }, [activeQuestionIndex]);
}

export default SwiperInvisibleController;