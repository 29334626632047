/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "stores/auth";
import useEditor from "stores/use-editor";
import { useEditorEvents } from "stores/use-editor-events";
import Preview from "./components/Editor/Preview";
import "./EditorPage.scss";
// import { handleDrag } from "utils/utls";
import { toaster } from "evergreen-ui";
import LiveSaveDialog from "./components/Editor/Dialog/LiveSaveDialog";
import LiveInitialDialog from "./components/Editor/Dialog/LiveInitialDialog";
import EditorSavePopup from "./components/EditorSavePopup/EditorSavePopup";
import EditorBackButton from "./components/EditorBackButton";
import { useDesign } from "../../stores/design";
import { convertArrayToObject } from "../../utils/convertArrayToObject";
import useCustomTags from "stores/use-custom-tags";
import { postEvents } from "utils/postEvents";
import { useSwiper } from "stores/use-swiper";
import Tabs from "./components/Tabs";
import RenderContentOrDesign from "./components/RenderContentOrDesign";
import GuidencePopup from "pages/ProductPage/components/GuidencePopup";
import useExpTableProps from "hooks/useExpTableProps";
import useExperiencePage from "hooks/useExperiencePage";
import { Client } from "Client";
import ExpPlacementsModal from "pages/ExperiencesPage/components/ExpPlacementsModal";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-name-icon.svg";
import { ReactComponent as Info } from "../../assets/icons/info-gray.svg";
import useEditorActions from "../../hooks/useEditorActions";
import saveExp from "../../utils/saveExp";

export default function EditorPage() {
  const navigate = useNavigate();
  const { id: experienceId } = useParams();
  const setMinimized = useAuth((state) => state.setMinimized);
  const [disable, setDisable] = useState(false);
  const { defaultEditOBJ } = useExpTableProps();
  const { handleSaveChanges, loading, liveSaveModal, setLiveSaveModal } =
    useEditorActions();
  // const dragRef = useRef();
  const previewRef = useRef();
  const [editExpOBJ, setEditExpOBJ] = useState(defaultEditOBJ);
  const { playEvents, events, addEvents, clearEvents } = useEditorEvents();
  const { clearTags } = useCustomTags();
  const {
    experience,
    fetchExperience,
    updateExperience,
    updateQuestions,
    designView,
    showEditorUnsavedPopup,
    questions,
    updateBgImageId,
    setUnsavedPopup,
    updateSelectedTagsForRemoval,
    fetchQuestions,
    selectedTagsForRemoval,
    changeQuestionId,
    changeTabs,
    colorError,
    tabs,
  } = useEditor();



  const { tagTypesToUpdate, tagTypes, tagsImages, tagsImagesEdited } =
    useSwiper();
  const {
    setForceFetch,
    allExperiences,
    unsuccessfulLoad,
    setUnsuccessfulLoad,
    isEditing,
    setIsEditing,
    brand,
  } = useExperiencePage();
  const { getDesign } = useDesign();

  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState("");
  const [initialModal, setInitialModal] = useState(false);
  const { afterActionRedirect, setRedirect } = useDesign();
  const [isPlacementsModalOpen, setIsPlacementsModalOpen] = useState();
  const { tags, saveCustomTags } = useCustomTags();
  const redirectTo = afterActionRedirect ? afterActionRedirect : -1;
  const [showGuidancePopup, setShowGuidancePopup] = useState(false);
  const [experienceName,setExperienceName]=useState("")
  // useEffect(() => {
  //   if (localStorage.getItem("SHOW_EDITOR_TUTORIAL") !== "false") {
  //     setShowGuidancePopup(true);
  //     localStorage.setItem("SHOW_EDITOR_TUTORIAL", "false");
  //   }
  // }, []);

 

  useEffect(() => {
    // @ts-ignore
    window.skeep?.destroyAll();
  }, [experience?.id, designView]);
  useEffect(() => {
    fetchExperience(experienceId);
    changeTabs("experience-content");
    setIsEditing(experience);
    getDesign();
  }, [experienceId]);

  useEffect(() => {
    if (experience)
      playEvents(
        experience,
        updateExperience,
        questions,
        updateQuestions,
        updateSelectedTagsForRemoval
      );
  }, [events]);

  useEffect(() => {
    if (experience?.id) {
      Client.get(`emp/experiences/${experience?.id}`).then((res) => {
        setExperienceName(res.data.name)
        
      });
    }
  }, [experience?.id]);

  useEffect(() => {
    if (experience?.id) {
      fetchQuestions(experience?.id);
    }
  }, [experience?.id, fetchQuestions]);

  useEffect(() => {
    setMinimized(true);
  }, [setMinimized]);

  useEffect(() => {
    if (experience?.type === "QUIZ") {
      const boolean = questions.every((q) =>
        q.answers.every((obj) => obj.answer !== "")
      );

      setDisable(!boolean);
    }
  }, [questions]);

  useEffect(() => {
    if (experience?.status === "LIVE") {
      setInitialModal(true);
    }
  }, [experience?.status]);

  const changeExp = (keyName, value) => {
    setEditExpOBJ((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };
  const saveEditedExperience = async () => {
    saveExp(editExpOBJ,experience)
      .then((res) => {
        toaster.success("Experience updated");
        setForceFetch(Math.random());
      })
      .catch((err) =>
        toaster.danger("There was a problem updating the experience.")
      );
  };

  const handlePublishClick = async () => {
    if (experience?.status !== "LIVE") {
      return setIsPlacementsModalOpen(experience);
    } else {
      await Client.put(`/emp/experiences/${experience?.id}/status`, {
        status: "STOPPED",
      }).then(() =>
        updateExperience({
          ...experience,
          status: experience?.status === "LIVE" ? "STOPPED" : "LIVE",
        })
      );
      toaster.success("Experience paused successfully");
      setForceFetch(Math.random());
    }
  };

  return (
    <div className="app-page editor-page">
      {showEditorUnsavedPopup && (
        <EditorSavePopup
          setShowBackAlert={setUnsavedPopup}
          saveChangesAction={handleSaveChanges}
          onClickLeave={() => {
            clearEvents();
            setUnsavedPopup(false);
            setRedirect("experiences");
            navigate(redirectTo === -1 ? "/experiences" : redirectTo);
          }}
        />
      )}
      <div className="fa-center app-page-header">
        <h1 className="app-page-header-title title-edit">
          {isEditingName ? (
            <input
              type="text"
              placeholder="Name"
              defaultValue={experienceName}
              onChange={(e) =>{ setName(e.target.value) ;setExperienceName(e.target.value)}}
            />
          ) : (
            <span>{experienceName}</span>
          )}
          {isEditingName ? (
            <>
              <button
                className="icon-button"
                onClick={() => setIsEditingName(false)}
              >
                <p className="cancel-exp-btn">Cancel</p>
              </button>
              <button
                className="icon-button"
                onClick={() => {
                  addEvents("experience", "update", { name: name });
                  setIsEditingName(false);
                }}
              >
                <p className="save-exp-btn">Save</p>
              </button>
            </>
          ) : (
            <div className="edit-icon-container"  onClick={() => setIsEditingName(true)}>
              <EditIcon
                className="edit-title"
              />
            </div>
          )}
        </h1>
        {/* <ExploreButton
          videoUrl={"https://www.youtube.com/embed/x5rnCuLLtz4"}
          name="Editor"
        /> */}
      </div>

      <div className="app-page-content">
        <div className="btn-container">
          <EditorBackButton
            onClick={() => {
              events.length > 0
                ? setUnsavedPopup(true)
                : navigate("/experiences");
            }}
          />
        </div>
        <div className="editor-page-header">
          <h1 className="page-title">
            Edit Experience{" "}
            <Info
              className="info-icon"
              fill="#75787E"
              onClick={() => setShowGuidancePopup(true)}
            />{" "}
          </h1>
        </div>
        <h3 className="page-subheading">
          Customize your experience, Change the design, image and content
        </h3>
        <div className="editor-container">
          <div className="editor">
            <Tabs />
            <div className="tabs-content">
              <RenderContentOrDesign />
              {colorError !== undefined && tabs.includes("design") && (
                <div style={{ fontSize: "10px", color: "red" }}>
                  Your {colorError} background and answer have similar colors
                </div>
              )}
            </div>
          </div>
          <div className="dragMe"></div>
          <div className="preview" ref={previewRef}>
            <Preview
              previewOf={"experience"}
              isEditing={isEditing}
              loading={loading}
              disable={disable}
              editExpOBJ={editExpOBJ}
              handlePublishClick={handlePublishClick}
              events={events}
              handleSaveChanges={handleSaveChanges}
              brand={brand}
            />
          </div>
        </div>
        {liveSaveModal && (
          <LiveSaveDialog
            handleChanges={handleSaveChanges}
            showDialog={liveSaveModal}
            setLiveSaveModal={setLiveSaveModal}
          />
        )}
        {initialModal && <LiveInitialDialog showDialog={true} />}
      </div>
      {isPlacementsModalOpen?.id && (
        <ExpPlacementsModal
          experience={isPlacementsModalOpen}
          editExpOBJ={editExpOBJ}
          handleForceFetch={setForceFetch}
          changeExp={changeExp}
          setEditExpOBJ={setEditExpOBJ}
          saveEditedExperience={saveEditedExperience}
          setIsPlacementsModalOpen={setIsPlacementsModalOpen}
          lastStepButtonText={"Publish"}
        />
      )}
      <GuidencePopup
        show={showGuidancePopup}
        setShow={setShowGuidancePopup}
        videoContent={true}
        videoUrl={"https://www.youtube.com/embed/x5rnCuLLtz4"}
        name={"Editor"}
      />
    </div>
  );
}
