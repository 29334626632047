import create from "zustand";
import {devtools} from "zustand/middleware";
import axios from "axios";
import {APIURL, Client} from "Client";
import {OpenReplay} from "utils/OpenReplay";

const useAuth = create()(
  devtools(
    (set, get) => ({
      jwt: localStorage.getItem("SKP_JWT"),
      minimized: false,
      subSidebar: false,
      error: false,
      errorUnauthorized: false,
      errorVerificationWasResent: false,
      emailVerificationWasResent: false,
      accountOBJ: {},
      allExperiences:[],

      setMinimized: (minimized) => set({ minimized }),
      setAllExperiences: (allExperiences) => set({ allExperiences }),
      setSubSidebar: (subSidebar) => set({ subSidebar, minimized: subSidebar }),
      toggleSubSidebar: () => {
        set({ subSidebar: !get().subSidebar, minimized: !get().subSidebar });
      },
      toggleMinimized: () => set({ minimized: !get().minimized }),
      googleLogin: async (token) => {
        axios
          .post(
            APIURL + "/login",
            {},
            {
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then((res) => {
            localStorage.setItem("SKP_JWT", res.data.token);
            localStorage.setItem("SKP_BRANDID", res.data.brandID);
            localStorage.setItem("SKP_NAME", res.data.fullName);
            localStorage.setItem("SKP_BRAND", res.data.brandName);
            Client("/emp/brand").then((res) => {
              const status = res.data.status;
              if (status.includes("ON_BOARDING")) {
                window.location.href = "/experiences";
              } else if (!status.includes("ON_BOARDING")) {
                window.location.href = "/overview?f=1";
              } else {
                window.location.href = "/login";
              }
            });
            set({ jwt: res.data });
          })
          .catch((error) => {
            set({ error: true });
          });
      },
      login: async (email, password) => {
        axios
          .post(
            APIURL + "/login",
            {},
            {
              auth: {
                username: email?.toLowerCase()?.trim(),
                password,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("SKP_JWT", res.data.token);
            localStorage.setItem("SKP_BRANDID", res.data.brandID);
            localStorage.setItem("SKP_NAME", res.data.fullName);
            localStorage.setItem("SKP_BRAND", res.data.brandName);
            localStorage.setItem("SKP_USERID", res.data.userID);
            localStorage.setItem("EMAIL", email);
            OpenReplay.setUser(res.data.userID);
            Client("/emp/brand").then((brandRes) => {
              window.analytics?.identify(res.data.userID, {
                email: email,
                name: res.data.fullName,
                plan: brandRes.data.billing?.plan,
              });
              window.analytics?.track(
                "Signed In",
                {
                    userId: res.data.userID,
                },
                {
                  context: {
                    groupId: res.data.brandID,
                  },
                }
              );

              const status = brandRes.data.status;
              localStorage.setItem("INDUSTRY", brandRes.data.industry);
              if (status.includes("ON_BOARDING")) {
                window.location.href = "/onboarding";
              } else if (!status.includes("ON_BOARDING")) {
                  if (localStorage.getItem(`${res.data.brandID}_SKP_NOT_FIRST_LOGIN`)){
                      window.location.href = "/experiences";
                  } else {
                      Client("/experiences").then(res => {
                          localStorage.setItem(`${res.data.brandID}_SKP_NOT_FIRST_LOGIN`,"true");
                          if (res.data.length) return window.location.href = "/experiences";
                          window.location.href = "/onboarding";
                      })

                  }

              } else {
                window.location.href = "/login";
              }
            });
            set({ jwt: res.data });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Unauthorized
              set({ errorUnauthorized: true });
            } else {
              set({ error: true });
            }
          });
      },
      setAccount: (value) => {
        set({ accountOBJ: value });
      },
      resendVerificationEmail: async (username, password) => {
        axios
          .post(
            APIURL + "/emailVerification",
            {},
            { auth: { username, password } }
          )
          .then((res) => {
            set({
              emailVerificationWasResent: true,
              errorVerificationWasResent: false,
            });
          })
          .catch((err) => {
            set({ errorVerificationWasResent: true });
          });
      },
    }),{
          name: "auth",
      }
  )
);

export default useAuth;
