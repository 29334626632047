import React, {useEffect, useState} from "react";

const Switch = ({ defaultValue, onChange, switchVal1, switchVal2 }) => {
  const [value, setValue] = useState(defaultValue);
  const [addEvent, setAddEvent] = useState(false);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    if (addEvent) {
      onChange(value);
      setAddEvent(false);
    }
  }, [value]);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddEvent(true);
        setValue(value === switchVal1 ? switchVal2 : switchVal1);
      }}
      className={`switch ${value === switchVal2 && "switch-disabled"}`}
    >
      <button
        className="capitalize"
        style={value === switchVal1 ? { left: "15%" } : { left: "42%" }}
      >
        {value === switchVal1 ? switchVal1 : switchVal2}
      </button>
      <div
        style={value === switchVal1 ? { left: "65%" } : { left: "6%" }}
      ></div>
    </div>
  );
};

export default Switch;
