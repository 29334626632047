import {Client} from "Client";
import {
    Button,
    Dialog,
    majorScale,
    Pane,
    Text,
    TextInput, toaster,
} from "evergreen-ui";
import {SelectedItem} from "pages/ExperiencesPage/CreateDialog/components/SelectedItem";
import CreateDialogButton from "pages/ExperiencesPage/CreateDialog/CreateDialogButton";
import {useEffect, useState} from "react";
import useCustomTags from "stores/use-custom-tags";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {ReactComponent as SearchIcon} from "../../../../assets/icons/search-icon-new.svg";
import {IoClose} from "react-icons/io5";
import {ReactComponent as AllProductsIcon} from "../../../../assets/images/all-product-selected.svg";
import {findDuplicates} from "../../../../utils/findDuplicates";
import { useParams } from "react-router-dom";

const AddProductsDialog = ({show, setShow, initialSelectedProducts, setForceRefetch}) => {
    const {
        experience,
    } = useEditor();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const { id: experienceId } = useParams();
    const [selectAllProducts, setSelectAllProducts] = useState(false);
    const [loading, setLoading] = useState(false);
    const onSearchProducts = (string) => {
        // If search is empty -> show all products
        setSearchInput(string);
        setSelectAllProducts(false);
    };



    useEffect(() => {
        Client.post(`/emp/products?page=${0}`, {
            names: [],
            tags: [],
            types: [],
            retrieveAll: true,
        }).then((res) => {
            setAllProducts(res.data);
        });
    }, []);
    useEffect(() => {
        setSelectedProducts(initialSelectedProducts);
    },[initialSelectedProducts]);
    const addOrRemoveSelectedProduct = (productOBJ) => {
        const index = selectedProducts.findIndex((p) => p.id === productOBJ.id);
        index !== -1
            ? setSelectAllProducts(false)
            : productsAfterSearch?.length === 1 && setSelectAllProducts(true);

        setSelectedProducts(
            index !== -1
                ? [
                    ...selectedProducts.slice(0, index),
                    ...selectedProducts.slice(index + 1),
                ]
                : [...selectedProducts, productOBJ]
        );
    };

    const selectAll = () => {
        if (selectAllProducts) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts([...productsAfterSearch, ...selectedProducts]);
        }
    };

    const saveChanges = async () => {
        const addedProducts = selectedProducts?.filter(
            (p) => !initialSelectedProducts?.map((p) => p.id)?.includes(p.id)
        );
        const removedProducts = initialSelectedProducts?.filter(
            (p) => !selectedProducts?.map((p) => p.id)?.includes(p.id)
        );
        try {
            setLoading(true);
            if(experienceId){

                for (const product of addedProducts) {
                    await Client.put(
                        `emp/experiences/${experienceId}/product/${product.id}`
                    );
                }
                for (const product of removedProducts) {
                    await Client.delete(
                        `emp/experiences/${experienceId}/product/${product.id}`
                    )
                }
                setShow(false);
                setForceRefetch(Math.random());
            }
        } catch (e) {
            toaster.danger("Products could not be added to experience.");
        }
        setLoading(false);
    };

    const productsUI = [
        // ...(allProducts || []),
        ...(allProducts?.filter(
            (p) => !selectedProducts?.map((p) => p.id)?.includes(p.id)
        ) || []),
    ];

    const productsAfterSearch = productsUI?.filter((p) => p?.name?.toLowerCase()?.includes(searchInput?.toLowerCase()));

    const finalProducts = productsAfterSearch.map(p => ({
        ...p,
        subtitle: findDuplicates(productsAfterSearch)?.includes(p?.name) ? "/" + p?.url.split("/").at(-1) : ""
    }));

    const finalSelectedProducts = selectedProducts?.map(p => ({
        ...p,
        subtitle: findDuplicates(productsUI)?.includes(p?.name) ? "/" + p?.url.split("/").at(-1) : ""
    }))

    return (
        <Dialog
            isShown={show}
            width="40vw"
            onCloseComplete={() => {
                setShow(false);
            }}
            hasFooter={false}
            hasHeader={false}
            shouldCloseOnOverlayClick={false}
            contentContainerProps={{padding: "30px"}}
            containerProps={{
                maxWidth: 700,
                width: 700,
                minHeight: 600,
                borderRadius: "16px",
            }}
        >
            <div
                className="create-experience-dialog"
                style={{
                    height: "100%",
                    minHeight: 500,
                    maxHeight: 550,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Button
                    onClick={() => {
                        setShow(false);
                    }}
                    appearance="minimal"
                    position="absolute"
                    top={majorScale(2)}
                    fontSize={majorScale(3)}
                    right={majorScale(2)}
                >
                    &times;
                </Button>
                <Text
                    style={{
                        fontSize: "24px",
                        color: "#000",
                        fontWeight: 500,
                        width: "100%",
                    }}
                >
                    Add New Products <br/>{" "}
                </Text>
                <Text
                    color="#75787E"
                    paddingY="15px"
                    paddingBottom="0px"
                    fontSize="12px"
                >
                    Add products to your experience from the list below{" "}
                </Text>
                <div style={{position: "relative", margin: "20px 0 0px"}}>
                    <SearchIcon
                        style={{
                            width: "18px",
                            height: "16px",
                            position: "absolute",
                            top: 16,
                            left: 8,
                            stroke: "#75787E",
                        }}
                    />
                    {searchInput?.length > 1 && (
                        <IoClose
                            size={16}
                            style={{
                                position: "absolute",
                                top: 16,
                                left: 219,
                                fill: "#96989b",
                                cursor: "pointer",
                            }}
                            onClick={() => onSearchProducts("")}
                        />
                    )}
                    <TextInput
                        className="added-product-input-box"
                        height={48}
                        paddingLeft={28}
                        placeholder="Search Product"
                        value={searchInput}
                        onChange={(e) => onSearchProducts(e.target.value)}
                        size="large"
                        width={253}
                        borderRadius={8}
                    ></TextInput>
                </div>
                <Pane
                    display="flex"
                    gap={16}
                    marginBottom={26}
                    borderTop="1px solid lightgrey"
                    style={{
                        border: "none",
                        maxHeight: "300px",
                        overflow: "hidden",
                        marginTop: 20,
                        marginBottom: -2,
                    }}
                    className="create-selected-items-section"
                >
                    <div className="products-container">
                        <div>
                            <SelectedItem
                                item={{name: "Product "}}
                                border={false}
                                addOrRemoveSelectedProduct={() => {
                                    selectAll();
                                    setSelectAllProducts(!selectAllProducts);
                                }}
                                type="products"
                                selected={
                                    selectAllProducts || finalProducts?.length === 0
                                }
                            />
                            {finalProducts?.length === 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "80%",
                                        flexDirection: "column",
                                    }}
                                >
                                    <AllProductsIcon/>
                                    <p style={{color: "#75787E", marginTop: "8px"}}>
                                        All product selected
                                    </p>
                                </div>
                            )}
                            {finalProducts
                                .filter(
                                    (p) => !selectedProducts?.map((p) => p.id)?.includes(p.id)
                                )
                                ?.map((product, idx) => (
                                    <SelectedItem
                                        item={product}
                                        subTitle={product.subtitle}
                                        addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                                        selectedProducts={[]}
                                        type="product"
                                    />
                                ))}
                        </div>
                        <div className="separator"></div>
                        <div>
                            <p>
                                Selected Products |{" "}
                                <span
                                    style={{
                                        color: "#75787E",
                                    }}
                                >{`${selectedProducts?.length}/${allProducts?.length} Products `}</span>
                            </p>
                            {finalSelectedProducts?.map((product, idx) => (
                                <SelectedItem
                                    item={product}
                                    subTitle={product.subtitle}
                                    addOrRemoveSelectedProduct={addOrRemoveSelectedProduct}
                                    selectedProducts={selectedProducts}
                                    type="product"
                                />
                            ))}
                        </div>
                    </div>
                    {" "}
                </Pane>
                <CreateDialogButton
                    text={"Save Changes"}
                    onClick={async () => {
                        await saveChanges();
                    }}
                    disabled={loading}
                    style={{padding: "25px 30px", marginTop: "18px"}}
                />
            </div>
        </Dialog>
    );
};

export default AddProductsDialog;
