import CustomTextArea from "components/Forms/CustomTextArea";
import useEditor from "stores/use-editor";
import { useEditorEvents } from "../../../stores/use-editor-events";
import Switch from "../../../components/Switch/Switch";
import { Pane } from "evergreen-ui";
import { useState } from "react";
import CustomInput from "../../../components/Forms/CustomInput";
import "./ResultPage.scss";
import { debounceInput } from "../../../utils/utls";

const ResultsContent = () => {
  const { experience } = useEditor();
  const { design } = experience;
  const { addEvents } = useEditorEvents();
  const recommendation = design?.recommendation;
  const [coupon, setCoupon] = useState(!design?.disableCoupon);

  return (
    <div className="question-product">
      <div className="data">
        <div className="data-holders">
          Title
          {/* @ts-ignore */}
          <CustomTextArea
            defaultValue={recommendation?.title}
            charLimit={75}
            onChange={debounceInput(
              (e) =>
                addEvents("design", "update", {
                  "recommendation/title": e.target.value,
                }),
              500
            )}
          />
        </div>
        <div className="data-holders">
          Cta button
          {/* @ts-ignore */}
          <CustomTextArea
            width={"50%"}
            defaultValue={recommendation?.ctaButtonText}
            charLimit={25}
            onChange={debounceInput(
              (e) =>
                addEvents("design", "update", {
                  "recommendation/ctaButtonText": e.target.value,
                }),
              500
            )}
          />
        </div>
        <div className="data-holders">
          More details
          {/* @ts-ignore */}
          <CustomTextArea
            width={"50%"}
            defaultValue={recommendation?.hyperlinkText}
            charLimit={25}
            onChange={debounceInput(
              (e) =>
                addEvents("design", "update", {
                  "recommendation/hyperlinkText": e.target.value,
                }),
              500
            )}
          />
        </div>
        <div className="data-holders">
          More recommended products
          {/* @ts-ignore */}
          <CustomTextArea
            defaultValue={recommendation?.moreProductsText}
            charLimit={40}
            onChange={debounceInput(
              (e) =>
                addEvents("design", "update", {
                  "recommendation/moreProductsText": e.target.value,
                }),
              500
            )}
          />
        </div>
        <Pane
          display="flex"
          alignItems="center"
          gap={16}
          marginTop={30}
          marginBottom={30}
        >
          <div>Coupon code</div>
          <Switch
            switchVal1={"enabled"}
            switchVal2={"disabled"}
            defaultValue={design?.disableCoupon ? "disabled" : "enabled"}
            onChange={async (enabled) => {
              setCoupon(enabled === "enabled");
              await addEvents("design", "update", {
                disableCoupon: enabled === "disabled",
              });
            }}
          />
        </Pane>
        {coupon && (
          <div style={{ display: "flex", gap: 50 }}>
            <div className="data-holders">
              Code
              {/* @ts-ignore */}
              <CustomTextArea
                defaultValue={experience?.coupon?.name}
                charLimit={20}
                width={200}
                onChange={debounceInput(
                  (e) =>
                    addEvents("experience", "update", {
                      "coupon/name": e.target.value,
                    }),
                  500
                )}
              />
            </div>
            <div className="data-holders">
              Percentage
              {/* @ts-ignore */}
              <CustomInput
                from="result-page"
                defaultValue={experience?.coupon?.amount ?? 10}
                // value={experience?.coupon?.amount}
                error={
                  Number(experience?.coupon?.amount) > 99 && "Invalid input"
                }
                showErrorMessage
                width={75}
                max="99"
                styleErrorMessage={{ whiteSpace: "nowrap" }}
                type={"number"}
                sign={"%"}
                onChange={debounceInput(
                  (e) =>
                    addEvents("experience", "update", {
                      "coupon/amount": e.target.value,
                    }),
                  500
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultsContent;
