import {useEffect, useState} from "react";
import {Client} from "../../Client";
import {COLLECTION, PAGE, PRODUCT_PAGE} from "./PlacementWizard";

const usePlacements = ({activeTab, }) => {
    const [placements, setPlacements] = useState({
        [COLLECTION]:[],
        [PAGE]:[],
        [PRODUCT_PAGE]:[]
    });
    const [loading,setLoading]=useState(true)
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setLoading(true)
        if (activeTab === COLLECTION) {
            Client("/emp/collections").then(res => {
                setPlacements(prev => ({
                    ...prev,
                    [COLLECTION]: res.data
                }))
                setLoading(false)
                 setReload(false)
            })
        }

        if (activeTab === PAGE) {
            setLoading(true)
            Client("/emp/pages").then(res => {
                setPlacements(prev => ({
                    ...prev,
                    [PAGE]: [
                        {handle:"/",title:"Homepage"},
                        ...res.data,
                    ]
                }))
                setLoading(false)
                setReload(false)
            })
        }

        if (activeTab === PRODUCT_PAGE) {
            setLoading(true)
            Client("/emp/product-pages").then(res => {
                setPlacements(prev => ({
                    ...prev,
                    [PRODUCT_PAGE]: res.data
                }))
                setLoading(false)
                setReload(false)
            })
        }
       
    },[activeTab, reload]);

    return{placements,loading, reload, setReload}
}

export default usePlacements;