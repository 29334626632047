import {Li, majorScale, Menu, Popover, Position, Ul} from "evergreen-ui";
import {FaEllipsisV} from "react-icons/fa";

const ProductRowMenu = ({ handleDelete, handleEdit }) => {
  return (
    <>
      <Popover
        statelessProps={{
          background: "#E4F1FF",
          borderRadius: majorScale(1),
        }}
        position={Position.BOTTOM_RIGHT}
        content={({ close }) => (
          <Menu>
            <Ul className="brand-widget-menuitem" onClick={close}>
              <Li
                onClick={() => {
                  handleEdit();
                }}
              >
                Edit
              </Li>
              <Li
                style={{ color: "#E45447" }}
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </Li>
            </Ul>
          </Menu>
        )}
      >
        <div style={{ cursor: "pointer" }}>
          <FaEllipsisV size={16} color="#0F0F0F" />
        </div>
      </Popover>
    </>
  );
};

export default ProductRowMenu;
