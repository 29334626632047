import React, {useEffect, useState} from "react";
import {Client} from "../../Client";
import {useLocation, useNavigate} from "react-router-dom";
import "./PlacementsPage.scss";
import InputsRow from "./components/InputsRow";
import Button from "../../components/Button/Button";

import {ReactComponent as BackButton} from "../../assets/icons/back-button.svg";
import Header from "components/Header/Header";

const PlacementsPage = () => {
  const [saveDisabled, setSaveDisabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const experienceOBJ = location.state;
  const [placements, setPlacements] = useState(
    experienceOBJ?.locations || [
      {
        implementationType: null,
        path: "",
        settings: {
          parentID: "",
        },
      },
    ]
  );

  const [showTour, setShowTour] = useState(false);
  const [tourCurrentIndex, setTourCurrentIndex] = useState(0);

  function handleCurrentTourIndex(index) {
    setTourCurrentIndex(index);
  }

  function handleOpenTour(value) {
    setTourCurrentIndex(0);
    setShowTour(value);
  }

  const addPlacement = () => {
    setPlacements((prev) => [
      ...prev,
      {
        implementationType: null,
        path: null,
      },
    ]);
  };

  // Handle validation
  useEffect(() => {
    if (!placements[0].path && placements[0].implementationType === "POPUP")
      setSaveDisabled(true);
    if (placements[0].implementationType !== "POPUP") setSaveDisabled(false);
    if (
      placements[0].implementationType ===
        experienceOBJ?.locations[0].implementationType &&
      placements[0].path === experienceOBJ?.locations[0].path
    )
      setSaveDisabled(true);
    if (
      placements[0].implementationType === "EMBEDDED_PAGE" &&
      (!placements[0]?.settings?.parentID || !placements[0]?.path)
    )
      return setSaveDisabled(true);
    if (
      placements[0].implementationType === "EMBEDDED_PAGE" &&
      placements[0]?.settings?.parentID &&
      placements[0]?.path
    )
      return setSaveDisabled(false);
  }, [placements]);

  const onSavePlacements = async () => {
    const payload = placements;

    try {
      const res = await Client.put(
        `/emp/experiences/${experienceOBJ.id}/locations`,
        { locations: payload }
      );
      navigate("/experiences");
    } catch (e) {
      alert(e.message);
    }
  };


    return (
        <div className="app-page placements-page">
            {/* {showTour && <TourPopup tourCurrentIndex={tourCurrentIndex} handleCurrentTourIndex={handleCurrentTourIndex} handleOpenTour={handleOpenTour} />} */}
            <Header name="Placements" handleOpenTour={handleOpenTour} />
            <div className="app-page-content">
                {/* <button className="back-button" onClick={() => navigate("/experiences")}>
                    <FontAwesomeIcon icon={faArrowLeft} className="delete-row"/>
                    Back
                </button> */}

        <div
          onClick={() => navigate("/experiences")}
          style={{
            display: "inline-block",
            cursor: "pointer",
          }}
        >
          <BackButton style={{ verticalAlign: "middle" }} />
          <span
            style={{
              verticalAlign: "middle",
              fontFamily: "Satoshi",
              fontSize: "13px",
            }}
          >
            Back
          </span>
        </div>
        <div className="placements-page-titles">
          <h2>{experienceOBJ?.name}</h2>
          <p className="placements-page-subtitle">
            Where would you like to place the experience on your site
          </p>
        </div>
        {placements.map((placement, index) => (
          <InputsRow
            key={index}
            index={index}
            setPlacements={setPlacements}
            placements={placements}
            experienceOBJ={experienceOBJ}
            setSaveDisabled={setSaveDisabled}
          />
        ))}
        {/*<span className="fa-center add-placement-container" onClick={addPlacement}>*/}
        {/*    <FontAwesomeIcon icon={faPlus} />*/}
        {/*    Add placement*/}
        {/*</span>*/}
        <div className="placements-page-footer">
          <Button
            text="Save"
            type="SUBMIT"
            disabled={saveDisabled}
            onClick={onSavePlacements}
          />
        </div>
      </div>
    </div>
  );
};

export default PlacementsPage;
