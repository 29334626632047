import CustomTextArea from "components/Forms/CustomTextArea";
import {useState} from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";

import CustomImageInput from "components/Forms/CustomImageInput";
import CustomInput from "components/Forms/CustomInput";
import CustomRangeTag from "components/Forms/CustomRangeTag";
import {Pane} from "evergreen-ui";
import uploadToS3 from "utils/uploadToS3";
import {calculateThirds} from "utils/utls";
import AddProductsDialog from "../../AddProductsDialog";

export default function Range({ idx, ques }) {
  const {
    questions,
    activeQuestionIndex,
    experience: { design },
  } = useEditor();
  const [s3Path, setS3Path] = useState();
  const { addEvents } = useEditorEvents();
  const uploadImage = async (file) => {
    const s3PathFetched = await uploadToS3(file);
    setS3Path(s3PathFetched);
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/image`]: s3PathFetched,
    });
  };
  const activeQuestionId = questions[activeQuestionIndex].id;
  const [showProductModal, setShowProductModal] = useState(false);
  const updateAnswers = (minValue, maxValue) => {
    //  const activeQues= design.questions.find((q)=>q.id===questions[activeQuestionIndex].id)
    const rangeAnswers = calculateThirds(minValue, maxValue);
    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: activeQuestionIndex,
      answers: [
        {
          ...questions[activeQuestionIndex].answers[0],
          answer: `Range ${rangeAnswers[0]}-${rangeAnswers[1]}`,
        },
        {
          ...questions[activeQuestionIndex].answers[1],
          answer: `Range ${rangeAnswers[1]}-${rangeAnswers[2]}`,
        },
        {
          ...questions[activeQuestionIndex].answers[2],
          answer: `Range ${rangeAnswers[2]}-${rangeAnswers[3]}`,
        },
      ],
    });
  };

  return (
    <div className="question-content">
      <div className="question-input" style={{ width: "100%" }}>
        <div className="inputs" style={{ border: "none", padding: 0 }}>
          {/* @ts-ignore */}
          <CustomTextArea
            placeHolder={"e.g 10$ per month "}
            width={"60%"}
            label={"Min Value"}
            value={design.questions[activeQuestionId].minLabel}
            onChange={(e) => {
              addEvents("design", "update", {
                [`questions/${questions?.[activeQuestionIndex]?.id}/minLabel`]:
                  e.target.value,
              });
            }}
            charLimit={40}
          />
          {/* @ts-ignore */}
          <CustomInput
            onChange={(e) => {
              addEvents("design", "update", {
                [`questions/${questions?.[activeQuestionIndex]?.id}/minValue`]:
                  e.target.value,
              });
              updateAnswers(
                e.target.value,
                design.questions[activeQuestionId].maxValue
              );
            }}
            value={design.questions[activeQuestionId].minValue}
            type="number"
            placeHolder="Value number"
            width={"35%"}
          />
          {/* @ts-ignore */}
        </div>
        <div className="inputs" style={{ border: "none", padding: 0 }}>
          {/* @ts-ignore */}
          <CustomTextArea
            placeHolder={"e.g 10$ per month "}
            width={"60%"}
            label={"Max Value"}
            onChange={(e) => {
              addEvents("design", "update", {
                [`questions/${questions?.[activeQuestionIndex]?.id}/maxLabel`]:
                  e.target.value,
              });
            }}
            value={design.questions[activeQuestionId].maxLabel}
            charLimit={40}
          />
          {/* @ts-ignore */}
          <CustomInput
            onChange={(e) => {
              addEvents("design", "update", {
                [`questions/${questions?.[activeQuestionIndex]?.id}/maxValue`]:
                  e.target.value,
              });
              updateAnswers(
                design.questions[activeQuestionId].minValue,
                e.target.value
              );
            }}
            value={design.questions[activeQuestionId].maxValue}
            type="number"
            placeHolder="Value number"
            width={"35%"}
          />
          {/* @ts-ignore */}
        </div>
      </div>

      <div className="inputs" style={{ width: "100%" }}>
        <CustomRangeTag setShowProductModal={setShowProductModal} />
      </div>

      <Pane marginY={16}>
        s
        <div className="selection-inputs">
          {/* @ts-ignore */}

          <CustomImageInput
            label="Side Image"
            onImageChange={uploadImage}
            accept={"image/png"}
            onDelete={() => {
              addEvents("design", "update", {
                [`questions/${questions[activeQuestionIndex].id}/image`]:
                  undefined,
              });
            }}
            value={
              s3Path ??
              design?.questions?.[questions[activeQuestionIndex]?.id]?.image
            }
            placeHolder="Choose an image"
          />
        </div>
        <AddProductsDialog
          show={showProductModal}
          setShow={setShowProductModal}
          products={[]}
          noOfConnectedProducts={0}
        />
      </Pane>
    </div>
  );
}
