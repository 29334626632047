import {Pane} from "evergreen-ui";
import CustomTextArea from "../../../../../components/Forms/CustomTextArea";
import {useEditorEvents} from "../../../../../stores/use-editor-events";
import useEditor from "../../../../../stores/use-editor";

const EditorSwiperIntro = () => {
    const { addEvents } = useEditorEvents();
    const { experience:{design},experience} = useEditor();
    const introDesign = experience?.design?.intro || {};

    return (
        <div>
            Title
            {/* @ts-ignore */}
            <CustomTextArea
                placeHolder={introDesign?.header}
                value={introDesign?.header}
                charLimit={60}
                onChange={(s3Url) =>
                    addEvents("design", "update", {
                        "intro/header": s3Url,
                    })
                }
            />

            <div className="data-holders">
                Tip
                {/* @ts-ignore */}
                <CustomTextArea
                    placeHolder={introDesign?.hintText}
                    value={introDesign?.hintText}
                    charLimit={60}
                    onChange={(e) =>
                        addEvents("design", "update", {
                            "intro/hintText": e.target.value,
                        })
                    }
                />
            </div>
            <div className="data-holders">
                CTA Button
                {/* @ts-ignore */}
                <CustomTextArea
                    width={'50%'}
                    placeHolder={introDesign?.header}
                    value={introDesign?.header}
                    charLimit={25}
                    onChange={(e) =>
                        addEvents("design", "update", {
                            "intro/header": e.target.value,
                        })
                    }
                />
            </div>
            <div className="data-holders">
                <Pane display="flex" alignItems="center" justifyContent="space-between">
                    <div>
                        Yes Button
                        {/* @ts-ignore */}
                        <CustomTextArea
                            placeHolder={introDesign?.thumbsDownText}
                            value={introDesign?.thumbsDownText}
                            charLimit={14}
                            onChange={(e) =>
                                addEvents("design", "update", {
                                    "intro/thumbsDownText": e.target.value,
                                })
                            }
                        />
                    </div>
                    <div>
                        No Button
                        {/* @ts-ignore */}
                        <CustomTextArea
                            placeHolder={introDesign?.thumbsUpText}
                            value={introDesign?.thumbsUpText}
                            charLimit={14}
                            onChange={(e) =>
                                addEvents("design", "update", {
                                    "intro/thumbsUpText": e.target.value,
                                })
                            }
                        />
                    </div>

                </Pane>

            </div>
        </div>

)
}

export default EditorSwiperIntro;