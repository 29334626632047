import {Button} from "evergreen-ui";
import React from "react";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";

import "./CustomRangeTag.scss";

export default function CustomRangeTag({setShowProductModal}) {
  const { addEvents } = useEditorEvents();
  const {
    changeEditAnswerIndex,
    activeQuestionIndex,
    questions,
    addToTagMap,
    tagsMap,
    deleteTag,
    setShowAddTagDialog,
  } = useEditor();
  const rangeOptions = questions[activeQuestionIndex].answers;


  return (
    <div className="quiz-rating-tag-container">
      <p className="quiz-rating-tag-header">Choose tags for each groups</p>
      <div className="quiz-rating-tag-table-header-container">
        <p className="quiz-rating-tag-table-header-left">Range groups</p>
        <p className="quiz-rating-tag-table-header">Tags</p>
      </div>
      {rangeOptions.map((range, idx) => {
        return (
          <div>
            <div
              className="quiz-rating-tag-table-container"
              style={{ display: "flex" }}
            >
              <div className="quiz-rating-tag-table-left">
                <p className="quiz-rating-tag-table-left-placeholder">
                  {range.answer}
                </p>
              </div>
              <Button
                border="none"
                color="#017CF8"
                appearance="none"
                background="none"
                textDecoration="underline"
                onClick={() => setShowProductModal(true)}
              >
                Connected Products
              </Button>
            
            </div>
          </div>
        );
      })}
    </div>
  );
}
