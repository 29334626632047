import CustomTagsSearchV2 from 'components/CustomTags/CustomTagsSearchV2';
import {ReactComponent as InfoSign} from "../../assets/icons/info-tag.svg"
import {Tooltip} from 'evergreen-ui';
import React from 'react'
import useEditor from 'stores/use-editor';
import {useEditorEvents} from 'stores/use-editor-events';

import "./CustomRatingTag.scss"
import CustomTagsInput from 'components/CustomTags/CustomTagsInput';

export default function CustomRatingTag() {

  const { addEvents } = useEditorEvents();

  const { changeEditAnswerIndex, activeQuestionIndex, questions ,addToTagMap,tagsMap,deleteTag,setShowAddTagDialog} = useEditor();
  const ratingOptions = questions[activeQuestionIndex].answers

  return (
    <div className='quiz-rating-tag-container'>
      <p className='quiz-rating-tag-header'>Choose tags for each groups</p>
      <div className='quiz-rating-tag-table-header-container'>
        <p className='quiz-rating-tag-table-header-left'>Range groups</p>
        <p className='quiz-rating-tag-table-header'>Tags</p>
      </div>
      {ratingOptions.map((rating,idx) => {
        return (
        <div>
        <div className='quiz-rating-tag-table-container' style={{display:'flex'}}>
          <div className='quiz-rating-tag-table-left'>
            <p className='quiz-rating-tag-table-left-placeholder'>{rating.answer}</p>
          </div>
          <CustomTagsSearchV2
              api={"emp/tags"}
              placeHolder="Search tags"
              onCreateNew={() => {
                setShowAddTagDialog(true);
              }}
              onChange={(tags) => {
                changeEditAnswerIndex(idx);
                tags.forEach(t=>addToTagMap(t));
                addEvents("question", "update", {
                  questionId: questions[activeQuestionIndex].id,
                  quesIdx: activeQuestionIndex,
                  answers: [
                    ...questions[activeQuestionIndex].answers.slice(0, idx),
                    {
                      ...questions[activeQuestionIndex].answers[idx],
                      tagIDs: tags.map((t) => t.id),
                    },
                    ...questions[activeQuestionIndex].answers.slice(idx + 1),
                  ],
                });
              }}
            />
            
        </div>
        {ratingOptions.tagIDs?.length > 0 && (
              <div className="tag-inputs">
                {ratingOptions.tagIDs.map((tagID) => (
                  // @ts-ignore
                  <CustomTagsInput
                    key={tagID}
                    tag={tagsMap.find((tag) => tag.id === tagID)?.name}
                    deleteTag={() => {
                      deleteTag(tagsMap.find((tag) => tag.id === tagID));
                    }}
                  />
                ))}
                <Tooltip
                  statelessProps={{
                    backgroundColor: "#E4F1FF",
                    color: "black",
                  }}
                  content={
                    <p>
                      <small>
                        Verify that the tags selected match your answer, if not,
                        search for relevant tags or create tags using the
                        "search tags" field.
                      </small>
                    </p>
                  }
                >
                  <InfoSign fill="#E46054" stroke="#E46054" />
                </Tooltip>
              </div>
            )}
        </div>
        )
      })}
    </div>
  )
}
