import imageCompressionLib from 'browser-image-compression';

export const imageCompression = async (file) => {

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    };

    try {
        return imageCompressionLib(file, options);
    } catch (error) {
        console.info(error);
    }
}