const ExpPlacementsModalFooter = ({
  isShopifyUser,
  isManuelSetUp,
  saveEditedExperience,
  lastStepButtonText,
  publishExp,
  disableSaveButton,
  setStep,
  step,
  embedded,
}) => {
  const lastStep =
    step === 3 || // Step 3 is the max step possible
    (step === 2 && !embedded && !isManuelSetUp && isShopifyUser) || // Step 2 is final when the user is shopify with normal popup.
    (step === 1 && !isShopifyUser && !isManuelSetUp && !embedded); // Step 1 is final when the user is NOT shopify with normal popup
  const publishMode = lastStepButtonText === "Publish";

  const onClickButton = async () => {
    if (disableSaveButton) return;

    if (step === 1) {
      if (!isShopifyUser) {
        if (!isManuelSetUp && !embedded) {
          // If NOT SHOPIFY and it's normal popup - just publish
          if (publishMode) return publishExp();
          return saveEditedExperience();
        }
        if ((isManuelSetUp && !embedded) || embedded) return setStep(3);
      }

      if (isShopifyUser) return setStep(step + 1);
    }

    if (step === 2) {
      if (isManuelSetUp) return setStep(step + 1);
      if (!embedded && publishMode) return publishExp();
      if (!embedded && !publishMode) return saveEditedExperience();
      return setStep(step + 1);
    }

    // STEP 3 IS THE MAX STEP POSSIBLE TO REACH
    if (step === 3) {
      if (publishMode) return publishExp();
      return saveEditedExperience();
    }
  };

  return (
    <button
      className={`save-changes-button ${
        !disableSaveButton ? "save-changes-button-active" : ""
      } `}
      onClick={onClickButton}
    >
      {lastStep ? lastStepButtonText : "Next"}
    </button>
  );
};

export default ExpPlacementsModalFooter;
