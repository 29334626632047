import {Dialog, Pane} from "evergreen-ui";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {useQuiz} from "stores/use-quiz";
import "./Dialog.scss";


const NoConnectedProductsDialog = ({ show, setShow }) => {
  const {
    activeQuestionIndex,
    changeActiveQuestionIndex,
    questions,
  } = useEditor();
  const {dontShowProductDialog,setDontShowProductDialog}=useQuiz();
  const { addEvents } = useEditorEvents();
  return (
    <Pane>
      <Dialog
        isShown={show}
        width="470px"
        hasFooter={false}
        hasHeader={false}
        topOffset={window.innerHeight / 3}
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
      >
        <div className="dialog-content">
          <h4 style={{fontWeight:500}}> Save Question </h4>
          <p>
          By saving, You agree that there are answers that aren't related to products

          </p>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0",justifyContent:'space-between' }}
        >
          <button
            className="radio-button"
            onClick={(e) => {
              setDontShowProductDialog(!dontShowProductDialog);
            }}
          >
            <span
              style={{
                backgroundColor:dontShowProductDialog
                  ? "#017CF8"
                  : "transparent",
              }}
            ></span>
          </button>
          <p style={{color:'#75787E',fontSize:'14px'}}>Don’t show this again</p>
          <div className="container">
          <button
            onClick={() => {
            changeActiveQuestionIndex(undefined);
              setShow(false);
            }}
            className="cancel dialog-btn"
            style={{ color: "#75787E", marginRight: "16px", cursor: "pointer", background: 'none' }}
          >
            Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={() => {
                addEvents("question", "update", {
                    questionId: questions[activeQuestionIndex].id,
                    quesIdx: questions.length - 1,
                    isNew: false,
                  });
                  changeActiveQuestionIndex(undefined);
            }}
            style={{
              color: "#fff",
              background: "#017CF8",
              marginRight: "16px",
              cursor: "pointer",
            
            }}
          >
            Save
          </button>
        </div>
        </div>

       
      </Dialog>
    </Pane>
  );
};

export default NoConnectedProductsDialog;