import create from "zustand";
import {devtools} from "zustand/middleware";
import {SwiperState} from "./Types/SwiperTypes";


export const useSwiper = create<SwiperState>()(
  devtools(
    (set, get) => ({
      activeSentenceID: "",
      activeSentenceIndex:null,
      activeTagID:'',
      tagTypes: [],
      sentencesTags: {},
      tagsImages: {},
      tagsImagesEdited: [
        { type: "", tagID: "", newImageS3Path: "", newImagePublic: "" },
      ],
      tagTypesToUpdate: [],

      changeActiveTagID:(tagID)=>{
        set({
          activeTagID: tagID,
        });
      },

      changeActiveSentence: (sentenceID,sentenceIDX) => {
        set({
          activeSentenceID: sentenceID,
          activeSentenceIndex:sentenceIDX
        });
      },

      setSentences: (v) => {
        set({
          tagTypes: v.tagTypes,
          sentencesTags: v.tags,
          tagsImages: v.images,
        });
      },

      setSentence: (idx, tagTypeOBJ, value) => {
        set({
          tagTypes: [
            ...get().tagTypes.slice(0, idx),
            { ...get().tagTypes[idx], sentence: value },
            ...get().tagTypes.slice(idx + 1, get().tagTypes.length),
          ],
        });
      },

      setTagTypeImage: ({ type, s3Path }) => {
        set({
          tagsImages: {
            ...get().tagsImages,
            [type]: s3Path,
          },
        });
      },

      setTagImage: (imageObj) => {
        // obj -> {type:"",tagID:"",newImage:""}
        if (get().tagsImagesEdited.some((obj) => obj.tagID === obj.tagID)) {
          set({
            tagsImagesEdited: [
              ...get().tagsImagesEdited.filter(
                (obj) => obj.tagID !== obj.tagID
              ),
              imageObj,
            ],
          });
        } else {
          set({
            tagsImagesEdited: [...get().tagsImagesEdited, imageObj],
          });
        }
      },

      addToTagTypesToUpdate: (tagTypeOBJ) => {
        if (!get().tagTypesToUpdate.includes(tagTypeOBJ.id)) {
          set({ tagTypesToUpdate: [...get().tagTypesToUpdate, tagTypeOBJ.id] });
        }
      },
    }),
    {
      name: "swiper",
    }
  )
);
