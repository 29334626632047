import useEditor from "../stores/use-editor";
import {useDesign} from "../stores/design";
import {useEditorEvents} from "../stores/use-editor-events";

const useFirstQuestion = () => {
    const { addEvents } = useEditorEvents();
    const { setDesign } = useDesign();
    const { experience: { design } } = useEditor();


    const changeFirstQuestion = async (questionID) => {
        await setDesign("firstQuestionID",questionID);
        await addEvents("design", "update", {"firstQuestionID": questionID });
    }

    return {changeFirstQuestion};
}

export default useFirstQuestion;