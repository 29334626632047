import CustomTextArea from "components/Forms/CustomTextArea";
import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";
import {BiMoveVertical} from "react-icons/bi";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import useCustomTags from "stores/use-custom-tags";
import {DEFAULT_ANSWER} from "utils/finalVariables";
import uploadToS3 from "utils/uploadToS3";
import {ReactComponent as Delete} from "../../../../../../../assets/icons/delete.svg";
import AddProductsDialog from "../../AddProductsDialog";
import {checkImageSelection, debounceInput} from "../../../../../../../utils/utls";
import AnswerConnectedProducts from "../../../AnswerConnectedProducts";
import useDeleteProperties from "hooks/useDeleteProperties";

const SelectionAnswer = ({ ans, idx, imageSelection }) => {
  const { addEvents } = useEditorEvents();
  const {
    questions,
    activeQuestionIndex,
    changeEditAnswerIndex,
    experience: { design },
    setScrollAnswer,
    setIsEditing,
      setAnswersProductsCountLocal,
  } = useEditor();
  const [showProductModal, setShowProductModal] = useState(false);
  const [noOfConnectedProducts, setNoOfConnectedProducts] = useState(0);
  const [products, setProducts] = useState([]);
  const { addTag, tags } = useCustomTags();
  const { deleteAnswer } = useDeleteProperties();

  const uploadAnswerImage = async ({s3Url}) => {
    addEvents("design", "update", {
      [`questions/${questions[activeQuestionIndex].id}/${ans?.id}`]:
        s3Url,
    });
  };

  useEffect(() => {
    if (ans.tagIDs?.[0]) {
      setNoOfConnectedProducts(tags[ans.tagIDs?.[0]]?.products?.length || 0);
    }
  }, [ans.tagIDs, tags]);

  const editAnswer = (e, ans, idx) => {
    setIsEditing(true);
    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: activeQuestionIndex,
      answers: [
        ...questions[activeQuestionIndex].answers.slice(0, idx),
        { ...ans, answer: e.target.value },
        ...questions[activeQuestionIndex].answers.slice(idx + 1),
      ],
    });
  };

  const handleBlurAnswer = (e) => {
    setScrollAnswer("false");
    if (e.target.value === "") return;

    let tag;
    if (tags[ans.tagIDs[0]]) {
      tag = addTag({
        ...tags[ans.tagIDs[0]],
        name: e.target.value,
      });
    } else {
      tag = addTag({
        name: e.target.value,
        originalTagId: ans.tagIDs[0],
      });
    }

    addEvents("question", "update", {
      quesIdx: activeQuestionIndex,
      questionId: questions[activeQuestionIndex].id,
      answers: [
        ...questions[activeQuestionIndex].answers.slice(0, idx),
        {
          ...ans,
          answer: e.target.value,
          tagIDs: [tag.id],
        },
        ...questions[activeQuestionIndex].answers.slice(idx + 1),
      ],
    });
  };

  const isImageSelection = checkImageSelection(
    questions[activeQuestionIndex],
    design
  );
  const noConnectedProducts = noOfConnectedProducts === 0;

  return (
    <>
      <Draggable
        key={ans.id.toString()}
        index={idx}
        draggableId={ans.id.toString()}
      >
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div style={{ position: "relative" }}>
              <BiMoveVertical
                style={{
                  marginTop: 32,
                  position: "absolute",
                  left: -28,
                }}
                size={16}
                color={"#A3A3A3"}
              />

              <div
                className="selection-inputs"
                style={{ position: "relative" }}
              >
                {isImageSelection ? (
                  /* @ts-ignore */
                  <CustomTextArea
                    placeHolder={DEFAULT_ANSWER}
                    defaultValue={
                      questions[activeQuestionIndex]?.answers?.[idx]?.answer
                    }
                    name={"Answer" + (idx + 1)}
                    width={"45%"}
                    imgValue={
                      design?.questions?.[questions[activeQuestionIndex]?.id]?.[
                        ans?.id
                      ] ?? ""
                    }
                    // @ts-ignore
                    onImageChange={uploadAnswerImage}
                    onChange={debounceInput((e) => {
                      editAnswer(e, ans, idx);
                      handleBlurAnswer(e);
                    },500)}
                    //onBlur={handleBlurAnswer}
                    charLimit={40}
                    icon={true}
                  />
                ) : (
                  /* @ts-ignore */
                  <CustomTextArea
                    placeHolder={DEFAULT_ANSWER}
                    deafultValue={questions[activeQuestionIndex]?.answers?.[idx]?.answer}
                    width={"45%"}
                    onClick={() => changeEditAnswerIndex(idx)}
                    //onBlur={(e) => handleBlurAnswer(e)}
                    onChange={debounceInput((e) => {
                      editAnswer(e, ans, idx);
                      handleBlurAnswer(e);
                    }, 500)}
                    charLimit={
                      questions[activeQuestionIndex]?.type === "CLUSTER"
                        ? 20
                        : 40
                    }
                  />
                )}
                <AnswerConnectedProducts
                  noConnectedProducts={noConnectedProducts}
                  setShowProductModal={setShowProductModal}
                  noOfConnectedProducts={noOfConnectedProducts}
                  answerOBJ={ans}
                />

                {questions?.[activeQuestionIndex]?.answers.length > 2 && (
                  <Delete
                    style={{
                      position: "absolute",
                      right: -28,
                      marginBottom: 10,
                    }}
                    fill={"#017CF8"}
                    onClick={() => deleteAnswer(ans, idx)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
      <AddProductsDialog
        show={showProductModal}
        setShow={setShowProductModal}
        products={products}
        ans={ans}
        idx={idx}
        setNoOfConnectedProducts={setNoOfConnectedProducts}
        setAnswersProductsCountLocal={setAnswersProductsCountLocal}
      />
    </>
  );
};

export default SelectionAnswer;
