import "../DesignV2.scss";
import {ReactComponent as Error} from "../../../../../assets/icons/error.svg";
import useEditor from "stores/use-editor";
import {EMAIL} from "pages/EditorPage/design_templates";
import {camelCaseToTitleCase} from "utils/utls";
import FontPickerWrapper from "../../FontPickerWrapper";
import ColorPickerWrapper from "../../ColorPickerWrapper";
import uploadToS3 from "utils/uploadToS3";
import {useEditorEvents} from "stores/use-editor-events";
import DesignDisabledError from "../../DesignDisabledError";

const EmailDesign = () => {
  const { experience, setColorPicker, colorPicker } = useEditor();
  const { addEvents } = useEditorEvents();
  const email = experience?.design?.email;

  const pickerOpts = {
    types: [
      {
        description: "Fonts",
        accept: {
          "font/*": [".ttf"],
        },
      },
    ],
    excludeAcceptAllOption: true,
    multiple: false,
  };

  const handleColor = (item) => {
    if (item === colorPicker) {
      setColorPicker(null);
      return;
    }
    setColorPicker(item);
  };

  async function handleGetFontFile(option, heading, item) {
    if (option.value === "Custom") {
      try {
        let showFile = await window.showOpenFilePicker(pickerOpts);
        let file = await showFile[0].getFile();
        uploadToS3(file).then((res) => {
          addEvents("design", "update", {
            [`fonts/${heading + "_" + item}`]: res,
          });
        });
      } catch (e) {}
    } else {
      addEvents("design", "update", {
        [`fonts/${heading + "_" + item}`]: option.value,
      });
    }
  }
  return (
    <div style={{width:'85%'}}>
    <div className="design-v2-rows">
      {/* {heading1 === "email" && email?.enabled === "disabled" ? (
              <p className="design-v2-error" >
                <Error/>To edit the design, enable "email" in "content" tab
              </p>
            ) : (
              <> */}
      {email?.enabled === "disabled" ? (
        <DesignDisabledError text='To edit the design, enable "email" in "content" tab'/>
      ) : (
        <>
          <div className="font-controller">
            {Object.keys(EMAIL).map((heading, idx) => {
              return Object.keys(EMAIL[heading]).flatMap((item, i) => {
                if (EMAIL[heading][item] === "font") {
                  return (
                    <div key={idx + i + ""}>
                      <div className="design-v2-font-controllers">
                        <div className="design-v2-font-row">
                          <p className="design-v2-font-label">
                            {camelCaseToTitleCase("font")}
                          </p>
                          <FontPickerWrapper
                            heading={heading}
                            item={item}
                            handleGetFontFile={handleGetFontFile}
                          />
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              });
            })}
          </div>
          <div className="design-v2-colors-controller">
            <div className="design-property-row design-row-titles">
              <p className="design-property-name" />
              <div className="design-properties-titles design-properties">
                <p>Background</p>
                <p>Border</p>
                <p>Text</p>
              </div>
            </div>
            <div className="design-v2-rows">
              {Object.keys(EMAIL).flatMap((heading) => (
                <div className="design-property-row">
                  <p className="design-property-name">
                    {camelCaseToTitleCase(heading)}
                  </p>
                  <div className="design-properties">
                    {Object.keys(EMAIL[heading]).map((item, idx) => {
                      if (
                        EMAIL[heading][item] === "color" ||
                        EMAIL[heading][item] === "gradientColor"
                      ) {
                        return (
                          <ColorPickerWrapper
                            key={heading + EMAIL[heading][item] + idx}
                            type={EMAIL}
                            prefix="email"
                            designOBJ={experience?.design}
                            heading={heading}
                            item={item}
                            handleColor={handleColor}
                          />
                        );
                      } else {
                        return (
                          <div
                            style={{
                              minWidth: "22px",
                              margin: "0px 21.5px",
                              color: "#DBE1E7",
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              height: "1px",
                              border: "1px solid #DBE1E7",
                            }}
                          ></div>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
    </div>
  );
};

export default EmailDesign;
