import Star from "../../../../assets/icons/Star";
import {useState} from "react";
import {useEditorEvents} from "stores/use-editor-events";
import {useDesign} from "stores/design";
import useEditor from "../../../../stores/use-editor";
import useFirstQuestion from "../../../../hooks/useFirstQuestion";

const FirstQuestionStar = ({ index, questionOBJ }) => {
  const { setDesign } = useDesign();
  const {
    experience: { design },
  } = useEditor();
  const activeFirstQuestion = design?.firstQuestionID === questionOBJ?.id;
  const [isHovered, setIsHovered] = useState(false);
  const { addEvents } = useEditorEvents();
  const { changeFirstQuestion } = useFirstQuestion();

  return (
    <>
      <Star
        color={activeFirstQuestion ? "#017CF8" : "#F5F5F5"}
        onClick={() => {
          if (design?.firstQuestionID) {
            return changeFirstQuestion(undefined);
          }
          changeFirstQuestion(questionOBJ?.id);
        }}
        setIsHovered={setIsHovered}
      />
      {isHovered && (
        <div className="star-tooltip">First question in the quiz</div>
      )}
    </>
  );
};

export default FirstQuestionStar;
