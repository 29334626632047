import create from "zustand";
import {devtools} from "zustand/middleware";

interface ITag {
  name: string;
  type: string;
  id: string;
}

interface IProductType {
  name: string;
  id: string;
}

interface IState {
  names: string[];
  tags: ITag[];
  page: number;
  productIds: string[];
  totalPages: number;
  productTypes: IProductType[];
  addFilter(
    name: string,
    value: ITag | string | IProductType,
    toggle?: boolean
  ): void;
  removeFilter(name: string, index: number): void;
  setPage(page: number): void;
  setTotalPages(totalPages: number): void;
  clearFilters(): void;
}

const useProducts = create<IState>()(
  devtools(
    (set, get) => ({
      names: [],
      tags: [],
      page: 1,
      productIds: [],
      totalPages: 0,
      productTypes: [],
      addFilter: (name, value, toggle = false) => {
        let index = -1;
        if (typeof value === "string" && value.length >= 2)
          index = get()[name].findIndex((a) => a === value);
        else if (typeof value === "object") {
          index = get()[name].findIndex((a) => a.id === value.id);
        }
        if (index === -1)
          set({
            [name]: [...get()[name], value],
          });
        else if (toggle)
          set({
            [name]: [
              ...get()[name].slice(0, index),
              ...get()[name].slice(index + 1),
            ],
          });
      },
      removeFilter: (name, index) => {
        set({
          [name]: [
            ...get()[name].slice(0, index),
            ...get()[name].slice(index + 1),
          ],
        });
      },
      clearFilters: () => {
        set({
          names: [],
          productTypes: [],
          tags: [],
          productIds:[],
        });
      },
      setPage: (page) =>
        set({
          page,
        }),
      setTotalPages: (totalPages) => set({ totalPages }),
    }),
    {
      name: "emp-products",
    }
  )
);

export default useProducts;
