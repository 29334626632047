import {ReactComponent as Error} from "../../../assets/icons/error.svg";
import "./DesignDisabledError.scss";

const DesignDisabledError = ({text}) => {

    return(
        <p className="design-v2-error">
            <Error />
            <p>{text}</p>
        </p>
    )
}

export default DesignDisabledError;