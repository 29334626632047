import React, {useEffect, useState} from "react";
import CustomInput from "../../Forms/CustomInput";
import CustomTagsInput from "../../CustomTags/CustomTagsInput";
import {ReactComponent as Eye} from "../../../assets/icons/eye.svg";
import {ReactComponent as EyeClosed} from "../../../assets/icons/eye-closed.svg";
import {checkIfPasswordPass, passwordRestrictions, stringHasHebrew,} from "utils/handleOnboardingPassword";
import isOnboardingPropertyHasErrors from "utils/onboardingValidation";
import "./SignUp.scss";
import {useNavigate} from "react-router-dom";
import {GuestClient} from "Client";

import useAuth from "stores/auth";

export default function SignUp({ signupData, setSignupData }) {
  const { login, error } = useAuth();

  const navigate = useNavigate();

  const [visiblePassword, setVisiblePassword] = useState(false);

  const [name, setName] = useState(
    new URLSearchParams(window.location.search).get("name") || undefined
  );
  const [email, setEmail] = useState(
    new URLSearchParams(window.location.search).get("email") || undefined
  );
  const [brandName, setBrandName] = useState(
    new URLSearchParams(window.location.search).get("brandName") || undefined
  );
  const [isNameAndEmailDisabled, setIsNameAndEmailDisabled] = useState(
    name || false
  );
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [invalidFullNameWarning, setInvalidFullNameWarning] = useState("");
  const [invalidBrandNameWarning, setInvalidBrandNameWarning] = useState("");
  const [invalidEmailWarning, setInvalidEmailWarning] = useState("");
  const [signUpError, setSignUpError] = useState(false);
  const [googleError, setGoogleError] = useState(false);
  const [validations, setValidations] = useState([false, false, false, false]);

  const [token, setToken] = useState(
    new URLSearchParams(window.location.search).get("t")
  );
  const [hasToken, setHasToken] = useState(true);

  useEffect(() => {
    useAuth.setState({ error: false });
  }, [window.location]);


  const onSuccess = (res) => {
    let sameEmail = isNameAndEmailDisabled
      ? res.profileObj.email === email
      : true;
    if (token !== null && sameEmail) {
      const data = {
        username: res.profileObj.email,
        password: res.profileObj.googleId,
        fullName: res.profileObj.name,
        origin: "GOOGLE",
      };

      GuestClient.post(`/signup?t=${token}`, data)
        .then((res) => {
          window.location.href = "/login";
        })
        .catch((error) => {
          if (error.response) {
            setGoogleError(sameEmail);
          }
        });
    }
  };

  const onFailure = (res) => {
    setGoogleError(true);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "fullName") {
      setName(value);
      let isValid = isOnboardingPropertyHasErrors(true, "fullName", value);
      if (isValid !== undefined) {
        setInvalidFullNameWarning(isValid);
      } else {
        setInvalidFullNameWarning("");
      }
    } else if (name === "brandName") {
      let isValid = isOnboardingPropertyHasErrors(true, "brandName", value);
      if (isValid !== undefined) {
        setInvalidBrandNameWarning(isValid);
      } else {
        setInvalidBrandNameWarning("");
      }
      setBrandName(value);
    } else if (name === "email") {
      let isValid = isOnboardingPropertyHasErrors(true, "username", value);
      if (isValid !== undefined) {
        setInvalidEmailWarning(isValid);
      } else {
        setInvalidEmailWarning("");
      }
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
      handleValidations(value);
    }
  };

  const handleTermsChange = (event) => {
    const isChecked = event.target.checked;

    setTerms(isChecked);
  };

  const handleRedirect = (type) => {
    if (type === "terms")
      return window.open("https://app.skeep.io/terms-and-conditions", "_blank");
    if (type === "privacy")
      return window.open("https://app.skeep.io/privacy-policy", "_blank");
  };

  function handleValidations(value) {
    setValidations([
      checkIfPasswordPass("8 characters", value),
      checkIfPasswordPass("Uppercase letter", value),
      checkIfPasswordPass("Lowercase letter", value),
      checkIfPasswordPass("Digit", value),
    ]);
  }

  const handleSignupButton = () => {
    const emailPayload = email?.toLowerCase()?.trim();

    setSignUpError(false);
    if (token !== null) {
      const data = {
        fullName: name,
        username: emailPayload,
        password: password,
        origin: "SKEEP",
      };
      let url = `/signup?t=${token}`;
      if (brandName) url = url + `&brandName=${brandName}`;
      GuestClient.post(url, data)
        .then((res) => {
          localStorage.setItem("UNVERIFIED_EMAIL", emailPayload);
          navigate(res.data, { state: { email: emailPayload } });
        })
        .catch((error) => {
          if (error.response) {
            setSignUpError(true);
          }
        });
    }
  };

  const handleSignUpValidations =
    name !== "" &&
    email !== "" &&
    password !== "" &&
    invalidEmailWarning === "" &&
    invalidFullNameWarning === "" &&
    terms &&
    validations[0] &&
    validations[1] &&
    validations[2] &&
    validations[3];

  return (
    <div className="signup-page">
      <h1 className="signup-title">Sign up</h1>
      <p className="signup-subtitle">
      Sign up to the see the magic happen
      </p>
      { token && <CustomInput
        label="Brand Name"
        placeHolder="Enter brand name"
        // disabled={isNameAndEmailDisabled}
        size="semi-large"
        name="brandName"
        onChange={handleInputChange}
        value={brandName}
        error={isOnboardingPropertyHasErrors(true, "brandName", name)}
      />}
      {invalidBrandNameWarning !== "" && (
        <div className="warning-text-container">
          <p className="warning-text">{invalidBrandNameWarning}</p>
        </div>
      )}
      <CustomInput
        label="Name"
        placeHolder="Enter name"
        disabled={isNameAndEmailDisabled}
        size="semi-large"
        name="fullName"
        onChange={handleInputChange}
        value={name}
        error={isOnboardingPropertyHasErrors(true, "fullName", name)}
      />
      {invalidFullNameWarning !== "" && (
        <div className="warning-text-container">
          <p className="warning-text">{invalidFullNameWarning}</p>
        </div>
      )}
      <CustomInput
        type="email"
        name="email"
        label="Email"
        placeHolder="Enter email"
        disabled={isNameAndEmailDisabled}
        size="semi-large"
        onChange={handleInputChange}
        value={email}
        error={isOnboardingPropertyHasErrors(true, "username", email)}
      />
      {invalidEmailWarning !== "" && (
        <div className="warning-text-container">
          <p className="warning-text">{invalidEmailWarning}</p>
        </div>
      )}
      <CustomInput
        label="Password"
        name="password"
        placeHolder="Create password"
        type={visiblePassword ? "text" : "password"}
        size="semi-large"
        units={
          visiblePassword ? (
            <EyeClosed
              fill="#AEB2BA"
              onClick={() => setVisiblePassword(!visiblePassword)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Eye
              fill="#AEB2BA"
              onClick={() => setVisiblePassword(!visiblePassword)}
              style={{ cursor: "pointer" }}
            />
          )
        }
        onChange={handleInputChange}
        value={password}
        error={stringHasHebrew(password)}
      />
      <div
        className="password-note"
        style={{
          width: "429px",
          marginBottom: "30px",
          marginTop: "5px",
          justifySelf: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p className="note">The password must match the terms below</p>
        <div className="tags-wrap">
          {passwordRestrictions.map((restriction, index) => {
            return (
              <CustomTagsInput
                key={index}
                tag={restriction}
                index={index}
                notEdit
                tagOK={checkIfPasswordPass(restriction, password)}
              />
            );
          })}
        </div>
      </div>
      <label className="platform-radio">
        <input
          type="checkbox"
          name="platforms"
          value="Popup"
          onChange={handleTermsChange}
          style={{ cursor: "pointer" }}
          checked={terms}
        />
        <span className="platform-radio-label">
          I agree to the{" "}
          <span className="terms-link" onClick={() => handleRedirect("terms")}>
            terms and conditions
          </span>{" "}
          and{" "}
          <span
            className="terms-link"
            onClick={() => handleRedirect("privacy")}
          >
            privacy policy.
          </span>
        </span>
      </label>
      {signUpError && (
        <div className="warning-text-container">
          <p className="warning-text">
            Something went wrong. Please try again.
          </p>
        </div>
      )}
      {!hasToken && (
        <div className="warning-text-container">
          <p className="warning-text">No brand id. Please get a brand id.</p>
        </div>
      )}
      {error && (
        <div className="field error-field">Incorrect email or password</div>
      )}
      <button
        className={
          handleSignUpValidations
            ? "signup-button-container"
            : "signup-button-container-disabled"
        }
        disabled={!handleSignUpValidations}
        onClick={handleSignupButton}
      >
        <p
          className={
            handleSignUpValidations ? "signup-button" : "signup-button-disabled"
          }
        >
          Sign up
        </p>
      </button>
      {/* <div className="signup-or-container">
        <div className="signup-or-line"></div>
        <p className="signup-or">Or</p>
        <div className="signup-or-line"></div>
      </div> */}
      {/* <GoogleLogin
        clientId="524855639970-la1q8qb0lh6dplhkm195lovr81tcafea.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            disabled={!terms}
            style={
              terms
                ? { backgroundColor: "#fff" }
                : { backgroundColor: "#f3f5f7" }
            }
            className={"signup-button-google-container"}
            onClick={renderProps.onClick}
          >
            <img src={google} />
            <p className="signup-button-google">Sign up with Google</p>
          </button>
        )}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
      /> */}
      {googleError && (
        <div className="warning-text-container">
          <p className="warning-text">
            Something went wrong. Please try again later.
          </p>
        </div>
      )}
    </div>
  );
}
