import {ObjectID} from "bson";
import {Button, Pane} from "evergreen-ui";
import {useEffect, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import Answer from "./Answer";

const Answers = ({ ques }) => {
  const { addEvents } = useEditorEvents();
  const [add, setAdd] = useState();
  const {
    changeEditAnswerIndex,
    editAnswerIndex,
    questions,
    activeQuestionIndex,
    setAnswerError,
    setScrollAnswer,
  } = useEditor();

    const checkValidation = () => {
    // const boolean2=ques?.type === "SELECTION" ||  ques?.type === "IMAGE_SELECTION" ?
    // : ques?.answers?.length >= 12
    const boolean = questions[activeQuestionIndex].answers.every(
      (obj) => obj.answer !== ""
    );
    setAnswerError(!boolean);
    setAdd(boolean);
  };
  useEffect(() => {
    checkValidation();
  }, [questions]);

  const items = ques?.answers?.map((ans, idx) => (
    <Answer ques={ques} ans={ans} idx={idx} key={ans.id} />
  ));

  return (
    <div className="answers-container">
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          if (isNaN(destination?.index) || source.index === destination?.index)
            return;
          let answers = JSON.parse(
            JSON.stringify(questions[activeQuestionIndex].answers)
          );
          answers.splice(
            destination.index,
            0,
            answers.splice(source.index, 1)[0]
          );
          addEvents("question", "update", {
            questionId: questions[activeQuestionIndex].id,
            quesIdx: activeQuestionIndex,
            answers,
          });
          if (source.index === editAnswerIndex) {
            changeEditAnswerIndex(destination.index);
          }
        }}
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Pane marginTop={16}>
        <Button
          // disabled={!add}
          onClick={() => {
            addEvents("question", "update", {
              questionId: questions[activeQuestionIndex].id,
              quesIdx: activeQuestionIndex,
              answers: [
                ...questions[activeQuestionIndex].answers,
                { id: new ObjectID().toString(), answer: "", tagIDs: [] },
              ],
            });
            changeEditAnswerIndex(ques?.answers.length);
            setScrollAnswer("true");
          }}
          color="#017CF8"
          size="large"
          appearance="minimal"
        >
          + Add Answer
        </Button>
      </Pane>
    </div>
  );
};

export default Answers;
