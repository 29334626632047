export const PRODUCT = {
  primary: {
    font: "font",
  },
  page: {
    background: "color",
    border: null,
    text: "color",
  },
  elements: {
    background: "color",
    border: "color",
    text: "color",
  },
  // fixedBar: {
  //   background: "color",
  //   border: null,
  //   text: "color",
  // },
  // fixedBarElements: {
  //   background: "color",
  //   border: "color",
  //   text: "color",
  // },
  // extraSection: {
  //   background: "color",
  //   border: null,
  //   text: "color",
  // },
  // extraSectionElements: {
  //     background: "color",
  //     border: "color",
  //     text: "color",
  // },
};
export const RECOMMENDATION = {
  page: {
    background: "color",
    border: null,
    text: "color",
  },
  coupon: {
    background: "color",
    border: "color",
    text: "color",
  },
  card: {
    background: "color",
    border: "color",
    text: "color",
  },
  ctaButtons: {
    background: "color",
    border: "color",
    text: "color",
  },
};
export const EMAIL = {
  general: {
    background: "color",
    border: "color",
    text: "color",
  },
  button: {
    text: "color",
    background: "color",
  },
};
