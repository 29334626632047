import NavigationButtons from "../components/NavigationButtons";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/scss';
import useEditor from "../../../stores/use-editor";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useExperiencePage from "../../../hooks/useExperiencePage";
import {useDesign} from "../../../stores/design";
import {Pane, toaster} from "evergreen-ui";
import {useOnboarding} from "../../../stores/use-onboarding";
import OnboardingSwiperHeader from "../components/OnboardingSwiperHeader";
import SwiperInvisibleController from "../components/SwiperInvisibleController";
import {Client} from "../../../Client";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import uploadToS3 from "../../../utils/uploadToS3";
import CustomImageInput from "../../../components/Forms/CustomImageInput";
import {ReactComponent as Delete} from "../../../assets/icons/delete.svg";
import {ReactComponent as Hint} from "../../../assets/icons/hint.svg";
import useCustomTags from "../../../stores/use-custom-tags";
import {useEditorEvents} from "../../../stores/use-editor-events";
import {debounceInput, getProductsForAnswer} from "../../../utils/utls";
import useEditorActions from "../../../hooks/useEditorActions";
import {useMediaQuery} from "@mantine/hooks";
import QuestionTitleBar from "../components/QuestionTitleBar";
import {ObjectID} from "bson";
import AddQuestion from "../../EditorPage/components/Editor/Quiz/AddQuestion";
import StepHeader from "./StepHeader";
import delay from "../../../utils/delay";

const QuestionsEditor = ({title, subtitle, subtitleNoQuestionGenerated,swiperHeaderText, showSideImageFeature,swiperHeaderTextNoQuestions}) => {
    const {
        experience,
        experience: {design},
        fetchExperience,
        changeActiveQuestionIndex,
        questions,
        fetchQuestions,
        resetInitialState,
        changeTabs,
        activeQuestionIndex,
        questionsGenerated,
    } = useEditor();
    const {setIsEditing} = useExperiencePage();
    const {step, setStep,imageForNonEnglish} = useOnboarding();
    const {getDesign} = useDesign();
    const [s3Paths, setS3Paths] = useState({});
    const selectedQuestionID = questions[activeQuestionIndex]?.id;
    const navigate = useNavigate();
    const {addTag} = useCustomTags();
    const {addEvents} = useEditorEvents();
    const {handleSaveChanges} = useEditorActions();
    const smallDesktop = useMediaQuery("(max-width: 1500px)");
    const finalStepForNoQuestionsGenerated = showSideImageFeature && !questionsGenerated;
    const showExistingQuestions = questionsGenerated || (!questionsGenerated && design?.questionsToAdd?.length === 1) || showSideImageFeature;

    const uploadImage = async ({s3Url},type="image") => {
        addEvents("design", "update", {
            [`questions/${questions[activeQuestionIndex].id}/${type}`]: s3Url,
        })
    };

    useEffect( () => {
        const fire = async () => {
            const id = new ObjectID().toString();
            if (!questionsGenerated && !questions.length) {
                addEvents("question", "create", {
                    type: "SELECTION",
                    id
                });
            }
            await delay(600);
            if (!questionsGenerated && questions?.length) {
                addEvents("design", "update", {
                    [`questions/${questions[activeQuestionIndex].id}/image`]: imageForNonEnglish,
                })
            }
        }

        fire();
    }, [questionsGenerated, questions]);


    const deleteImage = () => {
        let hold = s3Paths;
        delete s3Paths[selectedQuestionID];
        setS3Paths(hold);
        addEvents("design", "update", {
            [`questions/${questions[activeQuestionIndex].id}/image`]: undefined,
        })
    }

    useEffect(() => {
        Client("/experiences").then(res => {
            if (!res.data.length) return toaster.danger("No experiences found.")

            fetchExperience(res?.data[0]?.id);
            changeTabs("experience-content");
            setIsEditing(experience);
            getDesign();
            changeActiveQuestionIndex(0);

            if (experience?.id && questionsGenerated) {
                fetchQuestions(experience?.id, 5);
            }
        });
    }, [experience?.id]);



    const handleNextButtonClick = async () => {
        handleSaveChanges(true, false).then(() => {
            if (finalStepForNoQuestionsGenerated) {
                resetInitialState();
                navigate(`/experiences/edit/${experience?.id}/content`);
            } else {
                setStep(step + 1);
                changeActiveQuestionIndex(0);
            }
        }).catch((err) => {
            toaster.danger("There was a problem saving the experience.")
        })
    };

    const handleSkipToEditorClick = () => {
        handleSaveChanges(true, false)
            .then(() => {
                resetInitialState();
                navigate(`/experiences/edit/${experience?.id}/content`)
            });
    }

    return (
        <div className="onboarding-step space-around">
            <StepHeader/>

            <div className="onboarding-titles-container">
                <h2>{title}</h2>
                <p>{!questionsGenerated ? subtitleNoQuestionGenerated :  subtitle}</p>
            </div>
            {questionsGenerated && <OnboardingSwiperHeader />}

            <Swiper
                spaceBetween={50}
                slidesPerView={smallDesktop ? 0.262 : 0.19}
                width={100}
                allowTouchMove={false}
                className="swiper-container"
            >
                <div className="wall"/>
                <SwiperInvisibleController/>

                {showExistingQuestions ? (
                        questions?.map((question, questionIndex) => (
                            <SwiperSlide key={question.id}>
                                <div>
                                    <QuestionTitleBar
                                        showDeleteButton={!showSideImageFeature && questions?.length > 3}
                                        questionIndex={questionIndex}
                                    />

                                    {showSideImageFeature ? (<CustomImageInput
                                        label="Side Image"
                                        onChange={uploadImage}
                                        accept={"image/png"}
                                        onDelete={deleteImage}
                                        defaultValue={s3Paths?.[question?.id] ? s3Paths?.[selectedQuestionID] : design?.questions?.[question?.id]?.image}
                                        placeHolder="Choose an image"
                                    />) : (<>
                                        <p style={{marginBottom: 20}}>Answers</p>
                                        <Pane overflow="auto" height={225} width={"100%"}>
                                            {question?.answers?.map((ans, answerIndex) => {

                                                return (
                                                    <Pane display={"flex"} alignItems={"center"} gap={8} key={ans?.id}>
                                                        <CustomTextArea
                                                            width={"40%"}
                                                            placeHolder={ans.answer || "Type your answer here"}
                                                            defaultValue={ans.answer}
                                                            charLimit={question?.type === "CLUSTER" ? 20 : 40}
                                                            onChange={debounceInput((e) => {
                                                                addEvents("question", "update", {
                                                                    quesIdx: questionIndex,
                                                                    questionId: questions[questionIndex].id,
                                                                    answers: [...questions[questionIndex].answers.slice(0, answerIndex), {
                                                                        ...questions[questionIndex].answers[answerIndex],
                                                                        answer: e.target.value,
                                                                    }, ...questions[questionIndex].answers.slice(answerIndex + 1),],
                                                                });
                                                            },500)}
                                                            onBlur={async () => {
                                                                const products = await getProductsForAnswer(experience?.id, ans.tagIDs[0])
                                                                const newTag = addTag({
                                                                    originalTagId: ans.tagIDs[0], name: ans.answer, products: [...products],
                                                                });
                                                                addEvents("question", "update", {
                                                                    quesIdx: questionIndex,
                                                                    questionId: questions[questionIndex].id,
                                                                    answers: [...questions[questionIndex].answers.slice(0, answerIndex), {
                                                                        ...questions[questionIndex].answers[answerIndex],
                                                                        tagIDs: [newTag.id],
                                                                    }, ...questions[questionIndex].answers.slice(answerIndex + 1),],
                                                                });
                                                            }}
                                                        />{question.answers.length > 2 && <Delete
                                                        fill="#017CF8"
                                                        style={{minWidth: 18, maxWidth: 18, cursor: "pointer"}}
                                                        onClick={() => {
                                                            addEvents("question", "update", {
                                                                quesIdx: questionIndex,
                                                                answers: [
                                                                    ...questions[questionIndex].answers.slice(0, answerIndex),
                                                                    ...questions[questionIndex].answers.slice(answerIndex + 1),
                                                                ]
                                                            });
                                                        }}
                                                    />}
                                                    </Pane>)
                                            })}
                                        </Pane>
                                    </>)}

                                </div>
                            </SwiperSlide>))
                    ) : (
                        questions?.map((question, questionIndex) => (
                            <SwiperSlide>
                                <div className="onboarding-new-question">
                                    <AddQuestion />
                                </div>
                            </SwiperSlide>))
                    )
                }

            </Swiper>
            {!showSideImageFeature && <p className="hint-text"><Hint/>No worries! You can add answers later.</p>}

            <NavigationButtons
                // disablePrimaryButton={!selectedCollection}
                handleNextButtonClick={handleNextButtonClick}
                handleSkipToEditorClick={handleSkipToEditorClick}
                primaryButtonText={finalStepForNoQuestionsGenerated ? "Go to editor" : "Next step"}
                secondaryButtonText=""
            />
        </div>)
}

export default QuestionsEditor;