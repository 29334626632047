import Tracker from "@openreplay/tracker";
import trackerZustand from "@openreplay/tracker-zustand";
import {ObjectId} from "bson";

export class OpenReplay {
  static PROJECT_KEY =
    window.location.hostname !== "emp.skeep.io"
      ? "hIK3KW02iNgZ84yrDApW"
      : "sp7ndSac2HE9gTnDO2JJ";
  static userID = localStorage.getItem("EMAIL") ?? new ObjectId().toString();
  static tracker = new Tracker({
    projectKey: this.PROJECT_KEY,
    __DISABLE_SECURE_MODE: true,
  });
  static zustandPlugin = this.tracker.use(trackerZustand());
  static init() {
    OpenReplay.tracker.start({
      userID: OpenReplay.userID,
    });
  }

  public static setUser(userID: string) {
    if (OpenReplay.userID === userID || !OpenReplay.tracker) return;
    OpenReplay.tracker.setUserID(userID);
    OpenReplay.userID = userID;
  }
  public static createLogger(storeName: string): Function {
    if (!OpenReplay.tracker)
      return (config) => (set, get, api) =>
        config(
          (...args) => {
            set(...args);
          },
          get,
          api
        );
    return OpenReplay.zustandPlugin(storeName);
  }
}
