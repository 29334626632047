import {Button, majorScale, Pane, TextInput,} from "evergreen-ui";

export function Step1({ show, setShow, experience, setExperience }) {
  return (
    <>
      <Button
        onClick={() => {
          setShow(false);
        }}
        appearance="minimal"
        position="absolute"
        top={majorScale(2)}
        fontSize={majorScale(3)}
        right={majorScale(2)}
      >
        &times;
      </Button>
      <Pane
        display="flex"
        gap={26}
        position="relative"
        paddingY={34}
        borderBottom="1px solid #DBE1E7"
        style={{ border: "none" }}
        width={250}
      >
          <p style={{position:"absolute",top:45,right:12,zIndex:99999,color:"gray"}}>{experience?.name?.length}/25</p>
        <TextInput
        className="experience-name-input"
          value={experience.name}
          onChange={(e) => {
              if (e.target.value.length > 25) return;
            setExperience({
              ...experience,
              name: e.target.value,
            });
          }}
          placeholder="Experience name"
          size="large"
        />
        
        {/*<Popover*/}
        {/*  statelessProps={{*/}
        {/*    background: "#E4F1FF",*/}
        {/*    borderRadius: majorScale(1),*/}
        {/*  }}*/}
        {/*  position={Position.BOTTOM_LEFT}*/}
        {/*  content={({ close }) => (*/}
        {/*    <Menu>*/}
        {/*      <Ul className="menu-items" padding={8}>*/}
        {/*        {["QUIZ"].flatMap((type) => (*/}
        {/*          <Li*/}
        {/*            key={type}*/}
        {/*            onClick={(e) => {*/}
        {/*              setExperience({*/}
        {/*                ...experience,*/}
        {/*                type: type,*/}
        {/*              });*/}
        {/*              close();*/}
        {/*            }}*/}
        {/*            className="menu-item"*/}
        {/*            paddingX={8}*/}
        {/*          >*/}
        {/*            {toTitleCase(type.replace("_", " "))}*/}
        {/*          </Li>*/}
        {/*        ))}*/}
        {/*      </Ul>*/}
        {/*    </Menu>*/}
        {/*  )}*/}
        {/*>*/}
        {/*  <Text*/}
        {/*    cursor="pointer"*/}
        {/*    display="inline-flex"*/}
        {/*    border="1px solid #EDEDED"*/}
        {/*    alignItems="center"*/}
        {/*    height={40}*/}
        {/*    padding={14}*/}
        {/*    width="200px"*/}
        {/*    borderRadius={5}*/}
        {/*    justifyContent="space-between"*/}
        {/*  >*/}
        {/*    {toTitleCase(experience.type?.replace("_", " ")) ||*/}
        {/*      "Experience type"}*/}
        {/*    <BiCaretDown />*/}
        {/*  </Text>*/}
        {/*</Popover>*/}
      </Pane>
    </>
  );
}
