import classes from "../../styles/OverviewPage.module.css";
import React from "react";
import InfoWithTooltip from "../InfoWithTooltip/InfoWithTooltip";

const OverviewBox = ({label,value,tooltipText,isEmailBox,subValue}) => {

    return(
        <div className={classes.overviewBox}>
            <div className={classes.summaryInfoTopContainer}>
                <p className={classes.summaryInfoTopName}>{label}</p>
                <InfoWithTooltip text={tooltipText} width={250}/>
            </div>
            <div className={classes.summaryInfoBottomContainer}>
                <p className={classes.summaryInfoBottomInfo}>{value}<span style={{color:"gray",fontSize:"14px",lineHeight:1,marginLeft:6}}>{subValue}</span></p>
                {false && isEmailBox && <p className={classes.summaryInfoBottomLink}>View emails</p>}
            </div>
        </div>
    )
}

export default OverviewBox;