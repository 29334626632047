import {Client} from "Client";
import {Dialog, Pane} from "evergreen-ui";
import {useState} from "react";
import useEditor from "stores/use-editor";

const LiveInitialDialog = () => {
  const [show, setShow] = useState(true);
  const { experience, changeKeyValue } = useEditor();
  return (
    <Pane className={"dialog"}>
      <Dialog
        isShown={show}
        onCloseComplete={() => setShow(false)}
        title="Edit Answer"
        hasFooter={false}
        hasHeader={false}
        overlayProps={{
          zIndex:21
        }}
        width="629px"
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
        topOffset={window.innerHeight / 2 - 200}
      >
        <div className="dialog-content">
          <h4 style={{ fontWeight: 500 }}>
            Just a moment!
            <br />
            You’re editing a LIVE experience!
          </h4>
          <p
            style={{
              padding: "40px 0 40px 0",
              color: "#000",
              lineHeight: "24px",
            }}
          >
            Changes made to a live experience are reflected immediately in your
            store. If you're unsure whether to edit in live mode, pause the
            experience first.
          </p>
        </div>
        <div className="container" style={{ margin: " -10px -25px" }}>
          <button
            onClick={() => {
              setShow(false);
            }}
            className="cancel dialog-btn"
            style={{
              color: "#017CF8",
              marginRight: "16px",
              cursor: "pointer",
                background: 'none'
            }}
          >
            Got it!
          </button>
          <button
            className="confirm dialog-btn"
            onClick={async () => {
              changeKeyValue("status", "STOPPED");
              await Client.put(`/emp/experiences/${experience?.id}/status`, {
                status: "STOPPED",
              });
              setShow(false);
            }}
            style={{
              background: "#017CF8",
              marginRight: "16px",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            Pause Experience
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};
export default LiveInitialDialog;
