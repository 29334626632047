import React, { useState } from "react";
import TagInput from "./CustomTagsInput";
import Autosuggest from "react-autosuggest";

import "./CustomInputs.scss";
import { Client } from "../../Client";
import { ReactComponent as Close } from "assets/icons/close.svg";
import InfoWithTooltip from "components/InfoWithTooltip/InfoWithTooltip";
import { Button, Pane, Popover, Text } from "evergreen-ui";
// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

// Use your imagination to render suggestions.
const theme = {
  container: "field field-tags",
  containerOpen: "field open",
  input: "field-input",
  inputOpen: "field-input open",
  inputFocused: "field-input focused",
  suggestionsContainer: "tags-autosuggest__suggestions-container",
  suggestionsContainerOpen: "tags-autosuggest__suggestions-container--open",
  suggestionsList: "tags-autosuggest__suggestions-list",
  suggestion: "tags-autosuggest__suggestion",
  suggestionFirst: "tags-autosuggest__suggestion--first",
  suggestionHighlighted: "tags-autosuggest__suggestion--highlighted",
  sectionContainer: "tags-autosuggest__section-container",
  sectionContainerFirst: "tags-autosuggest__section-container--first",
  sectionTitle: "tags-autosuggest__section-title",
};
function CustomTagsSearch({
  onTagChange,
  placeHolder,
  onlyOneTag,
  width,
  height,
  name,
  payload,
  api,
  type = "GET",
  params = "",
  label,
  noTags,
  onCreateNew,
  controlledValues,
  onValuesChange,
  defaultValue = [],
  deleteOnlyTag,
  tooltip,
  style = {},
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [tags, setTags] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  // useEffect(() => {
  //   if (noTags) onTagChange(tags);
  // }, [tags, onTagChange, noTags]);

  const getSuggestionValue = (suggestion) => {
    renderTags(suggestion);
    return suggestion.name;
  };
  const renderSuggestion = (suggestion) => {
    return <div className="div-sug">{suggestion.name}</div>;
  };

  const renderTags = (tag) => {
    if (tag.name === "+ Add tag") {
      onCreateNew();
      return;
    }
    if (
      onValuesChange &&
      controlledValues &&
      controlledValues.findIndex((v) => v.id === tag.id) === -1
    ) {
      onValuesChange([...controlledValues, tag]);
    }
    const newTags = [...tags, tag.name];
    setTags(newTags);
    if (noTags) {
      onTagChange(newTags);
    } else onTagChange(name, tag);
  };

  const onChange = (event, { newValue, method }) => {
    if (newValue !== "+ Add tag") setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length < 3) return;
    setLoading(true);
    try {
      let response;
      if (type === "GET") {
        response = await Client.get(`${api}/${value}${params}`);
      } else if (type === "GET-QUERY") {
        response = await Client.get(`${api}?query=${value}`);
      } else {
        response = await Client.post(`${api}`, payload);
      }
      let data = response?.data.map((sugg) => ({ name: sugg }));
      if (onCreateNew) {
        data = [...data, { name: "+ Add tag" }];
      }
      setSuggestions(data);
      if (data.length !== 0) {
        setShowPopover(true);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setValue("");
    setShowPopover(false);
  };
  const inputProps = {
    placeholder: placeHolder,
    name: name,
    value: value,
    onChange: onChange,
  };

  const deleteTag = (index) => {
    if (noTags && !onlyOneTag) return;
    if (onlyOneTag && noTags) {
      setTags([]);
      onTagChange([]);
      return;
    }

    setTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
    onTagChange(name, index);
  };

  if (onlyOneTag)
    return (
      <div className="tags-container" style={{ width: width || null }}>
        {label && (
          <span className="field-label" style={{ display: "flex" }}>
            {label}

            {tooltip && (
              <InfoWithTooltip tooltipDOM="adminAPI" text={tooltip} />
            )}
          </span>
        )}
        <div
          className="tags-container css-dtg6vv-Control only-one-tag-obj"
          style={{ color: "black", fontSize: "14px", height }}
        >
          <p>{onlyOneTag}</p>
          <span
            onClick={() => deleteTag(0)}
            style={{ cursor: "pointer", zIndex: 50, paddingTop: 4 }}
          >
            <Close />
          </span>
        </div>
      </div>
    );

  return (
    <div className="tags-container">
      {/* <div className="field-label">{label}</div> */}
      {label && (
        <span
          className="field-label"
          style={{ display: "flex", alignItems: "center" }}
        >
          {label}

          {tooltip && <InfoWithTooltip tooltipDOM="adminAPI" text={tooltip} />}
        </span>
      )}
      <div className="tag-search" style={{ marginBottom: "20px", ...style }}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={theme}
          renderSuggestionsContainer={(params) =>
            loading ? (
              <div className="input-loading">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                {/* <div {...params.containerProps}>{params.children}</div> */}
                <Popover
                  isShown={showPopover}
                  content={
                    <Pane width={240} height={240} backgroundColor="#e3f1ff ">
                      <div
                        {...params.containerProps}
                        className="pane-container"
                      >
                        {params.children}
                      </div>
                    </Pane>
                  }
                >
                  <Text fontSize="1px" height="1px">
                    t
                  </Text>
                </Popover>
              </>
            )
          }
        />
      </div>
      {tags.length > 0 && !noTags && !onlyOneTag && (
        <div className="tag-inputs">
          {tags.map((tag, index) => (
            <TagInput
              key={index}
              tag={tag}
              deleteTag={deleteTag}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomTagsSearch;
