import create from "zustand";
import {devtools} from "zustand/middleware";
import {OpenReplay} from "utils/OpenReplay";

const initialState = {
    step: 0,
    design: {
        background: "#FFF",
        buttons: "#c1c1c1",
        text: "#c1c1c1"
    },
    productsForAnimation: [0, 1, 2],
    createExpLoading: false,
    collectionOBJ: {},
    imageForNonEnglish: "",
    showDemoBtn: false,
    scanningMethod:"products"
};

export const useOnboarding = create()(
    devtools(
        (set, get) => ({
            ...initialState,
            setDesignProp: (name, value) =>
                set({
                    design: {
                        ...get().design,
                        [name]: value
                    }
                }),
            setStep: v => {
                set({step: v})
            },
            setDesign: design => {
                set({design})
            },
            setCollectionOBJ: collectionOBJ => {
                set({collectionOBJ})
            },
            setProductsForAnimation: products => {
                set({productsForAnimation: products})
            },
            setCreateExpLoading: bool => {
                set({createExpLoading: bool})
            },
            setImageForNonEnglish: v => {
                set({imageForNonEnglish: v})
            },
            setShowDemoBtn: bool => {
                set({showDemoBtn: bool})
            },
            setScanningMethod: v => {
                set({scanningMethod:v})
            }
        }),
        {
            name: "onboarding-redux", // unique name
        }
    )
);