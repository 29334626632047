import {Client, GuestClient} from "Client";

const uploadToS3 = async (file) => {
  var formData = new FormData();
  formData.append("file", file, file.name);

  try {
    const { data } = await Client.post("emp/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (e) {
  }
};

export const uploadToS3SRT = async (file, srt) => {
  var formData = new FormData();
  formData.append("file", file);

  try {
    const { data } = await GuestClient.post("/onboarding/upload", formData, {
      headers: {
        "X-SRT": srt,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (e) {
  }
};

export default uploadToS3;
