import React from "react";
import "./BillingPlansToggleOpen.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import check from "../../assets/icons/billing-check-blue.svg";
import dash from "../../assets/icons/billing-dash.svg";
import BillingPlanToggleOpen from "components/BillingPlanToggleOpen/BillingPlanToggleOpen";
import {
  basic,
  free,
  platinum,
  premium,
  skeepx,
} from "../../utils/billingPricing";

export default function BillingPlansToggleOpen({
  goToPayment,
  toggleOpen,
  handleToggleOpen,
  disabledButton,
  currentPlan,
  selected,
  handleSelectedPlan,
  billing,
}) {
  const getSelectedPlan = (plan) => {
    handleSelectedPlan(plan);
  };



  return (
    <div className="app-toggle-open-container">
      <div className="app-toggle-card-container">
        {(billing?.plan === "Skeep X"||billing?.hasCustomPlan )? (
          <BillingPlanToggleOpen
            goToPayment={goToPayment}
            info={skeepx}
            billing={billing}
            disabledButton={disabledButton}
            getSelectedPlan={getSelectedPlan}
            selected={skeepx.name === selected}
            currentPlan={skeepx.name_uppercase === currentPlan}
          />
        ) : (
          <>
            <BillingPlanToggleOpen
              goToPayment={goToPayment}
              info={premium}
              billing={billing}
              disabledButton={disabledButton}
              getSelectedPlan={getSelectedPlan}
              selected={premium.name === selected}
              currentPlan={premium.name_uppercase === currentPlan}
            />
            <BillingPlanToggleOpen
              goToPayment={goToPayment}
              info={platinum}
              billing={billing}
              disabledButton={disabledButton}
              getSelectedPlan={getSelectedPlan}
              selected={platinum.name === selected}
              currentPlan={platinum.name_uppercase === currentPlan}
            />
            <BillingPlanToggleOpen
              goToPayment={goToPayment}
              info={skeepx}
              billing={billing}
              disabledButton={disabledButton}
              getSelectedPlan={getSelectedPlan}
              selected={skeepx.name === selected}
              currentPlan={skeepx.name_uppercase === currentPlan}
            />
          </>
        )}
      </div>
      <div className="app-toggle-container">
        <TableContainer sx={{ maxWidth: 1110 }}>
          <Table sx={{ maxWidth: 1110 }} aria-label="simple table">
            <TableHead>
              {(billing?.plan === "Skeep X"|| billing?.hasCustomPlan )? (
                <>
                  {premium.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-active">
                      <p className="app-toggle-table-header-active"></p>
                      <p className="app-toggle-table-subheader"></p>
                    </TableCell>
                  ) : (
                    <TableCell
                      className={"app-toggle-table-header"}
                    ></TableCell>
                  )}
                  {platinum.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-active">
                      <p className="app-toggle-table-header-active"></p>
                      <p className="app-toggle-table-subheader"> </p>
                    </TableCell>
                  ) : (
                    <TableCell
                      className={"app-toggle-table-header"}
                    ></TableCell>
                  )}
                  {skeepx.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-skeep-active">
                      <p className="app-toggle-table-header-skeepx">SkeepX</p>
                      <p className="app-toggle-table-subheader">
                        Automatic creation experiences, personalised to your
                        store
                      </p>
                    </TableCell>
                  ) : (
                    <TableCell className="app-toggle-table-header-skeepx">
                      SkeepX
                    </TableCell>
                  )}

                  <TableCell className={"app-toggle-table-header"}></TableCell>
                  {skeepx.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-skeep-active">
                      <p className="app-toggle-table-header-skeepx"></p>
                      <p className="app-toggle-table-subheader"> </p>
                    </TableCell>
                  ) : (
                    <TableCell className="app-toggle-table-header-skeepx"></TableCell>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell className="app-toggle-table-header-container"></TableCell>
                  {premium.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-active">
                      <p className="app-toggle-table-header-active">Growth</p>
                      <p className="app-toggle-table-subheader">
                        Automatic creation experiences, personalised to your
                        store{" "}
                      </p>
                    </TableCell>
                  ) : (
                    <TableCell className={"app-toggle-table-header"}>
                      Growth
                    </TableCell>
                  )}
                  {platinum.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-active">
                      <p className="app-toggle-table-header-active">Platinum</p>
                      <p className="app-toggle-table-subheader">
                        Automatic creation experiences, personalised to your
                        store{" "}
                      </p>
                    </TableCell>
                  ) : (
                    <TableCell className={"app-toggle-table-header"}>
                      Platinum
                    </TableCell>
                  )}

                  {skeepx.name_uppercase === currentPlan ? (
                    <TableCell className="app-toggle-table-header-container-skeep-active">
                      <p className="app-toggle-table-header-skeepx">SkeepX</p>
                      <p className="app-toggle-table-subheader">
                        Automatic creation experiences, personalised to your
                        store{" "}
                      </p>
                    </TableCell>
                  ) : (
                    <TableCell className="app-toggle-table-header-skeepx">
                      SkeepX
                    </TableCell>
                  )}
                  
                
                    <TableCell className="app-toggle-table-header-skeepx">
                      
                    </TableCell>
               
                </TableRow>
              )}
            </TableHead>
            <TableBody sx={{ maxWidth: 1110 }}>
              <TableRow style={{ borderBottom: "2px solid black" }}>
                <TableCell className="app-toggle-table-features-group-header">
                  Plan
                </TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Published quizzes
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                        <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                       <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  Custom
                </TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>
                    :
<>
                <TableCell
                  className={
                    premium.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-container-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <p className="app-toggle-table-features-info-top">5</p>
                </TableCell>
                <TableCell
                  className={
                    platinum.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-container-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <p className="app-toggle-table-features-info-top">8</p>
                </TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  Custom
                </TableCell>
                </>
                }
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Number of engagements / month
                </TableCell>
                {
                     (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                     <>
                       <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  Custom
                </TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                     </>:
                     <>
                     
                <TableCell
                  className={
                    premium.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-container-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <p className="app-toggle-table-features-info-top">
                    Up to {premium.max_engagements}
                  </p>
                  <p className="app-toggle-table-features-info-bottom">
                    (Every additional engagement $0.1)
                  </p>
                </TableCell>
                <TableCell
                  className={
                    platinum.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-container-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <p className="app-toggle-table-features-info-top">
                    Up to {platinum.max_engagements}
                  </p>
                  <p className="app-toggle-table-features-info-bottom">
                    (Every additional engagement $0.08)
                  </p>
                </TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  Custom
                </TableCell>
                     </>
                }
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  White label
                </TableCell>
                {
                      (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                      <>
                    <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active app-toggle-table-features-info"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                      </>:
                      <>
                      
                <TableCell
                  className={
                    premium.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-container-active app-toggle-table-features-info"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                <TableCell
                  className={
                    platinum.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-container-active app-toggle-table-features-info"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active app-toggle-table-features-info"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                      </>
                }
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
              </TableRow>
              <TableRow style={{ borderBottom: "2px solid black" }}>
                <TableCell className="app-toggle-table-features-group-header">
                  Main features
                </TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  100% Automated Creation
                </TableCell>
                {
                     (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                     <>
                    <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                     </>:
                     <>
                     
                     <TableCell
                       className={
                         premium.name_uppercase === currentPlan
                           ? "app-toggle-table-features-info-active"
                           : "app-toggle-table-features-info"
                       }
                     >
                       <img src={check} />
                     </TableCell>
                     <TableCell
                       className={
                         platinum.name_uppercase === currentPlan
                           ? "app-toggle-table-features-info-active"
                           : "app-toggle-table-features-info"
                       }
                     >
                       <img src={check} />
                     </TableCell>
                     <TableCell
                       className={
                         skeepx.name_uppercase === currentPlan
                           ? "app-toggle-table-features-info-skeep-active"
                           : "app-toggle-table-features-info"
                       }
                     >
                       <img src={check} />
                     </TableCell>
                     <TableCell
                       className={"app-toggle-table-features-info"}
                     ></TableCell>
                     </>
                }
              </TableRow>

              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Manual creation
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                     <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>

              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Experience Editor
                </TableCell>
                 {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  A/B testing
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                     <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Analytics dashboard
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                       <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
     <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                   <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  AI Optimization
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                  <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow style={{ borderBottom: "2px solid black" }}>
                <TableCell className="app-toggle-table-features-group-header">
                  Integrations
                </TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Shopify
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                     <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                 <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Woocommerce
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                       <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Magento
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Salesforce commerce
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
    <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>


<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Klaviyo
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                       <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Mailchimp
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                     <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
  <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Flashy
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
    <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Custom CRM / email platform
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
     <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                  <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow style={{ borderBottom: "2px solid black" }}>
                <TableCell className="app-toggle-table-features-group-header">
                  Service & support
                </TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
                <TableCell className="app-toggle-table-features-no-info"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Seats
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  Custom
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                  <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      Up to 2 seats
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      Up to 4 seats
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      Custom
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>

              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Dedicated CSM
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                  <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={dash} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Export analytics and reports
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                  <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Download collected data
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                       <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>

<TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  <img src={check} />
                </TableCell>


<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                   <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      <img src={check} />
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
              <TableRow>
                <TableCell className="app-toggle-table-features-tag">
                  Indexing & catalog updates
                </TableCell>
                {
                    (billing?.plan === "Skeep X"|| billing?.hasCustomPlan )?
                    <>
                      <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
   <TableCell
                  className={
                    skeepx.name_uppercase === currentPlan
                      ? "app-toggle-table-features-info-skeep-active"
                      : "app-toggle-table-features-info"
                  }
                >
                  Daily
                </TableCell>

<TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                  <TableCell
                  className={"app-toggle-table-features-info"}
                ></TableCell>
                    </>:
                    <>
                    
                    <TableCell
                      className={
                        premium.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      Weekly
                    </TableCell>
                    <TableCell
                      className={
                        platinum.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      Daily
                    </TableCell>
                    <TableCell
                      className={
                        skeepx.name_uppercase === currentPlan
                          ? "app-toggle-table-features-info-skeep-active"
                          : "app-toggle-table-features-info"
                      }
                    >
                      Daily
                    </TableCell>
                    <TableCell
                      className={"app-toggle-table-features-info"}
                    ></TableCell>
                    </>
                }
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="app-billing-toggle-container">
          <button
            className="app-billing-toggle-button"
            onClick={handleToggleOpen}
          >
            Hide plan features
          </button>
        </div>
        {/* <div className='app-billing-confirm-container'>
                <Button type="BUTTON" disabled={selected === "" || selected === "SkeepX"} text="Confirm" onClick={() => goToPayment()} />
            </div> */}
      </div>
    </div>
  );
}
