import {useOnboarding} from "../../../stores/use-onboarding";
import useOutsideAlerter from "./useOutside";
import {ChromePicker} from "react-color";
import {useRef, useState} from "react";

const ColorWidget = ({title, miniVersion}) => {
    const whiteColors = ["#FFF", "#fff", "#FFFF", "#ffff", "#FFFFF", "#fffff", "#FFFFFF", "#ffffff", "rgba(255, 255, 255, 0.0)",];
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const {setDesignProp, design} = useOnboarding();
    const color = design[title.toLowerCase()];
    const colorPickerRef = useRef(null);


    useOutsideAlerter(colorPickerRef, () => setIsColorPickerOpen(false));
    const handleChangeComplete = (color, event) => {
        setDesignProp(title.toLowerCase(), color?.hex);
    };


    return (<div
        className="color-widget-container"
        onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
    >
        <p>{title}</p>
        <div className="color-widget-box">
            <div
                className={`color-circle ${miniVersion ? "color-circle-mini" : ""}`}
                style={{
                    backgroundColor: color, border: whiteColors?.includes(color) ? "1px solid lightgray" : ""
                }}/>
            {!miniVersion && <p className="color-label">{color}</p>}
        </div>
        {isColorPickerOpen && <div
            className="color-picker-widget"
            onClick={e => e.stopPropagation()}
            ref={colorPickerRef}
        >
            <ChromePicker
                color={color}
                onChangeComplete={handleChangeComplete}
            />
        </div>}
    </div>)
}

export default ColorWidget;