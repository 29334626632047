import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon-new.svg";
import { ReactComponent as Schedule } from "../../assets/icons/schedule.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as LogoMini } from "../../assets/icons/logoMini.svg";
import { ReactComponent as Overview } from "../../assets/icons/new-overview-icon.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/question-icon.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as SideBarArrow } from "../../assets/icons/sideBarArrow.svg";
import { ReactComponent as StoreIcon } from "../../assets/icons/sidebar-store-icon.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/support-icon.svg";
import { ReactComponent as UpgradeIcon } from "../../assets/icons/upgrade-plan-icon.svg";
import {
  basic,
  free,
  platinum,
  premium,
  skeepx,
} from "../../utils/billingPricing";

import { Client } from "Client";
import BrandWidget, {
  Stat,
  StoreMenu,
} from "components/BrandWidget/BrandWidget";
import {majorScale, Pane, Popover, Position, toaster} from "evergreen-ui";
import { useEffect, useMemo, useState } from "react";
import {
  FaBriefcase,
  FaCreditCard,
  FaPuzzlePiece,
  FaUser,
  FaWindowMaximize,
} from "react-icons/fa";
import useAuth from "stores/auth";
import "./Sidebar.scss";
import { useDesign } from "stores/design";
import { useEditorEvents } from "stores/use-editor-events";
import useEditor from "stores/use-editor";
import { Skeleton } from "@mui/material";
import useExperiencePage from "hooks/useExperiencePage";
import { MdInfoOutline } from "react-icons/md";

export default function Sidebar({ onMinimized, minimized, onLogOut }) {
  const [isShopifyUser, setIsShopifyUser] = useState(false);
  const [accountData, setAccountData] = useState({});
  const { events } = useEditorEvents();
  const [brand, setBrand] = useState([]);
  const { subSidebar, toggleSubSidebar, setSubSidebar } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const userIsInSettingsPages =
    location.pathname.includes("billing") ||
    location.pathname.includes("profile") ||
    location.pathname.includes("integrations") ||
    location.pathname.includes("user-management");
  const { allExperiences } = useAuth();
  const { setUnsavedPopup } = useEditor();
  const { accountOBJ, setMinimized } = useAuth();
  const numberOfLiveExp = allExperiences.filter(
    (e) => e.status === "LIVE"
  )?.length;

  let repliesQuota = useMemo(() => {
    return accountData
      ? accountData?.engagementsQuota === 0
        ? 0
        : Math.min(
            100,
            (accountData?.engagements / accountData?.engagementsQuota) * 100
          ).toFixed(0)
      : 0;
  }, [accountData]);

  const listOfPlans = [free, basic, premium, platinum, skeepx];

  const planOBJ = listOfPlans?.find(
    (planOBJ) =>
      planOBJ?.name_uppercase ===
      accountOBJ?.plan?.replaceAll(" ", "")?.toUpperCase()
  );

  const limitOfQuizzesReached =
    planOBJ?.maxPublishedQuizzes &&
    numberOfLiveExp >= planOBJ?.maxPublishedQuizzes;

  useEffect(() => {
    Client.get("/emp/billing").then((res) => {
      setIsShopifyUser(res.data?.integrations.includes("SHOPIFY"));
      setAccountData(res.data);
    });

    Client("/emp/brand").then((res) => {
      setBrand(res.data);
    });
  }, []);

  function capitalizeFirstLetter(string) {
    if (string === "PLATINUM_NEW") {
      string = "PLATINUM";
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const openHubspotChat = async () => {
      try {
        await window.HubSpotConversations.widget.open();
      } catch (e) {
        window.location = "#hs-chat-open";
      }
  }

  return (
    <div className={`sidebar ${minimized ? "minimized" : ""}`}>
      <div
        className={`${subSidebar ? "sidebar-hidden" : "sidebar-minimize"}`}
        onClick={onMinimized}
      >
        <SideBarArrow />
      </div>

      <div className="sidebar-container">
        <div
          className="sidebar-logo"
          onClick={() => {
            if (events.length > 0) {
              setUnsavedPopup(true);
              setMinimized(true);
            } else {
              navigate("/experiences");
            }
          }}
        >
          {!minimized && <Logo />}
          {minimized && (
            <LogoMini
              onClick={() => {
                setSubSidebar(false);
              }}
            />
          )}
        </div>

        <div className="sidebar-tabs-container">
          <div className="sidebar-options">
            {/* <div className="sidebar-options-title">BUILDER</div> */}
            <NavLink
              title="Experiences"
              to="/experiences"
              onClick={() => {
                if (events.length > 0) {
                  setUnsavedPopup(true);
                  setMinimized(true);
                } else {
                  setSubSidebar(false);
                }
              }}
              className="sidebar-option"
            >
              <LeavingEditorListener to="/experiences">
                <FaWindowMaximize color={"black"} />
                {!minimized && <span>Experiences</span>}
              </LeavingEditorListener>
            </NavLink>
          </div>
          <div className="sidebar-options">
            {/* <div className="sidebar-options-title">Analytics</div> */}
            <NavLink
              to="/analytics"
              className="sidebar-option"
              title="Analytics"
              onClick={() => {
                if (events.length > 0) {
                  setUnsavedPopup(true);
                  setMinimized(true);
                } else {
                  setSubSidebar(false);
                }
              }}
              style={{ justifyContent: minimized ? "center" : "flex-start" }}
            >
              <LeavingEditorListener to="/analytics">
                <Overview />
                {!minimized && <span>Analytics</span>}
              </LeavingEditorListener>
            </NavLink>
          </div>
          <div className="sidebar-options"></div>
          <div
            className="sidebar-options logout"
            style={{ backgroundColor: "#E4F1FF" }}
          >
            {!minimized ? (
              <>
                <button
                  className="sidebar-option"
                  id={userIsInSettingsPages ? "sidebar-active" : ""}
                  style={{ marginBottom: "0px" }}
                  onClick={() => {
                    navigate("/profile");
                    toggleSubSidebar();
                  }}
                >
                  <Settings />
                  <span>Settings</span>
                </button>
              </>
            ) : (
              <button
                className="sidebar-option"
                id={userIsInSettingsPages ? "sidebar-active" : ""}
                style={{ marginBottom: "0px" }}
                onClick={() => {
                  if (events.length > 0) {
                    setUnsavedPopup(true);
                    setSubSidebar(false);
                    setMinimized(true);
                  } else {
                    navigate("/profile");
                    toggleSubSidebar();
                    setMinimized(true);
                  }
                }}
                title="Settings"
              >
                <Settings />
              </button>
            )}
            {events.length > 0 ? (
              <button
                title="Help"
                className="sidebar-option"
                style={{ marginBottom: "0px" }}
                onClick={() => {
                  setUnsavedPopup(true);
                  setSubSidebar(false);
                  setMinimized(true);
                }}
              >
                <QuestionIcon />
                {!minimized && <span>Help</span>}
              </button>
            ) : (
              <Popover
                statelessProps={{
                  borderRadius: "8px",
                  width: "216px",
                  marginTop: "-35px",
                }}
                position={Position.RIGHT}
                content={({ close }) => (
                  <Pane style={{ padding: "10px 0" }}>
                    <div
                      className="help-center-btn"
                      onClick={() => window.open("https://support.skeep.io/")}
                    >
                      <InfoIcon style={{ marginLeft: "19px" }} />
                      <span
                        style={{
                          marginLeft: "18px",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        Help Center
                      </span>
                    </div>
                    <div
                      className="support-btn"
                      onClick={openHubspotChat}
                    >
                      <SupportIcon style={{ marginLeft: "19px" }} />
                      <span
                        style={{
                          marginLeft: "18px",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        Support
                      </span>
                    </div>
                    <div
                      className="help-center-btn"
                      onClick={() =>
                        window.open(
                          "https://meetings-eu1.hubspot.com/alexandra-shagal/support-call-from-emp"
                        )
                      }
                    >
                      <Schedule style={{ marginLeft: "19px" }} />
                      <span
                        style={{
                          marginLeft: "18px",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        Support call
                      </span>
                    </div>
                  </Pane>
                )}
                shouldCloseOnEscapePress={false}
              >
                <button
                  title="Help"
                  className="sidebar-option"
                  style={{ marginBottom: "0px" }}
                >
                  <QuestionIcon />
                  {!minimized && <span>Help</span>}
                </button>
              </Popover>
            )}
            {!subSidebar ? (
              <>
                {events.length > 0 ? (
                  <button
                    className="upgrade-plan-btn"
                    onClick={() => {
                      setUnsavedPopup(true);
                      setSubSidebar(false);
                      setMinimized(true);
                    }}
                  >
                    <UpgradeIcon />
                    <span className="upgrade-text">
                      {!minimized && "Upgrade Plan"}
                    </span>
                  </button>
                ) : brand?.billing?.plan === "SKEEPX" ? (
                  ""
                ) : (
                  <NavLink
                    title="Upgrade plan"
                    to={isShopifyUser ? "/billing/plans" : "/billing"}
                  >
                    <button className="upgrade-plan-btn">
                      <UpgradeIcon />
                      <span className="upgrade-text">
                        {!minimized && "Upgrade Plan"}
                      </span>
                    </button>
                  </NavLink>
                )}
                {events.length > 0 ? (
                  <div
                    className="store-icon sidebar-option"
                    title="Brand"
                    style={{ marginBottom: "0px" }}
                    onClick={() => {
                      setUnsavedPopup(true);
                      setSubSidebar(false);
                      setMinimized(true);
                    }}
                  >
                    <StoreIcon />
                  </div>
                ) : (
                  <Popover
                    statelessProps={{
                      borderRadius: majorScale(1),
                      marginTop: "-40px",
                      height: "fit-content",
                    }}
                    position={Position.RIGHT}
                    content={
                      <StoreMenu
                        accountData={accountData?.userAccounts}
                        LeavingEditorListener={LeavingEditorListener}
                        onLogOut={onLogOut}
                      />
                    }
                  >
                    {!minimized ? (
                      !brand?.status?.includes("ON_BOARDING") && (
                        <div
                          style={{
                            zIndex: 20,
                            marginTop: "12px",
                          }}
                        >
                          <BrandWidget
                            accountData={accountData}
                            isShopifyUser={isShopifyUser}
                            fromMinimizedMenu={false}
                            minimized={minimized}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="store-icon sidebar-option"
                        title="Brand"
                        style={{ marginBottom: "0px" }}
                      >
                        <StoreIcon />
                      </div>
                    )}
                  </Popover>
                )}
              </>
            ) : (
              <>
                <Popover
                  statelessProps={{
                    borderRadius: majorScale(1),
                    marginTop: "-40px",
                    height: "fit-content",
                  }}
                  position={Position.RIGHT}
                  content={
                    <StoreMenu
                      accountData={accountData?.userAccounts}
                      LeavingEditorListener={LeavingEditorListener}
                      onLogOut={onLogOut}
                    />
                  }
                >
                  {!minimized ? (
                    !brand?.status?.includes("ON_BOARDING") && (
                      <div
                        style={{
                          zIndex: 20,
                          marginTop: "12px",
                        }}
                      >
                        <BrandWidget
                          accountData={accountData}
                          isShopifyUser={isShopifyUser}
                          fromMinimizedMenu={false}
                          minimized={minimized}
                        />
                      </div>
                    )
                  ) : (
                    <div className="store-icon sidebar-option" title="Brand">
                      <StoreIcon />
                    </div>
                  )}
                </Popover>

                {
                  events.length > 0 && (
                    <div>
                      <button className="upgrade-plan-btn">
                        <UpgradeIcon onClick={() => alert("btn")} />
                        <span className="upgrade-text">
                          {!minimized && "Upgrade Plan"}
                        </span>
                      </button>
                    </div>
                  )

                  // <NavLink
                  //   title="Upgrade plan"
                  //   to={isShopifyUser ? "/billing/plans" : "/billing"}
                  // >
                  //   <button className="upgrade-plan-btn" onClick={()=>alert("btn")}>
                  //     <UpgradeIcon  />
                  //     <span className="upgrade-text">
                  //       {!minimized && "Upgrade Plan"}
                  //     </span>
                  //   </button>
                  // </NavLink>
                }
              </>
            )}
          </div>
        </div>
      </div>
      {subSidebar && (
        <>
          <div
            className={`sub-sidebar-container ${
              minimized ? "" : "extra-padding"
            }`}
          >
            <h1>Settings</h1>
            <NavLink
              to="/profile"
              className="option"
              style={{
                marginTop: "30px",
                marginLeft: "8px",
              }}
            >
              <FaUser color={"black"} />
              <span>Account</span>
            </NavLink>
            <NavLink
              to="/integrations"
              className="option"
              style={{
                justifyContent: minimized ? "center" : "flex-start",
                marginLeft: "8px",
                marginTop: "15px",
              }}
            >
              <LeavingEditorListener to="/integrations">
                <FaPuzzlePiece color={"black"} />
                <span>Integrations</span>
              </LeavingEditorListener>
            </NavLink>
            <NavLink
              to="/user-management"
              className="option"
              style={{
                justifyContent: minimized ? "center" : "flex-start",
                marginLeft: "8px",
                marginTop: "17px",
              }}
            >
              <LeavingEditorListener to="/user-management">
                <FaBriefcase color={"black"} />
                <span>User Management</span>
              </LeavingEditorListener>
            </NavLink>
            {accountData.plan === "Skeep X" && !isShopifyUser ? (
              ""
            ) : (
              <NavLink
                to={isShopifyUser ? "/billing/plans" : "/billing"}
                className="option"
                style={{
                  justifyContent: minimized ? "center" : "flex-start",
                  marginTop: "17px",
                  marginLeft: "8px",
                }}
              >
                <LeavingEditorListener
                  to={isShopifyUser ? "/billing/plans" : "/billing"}
                >
                  <FaCreditCard color={"black"} />
                  <span>Plans</span>
                </LeavingEditorListener>
              </NavLink>
            )}
            <Pane position="absolute" width="100%" bottom={26}>
              <Pane
                className="brand-widget"
                display="flex"
                boxShadow="0 0 20px #0001"
                flexDirection="column"
                margin="auto"
                justifySelf="flex-end"
                width={"90%"}
                borderRadius={majorScale(1)}
                minHeight={40}
                padding={majorScale(2)}
                backgroundColor="white"
              >
                {(accountData && (
                  <Pane>
                    <Pane
                      className="label"
                      justifyContent="space-between"
                      display="flex"
                      flexDirection="column"
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <p>Plan:</p>
                        <p style={{ color: "#75787E", marginTop: "2px" }}>
                          {capitalizeFirstLetter(accountData.plan)}
                        </p>
                      </div>
                      <div style={{ color: "black" }}>
                        Published experiences
                      </div>
                      {accountData.plan === "Skeep X" ? (
                        <div style={{ color: "#75787E" }}>
                          <p
                            className="limitations-text"
                            style={{
                              fontSize: 12,
                              color: "#000000",
                            }}
                          >
                            {numberOfLiveExp}
                          </p>
                        </div>
                      ) : (
                        <div style={{ color: "#75787E" }}>
                          {planOBJ?.maxPublishedQuizzes && (
                            <p
                              className="limitations-text"
                              style={{
                                fontSize: 12,
                                color: limitOfQuizzesReached
                                  ? "#E45447"
                                  : "#75787E",
                              }}
                            >
                              {numberOfLiveExp}/{planOBJ?.maxPublishedQuizzes}{" "}
                              Experiences
                            </p>
                          )}
                        </div>
                      )}
                    </Pane>
                  </Pane>
                )) || (
                  <Skeleton
                    className="title"
                    variant="text"
                    width={"100%"}
                    height={36}
                  />
                )}
                {(accountData && (
                  <Stat
                    label="Engagements"
                    value={
                      Number(repliesQuota) === 100 || Number(repliesQuota) < 70
                        ? `${accountData?.engagements} / ${accountData?.engagementsQuota}`
                        : `${repliesQuota}%`
                    }
                    percent={repliesQuota}
                  />
                )) || (
                  <Skeleton
                    className="title"
                    variant="text"
                    width={"100%"}
                    height={36}
                  />
                )}
              </Pane>
              {(limitOfQuizzesReached || Number(repliesQuota) >= 70) && (
                <Pane
                  background="#FFE4B4"
                  borderRadius={8}
                  width="90%"
                  margin="auto"
                  marginTop={16}
                  paddingX={4}
                  paddingY={8}
                  fontSize={10}
                  display="flex"
                >
                  <MdInfoOutline
                    size={14}
                    style={{
                      marginTop: 2,
                      marginRight: 4,
                    }}
                    color="#F4B037"
                  />
                  <div
                    style={{
                      width: "100%",
                      lineHeight: "18px",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {limitOfQuizzesReached ? (
                      <>
                        Your plan seems to have reached its maximum number of
                        experiences, please{" "}
                        <Link to="/billing/plans">upgrade</Link> and get more
                        experiences
                      </>
                    ) : (
                      <>
                        {accountData.plan === "SkeepX" ? (
                          <>
                            {Number(repliesQuota) >= 70 ? (
                              <>
                                Great progress! You’ve already used more than
                                70% of your monthly engagements quota
                              </>
                            ) : Number(repliesQuota) === 100 ? (
                              <>
                                You have reached 100% of your monthly engagement
                                quota, you will be charged per engagement until
                                your monthly billing cycle ends.{" "}
                                <span
                                  role="button"
                                  onClick={() =>
                                    window.open(
                                      "https://meetings-eu1.hubspot.com/tom-butz/skeep-product-call"
                                    )
                                  }
                                >
                                  Let’s talk
                                </span>
                                and customize your plan to your needs
                              </>
                            ) : null}
                          </>
                        ) : accountData.plan === "GROWTH" ||
                          accountData.plan === "PLATINUM_NEW"||accountData.plan==="FREE"||accountData?.plan==="BASIC" ? (
                          <>
                            {Number(repliesQuota) >= 70 && Number(repliesQuota)<100 ? (
                              <>
                                Great progress! You’ve already used more than
                                70% of your monthly engagements quota, You can{" "}
                                <Link to="/billing/plans"> upgrade </Link> and
                                get a larger engagement quota
                              </>
                            ) : Number(repliesQuota) === 100 ? (
                              <>
                                You have reached 100% of your monthly engagement
                                quota, you will be charged per engagement until
                                your monthly billing cycle ends.{" "}
                                <Link to="/billing/plans">Upgrade</Link> your
                                plan to get a larger engagement quota
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </Pane>
               )} 
            </Pane>
          </div>
        </>
      )}
    </div>
  );
}

const LeavingEditorListener = ({ children, to }) => {
  const { allRecommenderEventsSaved, togglePopupState, setRedirect } =
    useDesign();
  const location = useLocation();
  const editingExp = location.pathname.includes("/experiences/edit");
  const { events } = useEditorEvents();
  const { setUnsavedPopup } = useEditor();
  const leavingEditorClick = (e) => {
    // Leaving experience editor
    if (editingExp && events?.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      setRedirect(to);
      setUnsavedPopup(true);
    }

    // Leaving from Recommender page
    if (!allRecommenderEventsSaved) {
      e.preventDefault();
      e.stopPropagation();
      setRedirect(to);
      togglePopupState();
    }
  };

  return (
    <div
      onClick={(e) => leavingEditorClick(e)}
      className="click-listener-sidebar"
    >
      {children}
    </div>
  );
};
