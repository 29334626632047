import {useCallback, useEffect, useState} from "react";
import {Client} from "../Client";
import useAuth from "../stores/auth";

const useExperiencePage = () => {
  const { setAccount , setAllExperiences, allExperiences } = useAuth();
  const [isEditing, setIsEditing] = useState();
  const [brand, setBrand] = useState([]);
  const [unsuccessfulLoad, setUnsuccessfulLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [forceFetch, setForceFetch] = useState(Math.random());




  const fetchExperiencesPoller = useCallback(() => {
    setLoading(true);
    const poller = setInterval(() => {
      Client("/experiences").then((res) => {
        if (allExperiences.length !== res.data.length) {
          setAllExperiences(
            res.data.sort((a, b) => {
              if (a?.status === "LIVE") return -1;
              return 1;
            })
          );
          setLoading(false);
          clearInterval(poller);
        }
      });
    }, 5000);
  }, [allExperiences.length]);

  useEffect(() => {
    setIsEditing(undefined);
    setLoading(true);

    Client("/emp/brand")
      .then((res) => {
        setBrand(res.data);
      })
      .catch(() => {
        setUnsuccessfulLoad(true);
      });

    Client("/experiences")
      .then((res) => {
        setAllExperiences(
          res.data.sort((a, b) => {
            if (a?.status === "LIVE") return -1;
            return 1;
          })
        );
      })
      .catch(() => {
        setUnsuccessfulLoad(true);
      });

    Client("/emp/billing")
      .then((res) => {
        setAccount(res.data);
      })
      .catch(() => {
        setUnsuccessfulLoad(true);
      });

    setTimeout(() => setLoading(false), 2000);
  }, [setAccount, forceFetch]);

  return {
    forceFetch,
    setForceFetch,

    allExperiences,
    setAllExperiences,

    isEditing,
    setIsEditing,

    brand,
    setBrand,

    unsuccessfulLoad,
    setUnsuccessfulLoad,

    loading,
    fetchExperiencesPoller,

  
  };
};

export default useExperiencePage;
