import {useRef, useState} from "react";
import "./CreateTagPopup.scss";

import Button from "components/Button/Button";
import CustomTagsSearchV2 from "components/CustomTags/CustomTagsSearchV2";
import {IoArrowForwardOutline, IoCloseOutline} from "react-icons/io5";
import {ReactComponent as CrossIcon} from '../../assets/icons/cross-icon-new.svg'
import useAuth from "stores/auth";
import {Client} from "Client";

export default function CreateTagPopup({ onClose, onSave }) {
  const { minimized } = useAuth();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newTag, setNewTag] = useState({
    name: "",
    type: "",
  });
  const ref = useRef();
  function handleAddSelectedTag() {
    const existed = tags.find(
      (filter) => filter.type === newTag.type && filter.name === newTag.name
    );

    if (!existed) {
      setTags([
        ...tags,
        {
          ...newTag,
        },
      ]);
      // @ts-ignore
      ref?.current.clear();

      setNewTag({ name: "", type: "" });
    }
  }

  function handleRemoveTag(index) {
    setTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
  }


  return (
    <div
      className={
        minimized
          ? "create-tag-pop-up-container-minimized"
          : "create-tag-pop-up-container"
      }
    >
      <div
        className="create-tag-pop-up-box"
        style={{
          minHeight:
            tags.filter((tag) =>
              tag.type.toLowerCase().includes(newTag.type.toLowerCase())
            ).length > 3 && newTag.type !== ""
              ? "420px"
              : tags.filter((tag) =>
                  tag.type.toLowerCase().includes(newTag.type.toLowerCase())
                ).length > 2 && newTag.type !== ""
              ? "380px"
              : tags.filter((tag) =>
                  tag.type.toLowerCase().includes(newTag.type.toLowerCase())
                ).length > 1 && newTag.type !== ""
              ? "340px"
              : tags.filter((tag) =>
                  tag.type.toLowerCase().includes(newTag.type.toLowerCase())
                ).length > 0 && newTag.type !== ""
              ? "300px"
              : "280px",
        }}
      >
        <div className="create-tag-pop-up-header-container">
          <p className="create-tag-pop-up-header">Create Tags</p>
          <CrossIcon
          className="close-icon"
            style={{ cursor: "pointer" }}
            onClick={() => onClose()}
          />
        </div>
        <div className="create-tag-pop-up-input-box">
          <div className="create-tag-pop-up-input-container">
            <p className="create-tag-pop-up-input-header">Tag name</p>
            <input
              type="text"
              placeholder="e.g Natural"
              value={newTag.name}
              onChange={(e) => {
                // @ts-ignore
                setNewTag({ ...newTag, name: e.target.value });
              }}
              className="create-tag-pop-up-input-name-search"
            />
          </div>
          {/* @ts-ignore */}
          
          <CustomTagsSearchV2
            ref={ref}
            api={`emp/tags/types`}
            clearOnSelect={false}
            placeHolder="e.g Flavor"
            labelKey={"type"}
            label={"Tag Type"}
            onChange={(tags) => {
              setNewTag({
                ...newTag, 
                type: tags[0].type,
              });
            }}
            onSetNewTag={(value)=>{
              setNewTag({
                ...newTag, 
                type: value,
              });
            }}
          />

          <IoArrowForwardOutline
            color={
              newTag.name === "" ? "#75787E" : "#017CF8"
            }
            size={25}
            style={{ marginBottom: "7px", cursor: "pointer" }}
            onClick={() => handleAddSelectedTag()}
          />
        </div>
        {tags && tags.length > 0 && ( 
          <div className="create-tag-pop-up-selected-tags-container">
            {tags.map((selected, index) => {
              return (
                <div key={index} className="create-tag-pop-up-selected-tags">
                  <div className="create-tag-pop-up-selected-tags-left-container">
                    <p
                      className="create-tag-pop-up-selected-tags-type"
                      style={{ textTransform: "capitalize" }}
                    >
                      {selected.type.toLowerCase()}
                    </p>
                    <p className="create-tag-pop-up-selected-tags-name">
                      {selected.name}
                    </p>
                  </div>
                  <IoCloseOutline
                    color="#75787E"
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveTag(index)}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flexGrow: 1,
            width: "100%",
          }}
        >
          {/* @ts-ignore */}
          <Button
            type="SUBMIT"
            text={loading ? "Saving..." : "Save"}
            disabled={tags.length === 0 || loading}
            onClick={async () => {
              const promises = tags.map((t) =>
                Client.post("emp/tags", {
                  ...t,
                  language: "EN",
                  origin: "BRAND",
                })
              );
              setLoading(true);
              const res = await Promise.all(promises);
              const data = res.map((r) => r.data);
              setLoading(false);
              onSave(data);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
}
