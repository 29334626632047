import "./DataPage.scss"

const DataPage = () => {

    return(
        <div className="app-page f-center">
            Data Page
        </div>
    )
}

export default DataPage;