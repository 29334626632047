import {useOnboarding} from "../../../stores/use-onboarding";

const LoadingText = ({totalProductsScanned}) => {
    const collectionOBJ = useOnboarding(s => s.collectionOBJ);

    return (<div className="onboarding-step">
        <div className="scanning-text-container">
            <p className="scanning-text">Our AI is scanning your collection...</p>
            <p className="scanning-text2">{totalProductsScanned > collectionOBJ?.products_count ? collectionOBJ?.products_count : totalProductsScanned}/{collectionOBJ?.products_count || 0} Products scanned..</p>
            {totalProductsScanned >= collectionOBJ?.products_count && <p className="scanning-text2">Scanning product variants..</p>}
        </div>
    </div>)
};

export default LoadingText;