import React from "react";

import {ReactComponent as Info} from "../../assets/icons/info-gray.svg";
import {Position, Tooltip} from "evergreen-ui";

function InfoWithTooltip({ text, width, style = {}, icon,textStyle,position }) {
  return (
    <Tooltip
      statelessProps={{
        backgroundColor: "#E4F1FF",
        color: "black",
        fontSize:'14px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        ...textStyle
      }}
      position={position??Position.LEFT}
      content={
        <p>
          <small>{text}</small>
        </p>
      }
    >
      <div style={style}>
        {icon ? (
          icon
        ) : (
          <Info
            style={{ marginLeft: "10px", marginBottom: "-1px" }}
            width={12}
            height={12}
          />
        )}
      </div>
    </Tooltip>
  );
}

export default InfoWithTooltip;
