import {Client} from "Client";
import imageCompression from "browser-image-compression";
import {toaster} from "evergreen-ui";

export function segmentAdapter(segmentData) {
    let filters = {};
    if (segmentData?.segment?.source && segmentData?.segment.source.length > 0) filters["source"] = segmentData?.segment.source;
    if (segmentData?.segment?.rewardIDs && segmentData?.segment.rewardIDs.length > 0) filters["reward"] = segmentData?.segment.rewardIDs;
    if (segmentData?.segment?.medium && segmentData?.segment.medium.length > 0) filters["medium"] = segmentData?.segment.medium;
    if (segmentData?.segment?.countries && segmentData?.segment.countries.length > 0) filters["countries"] = segmentData?.segment.countries;
    if (segmentData?.segment?.questionnaireFilters && segmentData?.segment.questionnaireFilters.length > 0) filters["questionnaireFilters"] = segmentData?.segment.questionnaireFilters;
    return {
        members: segmentData.members,
        id: segmentData?.segment?.id,
        name: segmentData?.segment?.name,
        condition: segmentData?.segment?.conditionType?.toLowerCase() ?? "ALL",
        filters,
    };
}

export function truncate(input, length = 50) {
    if (input.length > length) {
        return input.substring(0, length) + "...";
    }
    return input;
}

export function updateObject(obj = {}, newValue, path) {
    var stack = path.split("/");
    let temp = JSON.parse(JSON.stringify(obj));
    let object = temp;
    while (stack.length > 1) {
        const key = stack.shift();
        if (!object[key]) {
            object[key] = {};
        }
        object = object[key];
    }
    object[stack.shift()] = newValue;
    return temp;
}

export function getValueFromPath(obj, path) {
    var stack = path.split("/");
    let temp = JSON.parse(JSON.stringify(obj));
    let object = temp;
    while (stack.length > 1) {
        const key = stack.shift();
        if (!object[key]) {
            object[key] = {};
        }
        object = object[key];
    }
    return object[stack.shift()];
}

export function camelCaseToTitleCase(text) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export async function s3ToPreview(getValue, obj) {
    if (typeof obj !== "object") return undefined;
    const r = JSON.parse(JSON.stringify(obj));
    await changeInJson(getValue, r);
    return r;
}

const changeInJson = async (getValue, obj) => {
    if (!obj) obj = {};
    for (const [k, v] of Object.entries(obj)) {
        if (typeof v === "object") {
            await changeInJson(getValue, v);
        } else if (typeof v === "string" && v.indexOf("s3://") !== -1) {
            obj[k] = await getValue(v);
        }
    }
};

export function checkAutofill(element) {
    try {
        return element.matches(":-internal-autofill-selected");
    } catch {
        return false;
    }
}

export const calculateThirds = (minValue, maxValue) => {
    const thirds = Math.floor((Number(maxValue) - Number(minValue)) / 3);
    return [Number(minValue), Number(minValue) + thirds, Number(minValue) + 2 * thirds, Number(maxValue),];
};

export const handleDrag = (e) => {
    let leftSide = e.target?.previousElementSibling;
    let rightSide = e.target?.nextElementSibling;
    let x = e.clientX;
    let y = e.clientY;
    let rightWidth = rightSide.getBoundingClientRect().width;
    const mouseUpHandler = function () {
        leftSide.style.removeProperty("user-select");
        leftSide.style.removeProperty("pointer-events");

        rightSide.style.removeProperty("user-select");
        rightSide.style.removeProperty("pointer-events");
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
    };
    const mouseMoveHandler = function (e) {
        leftSide.style.userSelect = "none";
        leftSide.style.pointerEvents = "none";

        rightSide.style.userSelect = "none";
        rightSide.style.pointerEvents = "none";
        const dx = e.clientX - x;

        const newLeftWidth = ((rightWidth - dx) * 100) / e.target.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${100 - newLeftWidth}%`;
    };
    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
};

export const toTitleCase = (str) => {
    return str?.replace(/\w\S*/g, (txt) => txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase());
};
export const handleQuery = (name, q) => {
    const idx = name.toLowerCase().indexOf(q.toLowerCase());
    if (idx !== -1) {
        let newItem = (<p className="product-search-tag-list-api-name">
            {name.substring(0, idx)}
            <span style={{color: "#017CF8"}}>{name.substr(idx, q.length)}</span>
            {name.substring(idx + q.length)}
        </p>);
        return newItem;
    }
};

export const handlePath = (path) => {
    try {
        const url = new URL(path);
        return url.pathname;
    } catch {
        return path;
    }
};

export const getColorTagBackground = (finalColor) => {
    if (typeof finalColor === "string") {
        if (finalColor.startsWith("rgba")) {
            const rgbColorArray = finalColor
                ?.split(/[^0-9]/g)
                ?.filter((val) => val !== "");
            const colorWithoutOpacity = `rgba(${rgbColorArray[0]},${rgbColorArray[1]},${rgbColorArray[2]})`;

            const colorWithWhiteOpcacity = `rgba(255, 255, 255, ${rgbColorArray[3] + "." + (rgbColorArray?.[4] ?? 0)})`;
            return `linear-gradient( 90deg, ${colorWithoutOpacity}, ${colorWithoutOpacity} 50%,${colorWithWhiteOpcacity} 50% )`;
        } else {
            return finalColor;
        }
    } else {
        return undefined;
    }
};

export const getProductsForAnswer = async (experienceID, tagId) => {
    const res = await Client.get(`emp/tags/products?tagID=${tagId}&experienceID=${experienceID}`);
    return res.data;
};


export const debounceInput = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const checkImageSelection = (question, design) => {
    let isImageSelection = question?.type?.toLowerCase() === "image_selection";
    question?.answers.forEach((answer) => {
        if (design?.questions?.[question?.id]?.[answer?.id]) {
            isImageSelection = true;
        }
    });
    return isImageSelection;
};

export const willBeInView = (top, threshold = 225) => {
    const html = document.documentElement;
    return top + threshold <= (window.innerHeight || html.clientHeight);
};

export const checkThreshold = (rgbArr) => {
    const threshhold = 8;
    const rDiff = Math.abs(rgbArr[0]?.[0] - rgbArr?.[1]?.[0]) < threshhold || Math.abs(rgbArr?.[1]?.[0] - rgbArr?.[2]?.[0]) < threshhold || Math.abs(rgbArr[0]?.[0] - rgbArr?.[1]?.[0]) < threshhold;
    const gDiff = Math.abs(rgbArr[0]?.[1] - rgbArr[1]?.[1]) < threshhold || Math.abs(rgbArr?.[1]?.[1] - rgbArr?.[2]?.[1]) < threshhold || Math.abs(rgbArr[0]?.[0] - rgbArr?.[1]?.[2]) < threshhold;

    const bDiff = Math.abs(rgbArr?.[0]?.[2] - rgbArr?.[1]?.[2]) < threshhold || Math.abs(rgbArr?.[1]?.[2] - rgbArr?.[2]?.[2]) < threshhold || Math.abs(rgbArr?.[0]?.[2] - rgbArr?.[1]?.[2]) < threshhold;

    if (rDiff || gDiff || bDiff) {
        return "error";
    } else {
        return "successful";
    }
};

export function hexToRGB(hex, alpha) {
    if (hex === "#000") return [0, 0, 0];
    if (hex === "#FFF") return [255, 255, 255];
    var r = parseInt(hex.slice(1, 3), 16), g = parseInt(hex.slice(3, 5), 16), b = parseInt(hex.slice(5, 7), 16);

    return [r, g, b];
}

export function urlToS3Link(url) {
    const a = url.split("?")[0]
    const b = a.split("/")
    return ["s3:/", b[3], b[4]].join("/");
}

export async function compressImage(file) {
    const fileSizeInMB = file?.size / 1024 / 1024; // Ex': 0.4MB

    if (fileSizeInMB > 0.65) {
        const options = {
            maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            throw new Error("There was a problem uploading the image.");
        }
    } else {
        return file;
    }

}

export async function s3ToHttpLink(url) {
    const {data: publicURL} = await Client.post("emp/view/file", {
        s3Path: decodeURIComponent(url),
    });
    return publicURL;
}
