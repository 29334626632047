import {APIURL, Client} from "Client";
import {Event, Question} from "stores/Types/EditorTypes";
import {Experience} from "stores/Types/ExperienceType";

/**
 *
 * @param events   list of events added to the store
 * @param questions array of questions
 * @param experience the experience we want to post Events for
 * @param updateBgImageId the function that updates question id key in design object
 * @param changeQuestionId the function that updates question id in experience object
 * @param finalTagsDesign the final design object to be posted
 * @param selectedTagsForRemoval array of tags to be deleted
 */
export const postEvents = async (
  events: Event[],
  questions: Question[],
  experience: Experience,
  updateBgImageId: (prevId: string, newId: string) => void,
  changeQuestionId: (prevId: string, newId: string) => void,
  finalTagsDesign: {},
  selectedTagsForRemoval: any[]
) => {
  await postExperienceEvents(experience, events, questions);
  await postQuestionEvents(
    questions,
    experience,
    updateBgImageId,
    changeQuestionId,
    events
  );
  if (experience.type === "SWIPER") {
    await postSwiperEvents(finalTagsDesign, experience);
  } else if (experience.type === "TAGS_SELECTION") {
    await postTagEvents(events, selectedTagsForRemoval, experience);
  }
};

const postQuestionEvents = async (
  questions: Question[],
  experience: Experience,
  updateBgImageId: (prevId: string, newId: string) => void,
  changeQuestionId: (prevId: string, newId: string) => void,
  events: Event[],
  fromOnboarding: Boolean = false,
) => {
  let payload = [];
  questions.forEach(async (question,questionIndex) => {
    const hasCreate = events.find(
      (e) =>
        e.metadata.id == question.id &&
        e.type === "create" &&
        e.key === "question"
    );
    const hasUpdate = events.find(
      (e) =>
        (e.metadata.questionId == question.id || e.metadata?.quesIdx === questionIndex) &&
        e.type === "update" &&
        e.key === "question"
    );

    const hasDelete = events.find(
      (e) =>
        e.metadata.questionId == question.id &&
        e.type === "delete" &&
        e.key === "question"
    );

    if (hasCreate && !hasDelete) {
        payload.push({
            id: question.id,
            question: question.question,
            type:
                question.type === "IMAGE_SELECTION" ? "SELECTION" : question.type,
            answers: question.answers,
            mode: "CREATE"
        })
    }

    if (!hasCreate && hasUpdate && !hasDelete) {
     payload.push(
        {
          id: question.id,
          question: question.question,
          type: question.type === "IMAGE_SELECTION" ? "SELECTION" : question.type,
          answers: question.answers,
            mode: "UPDATE"
        }
      )
    }

  });

  const res = await Client.post(`${APIURL}/emp/experiences/${experience.id}/question`, { questions: payload });
  await Client.put(`/emp/experiences/${experience.id}/design`, {
    ...experience.design,
    questions: {
      ...experience.design.questions,
      recommendation: undefined,
    },
    questionsToAdd: questions.map((ques) => ques.id),
  });
};

const postExperienceEvents = async (
  experience: Experience,
  events: Event[],
  questions: Question[]
) => {
  const promises = [];
  const hasUpdate = events.filter(
    (e) => e.type === "update" && e.key === "experience"
  );
  const hasDesignUpdate = events.filter(
    (e) => e.type === "update" && e.key === "design"
  );
  if (hasUpdate.length) {
    promises.push(
        Client.put(`/emp/experiences/${experience.id}`, {
            name: experience.name,
            coupon: { name: experience.coupon.name, amount: experience.coupon.amount },
            locations: experience.locations
        })
    );


  }
  if (hasDesignUpdate.length) {
    promises.push(
      Client.put(`/emp/experiences/${experience.id}/design`, {
        ...experience.design,
        recommendation: undefined,
        questionsToAdd: undefined,
      })
    );
    promises.push(
      Client.post(`/emp/brand/design`, {
        recommendation: experience.design?.recommendation,
        ...(experience?.design?.fonts?.primary_font
          ? {
              fonts: {
                primary_font: experience.design.fonts.primary_font,
              },
            }
          : {}),
      })
    );
  }
  return Promise.all(promises);
};

const postSwiperEvents = async (
  finalTagsDesign: any,
  experience: Experience
) => {
  await Client.put(`/emp/experiences/${experience?.id}/design`, {
    ...experience.design,
    customTags: {
      ...experience.design.customTags,
      ...finalTagsDesign,
    },
  });
};
const postTagEvents = async (
  events: Event[],
  selectedTagsForRemoval: any[],
  experience: Experience
) => {
  const hasUpdate = events.filter(
    (e) => e.type === "update" && e.key === "tags"
  );
  const promises = [];
  if (hasUpdate.length) {
    selectedTagsForRemoval.forEach((tag) => {
      promises.push(
        Client.delete(`/emp/experiences/${experience.id}/tags/${tag.value}`)
      );
    });
    promises.push(
      Client.put(`/emp/experiences/${experience?.id}/design`, {
        ...experience.design,
      })
    );
  }
  return Promise.all(promises);
};
