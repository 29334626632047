import React, {useState} from "react";
import standard from "../../../../../assets/images/standard.webp";
import gallery from "../../../../../assets/images/gallery.webp";
import chat from "../../../../../assets/images/chat.webp";
import gradient from "../../../../../assets/images/gradient.webp";
import greekWindow from "../../../../../assets/images/greekWindow.webp";

export default function Templates() {
  const templates = {
    Standard: standard,
    Chat: chat,
    "Greek Window": greekWindow,
    Gallery: gallery,
    Gradient: gradient,
  };
  const [template,setTemplate]=useState(null)
  return (
    <div className="templates">
      {Object.keys(templates).map((key, idx) => (
        <div className="template" onClick={()=>setTemplate(idx)} style={idx===template ?{border:'2px solid #017cf8'}:{}}>
          <img src={templates[key]} alt="" />
          {key}
        </div>
      ))}
    </div>
  );
}
