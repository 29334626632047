import {ThemeProvider} from "evergreen-ui";
import useAuth from "stores/auth";
import {SkeepTheme} from "theme/skeep";
import "./App.scss";
import AppRouter from "./routes/AppRouter";
import {useEffect} from "react";
import useEditor from "./stores/use-editor";
import useCustomTags from "./stores/use-custom-tags";
import {useDesign} from "./stores/design";
import {useEditorEvents} from "./stores/use-editor-events";
import {useQuiz} from "./stores/use-quiz";
import {OpenReplay} from "./utils/OpenReplay";
import useHubSpot from "./hooks/useHubSpot";

function App() {
  const { minimized } = useAuth();
  const resetEditor = useEditor((state) => state.reset);
  const resetCustomTags = useCustomTags((state) => state.clearTags);
  const resetDesign = useDesign((state) => state.reset);
  const resetEvents = useEditorEvents((state) => state.clearEvents);
  const resetQuiz = useQuiz((state) => state.reset);
  const {subSidebar}=useAuth();
  useHubSpot();

  useEffect(() => {
    if (window.location.pathname.includes("edit")) return;
    resetEditor();
    resetCustomTags();
    resetDesign();
    resetEvents();
    resetQuiz();
  }, [window.location.pathname]);

  if (
    localStorage.hasOwnProperty("SKP_NAME") &&
    localStorage.hasOwnProperty("EMAIL") &&
    localStorage.hasOwnProperty("SKP_USERID")
  ) {
    OpenReplay.setUser(localStorage.getItem("EMAIL"));
  }

  return (
    <ThemeProvider value={SkeepTheme}>
      <div className={`App ${minimized ? "minimized" : ""} ${subSidebar?"subsidebar":""}`}>
        <AppRouter />
      </div>
    </ThemeProvider>
  );
}

export default App;
