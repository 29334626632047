import Range from "./components/Range";
import Rating from "./components/Rating";
import Selection from "./components/Selection";
import {ReactComponent as Arrow} from "../../../../../../assets/icons/arrow.svg";
import {Client} from "../../../../../../Client";
import {ReactComponent as QuesIcon} from "../../../../../../assets/icons/quesIcon.svg";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {useEffect, useRef, useState} from "react";
import TitleEditor from "../TitleEditor";
import {checkImageSelection, debounceInput,} from "../../../../../../utils/utls";
import QuesTypeSlider from "../QuesTypeSlider";
import {Pane} from "evergreen-ui";
import EditorSavePopup from "pages/EditorPage/components/EditorSavePopup/EditorSavePopup";

export default function PreviousQuestionContent({idx, forceOpen}) {
  const {
    activeQuestionIndex,
    changeActiveQuestionIndex,
    questions,
    setConnectedProductsLoading,
    experience,
    isEditing,
    setIsEditing,
    setAnswersProductsCountFromServer,
  } = useEditor();
  const [showQuestionUnsavedDialog, setShowQuestionUnsavedDialog] =
    useState(false);
  const [saveQuestion, setSaveQuestion] = useState(false);
  const {addEvents} = useEditorEvents();
  const thisQuestionIsOpen = idx === activeQuestionIndex || forceOpen;
  const titleRef = useRef();

  function getTemplate() {
    switch (questions[activeQuestionIndex]?.type) {
      case "RATING":
        // @ts-ignore
        return <Rating/>;
      case "RANGE":
        // @ts-ignore
        return <Range/>;
      case "IMAGE_SELECTION":
        return <Selection imageSelection={true}/>;
      default:
        return <Selection imageSelection={false}/>;
    }
  }

  const handleSaveQuestion = () => {
    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: questions.length - 1,
      isNew: false,
      isNotSaved: true,
    });
    changeActiveQuestionIndex(undefined);
  };

  const handleQuestionChange = async () => {
    if (forceOpen) return;
    if (!questions[idx]?.isNotSaved) {
      setConnectedProductsLoading(true);
      Client.get(
        `/emp/answers/products?experienceID=${experience?.id}&questionID=${questions[idx]?.id}`
      ).then(({data: productsCountForAnswers}) => {
        if (productsCountForAnswers)
          setAnswersProductsCountFromServer(productsCountForAnswers);
      }).then(()=>{
        setConnectedProductsLoading(false);
      });
    }

    if (activeQuestionIndex === idx)
      return changeActiveQuestionIndex(undefined);
    changeActiveQuestionIndex(idx);
    if (questions[activeQuestionIndex]?.isNew) {
      addEvents("question", "delete", {
        quesIdx: questions.length - 1,
        questionId: questions[questions.length - 1].id,
      });
    }
  };
  useEffect(() => {
    if (saveQuestion) {
      handleSaveQuestion();
      setSaveQuestion(false);
      setIsEditing(false)
      handleQuestionChange()
    }
  }, [saveQuestion]);
  useEffect(() => {
    if (thisQuestionIsOpen) {
      // titleRef?.current?.focus();
      titleRef?.current.setSelectionRange(titleRef?.current.value.length, titleRef?.current.value.length);
      titleRef?.current?.parentElement.parentElement.scrollIntoView();
    }
  }, [thisQuestionIsOpen]);

  return (
    <div className="question">
      <div
        className="title"
        onClick={() => {
          if (isEditing) {
            setShowQuestionUnsavedDialog(true);
          } else handleQuestionChange();
        }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 4,
          }}
        >
          <div
            className="quesIcon"
            style={{
              background: "#C7E3FF",
            }}
          >
            <QuesIcon fill="#017CF8"/>
          </div>
        </div>
        {/* <div>{questions[idx]?.question}</div> */}
        <TitleEditor
          ref={titleRef}
          value={questions[idx]?.question}
          onChange={debounceInput(
            (e) =>
              addEvents("question", "update", {
                questionId: questions[activeQuestionIndex].id,
                quesIdx: idx,
                question: e.target.value,
              }),
            500
          )}
          editable={activeQuestionIndex === idx}
        />
        {!forceOpen && (
          <Arrow
            style={
              activeQuestionIndex === idx ? {transform: "rotate(180deg)"} : {}
            }
            className="arrow"
          />
        )}
      </div>
      {activeQuestionIndex === idx && (
        <Pane marginLeft={"1.5em"}>
          <QuesTypeSlider
            selected={
              checkImageSelection(
                questions[activeQuestionIndex],
                experience?.design
              )
                ? "IMAGE_SELECTION"
                : "SELECTION"
            }
            onChange={(type) => {
              if (type === "IMAGE_SELECTION") {
                addEvents("question", "update", {
                  questionId: questions[activeQuestionIndex].id,
                  quesIdx: activeQuestionIndex,
                  type,
                });
                questions[activeQuestionIndex].answers.forEach((answer) => {
                  addEvents("design", "update", {
                    [`questions/${questions[activeQuestionIndex].id}/${answer.id}`]:
                      "https://skeep-public.s3.eu-central-1.amazonaws.com/selection-placeholder.png",
                  });
                });
              } else {
                addEvents("question", "update", {
                  questionId: questions[activeQuestionIndex].id,
                  quesIdx: activeQuestionIndex,
                  type,
                });
                questions[activeQuestionIndex].answers.forEach((answer) => {
                  addEvents("design", "update", {
                    [`questions/${questions[activeQuestionIndex].id}/${answer.id}`]:
                    undefined,
                  });
                });
              }
            }}
          />
        </Pane>
      )}
      {activeQuestionIndex === idx && getTemplate()}
      {showQuestionUnsavedDialog && (
        <EditorSavePopup
          from="new-question"
          saveChangesAction={() => {
            setSaveQuestion(true);
            setShowQuestionUnsavedDialog(false)
          }}
          onClickLeave={() => setShowQuestionUnsavedDialog(false)}
        />
      )}
    </div>
  );
}
