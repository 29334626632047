import CustomImageInput from "components/Forms/CustomImageInput";
import CustomTextArea from "components/Forms/CustomTextArea";
import Switch from "components/Switch/Switch";
import {Pane} from "evergreen-ui";
import {useState} from "react";

import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import uploadToS3 from "utils/uploadToS3";
import {debounceInput} from "../../../../../utils/utls";

const EmailContent = () => {
  const { addEvents } = useEditorEvents();
  const { experience, toggleEmailDesign, emailDesign, setDesign } = useEditor();
  const email = experience?.design?.email;

  const enabled =
    email?.enabled ?? (experience?.type === "QUIZ" ? "enabled" : "disabled");
  const handleTitleClick = () => {
    if (email?.enabled === "disabled") {
      return;
    } else {
      toggleEmailDesign();
    }
  };
  const DEFAULT_TERMS = `I confirm that I have read and agree to ${window.location.hostname} 
Privacy Policy`;
  const uploadImage = ({s3Url}) => {
    addEvents("design", "update", {
      [`email/image`]: s3Url,
    });
  };
  return (
    <div className="question email">
      <div className="data" >
        <>
          <Pane display="flex" alignItems="center" gap={16} marginBottom={20}>
            <div>Email Section</div>

            <Switch
              switchVal1={"enabled"}
              switchVal2={"disabled"}
              defaultValue={
                email?.enabled ??
                (experience?.type === "QUIZ" ? "enabled" : "disabled")
              }
              onChange={async (enabled) => {
                await addEvents("design", "update", {
                  [`email/enabled`]:
                    enabled === "enabled" ? "enabled" : "disabled",
                });
              }}
            />
          </Pane>
          {enabled === "enabled" && (
            <>
              <Pane display="flex" alignItems="center" gap={16}>
                <div>Skip Hyperlink</div>

                <Switch
                  switchVal1={"enabled"}
                  switchVal2={"disabled"}
                  onChange={(enabled) => {
                    addEvents("design", "update", {
                      [`email/skipHyperLink/enabled`]:
                        enabled === "enabled" ? "enabled" : "disabled",
                    });
                  }}
                  defaultValue={
                    email?.skipHyperLink?.enabled === "enabled"
                      ? "enabled"
                      : "disabled"
                  }
                />
              </Pane>

              <div className="data-holders">
                Title
                {/* @ts-ignore */}
                <CustomTextArea
                  defaultValue={email?.title}
                  charLimit={90}
                  onChange={debounceInput((e) =>
                    addEvents("design", "update", {
                      "email/title": e.target.value,
                    }), 500)
                  }
                />
              </div>
              <div className="data-holders">
                Subtitle
                {/* @ts-ignore */}
                <CustomTextArea
                  defaultValue={email?.subtitle}
                  charLimit={75}
                  onChange={debounceInput((e) =>
                    addEvents("design", "update", {
                      "email/subtitle": e.target.value,
                    }), 500)
                  }
                />
              </div>
              <div className="data-holders">
              Terms of use consent
                {/* @ts-ignore */}
                <CustomTextArea
                  defaultValue={email?.terms ?? DEFAULT_TERMS}
                  charLimit={75}
                  onChange={debounceInput((e) =>
                    addEvents("design", "update", {
                      "email/terms": e.target.value,
                    }), 500)
                  }
                />
              </div>
              <div className="data-holders">
              Terms of use URL
                {/* @ts-ignore */}
                <CustomTextArea
                  defaultValue={email?.termsUrl ?? ""}
                  charLimit={75}
                  onChange={debounceInput((e) =>
                    addEvents("design", "update", {
                      "email/termsUrl": e.target.value,
                    }), 500)
                  }
                />
              </div>
              <div
                className="data-holders"
                style={{ borderBottom: "1px solid #DBE1E7", width: "82%" }}
              >
                Button Text
                {/* @ts-ignore */}
                <CustomTextArea
                  defaultValue={email?.button?.title}
                  charLimit={25}
                  width={184}
                  onChange={debounceInput((e) =>
                    addEvents("design", "update", {
                      "email/button/title": e.target.value,
                    }), 500)
                  }
                />
              </div>
              <div>
                <CustomImageInput
                  placeHolder={"Choose an image"}
                  visibleInfo={false}
                  label="Side Image"
                  accept={"image/png"}
                  onChange={uploadImage}
                  onDelete={() => {
                    addEvents("design", "update", {
                      [`email/image`]: undefined,
                    });
                  }}
                  defaultValue={experience?.design?.email?.image}
                />
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default EmailContent;
