import {ObjectID} from "bson";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import AddTagDialog from "./Dialog/AddTagDialog";
import Questions from "./Quiz/Questions";
import { useEffect } from "react";
import delay from "../../../../utils/delay";
import {useDesign} from "../../../../stores/design";

export default function Content() {
  const { addEvents } = useEditorEvents();
  const { questions,experience,setButtonShapeTab } = useEditor();
  const design = useDesign();

  // useEffect(() => {
  //   // Add default question for non-english sites.
  //   const addDefaultQuestion = async () => {
  //     addEvents("question", "create", {type: "SELECTION", id: new ObjectID().toString()});
  //
  //     await delay(300);
  //
  //     addEvents("design", "update", {
  //       [`questions/${questions?.[0].id}/image`]: design?.product?.images?.[0],
  //     })
  //   };
  //
  //
  //   if (questions?.length === 0) {
  //     addDefaultQuestion();
  //   }
  // }, [questions?.length]);

  const handleBtnClick = () => {
    addEvents("question", "create", {
      type: "SELECTION",
      id: new ObjectID().toString(),
    });
  };
  const newQuestion = questions[questions?.length - 1];

  return (
    <>
      <div className="content">
        <button
          className="add-btn"
          onClick={handleBtnClick}
          disabled={newQuestion?.isNew}
          style={newQuestion?.isNew ? { color: "#b0b0ae" } : {}}
        >
          <span>+</span> Add question
        </button>

        <div className="questions-list">
          <Questions />
        </div>
        <AddTagDialog />
      </div>
    </>
  );
}
