import {Dialog, Pane} from "evergreen-ui";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {useTags} from "stores/use-tags";
import "./Dialog.scss";

const TagsDeleteDialog = ({ show, setShow, name, tag = {} }) => {
  const {
    activeQuestionIndex,
    deleteCustomTag,
    questions,
    experience,
  } = useEditor();
  const {    setTagsDeleteDialog,
    tagsDeleteDialog,}=useTags();
  const { addEvents } = useEditorEvents();
  return (
    <Pane>
      <Dialog
        isShown={show}
        width="440px"
        hasFooter={false}
        hasHeader={false}
        topOffset={window.innerHeight / 3}
        containerProps={{ borderRadius: "16px" }}
        contentContainerProps={{ padding: "2rem" }}
      >
        <div className="dialog-content">
          <h4>Delete </h4>
          <p>
            Please note that deleting a question or tag might affect the
            accuracy of the recommended products
          </p>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
          <button
            className="radio-button"
            onClick={(e) => {
              setTagsDeleteDialog(name);
            }}
          >
            <span
              style={{
                backgroundColor: tagsDeleteDialog.includes(name)
                  ? "#017CF8"
                  : "transparent",
              }}
            ></span>
          </button>
          <p>Don’t show this again</p>
        </div>

        <div className="container">
          <button
            onClick={() => {
              setShow(false);
            }}
            className="cancel dialog-btn"
            style={{ color: "#75787E", marginRight: "16px", cursor: "pointer", background: 'none' }}
          >
            Cancel
          </button>
          <button
            className="confirm dialog-btn"
            onClick={() => {
              if (name === "tagQuestion") {
                addEvents("design", "update", {
                  tagTypesToIgnore: [
                    ...(experience?.design?.tagTypesToIgnore ?? []),
                    questions[activeQuestionIndex].tags?.[0]?.type,
                  ],
                });
                addEvents("question", "delete", {
                  quesIdx: activeQuestionIndex,
                  questionId: questions[activeQuestionIndex].id,
                });
                setShow(false);
              } else if (name === "tagComponent") {
                deleteCustomTag(tag?.id);
                setShow(false);
              }
            }}
            style={{
              color: "#fff",
              background: "#E45447",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </Dialog>
    </Pane>
  );
};

export default TagsDeleteDialog;
