import useColorCheck from "hooks/useColorCheck";
import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import "react-color-gradient-picker/dist/index.css";
import ReactDOM from "react-dom";
import useEditor from "stores/use-editor";
import { useEditorEvents } from "stores/use-editor-events";
import {
  DESIGN_PRODUCT_KEY,
  DESIGN_RECOMMENDATION_KEY,
} from "utils/finalVariables";
import "./ColorPicker.scss";

const get = (obj, path) => path.split("/").reduce((r, k) => r?.[k], obj);

export const colorPickerDiv = document.getElementById("color-picker");

const ColorPicker = ({
  path = "/",
  item = "",
  position: { top, left },
  disableAlpha = true,
}) => {
  const {
    experience: design,
    setDesign,
    setColorError,
    colorError,
    colorHistory,
    setColorHistory,
  } = useEditor();
  const newDesign = design?.design;
  const { addEvents } = useEditorEvents();
  const value =
    typeof get(newDesign, path) === "string" ? get(newDesign, path) : "";
  const [color, setColor] = useState(value ?? "#8CCFF2");
  const [gradientColors, setGradientColors] = useState(
    value?.includes("linear")
      ? {
          start: "#" + value?.split("#")[1]?.slice(0, 8),
          end: "#" + value?.split("#")[2]?.slice(0, 8),
        }
      : { start: "#fff", end: "#fff" }
  );

  const [active, setActive] = useState("start"); // start|end
  const [activeTab, setActiveTab] = useState(
    value?.includes("linear") && item === "gradientColor" ? "gradient" : "solid"
  );
  const pathArr = path.split("/");
  pathArr.pop();
  const colorOBJ = pathArr.reduce((total, key) => {
    return total?.[key];
  }, design?.design);

  const result = useColorCheck([colorOBJ?.background, colorOBJ?.text]);

  useEffect(() => {
    if (result === "error") {
      setColorError(pathArr[pathArr.length - 1]);
    } else {
      setColorError(undefined);
    }
  }, [result]);

  //reference for opacity
  // function RGBAToHexA(r,g,b,a) {
  //   r = r.toString(16);
  //   g = g.toString(16);
  //   b = b.toString(16);
  //   a = Math.round(a * 255).toString(16);

  //   if (r.length == 1)
  //     r = "0" + r;
  //   if (g.length == 1)
  //     g = "0" + g;
  //   if (b.length == 1)
  //     b = "0" + b;
  //   if (a.length == 1)
  //     a = "0" + a;

  //   return "#" + r + g + b + a;
  // }


  useEffect(() => {
    if (colorHistory.length === 0) {
      if (newDesign?.colorHistory) {
        setColorHistory(newDesign?.colorHistory)
      } 
    }
  }, [colorHistory]);

  const handleChangeComplete = (color, e) => {
    // reference for opacity
    // console.info(RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))
    // setColor(RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))
    const rgb = color.rgb;
    const newColor = disableAlpha
      ? color.hex
      : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
    setColor(newColor);
    if (colorHistory?.length === 12) {
      const newArr = colorHistory;
      newArr.shift();
      setColorHistory([...newArr, newColor]);
      addEvents("design", "update", {
        ["colorHistory"]: [...newArr, newColor],
      });
    } else {
      setColorHistory([...colorHistory, newColor]);
      addEvents("design", "update", {
        ["colorHistory"]: [...colorHistory, newColor],
      });
    }
    if (
      path.includes(DESIGN_RECOMMENDATION_KEY) ||
      path.includes(DESIGN_PRODUCT_KEY)
    ) {
      setDesign(path, newColor);
      addEvents("design", "update", {
        [path]: newColor,
        //reference for opacity
        // [path]: RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a),
      });
      //reference for opacity
      // setDesign(path, RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a));
    } else {
      addEvents("design", "update", {
        [path]: newColor,
        //reference for opacity
        // [path]: RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a),
      });
    }

  
  };

  const handleColorHistory = (color) => {
    setColor(color);
    if (
      path.includes(DESIGN_RECOMMENDATION_KEY) ||
      path.includes(DESIGN_PRODUCT_KEY)
    ) {
      setDesign(path, color);
      addEvents("design", "update", {
        [path]: color,
      });
    } else {
      addEvents("design", "update", {
        [path]: color,
      });
    }
  };

  useEffect(() => {
    if (item === "gradientColor" && activeTab === "gradient") {
      addEvents("design", "update", {
        [path]: `linear-gradient(${gradientColors.start}, ${gradientColors.end})`,
      });
    }
  }, [gradientColors]);

  return ReactDOM.createPortal(
    <div
      style={{
        position: "absolute",
        top,
        left,
        backgroundColor: "white",
        width: item === "gradientColor" ? "250px" : 0,
        height:
          item === "gradientColor" && activeTab === "gradient"
            ? "350px"
            : item === "gradientColor" && activeTab === "solid"
            ? "320px"
            : 0,
        borderRadius: "8px",
        boxShadow: "0px 8px 24px rgba(21, 23, 46, 0.1)",
      }}
    >
      <div
        ref={(el) => {
          if (
            el &&
            el.parentElement.parentElement.clientHeight -
              el.parentElement.getBoundingClientRect().y <
              30
          ) {
            el?.parentElement?.parentElement?.scrollTo(
              0,
              el?.parentElement.getBoundingClientRect().top
            );
          }
        }}
        className="gradient-container"
        style={{
          width: item === "gradientColor" ? "225px" : null,
          right: item === "gradientColor" ? "12px" : 0,
          top: item === "gradientColor" ? "10px" : 0,
        }}
      >
        {item === "gradientColor" && (
          <div className="btn-container">
            <span
              role="button"
              className={`${activeTab === "solid" ? "active" : ""}`}
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveTab("solid");
              }}
            >
              Solid
            </span>

            <span
              role="button"
              className={`${activeTab === "gradient" ? "active" : ""}`}
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveTab("gradient");
              }}
            >
              Gradient
            </span>
          </div>
        )}

        {activeTab === "solid" && (
          <div
            style={{ position: "absolute", zIndex: 1 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="chrome-picker-parent">
              <ChromePicker
                color={color}
                
                // presetColors={solidRecentlyUsedColors}
                disableAlpha={disableAlpha}
                onChangeComplete={handleChangeComplete}
              />
              <div className="color-history">
                <p className="color-history-para">Color history</p>

                <div className="colors-inner">
                  {colorHistory?.map((color, idx) => (
                    <div
                      key={idx}
                      className="color-history-dot"
                      onClick={() => handleColorHistory(color)}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "gradient" && (
          <div
            style={{ position: "absolute", zIndex: 1 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ChromePicker
              color={
                active === "start" ? gradientColors.start : gradientColors.end
              }
              // presetColors={filteredRecentlyUsedColors}
              disableAlpha
              onChangeComplete={(color, e) => {
                e.preventDefault();
                e.stopPropagation();
                setGradientColors({ ...gradientColors, [active]: color.hex });
                //reference for opacity
                // setGradientColors({ ...gradientColors, [active]: RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a) });
              }}
            />
            <div className="color-dots">
              <div
                className="color-selector"
                style={{
                  background: gradientColors.start,
                  border:
                    active === "start" ? "1px solid black" : "1px solid #aaa",
                }}
                onClick={() => {
                  setActive("start");
                }}
              ></div>
              <div
                className="linear-gradient"
                style={{
                  background: `linear-gradient(90deg,${gradientColors.start},${gradientColors.end})`,
                }}
              ></div>
              <div
                className="color-selector"
                style={{
                  background: gradientColors.end,
                  border:
                    active === "end" ? "1px solid black" : "1px solid #aaa",
                }}
                onClick={() => setActive("end")}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>,
    colorPickerDiv
  );
};

export default ColorPicker;
