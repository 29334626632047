import "./BillingPage.scss"
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../components/Button/Button";
import CircularProgress from "../../components/CircularProgress/CircleProgress";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Client} from "Client";
import LoadingPage from "../LoadingPage/LoadingPage";

import mastercard from "../../assets/icons/mastercard.svg";
import UnsuccessfulLoadData from "components/UnsuccessfulLoadData/UnsuccessfulLoadData";

const BillingPage = () => {
    const navigate = useNavigate();
    const [billingInformation, setBillingInformation] = useState([]);
    const [loading, setLoading] = useState(true);

    const [unsuccessfulLoad, setUnsuccessfulLoad] = useState(false);

    useEffect(() => {
        init()
        setTimeout(() => setLoading(false), 3000)
    }, [])

    function init() {
        Client.get('/emp/billing').then((res) => {
            setBillingInformation(res.data)
        }).catch((error) => {
            setUnsuccessfulLoad(true)
        })
    }

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <div className="app-page">
            <div className="fa-center app-page-header">
                <h1 className="app-page-header-title">Billing</h1>
            </div>
            {loading ? <LoadingPage /> : unsuccessfulLoad ? <UnsuccessfulLoadData /> :
                <div className="app-page-content billing">
                    <div className="billing-plan">
                        <div className="billing-plan-details">
                            <p className="billing-plan-details-name">{billingInformation.plan && capitalizeFirst(billingInformation.plan)}</p>
                            <p className="billing-plan-details-subtitle">See your plan, update your billing info, and download your invoices</p>
                        </div>
                        <Button text="Change plan" type="LINK" link="/billing/plans" />
                    </div>
                    <div className="billing-headlines">
                        <div className="billing-headlines-headline">
                            <p className="billing-headlines-headline-title">Experiences</p>
                            <div style={{ display: "flex", justifyContent: "space-between", lineHeight: '3vh' }}>
                                <div className="billing-headlines-headline-data">
                                    <p style={((billingInformation.experiences / billingInformation.experiencesQuota) * 100) >= 100 ? { color: "#E45447" } : { color: "#cfc6f6" }} className="billing-headlines-headline-data-status"><b>{Math.min(billingInformation.experiences, billingInformation.experiencesQuota)}</b> /{billingInformation.experiencesQuota}</p>
                                </div>
                                <CircularProgress
                                    size={32}
                                    strokeWidth={4}
                                    percentage={billingInformation.experiences && billingInformation.experiencesQuota ? ((billingInformation.experiences / billingInformation.experiencesQuota) * 100).toFixed(2) : null}
                                    color={((billingInformation.experiences / billingInformation.experiencesQuota) * 100) >= 100 ? "#E45447" : "#cfc6f6"}
                                />
                            </div>
                        </div>
                        <div className="billing-headlines-border"></div>
                        <div className="billing-headlines-headline">
                            <p className="billing-headlines-headline-title">Replies</p>
                            <div style={{ display: "flex", justifyContent: "space-between", lineHeight: '3vh' }}>
                                <div className="billing-headlines-headline-data">
                                    <p className="billing-headlines-headline-data-status"><b>{Math.min(billingInformation.engagements, billingInformation.engagementsQuota)}</b> /{billingInformation.engagementsQuota}</p>
                                </div>
                                <CircularProgress
                                    size={32}
                                    strokeWidth={4}
                                    percentage={billingInformation.engagements && billingInformation.engagementsQuota ? ((billingInformation.engagements / billingInformation.engagementsQuota) * 100).toFixed(2) : null}
                                    color={((billingInformation.engagements / billingInformation.engagementsQuota) * 100) >= 100 ? "#E45447" : "#cfc6f6"}
                                />
                            </div>
                        </div>
                        <div className="billing-headlines-border"></div>
                        <div className="billing-headlines-headline">
                            <p className="billing-headlines-headline-title">Next invoice</p>
                            <div className="billing-headlines-headline-data">
                                <p className="billing-headlines-headline-data-status">{billingInformation.nextInvoice && new Date(billingInformation.nextInvoice * 1000).toLocaleDateString("en", {
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric",
                                })}</p>
                            </div>
                        </div>
                        <div className="billing-headlines-border"></div>
                        <div className="billing-headlines-headline">
                            <p className="billing-headlines-headline-title">{billingInformation.plan && capitalizeFirst(billingInformation.plan)} plan</p>
                            <div className="billing-headlines-headline-data">
                                <p className="billing-headlines-headline-data-status"><b>${billingInformation.price}</b> /month</p>
                            </div>
                        </div>

                    </div>
                    <div className="billing-payment">
                        <div className="billing-payment-left-container">
                            <p className="billing-payment-header">Payment Method</p>
                            <p className="billing-payment-subheader">Paid with credit card</p>
                            <div className="billing-payment-info-container">
                                <img src={mastercard} />
                                <p className="billing-payment-info-text-one">**** 1234</p>
                                <p className="billing-payment-info-link">Change your payment method</p>
                            </div>
                        </div>
                        <div className="billing-payment-right-container">
                            <p className="billing-payment-header">Billing Info</p>
                            <p className="billing-payment-subheader">Email</p>
                            <div className="billing-payment-info-container">
                                <p className="billing-payment-info-text">hello@skeep.io</p>
                                <p className="billing-payment-info-link">Change your billing info</p>
                            </div>
                        </div>
                    </div>
                    <p className="billing-invoices-header">Billing History</p>
                    <div className="billing-invoices">

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '15px', fontFamily: 'Satoshi' }}>Date</TableCell>
                                        <TableCell sx={{ fontSize: '15px', fontFamily: 'Satoshi' }}>Description</TableCell>
                                        <TableCell sx={{ fontSize: '15px', fontFamily: 'Satoshi' }}>Invoice Total</TableCell>
                                        <TableCell sx={{ fontSize: '15px', fontFamily: 'Satoshi' }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billingInformation.invoices?.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ fontSize: '14px', fontFamily: 'Satoshi', color: '#75787E' }}>{row.date && new Date(row.date * 1000).toLocaleDateString("en", {
                                                month: "short",
                                                day: "numeric",
                                                year: "numeric",
                                            })}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontFamily: 'Satoshi', color: '#75787E' }}>{row.description}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontFamily: 'Satoshi', color: '#75787E' }}>US {dollarUS.format(row.total / 100)}</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontFamily: 'Satoshi', color: '#75787E', display: 'flex', textTransform: 'capitalize', alignItems: 'flex-end' }}>
                                                {row.status}
                                                &nbsp;&nbsp;
                                                <a href={row.url} target="_blank" style={{ fontSize: '12px', color: '#017CF8', lineHeight: '2.2vh', textDecoration: 'underline', cursor: 'pointer' }}>View invoice</a></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                </div>}
        </div>
    )
}

export default BillingPage;