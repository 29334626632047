import create from "zustand";
import {devtools} from "zustand/middleware";
import {TagsState} from "./Types/EditorTypes";

export const useTags = create<TagsState>()(
  devtools(
    (set, get) => ({
      tagsDeleteDialog: [],
      showAddTagDialog: false,

      setShowAddTagDialog: (show) => {
        set({
          showAddTagDialog: show,
        });
      },
      setTagsDeleteDialog: (v) => {
        set({ tagsDeleteDialog: [...get().tagsDeleteDialog, v] });
      },
    }),
    {
      name: "tags",
    }
  )
);
