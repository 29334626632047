import Button from "components/Button/Button";
import {useEffect, useState} from "react";
import useCustomTags from "stores/use-custom-tags";
import useEditor from "stores/use-editor";
import {useEditorEvents} from "stores/use-editor-events";
import {useQuiz} from "stores/use-quiz";
import NoConnectedProductsDialog from "../../Dialog/NoConnectedProductsDialog";
import Range from "./components/Range";
import Rating from "./components/Rating";
import Selection from "./components/Selection";

export default function QuestionContent({ type = "SELECTION" }) {
  const { questions, changeActiveQuestionIndex, activeQuestionIndex, setIsEditing } =
    useEditor();
  const { addEvents } = useEditorEvents();
  const { dontShowProductDialog, setDontShowProductDialog } = useQuiz();
  const { tags } = useCustomTags();
  const [save, setSave] = useState(true);
  const [connectedProductsDialog, setConnectedProductsDialog] = useState(false);
  function getTemplate() {
    switch (type) {
      case "RATING":
        return <Rating save={save} />;
      case "RANGE":
        return <Range save={save} />;
      case "IMAGE_SELECTION":
        return <Selection save={save} imageSelection={true} />;
      default:
        return <Selection save={save} imageSelection={false} />;
    }
  }

  const checkValidation = () => {
    const boolean = questions?.[activeQuestionIndex || 0]?.answers?.every((obj) => obj?.answer);
    setSave(boolean);
  };
  useEffect(() => {
    checkValidation();
  }, [questions]);

  const handleSaveQuestion = () => {
    // const answers = questions[activeQuestionIndex].answers;
    // const conneted = answers.filter((ans) => {
    //   if (tags[ans?.tagIDs[0]]?.products?.length > 0) {
    //     return ans;
    //   }
    // });
    // if (!dontShowProductDialog && conneted.length !== answers.length) {
    //   return setConnectedProductsDialog(true);
    // }
    addEvents("question", "update", {
      questionId: questions[activeQuestionIndex].id,
      quesIdx: questions.length - 1,
      isNew: false,
      isNotSaved:true
    });
                  setIsEditing(false);

    changeActiveQuestionIndex(undefined);
  };

  return (
    <div className="sample-question">
      {getTemplate()}

      <div className="btn-container">
        {/* @ts-ignore */}
        <Button
          type={"CANCEL"}
          text={"Cancel "}
          style={{ background: "none", fontSize: "16px" }}
          onClick={() => {
            addEvents("question", "delete", {
              quesIdx: questions.length - 1,
              questionId: questions[questions.length - 1].id,
            });
            changeActiveQuestionIndex(undefined);
          }}
        />
        {/* @ts-ignore */}
        <Button
          type={"BUTTON"}
          text={"Save question "}
          disabled={!questions[activeQuestionIndex]?.answers.every((obj) => obj?.answer)}
          onClick={handleSaveQuestion}
        />
        <NoConnectedProductsDialog
          show={connectedProductsDialog}
          setShow={setConnectedProductsDialog}
        />
      </div>
    </div>
  );
}
