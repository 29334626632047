// import Email from "./Email";
// import Product from "./Product";
// import Recommendation from "./Recommendation";
import SwiperText from "./Swiper/SwiperText";
import Sentence from "./Swiper/Sentence";
import useEditor from "../../../../stores/use-editor";
import useCustomTags from "../../../../hooks/useCustomTags";
import {useSwiper} from "stores/use-swiper";
import DeleteDialog from "./Dialog/DeleteDialog";
import DeleteAnswerDialog from "./Dialog/DeleteAnswerDialog";

export default function SwiperContent() {
  const { experience } = useEditor();
  const { tagTypes } = useSwiper();
  useCustomTags();

  const filteredTagTypes = tagTypes
    ?.map((tagTypeOBJ) => {
      if (!experience?.design?.tagTypesToIgnore?.includes(tagTypeOBJ?.type))
        return tagTypeOBJ;
      return null;
    })
    ?.filter(Boolean);
  return (
    <div className="tags-content">
      <SwiperText />
      {filteredTagTypes?.map((tagTypeOBJ, idx) => (
        <Sentence key={tagTypeOBJ.id} idx={idx} tagTypeOBJ={tagTypeOBJ} />
      ))}
      <DeleteDialog />
      <DeleteAnswerDialog />
    </div>
  );
}
