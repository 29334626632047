export { QUIZ } from "./quiz";
export { TAGS } from "./tags";
export { SWIPER } from "./swiper";
export const PRODUCT = {
  productName: {
   font: "font",    
    text: "color",
  },
  productDescription: {
    font: "font",
    text: "color",
  },
  tags: {
    background: "color",
    border: "color",
    text: "color",
  },
  bottomBar: {
    background: "color",
    text: "color",
  },
  cart: {
    background: "color",
    text: "color",
  },
  coupon: {
    background: "color",
    text: "color",
  },
  section: {
    background: "color",
    text: "color",
    elementBackground: "color",
    elementText: "color",
  },
};
export const RECOMMENDATION = {
  tags: {
    background: "color",
    border: "color",
    text: "color",
  },
  cart: {
    background: "color",
    text: "color",
  },
};
export const EMAIL = {
  modal: {
    background: "color",
    border: null,
    text: "color",
  },
  emailField: {
    background: "color",

    border: "color",
    text: "color",
  },
  button: {
    background: "color",
  
    border: "color",
    text: "color",
  },
  skipHyperlink: {
    background: undefined,
    border: undefined,
    text: "color",
  },
};

export const SWIPERINTRO = {
  productName: {
    text: "color",
  },
  page: {
    background: "color",
    border: undefined,
    text: "color",
  },
  cards: {
    background: "color",
    border: "color",
    text: undefined,
  },
  ctaButton: {
    background: "color",
    border: "color",
    text: "color",
  },
  yesButton: {
    background: "color",
    border: "color",
    text: "color",
  },
  noButton: {
    background: undefined,
    border: undefined,
    text: "color",
  },
  hint: {
    background: undefined,
    border: undefined,
    text: "color",
  },
};


export const QUIZINTRO = {
  page: {
    background: "color",
    border: undefined,
    text: "color",
  },
  animation: {
    background: "color",
    border: "color",
    text: "color",
  },
  ctaButton: {
    background: "color",
    border: "color",
    text: "color",
  }
};