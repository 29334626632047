import React, {useEffect, useState} from "react";

const CircularProgress = ({ size, strokeWidth, percentage, color }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;
  percentage = Math.min(100, percentage)

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', }}>
        <p style={{ marginRight: '10px', lineHeight: '3vh', fontSize: '12px', color: '#75787E' }}>{percentage}%</p>
        <svg width={size} height={size} viewBox={viewBox}>
          <circle
            fill="none"
            stroke="#DBE1E7"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
          />
            <circle
            fill="none"
            stroke={color}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[dash, circumference - dash]}
            strokeLinecap="round"
            style={{ transition: "all 0.5s" }}
          />
        </svg>
    </div>
  );
};

export default CircularProgress;