import {RECOMMENDATION} from "./default";
import RecommendationDesign from "./RecommendationDesign";

const ResultsDesign = () => {
  return (
    <div className="question">
      <div className="data">
        <div className="data-holders ">
          <RecommendationDesign type={RECOMMENDATION} prefix="product" />
        </div>
      </div>
    </div>
  );
};

export default ResultsDesign;
