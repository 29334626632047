import "./DatePicker.scss";
import React, {useState} from "react";
import moment from "moment";
import classes from "../../styles/OverviewPage.module.css";
import {MdArrowBackIos as Arrow} from "react-icons/md";
import {FaRegCalendarAlt} from "react-icons/fa";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {DayPickerRangeController} from "react-dates";
import Button from "../Button/Button";

function DatePicker({onChangeDate}) {
    const [activeItemIndex, setActiveItemIndex] = useState(2);
    const [isDatePickerRangeOpen, setIsDatePickerRangeOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dateRange, setDateRange] = useState({startDate: moment().subtract(30, 'days'), endDate: moment()});
    const [focusedInput, setFocusedInput] = useState("startDate");
    const menu = ["Today", "Last 7 Days", "Last 30 days", "This Month", "Custom Range"];

    const onClickItem = (e,itemName, index) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveItemIndex(index);
        if (itemName === "Today") {
            setIsDatePickerRangeOpen(false);
            setIsMenuOpen(false);
            onChangeDate({
                start: moment().toDate(),
                end: moment().startOf('date'),
                previousPeriod: {
                    start: moment().subtract(1, 'days').toDate(),
                    end: moment().subtract(1, 'days').startOf('date').toDate()
                }
            });
        }

        if (itemName === "Last 7 Days") {
            setIsDatePickerRangeOpen(false);
            setIsMenuOpen(false);
            onChangeDate({
                start: moment().toDate(),
                end: moment().subtract(1, 'week'),
                previousPeriod: {
                    start: moment().subtract(2, 'week').toDate(),
                    end: moment().subtract(3, 'week').toDate()
                }
            });
        }

        if (itemName === "Last 30 days") {
            setIsDatePickerRangeOpen(false);
            setIsMenuOpen(false);
            onChangeDate({
                start: moment().toDate(),
                end: moment().subtract(30, 'days'),
                previousPeriod: {
                    start: moment().subtract(30, 'days').toDate(),
                    end: moment().subtract(60, 'days').toDate()
                }
            });
        }

        if (itemName === "This Month") {
            setIsDatePickerRangeOpen(false);
            setIsMenuOpen(false);
            onChangeDate({
                start: moment().toDate(),
                end: moment().startOf('month'),
                previousPeriod: {
                    start: moment().subtract(30, 'days').toDate(),
                    end: moment().subtract(30, 'days').startOf('month').toDate()
                }
            });
        }

        if (itemName === "Custom Range") {
            setIsDatePickerRangeOpen(true);
        }
    };

  const onClickApply = () => {
    onChangeDate({ start: dateRange.startDate, end: dateRange.endDate });
    setIsMenuOpen(false);
  };

  const onClickCancel = () => {
    setIsDatePickerRangeOpen(false);
    setActiveItemIndex(1);
    setIsMenuOpen(false);
    onChangeDate({
      start: moment().toDate(),
      end: moment().subtract(1, "week"),
    });
  };

  const buttonText =
    menu[activeItemIndex] === "Custom Range"
      ? `${dateRange.startDate?.format("DD/MM") || ""} - ${
          dateRange.endDate?.format("DD/MM") || ""
        }`
      : menu[activeItemIndex];

  return (
    <button
      className={classes.filterRightButton}
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <FaRegCalendarAlt size={20} color="#017CF8" />
      <p className={classes.filterRightName}>{buttonText}</p>

      {isMenuOpen && (
        <div className="datepicker-container">
          <div className="datepicker-list-container">
            {menu.map((itemName, index) => (
              <div
                key={index}
                className="datepicker-list-item"
                id={activeItemIndex === index && "datepicker-list-item-active"}
                onClick={(e) => onClickItem(e, itemName, index)}
              >
                {itemName}
              </div>
            ))}
          </div>
          {isDatePickerRangeOpen && (
            <div className="datepicker-calander-container" >
              <DayPickerRangeController
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onDatesChange={(dates) => setDateRange(dates)}
                focusedInput={focusedInput || "startDate"}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                numberOfMonths={2}
                navPrev={<Arrow  className="datepicker-navigation-arrow-prev"/>}
                navNext={<Arrow className="datepicker-navigation-arrow-next" />}
                hideKeyboardShortcutsPanel
              />
              <div className="fa-center j-between datepicker-info-container" >
                <div>
                  <p className="datepicker-info-subtitle">Selected</p>
                  <div className="fa-center">
                    <p className="datepicker-info-dates">
                      {dateRange.startDate?.format("MMM D, YYYY")}
                    </p>
                    <p
                      className="datepicker-info-dates"
                      style={{ margin: "0px 5px" }}
                    >
                      {" "}
                      -{" "}
                    </p>
                    <p className="datepicker-info-dates">
                      {dateRange.endDate?.format("MMM D, YYYY")}
                    </p>
                  </div>
                </div>
                <div className="datepicker-button-container">
                  <Button text="Cancel"  onClick={onClickCancel} />
                  <Button
                    type="BUTTON"
                    text="Apply"
                    style={{
                      marginLeft: 16,
                      minWidth: "unset",
                      padding: "0 16px",
                    }}
                    onClick={onClickApply}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </button>
  );
}

export default DatePicker;
