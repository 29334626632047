import axios from "axios";
import axiosRetry from 'axios-retry';

export const DEV = process.env.REACT_APP_DEV === "1";
const currentDomain = window.location.hostname;
export const APIURL =
    currentDomain === "emp.skeep.io"
        ? "https://a.skeep.io/skeep/v1"
        : currentDomain === "emp-dev.skeep.io" || DEV
            ? "https://a-dev.skeep.io/skeep/v1"
            : "http://localhost:8080/skeep/v1";
export const Client = axios.create({
  baseURL: APIURL,
  timeout: 60000,
});
axiosRetry(Client, { retries: 3 });

export const GuestClient = axios.create({
  baseURL: APIURL,
  timeout: 60000,
});

Client.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem("SKP_JWT");
  return config;
});

Client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("SKP_JWT");
      window.location.href = "/login";
    } else {
      throw error;
    }
  }
);
