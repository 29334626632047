import {Client} from "../Client";

const saveExp = async (editExpOBJ,originalExpOBJ) => {
    const parentID = editExpOBJ?.parentID || originalExpOBJ?.locations?.[0]?.settings?.parentID || "quiz";
    const popup = ["POPUP", "MANUAL_POPUP"].includes(editExpOBJ?.implementationType || originalExpOBJ?.locations?.[0]?.implementationType);
    const embedded = !["POPUP", "MANUAL_POPUP"].includes(editExpOBJ?.implementationType);

    return Client.put(`emp/experiences/${originalExpOBJ?.id}`, {
        name: editExpOBJ?.name || originalExpOBJ?.name,
        locations: [
            {
                path: editExpOBJ.path || originalExpOBJ.locations[0].path,
                implementationType:
                    editExpOBJ.implementationType ||
                    originalExpOBJ.locations[0].implementationType,
                settings: {
                    parentID: embedded ? parentID : null,
                    delay: embedded ? null : editExpOBJ?.delay ?? (originalExpOBJ?.locations?.[0]?.settings?.delay || popup) ? "3" : null,
                },
            },
        ],
        coupon: {
            name: editExpOBJ?.couponName || originalExpOBJ.coupon.name,
            amount: editExpOBJ?.couponAmount || originalExpOBJ.coupon.amount,
        },
    })
}

export default saveExp;