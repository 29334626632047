/* eslint-disable jsx-a11y/alt-text */
import {Button, Dialog, DotIcon, majorScale, minorScale, Pane, Text,} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import Slide1 from "../../../../assets/images/guidence-popup/slide-1.svg";
import Slide2 from "../../../../assets/images/guidence-popup/slide-7.png";
import Slide3 from "../../../../assets/images/guidence-popup/slide-6.png";
import Slide4 from "../../../../assets/images/guidence-popup/slide-5.svg";

const TOTAL_SLIDES = 4;
const SLIDES = [
  {
    text: "Our AI scans your product pages and generates tags from the product information",
    image: Slide1,
  },
  {
    text: "Tags are connected to each answer so that we would be able to recommend the right product according to the user’s choices",
    image: Slide2,
  },
  {
    text: "Each answer should contain at least one tag that exists on at least one product",
    image: Slide3,
  },

  {
    text: "You can add new tags to any of the answers.  Every new tag needs to be connected to at least one product so that our AI will use it",
    image: Slide4,
  },
];

function GuidencePopup({ show, setShow }) {
  const [active, setActive] = useState(0);
  useEffect(() => {
    return () => {
      setActive(0);
    };
  }, [show]);
  return (
    <div style={{ maxHeight: "533px" }} className="conatiner">
      <Dialog
        isShown={show}
        onCloseComplete={() => {
          setShow(false);
          localStorage.setItem("SKP_SHOW_TAGS_GUIDANCE", "yes");
        }}
        header={
          <div style={{ display: "flex", gap: "16", flexDirection: "column" }}>
            <div>
              {Array(TOTAL_SLIDES)
                .fill(0)
                .flatMap((_, i) => (
                  <DotIcon
                    cursor="pointer"
                    onClick={() => {
                      setActive(i);
                    }}
                    size={majorScale(3)}
                    marginLeft={-minorScale(1)}
                    color={i === active ? "#017CF8" : "#F3F5F7"}
                    key={i}
                  />
                ))}
            </div>
            <Text fontSize={24} fontWeight={500} marginTop={23}>
              What is it?
            </Text>
            <Button
              onClick={() => {
                setShow(false);
              }}
              appearance="minimal"
              position="absolute"
              top={majorScale(2)}
              fontSize={majorScale(3)}
              right={majorScale(2)}
            >
              &times;
            </Button>
          </div>
        }
        hasFooter={false}
      >
        <Pane
          display="flex"
          height={320}
          flexDirection="column"
          alignItems="center"
        >
          <img
            style={{
              flex: 1,
              objectFit: "contain",
              width: "350.38px",
              height: "214.75px",
            }}
            src={SLIDES[active].image}
          />

          <Text
            fontSize={18}
            fontWeight={400}
            marginTop={majorScale(2)}
            textAlign="center"
            color="#040404"
          >
            {SLIDES[active].text}
          </Text>
        </Pane>
        <Pane marginY={25} display="flex" justifyContent="flex-end" gap={16}>
          {active > 0 && (
            <Button
              size="large"
              appearance="minimal"
              onClick={() => {
                setActive((prev) => prev - 1);
              }}
            >
              {"Back"}
            </Button>
          )}
          <Button
            className="popup-primary-button"
            size="large"
            fontSize={majorScale(2)}
            fontWeight="lighter"
            background="#017CF8"
            appearance="primary"
            autoFocus
            paddingX={40}
            onClick={() => {
              active === SLIDES.length - 1
                ? setShow(false)
                : setActive((prev) => prev + 1);
            }}
          >
            {active === SLIDES.length - 1 ? "Got it!" : "Next"}
          </Button>
        </Pane>
      </Dialog>
    </div>
  );
}

export default GuidencePopup;
